import React from 'react';
import Form from '../Form';
import AddressString from '../DealerLocator/AddressString';

export interface IOfficeI18nProps {
	phone?: string;
}

export interface IOfficeProps {
	name?: string;
	streetAddress?: string;
	city?: string;
	province?: string;
	postalCode?: string;
	phone?: string;
	formTitle?: string;
	formID?: number;
	/**
	 * @noUI
	 */
	children?: any;
	i18n?: IOfficeI18nProps;
}

const Office: React.FC<IOfficeProps> = ({
	name,
	streetAddress,
	city,
	province,
	postalCode,
	phone,
	formTitle,
	formID,
	children,
	i18n,
}) => {
	return (
		<div className="flex flex-col gap-4 px-4 py-6 lg:flex-row lg:items-start lg:justify-between lg:pt-20">
			<div className="lg:w-3/12">
				{name && <h3 className="mb-4 hidden lg:block">{name}</h3>}
				{(streetAddress || city || province) && (
					<p className="text-base md:text-xl">
						<AddressString
							streetAddress={streetAddress}
							city={city}
							province={province}
							postalCode={postalCode}
						/>
					</p>
				)}
				{phone && (
					<span className="font-bodyBold">
						{i18n?.phone || 'Customer Service Inquiries: '}
						<br />
						<a
							href={`tel:${phone}`}
							className="font-body text-primary hover:underline focus:underline"
						>
							{phone}
						</a>
					</span>
				)}
			</div>
			<div className="lg:w-8/12">
				{formTitle && (
					<h3 className="mb-4 lg:text-center">{formTitle}</h3>
				)}
				{formID && children && (
					<Form children={children} theme="light" />
				)}
			</div>
		</div>
	);
};

export default Office;
