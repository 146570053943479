import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { range } from 'lodash';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import CarouselItem from 'tbk-components/src/components/CarouselItem';
import { BgColor } from 'tbk-components/src/components/Core/colors';
import Image, { IImage } from 'tbk-components/src/components/Image';
import AdvancedMedia from '../AdvancedMedia';

export interface ICustomCarouselI18n {
	previous: string;
	next: string;
	goToSlide: string;
}
export interface ICustomCarouselWithImagePreviewsProps
	extends IBaseElementProps {
	showArrows?: boolean;
	showImageBullets?: boolean;
	perView?: number;
	startAt?: number;
	children?: any;
	backgroundColor?: BgColor;
	backgroundImage?: IImage;
	textColor?: 'light' | 'dark';
	//This is assuming the Children is images. This is not "synced" with the children prop and it expects the images.length to match the # of slides
	imagePreviews?: IImage[];
	i18n?: ICustomCarouselI18n;
	/**
	 * @noUI
	 */
	options?: { [x: string]: any };
	/**
	 * @noUI
	 */
	onMount?: (carousel: any, ref: HTMLDivElement) => void;
	id?: string;
}
const CustomCarouselWithImagePreviews: React.FC<
	ICustomCarouselWithImagePreviewsProps
> = ({
	children,
	className,
	classNames = [],
	showArrows = true,
	showImageBullets = true,
	perView = 1,
	i18n,
	startAt = 0,
	imagePreviews = [],
	backgroundColor = 'transparent',
	backgroundImage,
	textColor,
	options = {},
	onMount,
	id = 'carousel-component',
}) => {
	const [numSlides, setNumSlides] = useState(0);
	const ref = useRef(null);

	useEffect(() => {
		// @ts-ignore - can't find types even though types are installed
		import('@glidejs/glide/dist/glide.esm').then(({ default: Glide }) => {
			if (!ref.current) {
				return;
			}

			const carousel = ref.current! as HTMLDivElement;

			const totalSlides =
				carousel.querySelectorAll('.glide__slide').length;
			setNumSlides(totalSlides);

			if (carousel.dataset.initialized === 'true' || numSlides < 1) {
				// either already initialized or not ready with numSlides yet.
				return;
			}

			const glide = new Glide(`#${id}`, {
				type: 'carousel',
				startAt,
				perView,
				...options,
			});

			glide.on('move', () => {
				carousel.classList.add('moving');
			});

			glide.on('move.after', () => {
				carousel.classList.remove('moving');
			});

			const mountCarousel = () => {
				if (
					carousel.dataset.initialized === 'true' ||
					totalSlides < 1
				) {
					return;
				}
				carousel.dataset.initialized = 'true';
				glide.mount();
				onMount && onMount(glide, ref.current!);
			};

			// Initialize inside tabs
			const tabPane = carousel.closest('.tab-pane');
			if (tabPane !== null && !tabPane.classList.contains('active')) {
				const button = document.querySelector(`#${tabPane.id}-tab`);
				button &&
					button!.addEventListener('shown.bs.tab', mountCarousel);
			} else {
				mountCarousel();
			}
		});
	}, [startAt, perView, id, numSlides]);

	return (
		<div
			className={
				className ||
				cn(
					'glide',
					'carousel',
					`carousel--items-${numSlides}`,
					`bg-${backgroundColor}`,
					!textColor && backgroundColor === 'dark' && `text-light`,
					textColor && `text-${textColor}`,
					numSlides > 0 && 'pb-4',
					backgroundImage && 'has-background',
					...classNames,
				)
			}
			style={
				backgroundImage && {
					backgroundImage: `url('${backgroundImage.src}')`,
				}
			}
			ref={ref}
			id={id}
		>
			<div className="glide__track" data-glide-el="track">
				<ul className="glide__slides">{children}</ul>
			</div>

			{showArrows && children && (
				<div className="glide__arrows" data-glide-el="controls">
					<button
						className="btn-default glide__arrow glide__arrow--left btn"
						data-glide-dir="<"
						aria-label={i18n?.previous ?? 'Previous'}
					>
						<i className="icon icon-chevron-left" />
					</button>
					<button
						className="btn-default glide__arrow glide__arrow--right btn"
						data-glide-dir=">"
						aria-label={i18n?.next ?? 'Next'}
					>
						<i className="icon icon-chevron-right" />
					</button>
				</div>
			)}

			{showImageBullets && imagePreviews.length > 0 && (
				<div
					className="glide__bullets !mt-5 flex flex-wrap !justify-start gap-4"
					data-glide-el="controls[nav]"
				>
					{imagePreviews.map((image, index) => (
						<button
							key={index}
							className="glide__bullet glide__bullet--w-image group block aspect-[1/1] h-auto !w-full w-full max-w-[80px] overflow-hidden rounded-md transition-opacity"
							data-glide-dir={`=${index}`}
							aria-label={
								i18n?.goToSlide
									? `${i18n?.goToSlide} ${index + 1}`
									: `Go to slide ${index + 1}`
							}
						>
							<AdvancedMedia
								size="1/1"
								rounded
								image={image}
								classNames={[
									'!h-full !w-full object-cover transition-transform group-hover:scale-105',
								]}
							/>
						</button>
					))}
				</div>
			)}
		</div>
	);
};
export { CarouselItem };
export default CustomCarouselWithImagePreviews;
