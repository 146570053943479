import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { IImage } from 'tbk-components/src/components/Image';

export interface IImageToSVGProps extends IBaseElementProps {
	image?: IImage;
	/**
	 * Remove the width and height attributes from the SVG element.
	 */
	removeSizes?: boolean;
	strokeWidth?: number;
	onLoad?: () => void;
}

const ImageToSVG: React.FC<IImageToSVGProps> = ({
	id,
	className,
	classNames = [],
	image,
	removeSizes = false,
	strokeWidth,
	onLoad,
}) => {
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!image) return;

		/**
		 * Loading external SVG files and render them as SVG elements.
		 */
		const loadAndConvertSVG = async () => {
			const response = await fetch(image.src);
			const blob = await response.blob();
			const reader = new FileReader();

			reader.onload = () => {
				const svg = reader.result;

				if (!svg) return;

				const div = document.createElement('div');

				div.innerHTML = svg as string;

				const svgEl = div.querySelector('svg');

				if (!svgEl) return;

				svgEl.classList.add('max-w-full', 'max-h-full');

				if (removeSizes) {
					svgEl.removeAttribute('width');
					svgEl.removeAttribute('height');
				}
				/**
				 * Here we add the `!fill-current` and `!stroke-current` class to the SVG path element.
				 * These classes set the fill and stroke color to the current text color.
				 */
				const paths = div.querySelectorAll(
					'svg path',
				) as NodeListOf<SVGPathElement>;

				paths.forEach((path) => {
					/**
					 * Apply classes only if associated attr or style has a valid value.
					 */
					const fillColor =
						path.getAttribute('fill') ?? path.style.fill;
					const strokeColor =
						path.getAttribute('stroke') ?? path.style.stroke;

					if (fillColor !== '' && fillColor !== 'none') {
						path.classList.add('!fill-current');
					}

					if (strokeColor !== '' && strokeColor !== 'none') {
						path.classList.add('!stroke-current');
					}

					if (strokeWidth) {
						path.style.strokeWidth = `${strokeWidth}px`;
					}
				});

				ref.current?.appendChild(svgEl as Node);
			};
			reader.readAsText(blob);

			if (onLoad) onLoad();
		};

		loadAndConvertSVG();
	}, []);

	return (
		<div
			id={id}
			className={
				className ||
				cn(
					'svg__container flex items-center justify-center',
					...classNames,
				)
			}
			ref={ref}
		/>
	);
};

export default ImageToSVG;
