import React, { useEffect, useState } from 'react';
import { JSONLD } from 'react-structured-data';

export interface ILazyJSONLDProps {
	children: any;
}

/**
 * The JSONLD component puts out a <script> tag which Cloudflare blocks on production.
 *
 * This wrapper component only puts out the <script> tag if the component is mounted.
 *
 * See https://gitlab.tbkdev.com/tbk-devs/calian.com/-/issues/3763
 *
 */
const LazyJSONLD: React.FC<ILazyJSONLDProps> = ({ children }) => {
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		setIsMounted(true);
	}, []);

	return isMounted ? <JSONLD>{children}</JSONLD> : null;
};

export default LazyJSONLD;