import React from 'react';
import kebabCase from 'lodash/kebabCase';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Icon from '../Icon';
export interface IAvailableIconOptions {
	fuzionIconSelectValues?:
		| 'link'
		| 'rows'
		| 'map-tri-fold'
		| 'calendar-dots'
		| 'calendar-plus'
		| 'chat-teardrop'
		| 'map-pin'
		| 'filters'
		| 'caret-double-left'
		| 'caret-double-right'
		| 'question-mark'
		| 'blog'
		| 'download'
		| 'eye'
		| 'eye-slash'
		| 'play'
		| 'pause'
		| 'stars'
		| 'chevron-up'
		| 'chevron-down'
		| 'storefront'
		| 'caret-down'
		| 'caret-up'
		| 'close'
		| 'user'
		| 'shopping-cart'
		| 'search'
		| 'menu'
		| 'tbk-logo'
		| 'check'
		| 'minus'
		| 'plus'
		| 'chevron-left'
		| 'chevron-right'
		| 'instagram'
		| 'linkedin'
		| 'youtube'
		| 'facebook'
		| 'twitter'
		| 'filters'
		| 'calendar-plus'
		| 'calender-dots'
		| 'chat-teardrop';
}

export interface IIconSampleItem {
	iconName?: string;
}

export interface IIconSamplesProps extends IBaseElementProps {}

const IconSamples: React.FC<IIconSamplesProps> = ({
	className,
	classNames = [],
}) => {
	const fuzionIconSelectValues = [
		'link',
		'rows',
		'map-tri-fold',
		'calendar-dots',
		'calendar-plus',
		'chat-teardrop',
		'map-pin',
		'filters',
		'caret-double-left',
		'caret-double-right',
		'question-mark',
		'blog',
		'download',
		'eye',
		'eye-slash',
		'play',
		'pause',
		'stars',
		'chevron-up',
		'chevron-down',
		'storefront',
		'caret-down',
		'caret-up',
		'close',
		'user',
		'shopping-cart',
		'search',
		'menu',
		'tbk-logo',
		'check',
		'minus',
		'plus',
		'chevron-left',
		'chevron-right',
		'instagram',
		'linkedin',
		'youtube',
		'facebook',
		'twitter',
		'filters',
		'calendar-plus',
		'calender-dots',
		'chat-teardrop',
		'question-mark',
		'blog',
		'download',
		'eye',
		'eye-slash',
		'play',
		'pause',
		'stars',
		'caret-double-right',
		'caret-double-left',
	];

	return (
		<div className={className || cn('calian-icon-samples', ...classNames)}>
			<div className="container-narrow my-5">
				<div className="row">
					<div className="col-12">
						<h2 className="text-center">Icons</h2>
						<div className="container-fluid my-5 text-center">
							<div className="row my-5">
								{fuzionIconSelectValues.map(
									(iconName, index) => (
										<p className="lead" key={index}>
											{iconName} (classname: icon-
											{kebabCase(iconName)}):{' '}
											<Icon name={iconName}></Icon>
										</p>
									),
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default IconSamples;
