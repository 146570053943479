import React from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import cn from 'classnames';
import Breadcrumb from '../Breadcrumb';
import { ILink } from 'tbk-components/src/components/Button';
import TriangleImage from '../TriangleImage';
import { IImage } from 'tbk-components/src/components/Image';
import ContentSection from '../ContentSection';
import { IFuzionButtonProps } from '../Button';
import { Generic } from 'react-structured-data';
import LazyJSONLD from '../LazyJSONLD';

export interface IPageBannerProps extends IBaseElementProps {
	title?: string;
	/**
	 * Text
	 * @control richtext
	 */
	lead?: string;
	buttons?: IFuzionButtonProps[];
	sectionClassNames?: string[];
	titleClassName?: string;
	leadClassName?: string;
	buttonsClassName?: string;
	automaticBreadcrumbs?: boolean;
	breadcrumbs?: ILink[];
	layout?: 'Hero' | 'Hero Upside-Down';
	image?: IImage;
	disableImageOnMobile?: boolean;
	hasStickyHeader?: boolean;
}

/**
 * Page Banner
 * @block
 * @icon align-full-width
 */
const PageBanner: React.FC<IPageBannerProps> = ({
	id,
	className,
	classNames = ['bg-gradient-rainbow'],
	title,
	lead,
	buttons,
	sectionClassNames = [],
	titleClassName = 'h1-small',
	leadClassName,
	buttonsClassName,
	breadcrumbs = [],
	layout = 'Hero',
	hasStickyHeader = false,
	image,
	disableImageOnMobile = false,
}) => {
	return (
		<div
			id={id}
			className={
				className ||
				cn(
					'hero-banner__container lg:relative lg:flex lg:items-center',
					hasStickyHeader && 'pt-20 md:pt-30',
					...classNames,
				)
			}
		>
			<div className="container p-4 pt-10 md:px-8">
				<ContentSection
					title={title}
					titleElement={'h1'}
					lead={lead}
					buttons={buttons}
					classNames={[
						`!block lg:!flex lg:min-h-[250px] lg:max-w-[40%] lg:items-start lg:justify-center 2xl:max-w-[50%] ${layout === 'Hero Upside-Down' && 'lg:ml-auto lg:mr-0'}`,
						...sectionClassNames,
					]}
					titleClassName={titleClassName}
					leadClassName={leadClassName}
					buttonsClassName={buttonsClassName}
				/>
				{breadcrumbs && (
					<>
					<Breadcrumb classNames={['mt-10']} links={breadcrumbs} />
					<LazyJSONLD>
						<Generic
							type="page"
							jsonldtype="BreadcrumbList"
							schema={{ '@type': 'BreadcrumbList',
								'itemListElement': breadcrumbs.map((breadcrumb, index) => ({
									'@type': 'ListItem',
									'position': index + 1,
									'name': breadcrumb.label,
									'item': breadcrumb.href,
								})),
							}}
						/>
					</LazyJSONLD>
					</>
				)}
			</div>
			{image && (
				<TriangleImage
					image={image}
					mask={layout}
					classNames={[
						'hidden lg:flex h-full max-h-[360px] relative justify-start lg:absolute',
						layout === 'Hero' &&
							'mr-0 ml-auto lg:right-0 lg:bottom-0',
						layout === 'Hero Upside-Down' && 'lg:left-0 lg:top-0',
						disableImageOnMobile && 'hidden md:block',
					]}
				/>
			)}
		</div>
	);
};

export default PageBanner;
