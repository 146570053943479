import React from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import cn from 'classnames';
import Breadcrumb from '../Breadcrumb';
import { ILink } from 'tbk-components/src/components/Button';
import { IImage } from 'tbk-components/src/components/Image';
import Button, { IFuzionButtonProps } from '../Button';

import ProductTabs from './ProductTabs';
import ProductTab from './ProductTab';

//Tab Imports
import FeaturesTab, { IFeaturesTabProps } from './tabs/FeaturesTab';
import DownloadsTab, { IDownloadsTabProps } from './tabs/DownloadsTab';
import CommercialTab, { ICommercialTabProps } from './tabs/CommercialTab';
import AccessoriesTab, { IAccessoriesTabProps } from './tabs/AccessoriesTab';
import { Generic } from 'react-structured-data';
import LazyJSONLD from '../LazyJSONLD';

export interface ISimpleProduct {
	image?: IImage;
	title?: string;
	subheading?: string;
	cta?: ILink;
}

export interface IProductDetailTabsProps extends IBaseElementProps {
	breadcrumbs?: ILink[];
	title?: string;
	asideButtons?: IFuzionButtonProps[];
	featuresTab?: IFeaturesTabProps;
	downloadsTab?: IDownloadsTabProps;
	accessoriesTab?: IAccessoriesTabProps;
	commercialTab?: ICommercialTabProps;
}

/**
 * Product Details Tab
 * @block
 * @icon align-full-width
 */
const ProductDetailTabs: React.FC<IProductDetailTabsProps> = ({
	id,
	className,
	classNames = [],
	title,
	breadcrumbs = [],
	asideButtons = [],
	featuresTab,
	downloadsTab,
	accessoriesTab,
	commercialTab,
}) => {
	return (
		<div
			id={id}
			className={
				className ||
				cn(
					'product-details-tabs bg-white px-5 py-20 md:px-6 md:py-30',
					...classNames,
				)
			}
		>
			<div className="product-details-tabs--container mx-auto max-w-[1376px]">
				<div className="product-details-tabs--header flex flex-col gap-5 lg:pl-6">
					<Breadcrumb links={breadcrumbs} />
					<LazyJSONLD>
						<Generic
							type="page"
							jsonldtype="BreadcrumbList"
							schema={{ '@type': 'BreadcrumbList',
								'itemListElement': breadcrumbs.map((breadcrumb, index) => ({
									'@type': 'ListItem',
									'position': index + 1,
									'name': breadcrumb.label,
									'item': breadcrumb.href,
								})),
							}}
						/>
					</LazyJSONLD>
					<h2 className="product-details-tabs--title mb-0">
						{title}
					</h2>
				</div>
				<div className="product-details-tabs-container mt-16 flex flex-col items-start justify-between gap-6 lg:flex-row">
					<div className="tabs-wrapper w-full lg:max-w-[1024px]">
						<ProductTabs>
							{featuresTab && (
								<ProductTab
									title={featuresTab?.tabLabel || 'Features'}
									classNames={['pt-20']}
								>
									<FeaturesTab {...featuresTab} />
								</ProductTab>
							)}
							{downloadsTab && (
								<ProductTab
									title={
										downloadsTab?.tabLabel || 'Downloads'
									}
									classNames={['pt-20']}
								>
									<DownloadsTab {...downloadsTab} />
								</ProductTab>
							)}
							{accessoriesTab && (
								<ProductTab
									title={
										accessoriesTab?.tabLabel ||
										'Accessories'
									}
									classNames={['pt-20']}
								>
									<AccessoriesTab {...accessoriesTab} />
								</ProductTab>
							)}
							{commercialTab && (
								<ProductTab
									title={
										commercialTab?.tabLabel || 'Commercial'
									}
									classNames={['pt-20']}
								>
									<CommercialTab {...commercialTab} />
								</ProductTab>
							)}
						</ProductTabs>
					</div>
					{asideButtons?.length > 0 && (
						<div className="flex flex-wrap gap-3 border-t border-tertiary pt-10 md:border-t-0 md:pt-12 lg:max-w-[260px] lg:justify-end">
							{asideButtons?.map((button, index) => (
								<Button
									key={index}
									{...button}
									classNames={['lg:flex-grow']}
								/>
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ProductDetailTabs;
