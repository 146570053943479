import React from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { IImage } from 'tbk-components/src/components/Image';
import { colorPalette } from '../../../../tailwind-colors';
import cn from 'classnames';
import uniqueIdHash from '../../../hooks/generateUniqueIdHash';
export interface IWhyFuzionTriangleMaskProps extends IBaseElementProps {
	image?: IImage;
}
const WhyFuzionTriangleMask: React.FC<IWhyFuzionTriangleMaskProps> = ({
	image,
	id = 'triangle-canvas-why-fuzion',
	className,
	classNames = [],
}) => {
	const idHash = uniqueIdHash();
	const imageCanvasMaskId = `image-canvas-mask-${idHash}`,
		maskId = `mask-${idHash}`,
		clipPathId = `clippath-${idHash}`;

	return (
		<svg
			id={id}
			xmlns="http://www.w3.org/2000/svg"
			version="1.1"
			viewBox="0 0 514 758"
			className={className || cn(...classNames)}
		>
			<defs>
				<clipPath id={clipPathId}>
					<rect
						className="cls-3"
						fill="none"
						width="514"
						height="758"
					/>
				</clipPath>
				<mask
					id={maskId}
					x="-303"
					y="-179.4"
					width="1056.5"
					height="1229.6"
					maskUnits="userSpaceOnUse"
				>
					<g data-name="mask0 2611 40225">
						<path
							className="cls-1"
							fill={colorPalette.white.DEFAULT}
							stroke={colorPalette.white.DEFAULT}
							strokeWidth="2px"
							d="M-302,802V1h970v801H-302Z"
						/>
					</g>
				</mask>
			</defs>
			<mask id={imageCanvasMaskId}>
				<polygon
					className="cls-2"
					fill="#eee"
					strokeWidth="0px"
					points="474.5 329.8 46.6 758 514 758 514 424.1 474.5 329.8"
				/>
			</mask>
			<image
				xlinkHref={image?.src}
				mask={`url(#${imageCanvasMaskId})`}
				width="95%"
				height="70%"
				y="250"
				x="40"
				preserveAspectRatio="xMidYMid slice"
			/>
			<g className="cls-4" clipPath={`url(#${clipPathId})`}>
				<g>
					<path
						className="cls-3"
						fill="none"
						strokeWidth="0px"
						d="M672.7,803l-198.2-473.2L.8,803h672Z"
					/>
					<g className="cls-6" mask={`url(#${maskId})`}>
						<g>
							<path
								className="cls-5"
								fill="none"
								stroke={colorPalette.secondary.DEFAULT}
								strokeWidth="1px"
								strokeMiterlimit="10"
								d="M474.5,330.1v720.1"
							/>
							<path
								className="cls-5"
								fill="none"
								stroke={colorPalette.secondary.DEFAULT}
								strokeWidth="1px"
								strokeMiterlimit="10"
								d="M-34.7-179l509.2,509.2"
							/>
							<path
								className="cls-5"
								fill="none"
								stroke={colorPalette.secondary.DEFAULT}
								strokeWidth="1px"
								strokeMiterlimit="10"
								d="M474.5,330.1L-34.7,839.3"
							/>
							<path
								className="cls-5"
								fill="none"
								stroke={colorPalette.secondary.DEFAULT}
								strokeWidth="1px"
								strokeMiterlimit="10"
								d="M474.5,330.1l278.6,665.3"
							/>
							<path
								className="cls-5"
								fill="none"
								stroke={colorPalette.secondary.DEFAULT}
								strokeWidth="1px"
								strokeMiterlimit="10"
								d="M474.5,330.1L198.9,995.4"
							/>
							<path
								className="cls-5"
								fill="none"
								stroke={colorPalette.secondary.DEFAULT}
								strokeWidth="1px"
								strokeMiterlimit="10"
								d="M474.5,330.1L-295.5.5"
							/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default WhyFuzionTriangleMask;
