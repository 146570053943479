import React from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import cn from 'classnames';
import Button, { IFuzionButtonProps, ILink } from '../Button';
import Icon from '../Icon';
import Bullets, { IBullet } from '../Bullets';
import Image, { IImage } from 'tbk-components/src/components/Image';
import ContentSection from '../ContentSection';
import LineAccent from '../LineAccent';
import { twMerge } from 'tailwind-merge';
/**
 *
 * This component is expected to be wrapped inside a <Modal> component.
 * It will be up to the parent component to handle the modal state as well as max width of the modal.
 *
 */

export interface IProInsiderModalProps extends IBaseElementProps {
	onCancel: () => void;
	title?: string;
	excerpt?: string;
	bullets?: IBullet[];
	buttons?: IFuzionButtonProps[];
	image?: IImage;
	closeButtonAriaLabel?: string;
	link?: ILink;
}

const ProInsiderModal: React.FC<IProInsiderModalProps> = ({
	id,
	className,
	classNames = [],
	title,
	excerpt,
	image,
	link,
	buttons = [],
	bullets = [],
	onCancel = () => {},
	closeButtonAriaLabel = 'Close Modal',
}) => {
	return (
		<div
			id={id}
			className={
				className ||
				cn(
					'pro-insider-modal relative block overflow-hidden rounded-md',
					...classNames,
				)
			}
		>
			<button
				onClick={onCancel}
				className="pro-insider-modal--close absolute right-3 top-3 z-[1] flex h-8 w-8 items-center justify-center rounded-full bg-primary text-center text-white transition-opacity hover:opacity-75 md:right-5 md:top-5 md:h-12 md:w-12"
				aria-label={closeButtonAriaLabel}
			>
				<Icon name="close" />
			</button>
			<div className="pro-insider-content z-[0] flex max-h-[80vh] flex-col items-stretch md:flex-row">
				<div
					className={twMerge(
						'pro-insider-content--content relative flex w-full grow overflow-y-auto overflow-x-hidden bg-light px-5 py-16 text-primary md:px-6 md:py-30',
						image
							? 'md:basis-1/2 md:justify-end'
							: 'md:justify-center',
					)}
				>
					<ContentSection
						title={title}
						excerpt={excerpt}
						excerptClassName="subheading"
						buttons={buttons}
						classNames={[
							twMerge(
								'z-[1] ',
								image ? 'md:max-w-[435px]' : 'md:max-w-[580px]',
							),
						]}
					>
						{bullets?.length > 0 && (
							<Bullets
								iconColor="medium"
								bulletsGapSize="8px"
								bullets={bullets}
							/>
						)}
					</ContentSection>
					{link && (
						<Button
							classNames={['mt-4']}
							buttonType="Primary"
							link={link}
							onClick={() => {
								onCancel();
							}}
						/>
					)}
					<LineAccent
						color="tertiary"
						classNames={['absolute bottom-0 left-0 z-0']}
					/>
				</div>
				{image && (
					<div className="pro-insider-content--image relative w-auto object-cover md:basis-1/2">
						<Image
							{...image}
							className="absolute left-0 top-0 h-full w-full object-cover object-center"
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default ProInsiderModal;
