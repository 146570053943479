import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import SearchBar, { ISearchBarProps } from './SearchBar';
import HeaderSearchResults, {
	IHeaderSearchResultsProps,
} from './HeaderSearchResults';

interface ISearchI18nProps {
	viewAllSearchResults?: string;
}

export interface ISearchProps extends IBaseElementProps {
	searchBar?: ISearchBarProps;
	searchResults?: IHeaderSearchResultsProps;
	searchPageUrl?: string;
	i18n?: ISearchI18nProps;
}

const Search: React.FC<ISearchProps> = ({
	id,
	className,
	classNames = [],
	searchBar,
	searchResults,
	searchPageUrl,
	i18n,
}) => {
	const [productResults, setProductResults] = useState(null);
	const [resourceResults, setResourceResults] = useState(null);
	const [keywordLinks, setKeywordLinks] = useState([]);
	const [searchPerformed, setSearchPerformed] = useState(false);

	return (
		<div
			id={id}
			className={className || cn('search__container', ...classNames)}
		>
			<SearchBar
				{...searchBar}
				onProductSearch={(s, value, total) => {
					value.forEach((product) => {
						product.enableProductCompare = false;
						product.enableQuickViewModal = false;
						delete product.dollarIndicator;
						delete product.productWidth;
						delete product.textButton;
					});
					setProductResults({
						...productResults,
						productCards: {
							productCards: value.slice(0, 4),
						},
						totalProducts: total,
						allResultsUrl: {
							link: {
								label:
									i18n?.viewAllSearchResults ||
									'View all search results',
								href: searchPageUrl + `?search=${s}`,
							},
						},
					});
				}}
				onResourceSearch={(s, value) => {
					// Limit the number of resources displayed in header search results
					if (value.length > 5) value.length = 5;

					setResourceResults(value);
					// Reset keyword links
					setKeywordLinks([]);

					let words = s.split(' ').filter((word) => word !== '');

					// Only display the first 2 keywords
					if (words.length > 2) words = words.slice(0, 2);

					words.forEach((word) => {
						const link = {
							label: word,
							href: searchPageUrl + `?tagged=${word}`,
						};
						setKeywordLinks((prev) => [...prev, link]);
					});
				}}
				setSearchPerformed={setSearchPerformed}
			/>
			{productResults && resourceResults && (
				<HeaderSearchResults
					{...searchResults}
					products={productResults}
					suggestedItems={resourceResults}
					keywordsLinks={keywordLinks}
					searchPerformed={searchPerformed}
				/>
			)}
		</div>
	);
};

export default Search;
