import React, { useEffect } from 'react';
import Nav, { NavItem } from 'tbk-components/src/components/Nav';
import Icon from '../Icon';
import useScreenSize from '../../hooks/useScreenSize';

export interface IFooterNavColumnProps {
	navColumn: NavItem;
}

const FooterNavColumn: React.FC<IFooterNavColumnProps> = ({ navColumn }) => {
	const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

	const screenWidth = useScreenSize();
	const isXsAndDown = screenWidth < 480;

	if (!navColumn) {
		return null;
	}

	if (navColumn.children) {
		let children = navColumn.children as NavItem[];

		const handleDropdownClick = () => {
			setIsDropdownOpen(!isDropdownOpen);
		};

		// Dropdown becomes a normal listing of links on bigger viewports
		useEffect(() => {
			if (!isXsAndDown) {
				setIsDropdownOpen(true);
			} else {
				setIsDropdownOpen(false);
			}
		}, [isXsAndDown]);

		return (
			<div>
				{/* Buttons here instead of spans allow the dropdown to be accessible + not rendering
				the <ul> instead of hiding it solves that issue where you're tabbing through invisible links */}
				<button
					className="flex w-full cursor-pointer items-center justify-between font-header text-base leading-[1.3] text-white xs:cursor-auto"
					onClick={isXsAndDown ? handleDropdownClick : null}
					tabIndex={isXsAndDown ? 0 : -1}
				>
					{navColumn.label}
					<Icon
						name="chevron-down"
						classNames={[
							'xs:hidden',
							'!font-bold',
							'text-[0.8rem]',
							isDropdownOpen && 'transform rotate-180',
						]}
					/>
				</button>
				{isDropdownOpen && (
					<div>
						<Nav
							navItems={children}
							collapseEnabled={false}
							dropdownEnabled
							classNames={[
								'footer-nav-column',
								'flex',
								'flex-col',
								'gap-3',
								'font-bodyLight',
								'text-sm',
								'text-white',
								'font-light',
								'leading-[1.6]',
								'mt-4',
								'[&_a]:block',
								'[&_a]:leading-[1.3]',
							]}
						/>
					</div>
				)}
			</div>
		);
	} else {
		return (
			<div>
				<a
					href={navColumn.href}
					target={navColumn.target}
					className="text-base leading-[1.3] text-white"
				>
					{navColumn.label}
				</a>
			</div>
		);
	}
};

export default FooterNavColumn;
