import React, { useLayoutEffect, useRef } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Image, { IImage } from 'tbk-components/src/components/Image';
import ContentSection, { IContentSectionProps } from '../ContentSection';
import { gsap } from 'gsap';
import { IFuzionButtonProps } from '../Button';
import useScreenSize from '../../hooks/useScreenSize';

/* Renaming the interface to avoid conflicts with original IImageProps that stops images from rendering */
export interface ISingleImageProps {
	/**
	 * eg. top-0, right-0, bottom-0, left-0
	 */
	position?: string[];
	image?: IImage;
}

export interface IMultiImageCTAProps extends IBaseElementProps {
	title?: string;
	/**
	 * Text
	 * @control richtext
	 */
	excerpt?: string;
	buttons?: IFuzionButtonProps[];
	mainImage?: IImage;
	/**
	 * Please do NOT insert more than 3 images.
	 */
	leftImages?: ISingleImageProps[];
	/**
	 * Please do NOT insert more than 3 images.
	 */
	rightImages?: ISingleImageProps[];
}

/**
 * Multi-image CTA
 * @block
 * @icon schedule
 */
const MultiImageCTA: React.FC<IMultiImageCTAProps> = ({
	id,
	className,
	classNames = [],
	title,
	excerpt,
	buttons,
	mainImage,
	leftImages = [],
	rightImages = [],
}) => {
	// Using the hook here instead of Tailwind styling to avoid rendering the images instead of hiding them
	const screenWidth = useScreenSize();
	const isLgAndDown = screenWidth < 1024;

	const ref = useRef(null);
	useLayoutEffect(() => {
		if (isLgAndDown) return;
		const rightImages = ref.current.querySelector('.right-images');
		const leftImages = ref.current.querySelector('.left-images');

		let ctx = gsap.context(() => {
			let tl;
			if (ref.current) {
				gsap.matchMedia().add(
					{
						md: '(min-width: 768px)',
						reduced: '(prefers-reduced-motion: reduce)',
					},
					() => {
						tl = gsap.timeline({
							scrollTrigger: {
								trigger: ref.current,
								start: 'center center',
								end: 'bottom top',
								scrub: 1,
								pin: true,
							},
						});
						if (tl) {
							tl.fromTo(
								ref.current.querySelectorAll('.image-tile'),
								{
									opacity: 0.2,
								},
								{
									duration: 4,
									opacity: 1,
									stagger: {
										each: 0.15,
									},
								},
							)
								.fromTo(
									ref.current.querySelector('.main-image'),
									{
										y: 0,
									},
									{
										duration: 9,
										y: -40,
									},
									'-=4',
								)
								.fromTo(
									ref.current.querySelector('.content-tile'),
									{
										y: 0,
									},
									{
										duration: 9,
										y: 40,
									},
									'-=9',
								)
								.fromTo(
									rightImages,
									{
										scale: 1,
										x: 0,
									},
									{
										duration: 9,
										x: 200,
										scale: 1.3,
									},
									'-=9',
								)
								.fromTo(
									rightImages.querySelectorAll(
										'.image-tile',
									)[0],
									{
										scale: 1,
										y: 0,
									},
									{
										duration: 9,
										y: -50,
										scale: 1.5,
									},
									'-=9',
								)
								.fromTo(
									rightImages.querySelectorAll(
										'.image-tile',
									)[2],
									{
										scale: 1,
										x: 0,
										y: 0,
									},
									{
										duration: 9,
										y: 50,

										scale: 1.5,
									},
									'-=9',
								)
								.fromTo(
									leftImages,
									{
										scale: 1,
										x: 0,
									},
									{
										duration: 9,
										x: -200,
										scale: 1.4,
									},
									'-=9',
								)
								.fromTo(
									leftImages.querySelectorAll(
										'.image-tile',
									)[0],
									{
										scale: 1,
										y: 0,
									},
									{
										duration: 9,
										y: -50,
										scale: 1.5,
									},
									'-=9',
								)
								.fromTo(
									leftImages.querySelectorAll(
										'.image-tile',
									)[2],
									{
										scale: 1,
										x: 0,
										y: 0,
									},
									{
										duration: 9,
										y: 50,

										scale: 1.5,
									},
									'-=9',
								)
								.fromTo(
									rightImages,
									{
										opacity: 1,
									},
									{
										duration: 4,
										opacity: 0.4,
									},
									'-=3',
								)
								.fromTo(
									leftImages,
									{
										opacity: 1,
									},
									{
										duration: 4,
										opacity: 0.4,
									},
									'-=3',
								);
						}
					},
				);
			}
		}, ref);

		return () => {
			ctx.revert();
		};
	}, []);
	return (
		<div
			id={id}
			ref={ref}
			className={
				className ||
				cn(
					'multi-image-cta__container relative z-[1] overflow-hidden',
					...classNames,
				)
			}
		>
			<div className="container grid gap-4 px-4 py-8 md:p-8 lg:grid-cols-[calc(1/6*100%)_calc(2/3*100%)_calc(1/6*100%)] lg:gap-0 lg:py-[120px]">
				{leftImages.length > 0 && !isLgAndDown && (
					<div className="left-images z-0 max-w-full lg:relative">
						<div className="grid max-w-full grid-cols-3 gap-4 lg:absolute lg:bottom-[-65px] lg:left-[-32px] lg:grid-cols-none lg:gap-8">
							{leftImages.map((image, index) => {
								let aspectRatio;

								switch (index) {
									case 0:
										aspectRatio = 'aspect-[4/3]';
										break;
									case 1:
										aspectRatio = 'aspect-[2/3]';
										break;
									case 2:
										aspectRatio = 'aspect-square';
										break;
								}

								return (
									<div
										key={index}
										className="image-tile lg:relative "
									>
										<Image
											{...image.image}
											className={cn(
												`rounded-md object-cover ${aspectRatio} lg:relative`,
												Array.isArray(image.position)
													? image.position.join(' ')
													: image.position,
											)}
										/>
									</div>
								);
							})}
						</div>
					</div>
				)}
				<div className="relative z-[1] grid gap-4 md:min-h-[680px] md:grid-cols-2 lg:gap-8">
					{mainImage && (
						<div className="aspect-3/2 main-image max-h-[350px] overflow-hidden rounded-md md:aspect-auto md:max-h-full">
							<Image
								{...mainImage}
								className="h-full w-full object-cover"
							/>
						</div>
					)}
					<div className="bg-gradient-general-radial content-tile overflow-hidden rounded-md px-4 py-8 lg:flex lg:items-end lg:pb-[112px] lg:pl-8 lg:pr-[62px]">
						<ContentSection
							titleElement="h2"
							title={title}
							excerpt={excerpt}
							buttons={buttons}
							titleClassName="text-[1.625rem] lg:text-4xl m-0"
						/>
					</div>
				</div>
				{rightImages.length > 0 && !isLgAndDown && (
					<div className="right-images z-0 max-w-full lg:relative">
						<div className="grid max-w-full grid-cols-3 gap-4 lg:absolute lg:right-[-2rem] lg:top-0 lg:grid-cols-none lg:gap-8">
							{rightImages.map((image, index) => {
								let aspectRatio;

								switch (index) {
									case 0:
										aspectRatio = 'aspect-square';
										break;
									case 1:
										aspectRatio = 'aspect-[3/2]';
										break;
									case 2:
										aspectRatio = 'aspect-[2/3]';
										break;
								}

								return (
									<div
										key={index}
										className="image-tile lg:relative"
									>
										<Image
											{...image.image}
											className={cn(
												`rounded-md object-cover ${aspectRatio} lg:relative`,
												Array.isArray(image.position)
													? image.position.join(' ')
													: image.position,
											)}
										/>
									</div>
								);
							})}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default MultiImageCTA;
