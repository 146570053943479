import React, { useState } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { IImage } from 'tbk-components/src/components/Image';
import Button, { ILink } from '../Button';
import { colorPalette } from '../../../tailwind-colors';
import { twMerge } from 'tailwind-merge';
import AdvancedMedia from '../AdvancedMedia';
import { IBullet } from '../Bullets';
import ProInsiderModal from '../ProInsiderModal';
import Modal from 'tbk-components/src/components/Modal';

export interface ITileBlock {
	title?: string;
	description?: string;
	enableModalButton?: boolean;
	modalButtonLabel?: string;
	image?: IImage;
	modalImage?: IImage;
	modalTitle?: string;
	modalExcerpt?: string;
	modalBullets?: IBullet[];
	modalLink?: ILink;
}

export interface IPersonalizedSupportProps extends IBaseElementProps {
	title?: string;
	/**
	 * @control richtext
	 */
	excerpt?: string;
	tiles?: ITileBlock[];
	cta?: ILink;
}

/**
 * Care And Support
 * @icon grid-view
 * @block
 */
const PersonalizedSupport: React.FC<IPersonalizedSupportProps> = ({
	title,
	excerpt,
	tiles = [],
	className,
	classNames = [],
	cta,
	id,
}) => {
	const accentColor = colorPalette.tertiary.DEFAULT;
	const [state, updateState] = useState<any>({
		open: { default: false },
	});

	const toggleFilter = (key: any) => {
		updateState((s: any) => ({
			...s,
			open: {
				...s.open,
				[key]: !s.open[key],
			},
		}));
	};

	return (
		<div
			id={id}
			className={
				className ||
				cn(
					'personalized-support relative bg-light text-center',
					...classNames,
				)
			}
		>
			<svg
				className="absolute right-0 top-0 max-h-[309px] max-w-[455px]"
				viewBox="0 0 455 309"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M-122.789 250.16L127.371 0.000152588"
					stroke={accentColor}
					strokeMiterlimit="10"
				/>
				<path
					d="M377.529 250.16L127.369 0.000152588"
					stroke={accentColor}
					strokeMiterlimit="10"
				/>
				<path
					d="M-8.01953 326.85L127.37 9.15527e-05"
					stroke={accentColor}
					strokeMiterlimit="10"
				/>
				<path
					d="M454.219 135.39L127.369 0.00012207"
					stroke={accentColor}
					strokeMiterlimit="10"
				/>
				<path
					d="M127.37 0.00012207L-199.48 135.39"
					stroke={accentColor}
					strokeMiterlimit="10"
				/>
			</svg>

			<div
				className={twMerge(
					'relative mx-auto max-w-8xl px-8 py-16 md:py-30',
				)}
			>
				{(title || excerpt) && (
					<div className="mx-auto mb-10 flex max-w-[617px] flex-col gap-4 text-center sm:mb-20">
						{title && <h2 className="mb-0">{title}</h2>}

						{excerpt && (
							<p
								className="mb-0"
								dangerouslySetInnerHTML={{
									__html: excerpt,
								}}
							/>
						)}
					</div>
				)}
				{tiles?.length > 0 && (
					<div className="grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-4">
						{tiles.map((tile, index) => (
							<>
								<div className="relative block" key={index}>
									{tile?.image && (
										<AdvancedMedia
											image={tile.image}
											size="3/2"
											rounded
											classNames={['mb-6']}
										/>
									)}
									{tile?.title && (
										<h3 className="mb-4">{tile.title}</h3>
									)}
									{tile?.enableModalButton && (
										<Button
											buttonType="Outline"
											size="sm"
											link={{
												label:
													tile.modalButtonLabel ||
													'Become a Pro Insider',
												href: '#',
											}}
											onClick={() => {
												toggleFilter(`modal-${index}`);
											}}
										/>
									)}
								</div>
								{state.open[`modal-${index}`] && (
									<Modal
										onCancel={() => {
											toggleFilter(`modal-${index}`);
										}}
										modalClassNames={[
											'max-w-[1142px]',
											'modal-dialog-centered',
											'rounded-md',
											'overflow-hidden',
										]}
										trapFocus
									>
										<ProInsiderModal
											id={`modal-${index}`}
											title={tile?.modalTitle}
											image={tile?.modalImage}
											excerpt={tile?.modalExcerpt}
											bullets={tile?.modalBullets}
											link={tile.modalLink}
											onCancel={() => {
												toggleFilter(`modal-${index}`);
											}}
										/>
									</Modal>
								)}
							</>
						))}
					</div>
				)}

				{cta && (
					<Button
						link={cta}
						classNames={['mx-auto inline-block', 'mt-20']}
					/>
				)}
			</div>
		</div>
	);
};

export default PersonalizedSupport;
