import React, { useState, useRef } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Image, { IImage } from 'tbk-components/src/components/Image';
import Modal from 'tbk-components/src/components/Modal';
import Carousel from 'tbk-components/src/components/Carousel';

interface ISimpleImageBarI18nProps {
	close?: string;
	openLightbox?: string;
}

export interface ISimpleImageBarProps extends IBaseElementProps {
	enableLightbox?: boolean;
	images?: IImage[];
	i18n?: ISimpleImageBarI18nProps;
}

/**
 * Simple Image Bar
 * @block
 * @icon format-gallery
 */
const SimpleImageBar: React.FC<ISimpleImageBarProps> = ({
	id,
	className,
	classNames = [],
	enableLightbox = false,
	images = [],
	i18n,
}) => {
	const [showModal, setShowModal] = useState(false);
	const [start, setStart] = useState(0);

	return (
		<div
			id={id}
			className={
				className ||
				cn('simple-image-bar__container 2xl:container', ...classNames)
			}
		>
			{images.length > 0 && (
				<>
					<ul className="simple-image-bar__images grid grid-cols-2 gap-4 px-4 xs:grid-cols-3 sm:grid-cols-4 md:grid-cols-5">
						{images.map((image, index) => (
							<li
								key={index}
								className="aspect-square overflow-hidden rounded-lg"
							>
								{enableLightbox ? (
									<button
										className="block h-full w-full appearance-none border-0 p-0 hover:opacity-50 focus:opacity-50"
										onClick={() => {
											setStart(index);
											setShowModal(true);
										}}
										aria-label={
											i18n?.openLightbox ||
											'Open lightbox'
										}
									>
										<Image
											{...image}
											className="simple-image-bar__image h-full w-full object-cover"
										/>
									</button>
								) : (
									<Image
										{...image}
										className="simple-image-bar__image h-full w-full object-cover"
									/>
								)}
							</li>
						))}
					</ul>
					{showModal && enableLightbox && (
						<Modal
							onCancel={() => {
								setShowModal(false);
							}}
							modalClassNames={[
								'lg:max-w-[970px] xl:max-w-[1200px] flex items-center justify-center h-full simple-image-bar',
							]}
							headerClassNames={[]}
							bodyClassNames={[]}
							showClose={false}
							showCancel={false}
							showSubmit={false}
							closeOnOutsideClick={true}
							closeOnEscape={true}
							trapFocus
						>
							<div className="relative mx-auto w-full max-w-full lg:mt-0 lg:max-w-full">
								<div className="fixed right-0 top-0 z-[1] lg:right-4 lg:top-4">
									<button
										type="button"
										aria-label={
											i18n?.close || 'Close lightbox'
										}
										className="group flex h-10 w-10 items-center justify-center hover:bg-white"
										onClick={() => {
											setShowModal(false);
										}}
									>
										<i className="icon-close text-2xl !leading-none text-white group-hover:text-primary lg:text-3xl" />
									</button>
								</div>
								<div>
									<div>
										<div
											className="glide__track"
											data-glide-el="track"
										>
											<ul className="glide__slides">
												<Carousel
													options={{
														type: 'slider',
														perView: 1,
														startAt: start,
													}}
													showArrows={true}
													showBullets={false}
													classNames={[
														'w-full !pb-0',
													]}
												>
													{images.map(
														(image, index) => (
															<li
																key={index}
																className="glide__slide flex max-h-[calc(100vh-4rem)] w-full items-center justify-center overflow-hidden"
															>
																<div className="aspect-[3/2] overflow-hidden lg:rounded-md">
																	<Image
																		{...image}
																		className="simple-image-bar__image h-full w-full object-cover"
																	/>
																</div>
															</li>
														),
													)}
												</Carousel>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</Modal>
					)}
				</>
			)}
		</div>
	);
};

export default SimpleImageBar;
