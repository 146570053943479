import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';

export interface ISpacerProps extends IBaseElementProps {
	/**
	 * Spacer Size
	 */
	size:
		| 'Extra Small'
		| 'Extra Small on Mobile'
		| 'Small'
		| 'Medium'
		| 'Large on Desktop';
}

/**
 * Spacer
 * @block
 * @icon move
 */
const Spacer: React.FC<ISpacerProps> = ({
	size = 'Medium',
	id,
	className,
	classNames = [],
}) => (
	<div
		id={id}
		className={
			className ||
			cn(
				'spacer__container',
				size === 'Extra Small on Mobile' && 'h-4 sm:h-10',
				size === 'Extra Small' && 'h-6',
				size === 'Small' && 'h-10 sm:h-14',
				size === 'Medium' && 'h-10 sm:h-14 md:h-16 lg:h-20',
				size === 'Large on Desktop' && 'lg:h-30 h-10 sm:h-14 md:h-16',
				...classNames,
			)
		}
	></div>
);

export default Spacer;
