import React from 'react';
import Button from '../Button';
import { twMerge } from 'tailwind-merge';
import AddressString from './AddressString';
import { IDealerProps } from '../Dealer';
import { Generic } from 'react-structured-data';
import LazyJSONLD from '../LazyJSONLD';

export const genAddressStr = (streetAddress, city, province) => {
	const replaceSpaces = (str) => str.split(' ').join('+');

	const s = streetAddress ? replaceSpaces(streetAddress) : '';
	const c = city ? replaceSpaces(city) : '';
	const p = province ? replaceSpaces(province) : '';

	const addressComponents = [s, c, p].filter(Boolean);

	const addressString = addressComponents.join(',');

	return addressString;
};

const DealerCard: React.FC<IDealerProps> = ({
	id,
	name,
	searchIndex,
	streetAddress,
	city,
	province,
	postalCode,
	phone,
	dealerCptUrl,
	distance,
	i18n,
}) => {
	return (
		<div
			id={`dealer-card__container-${searchIndex}`}
			className="dealer-card__container"
		>
			<LazyJSONLD>
				<Generic
					type="page"
					jsonldtype="Place"
					schema={{ '@type': 'Place',
						'name': name,
						'address': {
							'@type': 'PostalAddress',
							'streetAddress': streetAddress,
							'addressLocality': city,
							'addressRegion': province,
							'postalCode': postalCode,
						},
						'telephone': phone,
						'url': dealerCptUrl?.href,
					  }}
				/>
			</LazyJSONLD>
			<div className="dealer-card__container-inner relative rounded-md bg-white">
				<div className="p-4 pr-12">
					<div className="dealer-card__header">
						<div className="flex items-center gap-x-2">
							{searchIndex >= 0 && (
								<span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-tertiary font-body text-sm text-medium">
									{searchIndex + 1}
								</span>
							)}
							{name && (
								<h3 className="h4 mb-0 font-header text-base capitalize">
									{name}
								</h3>
							)}
						</div>
						<div className="mt-1">
							{distance && (
								<span
									className={twMerge(
										'inline-flex items-center font-body text-sm text-medium',
										'after:mx-[6px] after:inline-block after:h-[2px] after:w-[2px] after:rounded-full after:bg-primary',
									)}
								>
									{distance}
								</span>
							)}
							{(streetAddress || city || province) && (
								<Button
									link={{
										href: `https://www.google.com/maps/dir/?api=1&origin=current+location&destination=${genAddressStr(
											streetAddress,
											city,
											province,
										)}`,
										label:
											i18n?.direction || 'Get Directions',
										target: '_blank',
									}}
									buttonType="Medium"
									size="underline"
									classNames={[
										'font-body text-sm !text-medium',
									]}
									rel="noopener noreferrer"
								/>
							)}
						</div>
					</div>
					<div className="mt-4">
						{(streetAddress || city || province) && (
							<p className="font-body capitalize text-medium lg:text-base">
								<AddressString
									streetAddress={streetAddress}
									city={city}
									province={province}
									postalCode={postalCode}
								/>
							</p>
						)}
					</div>
					<div className="mt-4 flex gap-2">
						{dealerCptUrl && (
							<Button
								link={{
									href: dealerCptUrl?.href + '?dealerId=' + id,
									label: i18n?.details || 'Details',
								}}
								buttonType="Secondary"
								size="sm"
								classNames={['font-body !px-4']}
							/>
						)}
						{phone && (
							<Button
								link={{
									href: `tel:${phone.replace(/\W/g, '')}`,
									label: phone,
								}}
								buttonType="Outline"
								size="sm"
								classNames={['font-body !px-4']}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default DealerCard;
