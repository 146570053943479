import React, { useEffect, useRef, useState } from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { twMerge } from 'tailwind-merge';
import { IFuzionButtonProps } from '../Button';
import ButtonList from '../Button/ButtonList';
import useScreenSize from '../../hooks/useScreenSize';
import PriceRangeIndicator from '../PriceRangeIndicator';
import GetAQuote, { IGetAQuoteProps } from '../GetAQuote';
import { ISpec } from '../ProductCard';

export interface IFloatingCTABarI18nProps {
	expandCollapseLabel?: string;
}

export interface IFloatingCTABarProps extends IBaseElementProps {
	productName?: string;
	priceRange?: string;
	priceRangeLabel?: string;
	width?: ISpec;
	species?: ISpec;
	collection?: ISpec;
	buttons?: IFuzionButtonProps[];
	mobileButtons?: IFuzionButtonProps[];
	specs?: ISpec[];
	getAQuote?: IGetAQuoteProps;
	mobileGetAQuote?: IGetAQuoteProps;
	i18n?: IFloatingCTABarI18nProps;
}

const FloatingCTABar: React.FC<IFloatingCTABarProps> = ({
	productName,
	priceRange,
	priceRangeLabel,
	width,
	species,
	collection,
	buttons = [],
	mobileButtons = [],
	specs = [],
	getAQuote,
	mobileGetAQuote,
	i18n,
	className,
	classNames = [],
}) => {
	const [expanded, setExpanded] = useState(false);
	const [heightOfTopPart, setHeightOfTopPart] = useState(0);
	const [generatedSpecs, setGeneratedSpecs] = useState([]);

	const mainComponentRef = useRef(null);

	/* This is mainly used so that on smaller viewports, the floating bar
	takes up more height */
	const screenWidth = useScreenSize();
	const isSmAndUp = screenWidth >= 640;

	let maxHeight = isSmAndUp ? 400 : 800;

	// Close the floating bar when clicking outside of it
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				mainComponentRef.current &&
				!mainComponentRef.current.contains(event.target)
			) {
				setExpanded(false);
			}
		};
		if (!expanded) return;
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [mainComponentRef, setExpanded, expanded]);

	/* Get the height of the top part of the floating bar so we know how much to show when
	it's collapsed */
	useEffect(() => {
		if (!mainComponentRef.current) return;

		const topPartElement = mainComponentRef.current.querySelector(
			'.top-part',
		) as HTMLDivElement;

		setHeightOfTopPart(topPartElement.clientHeight);
	}, [mainComponentRef, setHeightOfTopPart, screenWidth]);

	useEffect(() => {
		//Compile width, collection and priceRange into specs with a key of mobileOnly but only if there is a value in the spec
		const mobileOnlySpecs = [
			{
				label: collection?.label,
				value: collection?.value,
				mobileOnly: true,
			},
			{ label: priceRangeLabel, value: priceRange, mobileOnly: true },
		].filter((spec) => spec.value);

		const newSpecs = [...specs, ...mobileOnlySpecs];
		generatedSpecs.length !== newSpecs.length &&
			setGeneratedSpecs(newSpecs);
	}, [specs]);
	return (
		<div
			className={
				className ||
				twMerge('floating-cta-bar relative z-20', ...classNames)
			}
		>
			<div
				className={twMerge(
					'fixed bottom-0 left-1/2 w-full max-w-[736px] -translate-x-1/2 rounded-t-md bg-white transition-all',
				)}
				style={{
					maxHeight: expanded
						? `${maxHeight}px`
						: `${heightOfTopPart}px`,
					boxShadow: '0px 0px 7px 0px rgba(4,20,4,0.32)',
				}}
				onClick={(e) => {
					if (!expanded) {
						setExpanded(true);
					} else {
						setExpanded(false);
					}
				}}
				ref={mainComponentRef}
			>
				<div className="top-part relative flex items-center justify-between gap-y-4 px-4 pb-3 pt-2 max-sm:gap-x-2 sm:px-8">
					<button
						className="absolute left-1/2 top-0 mt-3 w-20 -translate-x-1/2 cursor-pointer rounded-md border-[2px] border-medium transition-opacity hover:opacity-75 focus:opacity-75"
						onClick={() => {
							setExpanded(!expanded);
						}}
						aria-label={
							i18n?.expandCollapseLabel || 'Expand/Collapse'
						}
					></button>
					<div className="flex flex-col items-center gap-3 max-sm:max-w-[50%] sm:items-start">
						<h1 className="h3 mb-0 pt-5 text-left sm:h2-large sm:mt-0 sm:text-start">
							{productName}
						</h1>
						<div className="hidden max-w-[450px] items-center gap-x-4 gap-y-2 sm:flex sm:flex-row">
							{priceRange && (
								<PriceRangeIndicator priceRange={priceRange} />
							)}
							{width && (
								<p className="mb-0 text-[11px] text-sm lg:text-[14px]">
									<span className="text-medium">
										{width?.label}:{' '}
									</span>
									{width?.value}
								</p>
							)}
							{/* Was asked to remove species because floating bar body also has it. (ticket #909)
							Leave the code here in case future changes require it. */}
							{false && species && (
								<p className="mb-0 text-[11px] text-sm lg:text-[14px]">
									<span className="text-medium">
										{species.label}:{' '}
									</span>
									{species.value}
								</p>
							)}
							{collection &&
								React.createElement(
									collection.href ? 'a' : 'p',
									{
										className: twMerge(
											'mb-0 text-[11px] text-sm lg:text-[14px]',
											collection.href &&
												'text-decoration-none text-inherit transition-opacity hover:opacity-75 focus:opacity-75',
										),
										href: collection.href,
										children: (
											<>
												<span className="text-medium">
													{collection.label}:{' '}
												</span>
												{collection.value}
											</>
										),
									},
								)}
						</div>
					</div>

					<div className="flex items-center justify-center gap-2 max-sm:pt-5 sm:flex-col">
						{buttons?.length > 0 && (
							<ButtonList
								buttons={buttons}
								orientation="Vertical"
								classNames={[
									'flex-nowrap',
									'items-center',
									'justify-center',
									'gap-2',
									'hidden',
									'sm:flex',
								]}
							/>
						)}
						{mobileButtons?.length > 0 && (
							<ButtonList
								buttons={mobileButtons}
								orientation="Vertical"
								classNames={[
									'flex-nowrap',
									'!flex-row',
									'items-center',
									'justify-center',
									'gap-4',
									'sm:hidden',
									'max-sm:[&_a]:!text-xs',
									'flex-shrink-0',
								]}
							/>
						)}
						{getAQuote?.formId &&
							getAQuote?.children &&
							isSmAndUp && (
								<div
									className={
										mobileGetAQuote?.formId &&
										'hidden sm:inline-block'
									}
								>
									<GetAQuote
										classNames={['p-0']}
										{...getAQuote}
									/>
								</div>
							)}
						{mobileGetAQuote?.formId &&
							mobileGetAQuote?.children &&
							!isSmAndUp && (
								<div className="inline-block sm:hidden">
									<GetAQuote
										classNames={['p-0']}
										{...mobileGetAQuote}
									/>
								</div>
							)}
					</div>
				</div>
				<div className="mx-auto mt-[22px] h-[1px] w-[calc(100%-2rem)] bg-tertiary sm:w-[calc(100%-4rem)]"></div>
				<div
					className="bottom-part columns-2 p-8 pb-4 max-sm:px-4"
					style={{
						/* This is so that if we have many specs they don't get pushed
						beyond the viewport. This way we ensure that all specs can be seen */
						maxHeight:
							expanded && `${maxHeight - heightOfTopPart}px`,
						overflowY: 'auto',
					}}
				>
					{generatedSpecs.map((spec, key) => (
						<div
							key={key}
							className={twMerge(
								'mb-4 break-inside-avoid-column sm:grid sm:grid-cols-10 sm:gap-x-8',
								spec.mobileOnly && 'sm:hidden',
							)}
						>
							<p className="col-span-3 mb-0 text-sm text-medium md:min-w-[80px]">
								{spec.label}:
							</p>
							<p className="col-span-7 mb-0 text-sm">
								{spec.label === priceRangeLabel ? (
									<PriceRangeIndicator
										priceRange={spec.value}
									/>
								) : (
									spec.value
								)}
							</p>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default FloatingCTABar;
