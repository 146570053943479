import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Button, { ILink } from 'tbk-components/src/components/Button';

export interface IBreadcrumbProps extends IBaseElementProps {
	links?: ILink[];
}

const Breadcrumb: React.FC<IBreadcrumbProps> = ({
	id,
	className,
	classNames = [],
	links = [],
}) => (
	<nav
		id={id}
		aria-label="breadcrumb"
		className={className || cn('breadcrumb__container', ...classNames)}
	>
		<ol className="breadcrumb flex flex-wrap items-center">
			{links.map((link, index) =>
				index === links.length - 1 ? (
					<li
						key={`${index}-${link.href}`}
						className={cn('breadcrumb-item before:content-none')}
						aria-current="page"
					>
						<span className="text-sm">
							{link.label}
						</span>
					</li>
				) : (
					<li
						key={`${index}-${link.href}`}
						className={cn('breadcrumb-item before:content-none')}
					>
						<Button
							{...link}
							className="breadcrumb-item-link text-sm underline hover:no-underline focus:no-underline"
						/>
						<span className="mx-2 inline-block text-sm">
							/
						</span>
					</li>
				),
			)}
		</ol>
	</nav>
);

export default Breadcrumb;
