import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import FooterTop, { IFooterTopProps } from './FooterTop';
import FooterBottom, { IFooterBottomProps } from './FooterBottom';

export interface IFooterProps extends IBaseElementProps {
	footerTopProps: IFooterTopProps;
	footerBottomProps: IFooterBottomProps;
}

const Footer: React.FC<IFooterProps> = ({
	id,
	className,
	classNames = [],
	footerTopProps,
	footerBottomProps,
}) => {
	return (
		<footer
			id={id}
			className={className || cn('footer__container', ...classNames)}
		>
			{footerTopProps && <FooterTop {...footerTopProps} />}
			{footerBottomProps && <FooterBottom {...footerBottomProps} />}
		</footer>
	);
};

export default Footer;
