import React, { useState } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';

export interface IAlertBarProps extends IBaseElementProps {
	children?: any;
	closeLabel?: string;
	isHidden?: boolean;
	useLocalStorage?: boolean;
	localStorageKey?: string;
	alertHash?: string;
	isClosable?: boolean;
}

const AlertBar: React.FC<IAlertBarProps> = ({
	children,
	className,
	classNames = [],
	closeLabel = 'Close',
	isHidden = false,
	useLocalStorage = true,
	localStorageKey = 'alert-bar-closed',
	alertHash = '1',
	isClosable,
}) => {
	const [hideAlertBar, setHideAlertBar] = useState<boolean>(
		isHidden === true ||
			(useLocalStorage === true &&
				localStorage.getItem(localStorageKey) === alertHash),
	);

	return (
		<div
			className={
				className ||
				cn(
					'alert-bar__container relative bg-medium',
					hideAlertBar && 'hidden',
					...classNames,
				)
			}
		>
			<div className="container flex items-center justify-center p-4 pr-[44px] text-sm text-white [&_a]:!btn-underline md:px-8 lg:text-center [&_a:focus]:no-underline [&_a:hover]:no-underline [&_a]:!mx-2 [&_a]:whitespace-nowrap [&_a]:!text-sm [&_a]:uppercase [&_a]:underline [&_p]:mb-0 [&_p]:text-sm">
				{children}
			</div>

			{isClosable && (
				<button
					type="button"
					className="btn-close absolute right-0 top-1/2 flex h-[44px] w-[44px] translate-y-[-50%] items-center justify-center text-white"
					aria-label={closeLabel}
					onClick={() => {
						if (useLocalStorage) {
							localStorage.setItem(localStorageKey, alertHash);
						}
						setHideAlertBar(true);
						const header = document.querySelector(
							'.header__container',
						) as HTMLElement;

						if (header && window.scrollY === 0) {
							header.style.top = '0';
						}
					}}
				>
					<i className="icon-close"></i>
				</button>
			)}
		</div>
	);
};

export default AlertBar;
