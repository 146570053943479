import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Button, { ILink } from '../Button';

export interface INextStepsBlock {
	title: string;
	description: string;
	link: ILink;
}

export interface INextStepsProps extends IBaseElementProps {
	heading?: string;
	blocks: INextStepsBlock[];
}

/**
 * Next Steps
 * @icon grid-view
 * @block
 */
const NextSteps: React.FC<INextStepsProps> = ({
	heading,
	blocks = [],
	className,
	classNames = [],
}) => (
	<div
		className={
			className || cn('next-steps relative bg-light', ...classNames)
		}
	>
		<svg
			className="absolute right-0 top-0 max-h-[349px] max-w-[566px]"
			viewBox="0 0 566 349"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_2490_7755)">
				<path
					d="M326.85 98.5171L577.01 348.677"
					stroke="#E8E5E0"
					strokeMiterlimit="10"
				/>
				<path
					d="M577.01 -151.643L326.85 98.5169"
					stroke="#E8E5E0"
					strokeMiterlimit="10"
				/>
				<path
					d="M462.23 -228.333L326.85 98.517"
					stroke="#E8E5E0"
					strokeMiterlimit="10"
				/>
				<path
					d="M326.85 98.5171L0 233.897"
					stroke="#E8E5E0"
					strokeMiterlimit="10"
				/>
				<path
					d="M326.85 98.517L0 -36.873"
					stroke="#E8E5E0"
					strokeMiterlimit="10"
				/>
				<path
					d="M653.7 233.897L326.85 98.5171"
					stroke="#E8E5E0"
					strokeMiterlimit="10"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2490_7755">
					<rect width="566" height="349" fill="white" />
				</clipPath>
			</defs>
		</svg>

		<div className="relative mx-auto max-w-8xl px-8 py-16 md:py-30">
			{heading && (
				<h2 className="mx-auto mb-10 max-w-[617px] text-center text-primary sm:mb-20">
					{heading}
				</h2>
			)}
			<div className="flex flex-col flex-wrap items-center gap-8 sm:flex-row sm:items-stretch sm:justify-center">
				{Array.isArray(blocks) &&
					blocks.length > 0 &&
					blocks.map((block, index) => (
						<div
							key={index}
							className="flex max-w-80 flex-col justify-between gap-y-10 rounded-md border border-secondary bg-light px-8 py-10 text-center"
						>
							<div className="">
								{block?.title && (
									<h3 className="mb-2 text-primary">
										{block.title}
									</h3>
								)}
								{block?.description && (
									<p className="text-base text-primary">
										{block.description}
									</p>
								)}
							</div>
							{(block?.link?.label || block?.link?.href) && (
								<Button
									link={block.link}
									size="sm"
									buttonType="Outline"
									classNames={['self-center']}
								/>
							)}
						</div>
					))}
			</div>
		</div>
	</div>
);

export default NextSteps;
