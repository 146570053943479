import React, { useEffect, useLayoutEffect, useRef } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Image, { IImage } from 'tbk-components/src/components/Image';
import ButtonList from '../Button/ButtonList';
import { IFuzionButtonProps } from '../Button';
/*
 * A lot of content-rich components are often using the same content layouts. Title, Excerpt, Buttons, etc.
 * This component is an attempt to standardize the layout of these components.
 * The architecture is expected that Content Loader should not necessarily see this component's interface, but rather whatever component this is apart of.
 * Then that component's props should wire into this one.
 * In addition, this component should have no bias to the color or alignment of the text. This should be handled by the parent component as well.
 */
export interface IContentSectionProps extends IBaseElementProps {
	accentImage?: IImage;
	eyebrow?: string;
	title?: string;
	titleElement?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	/**
	 * Lead
	 * @control richtext
	 */
	lead?: string;
	/**
	 * Text
	 * @control richtext
	 */
	excerpt?: string;
	/*
	 * This is to handle random cases such as adding a custom bullet list of text, etc
	 */
	children?: any;
	gapSize?: '16px' | '24px' | '32px';
	buttons?: IFuzionButtonProps[];
	eyeBrowClassName?: string;
	accentImageClass?: string;
	titleClassName?: string;
	leadClassName?: string;
	excerptClassName?: string;
	buttonsClassName?: string;
}

const ContentSection: React.FC<IContentSectionProps> = ({
	className,
	classNames = [],
	children,
	eyebrow,
	accentImage,
	title,
	titleElement = 'h2',
	lead,
	excerpt,
	buttons = [],
	eyeBrowClassName,
	titleClassName,
	leadClassName,
	excerptClassName,
	buttonsClassName,
	accentImageClass,
	gapSize = '32px',
	id,
}) => {
	return (
		<div
			className={
				className ||
				cn(
					'content-section',
					'inline-flex flex-col',
					gapSize === '16px' && 'gap-4',
					gapSize === '24px' && 'gap-6',
					gapSize === '32px' && 'gap-8',
					...classNames,
				)
			}
			id={id}
		>
			{accentImage && (
				<Image
					className={
						accentImageClass || '!h-auto !w-full !max-w-[86px]'
					}
					{...accentImage}
				/>
			)}
			{(title || eyebrow) && (
				<div className="content-section__head">
					{eyebrow && (
						<p
							className={cn(
								eyeBrowClassName ||
									'content-section__eyebrow eyebrow mb-2',
							)}
						>
							{eyebrow}
						</p>
					)}
					{title &&
						title !== '<br>' &&
						React.createElement(titleElement, {
							className: cn(
								titleClassName ||
									'content-section__title mb-0 ',
							),

							children: (
								<span
									dangerouslySetInnerHTML={{
										__html: title,
									}}
								></span>
							),
						})}
				</div>
			)}
			{lead && lead !== '<br>' && (
				<p
					className={cn(
						leadClassName || 'content-section__lead lead mb-0',
					)}
					dangerouslySetInnerHTML={{
						__html: lead,
					}}
				/>
			)}
			{excerpt && excerpt !== '<br>' && (
				<p
					className={cn(
						excerptClassName || 'content-section__excerpt mb-0',
					)}
					dangerouslySetInnerHTML={{
						__html: excerpt,
					}}
				/>
			)}
			{children}
			{buttons?.length > 0 && (
				<ButtonList
					buttons={buttons}
					classNames={[buttonsClassName || 'mt-2']}
				/>
			)}
		</div>
	);
};

export default ContentSection;
