import React, { useState } from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import cn from 'classnames';
import { ISpec } from '../../ProductCard';
import PriceRangeIndicator from '../../PriceRangeIndicator';
import Bullets, { IBullet } from '../../Bullets';
import Button, { IFuzionButtonProps } from '../../Button';
import Modal from 'tbk-components/src/components/Modal';
import Icon from '../../Icon';

export interface ICommercialModal {
	title?: string;
	excerpt?: string;
	formId?: string;
	children?: any;
	closeLabel?: string;
}

export interface ICommercialTabProps extends IBaseElementProps {
	tabLabel?: string;
	title?: string;
	/**
	 * @control richtext
	 */
	excerpt?: string;
	newsLetterButton?: IFuzionButtonProps;
	buttons?: IFuzionButtonProps[];
	modalContent?: ICommercialModal;
}

const CommercialTab: React.FC<ICommercialTabProps> = ({
	id,
	className,
	classNames = [],
	title,
	excerpt,
	newsLetterButton,
	buttons = [],
	modalContent,
}) => {
	const [modalOpen, setModalOpen] = useState(false);
	return (
		<>
			<div
				id={id}
				className={className || cn('commercial-tab', ...classNames)}
			>
				<div className="flex max-w-[768px] flex-col gap-5">
					{title && <h2 className="h3 mb-0">{title}</h2>}
					{excerpt && (
						<p
							className="lead mb-0 text-base"
							dangerouslySetInnerHTML={{ __html: excerpt }}
						/>
					)}
					{(newsLetterButton || buttons.length > 0) && (
						<div className="mt-5 flex flex-wrap gap-5">
							{newsLetterButton && (
								<Button
									{...newsLetterButton}
									onClick={(e) => {
										e.preventDefault();
										setModalOpen(true);
									}}
								/>
							)}
							{buttons.length > 0 &&
								buttons.map((button, index) => (
									<Button key={index} {...button} />
								))}
						</div>
					)}
				</div>
			</div>
			{modalOpen && (
				<Modal
					onCancel={() => setModalOpen(false)}
					modalClassNames={[
						'max-w-[1080px]',
						'modal-dialog-centered',
						'w-full',
						'px-4',
						'py-4',
					]}
					headerClassNames={[]}
					bodyClassNames={[]}
					showCancel={false}
					showSubmit={false}
					trapFocus
				>
					<div className="w-full overflow-hidden rounded-md bg-white px-6 py-24">
						<button
							aria-label={modalContent?.closeLabel || 'Close'}
							onClick={() => setModalOpen(false)}
							className="text-xxl absolute right-6 top-6"
						>
							<Icon
								name="close"
								classNames={[
									'h3 mb-0 hover:opacity-75 transition-opacity',
								]}
							/>
						</button>
						<div
							className="md:py-22 mx-auto
						flex max-w-[580px] flex-col gap-5 text-center"
						>
							{modalContent?.title && (
								<h2 className="mb-0">{modalContent.title}</h2>
							)}
							{modalContent?.excerpt && (
								<p
									className="lead mb-0"
									dangerouslySetInnerHTML={{
										__html: modalContent.excerpt,
									}}
								/>
							)}
							<div className="modal-form-container mt-10">
								{modalContent?.children}
							</div>
						</div>
					</div>
				</Modal>
			)}
		</>
	);
};

export default CommercialTab;
