import cn from 'classnames';
import React, { useState, AnchorHTMLAttributes } from 'react';
import Icon from '../Icon';
import ImageToSVG from '../ImageToSVG';
import { ITextColorsProps } from '../Colors';
import { kebabCase } from 'lodash';

// @ts-ignore
import lineBurstSrc from '../../assets/images/line-burst.svg';

const lineBurst = {
	src: lineBurstSrc,
	alt: 'Line Burst',
	width: 0,
	height: 0,
};

export interface ILink {
	href?: string;
	label?: string;
	target?: '_blank' | '_self' | '_parent' | '_top';
	children?: any;
}

export interface IFuzionButtonDataProps {
	sku?: string;
	title?: string;
	subtitle?: string;
	collection?: string;
	list_name?: string;
}
export interface IFuzionButtonProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
	id?: string;
	link?: ILink;
	buttonType?:
		| 'Primary'
		| 'Secondary'
		| 'White'
		| 'Medium'
		| 'Outline'
		| 'Outline Light';
	size?: 'lg' | 'sm' | 'underline';
	/**
	 * @noUI
	 */
	onClick?: (event: any) => any;
	/**
	 * @noUI
	 */
	icon?: string;
	decorative?: boolean;
	decorColor?: ITextColorsProps['options'];
	displayOnly?: boolean;
	className?: string;
	classNames?: string[];
	data?: IFuzionButtonDataProps;
	disabled?: boolean;
}

/**
 * Button
 * @icon button
 * @block
 */

const Button: React.FC<IFuzionButtonProps> = ({
	id,
	buttonType = 'Primary',
	size = 'lg',
	children,
	className,
	classNames = [],
	link,
	icon,
	decorative = false,
	decorColor = 'text-secondary',
	displayOnly = false,
	data,
	disabled = false,
	...props
}) => {
	const [decorImgLoaded, setDecorImgLoaded] = useState(false);
	const btnProps = {
		className:
			className ||
			cn(
				'btn',
				`btn-${kebabCase(buttonType)}`,
				`btn-${size}`,
				'transition-all duration-300 ease-in-out',
				decorative &&
					'inline-flex items-center !pr-0 !py-0 overflow-hidden opacity-0 invisible',
				decorative && decorImgLoaded && '!opacity-100 !visible',
				icon &&
					`inline-flex ${size === 'lg' && 'gap-4'} ${(size === 'sm' || size === 'underline') && 'gap-2'}`,
				disabled && 'opacity-50 cursor-not-allowed hover:filter-none',
				...classNames,
			),
		...props,
		href: link?.href,
		target: link?.target,
		title: link?.label,
		rel: link?.target === '_blank' ? 'noopener' : undefined,
		disabled: disabled,
	};

	const dataProps = {
		id: id,
		'data-sku': data?.sku,
		'data-title': data?.title,
		'data-subtitle': data?.subtitle,
		'data-collection': data?.collection,
		'data-list_name': data?.list_name,
	};

	const btnContent =
		size === 'underline' ? (
			<>
				<span
					className={cn(
						`border-${kebabCase(buttonType)}`,
						'border-b',
					)}
				>
					{btnProps.title}
				</span>
				{icon && <Icon name={icon} />}
				{decorative && (
					<ImageToSVG
						image={lineBurst}
						classNames={['ml-3', decorColor]}
						onLoad={() => setDecorImgLoaded(true)}
					/>
				)}
			</>
		) : (
			<>
				{btnProps?.title && <span>{btnProps.title}</span>}
				{children}
				{icon && <Icon name={icon} />}
				{decorative && (
					<ImageToSVG
						image={lineBurst}
						classNames={['ml-3', decorColor]}
						onLoad={() => setDecorImgLoaded(true)}
					/>
				)}
			</>
		);

	if (!btnProps.href || !btnProps.target || btnProps.target === '_self') {
		delete btnProps.target;
	}

	if (displayOnly) {
		return <span {...btnProps}>{btnContent}</span>;
	}

	let allProps = { ...btnProps };
	if (dataProps.id) {
		allProps = { ...allProps, ...dataProps };
	}

	return allProps.href ? (
		<a {...allProps}>{btnContent}</a>
	) : (
		// @ts-ignore
		<button {...allProps}>{btnContent}</button>
	);
};

export default Button;
