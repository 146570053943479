import React from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { twMerge } from 'tailwind-merge';
import BlogCard, { IBlogCardProps } from '../BlogCard';
import Button, { ILink } from '../Button';
import { isEmpty } from 'lodash';

export interface IBlogsListI18nProps {
	readMore?: string;
	noBlogsFound?: string;
}

export interface IBlogsListProps extends IBaseElementProps {
	sectionTitle?: string;
	displayCount?: boolean;
	viewAllLink?: ILink;
	blogs?: IBlogCardProps[];
	containerClassNames?: string[];
	gridClassNames?: string[];
	i18n?: IBlogsListI18nProps;
}

const BlogsList: React.FC<IBlogsListProps> = ({
	id,
	className,
	classNames = [],
	sectionTitle,
	displayCount = true,
	viewAllLink,
	blogs = [],
	containerClassNames = [],
	gridClassNames = [],
	i18n,
}) => {
	let defaultID = id;
	if (isEmpty(defaultID)) {
		defaultID = 'blogs';
	}

	return (
		<div
			id={defaultID}
			className={
				className ||
				twMerge('blogs-list__container bg-white', ...classNames)
			}
		>
			<div
				className={twMerge(
					'container px-4 py-8 md:px-8 lg:py-20',
					...containerClassNames,
				)}
			>
				{sectionTitle && (
					<div className="flex flex-wrap items-center justify-start gap-4 xs:flex-nowrap">
						<h2 className="mb-0 lg:text-4xl">{sectionTitle}</h2>
						{blogs?.length === 0 ? (
							<>
								<span>
									{i18n?.noBlogsFound || 'No Blogs Found'}
								</span>
							</>
						) : (
							<>
								{displayCount && blogs?.length > 0 && (
									<span className="flex h-[26px] w-[26px] items-center justify-center rounded-full bg-tertiary text-sm">
										{blogs.length}
									</span>
								)}
								{viewAllLink?.href && (
									<Button
										link={viewAllLink}
										buttonType="Medium"
										size="underline"
										icon="chevron-right"
										classNames={[
											'!text-base !text-medium xs:ml-auto xs:mr-0 [&>span]:border-none [&>span]:underline [&>span]:hover:no-underline [&>span]:focus:no-underline ',
										]}
									/>
								)}
							</>
						)}
					</div>
				)}
				<ul
					className={twMerge(
						'mt-6 grid gap-8 md:grid-cols-2 lg:mt-10 lg:grid-cols-3 lg:gap-8 lg:gap-y-20',
						...gridClassNames,
					)}
				>
					{blogs?.length > 0 && (
						<>
							{blogs?.map((blog, index) => (
								<li key={index}>
									<BlogCard
										{...blog}
										i18n={{
											readMore:
												i18n?.readMore || 'Read More',
										}}
									/>
								</li>
							))}
						</>
					)}
				</ul>
			</div>
		</div>
	);
};

export default BlogsList;
