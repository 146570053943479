import React from 'react';
import Tabs from '../Tabs';
import Tab from 'tbk-components/src/components/Tab';
import Office, { IOfficeI18nProps, IOfficeProps } from './Office';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { twMerge } from 'tailwind-merge';

export interface IContactTabsProps extends IBaseElementProps {
	sectionTitle?: string;
	offices?: IOfficeProps[];
	i18n?: IOfficeI18nProps;
}

/**
 * Contact Tabs
 * @icon button
 * @block
 */
const ContactTabs: React.FC<IContactTabsProps> = ({
	sectionTitle,
	offices = [],
	i18n,
	id,
	className,
	classNames = [],
}) => {
	return (
		<div
			id={id}
			className={
				className || twMerge('contact-tabs__container', ...classNames)
			}
		>
			<div className="container pt-8 md:p-8 lg:py-30">
				{sectionTitle && (
					<h2 className="m-0 mb-4 px-4 text-center lg:mb-10">
						{sectionTitle}
					</h2>
				)}
				{offices?.length > 0 && (
					<Tabs>
						{offices.map((office, index) => (
							<Tab key={index} title={office.name}>
								<Office {...office} i18n={i18n} />
							</Tab>
						))}
					</Tabs>
				)}
			</div>
		</div>
	);
};

export default ContactTabs;
