import React from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import cn from 'classnames';
import { IImage } from 'tbk-components/src/components/Image';
import AdvancedMedia from '../AdvancedMedia';
import { IVideoProps } from '../Video';
import { twMerge } from 'tailwind-merge';

export interface IMedia extends IBaseElementProps {
	mediaTitle?: string;
	image?: IImage;
	video?: IVideoProps;
}
export interface IContentMediaProps extends IBaseElementProps {
	medias?: IMedia[];
	forcedVideoAspectRatio?: boolean;
}

/**
 * Content Media
 * @block
 * @icon align-full-width
 */
const ContentMedia: React.FC<IContentMediaProps> = ({
	id,
	className,
	classNames = [],
	medias = [],
	forcedVideoAspectRatio = false,
}) => {
	return (
		<div
			id={id}
			className={
				className ||
				twMerge(
					'content-media inline-flex gap-6',
					medias?.length > 1 ? 'grid md:grid-cols-2' : 'inline-flex',
					...classNames,
				)
			}
		>
			{medias?.length > 0 &&
				medias.map((media, index) => (
					<div
						className={twMerge(
							'media-item',
							medias?.length === 1 && 'w-full',
						)}
					>
						{media.mediaTitle && (
							<h3 className="mb-5">{media.mediaTitle}</h3>
						)}
						<AdvancedMedia
							key={index}
							rounded
							image={media.image}
							size={
								medias.length === 1 || forcedVideoAspectRatio
									? '16/9'
									: '3/4'
							}
							video={media.video}
							disablePlayControls={true}
						/>
					</div>
				))}
		</div>
	);
};

export default ContentMedia;
