import React, { useRef, useEffect, useState } from 'react';
import cn from 'classnames';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { colorPalette } from '../../../tailwind-colors';

export interface IMapMarker extends google.maps.marker.AdvancedMarkerElement {
	onClick?: (
		marker: google.maps.marker.AdvancedMarkerElement,
		map: google.maps.Map,
	) => void;
}

export interface IMapProps extends IBaseElementProps {
	center?: google.maps.LatLng | google.maps.LatLngLiteral;
	zoom?: number;
	markers?: IMapMarker[];
	onMapLoaded?: (map: google.maps.Map) => void;
	onMapClick?: (event: google.maps.event) => void;
}

const Map: React.FC<IMapProps> = ({
	className,
	classNames = [],
	center,
	zoom = 4,
	markers = [],
	onMapLoaded,
	onMapClick,
}) => {
	const mapRef = useRef<HTMLDivElement>(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [map, setMap] = useState<google.maps.Map | null>(null);

	useEffect(() => {
		if (!mapRef.current || !isLoaded) {
			return;
		}

		const map = new window.google.maps.Map(mapRef.current, {
			center,
			zoom,
			mapId:
				// @ts-ignore
				window.GOOGLE_MAPS_ID,
		});

		setMap(map);
		onMapLoaded && onMapLoaded(map);
	}, [isLoaded]);

	useEffect(() => {
		if (!map || markers?.length === 0) {
			return;
		}

		let bounds = new google.maps.LatLngBounds();
		const mapMarkers = markers.map(({ onClick, ...markerOptions }) => {
			const marker = new window.google.maps.marker.AdvancedMarkerElement({
				map,
				...markerOptions,
			});
			bounds.extend(markerOptions.position);

			if (onClick) {
				marker.addListener('click', () => {
					onClick(marker, map);
				});
			}

			return marker;
		}) as google.maps.marker.AdvancedMarkerElement[];
		map.fitBounds(bounds);

		// Listen for when map is idle to set to our maximum zoom.  helpful when only one result
		const listener = google.maps.event.addListener(map, 'idle', () => {
			const maximumZoom = 16;
			if (map.getZoom() > maximumZoom) {
				map.setZoom(maximumZoom);
			}
			google.maps.event.removeListener(listener);
		});

		// Add a click listener to the map to open a new tab with Google Maps URL when user is in the dealer details page
		const mapClickListener = google.maps.event.addListener(
			map,
			'click',
			onMapClick,
		);

		return () => {
			mapMarkers.forEach((marker) => (marker.position = null));
			listener.remove();
			mapClickListener.remove();
		};
	}, [map, markers]);

	return (
		<>
			<Wrapper
				apiKey={
					// @ts-ignore
					window.GOOGLE_MAPS_API_KEY
				}
				callback={(status: Status) => {
					if (status === 'SUCCESS') {
						setIsLoaded(true);
					}
				}}
				libraries={['marker']}
				language={document.documentElement.lang}
			/>
			<div
				ref={mapRef}
				className={className || cn('map-container', ...classNames)}
				style={{
					width: '100%',
					height: '100%',
				}}
			/>
		</>
	);
};

export default Map;
