import React from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

export interface IResourceProps {
	name?: string;
	resourceType?: string;
	iconName?: string;
	url?: string;
}

interface IResourcesListI18nProps {
	noResourcesFound?: string;
}

export interface IResourcesListProps extends IBaseElementProps {
	sectionTitle?: string;
	resources?: IResourceProps[];
	i18n?: IResourcesListI18nProps;
}

const ResourcesList: React.FC<IResourcesListProps> = ({
	id,
	className,
	classNames = [],
	sectionTitle,
	resources = [],
	i18n,
}) => {
	const { t } = useTranslation();

	let defaultID = id;
	if (isEmpty(defaultID)) {
		defaultID = 'resources';
	}

	return (
		<div
			id={defaultID}
			className={
				className ||
				twMerge('resources-list__container bg-tertiary', ...classNames)
			}
		>
			<div className="container px-4 py-8 md:px-8 lg:py-20">
				{sectionTitle && (
					<div className="flex items-center justify-start gap-4">
						<h2 className="mb-0 lg:text-4xl">{sectionTitle}</h2>
						{resources?.length > 0 ? (
							<span className="flex h-[26px] w-[26px] items-center justify-center rounded-full bg-white text-sm">
								{resources.length}
							</span>
						) : (
							<span>
								{i18n?.noResourcesFound ||
									t('No Resources Found')}
							</span>
						)}
					</div>
				)}
				<ul className="mt-6 grid gap-2 md:grid-cols-2 md:gap-4 lg:mt-10 lg:grid-cols-3 lg:gap-8">
					{resources?.map(
						(resource, index) =>
							resource?.url && (
								<li key={index}>
									<a
										href={resource.url}
										target="_blank"
										rel="noreferrer"
										className="flex h-full min-h-12 items-center justify-between gap-4 rounded-md bg-white px-4 py-3 transition-all duration-300 ease-in-out hover:scale-105 hover:shadow focus:scale-105 focus:shadow lg:p-6"
									>
										{resource.name && (
											<span className="h4 mb-0 font-header">
												{resource.name}
											</span>
										)}
										{resource.iconName && (
											<i
												className={`icon-${resource.iconName} flex h-[24px] w-[24px] shrink-0 items-center justify-center rounded-full bg-medium text-base text-white lg:h-8 lg:w-8`}
											/>
										)}
									</a>
								</li>
							),
					)}
				</ul>
			</div>
		</div>
	);
};

export default ResourcesList;
