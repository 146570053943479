import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import ContentSection, { IContentSectionProps } from '../ContentSection';
import IconTextGrid, { IIconTextGridProps } from '../IconTextGrid';

export interface IFloorAndFeaturesProps extends IBaseElementProps {
	contentSection?: IContentSectionProps;
	iconTextGrid?: IIconTextGridProps;
}

/**
 * Floor and Features
 * @icon editor-ul
 * @block
 */
const FloorAndFeatures: React.FC<IFloorAndFeaturesProps> = ({
	contentSection,
	iconTextGrid,
	className,
	classNames = [],
}) => (
	<div
		className={
			className ||
			cn(
				'floor-and-features',
				'bg-gradient-general-radial',
				...classNames,
			)
		}
	>
		<div className="floor-and-features__container mx-auto flex max-w-8xl flex-col items-center gap-20 px-8 py-16 md:py-30 xl:flex-row xl:justify-end">
			{contentSection && (
				<ContentSection
					classNames={['max-w-[500px]', 'justify-center']}
					titleClassName="text-center mb-0 text-white xl:text-start"
					titleElement="h2"
					excerptClassName="text-center text-white xl:text-start"
					{...contentSection}
				/>
			)}
			{iconTextGrid && <IconTextGrid {...iconTextGrid} />}
		</div>
	</div>
);

export default FloorAndFeatures;
