import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { IImage } from 'tbk-components/src/components/Image';
import Button, { IFuzionButtonProps, ILink } from '../Button';
import ImageToSVG from '../ImageToSVG';
import { colorPalette } from '../../../tailwind-colors';
import { twMerge } from 'tailwind-merge';

export interface ICareBlock {
	icon?: IImage;
	title?: string;
	description?: string;
	cta?: ILink;
	buttonType: IFuzionButtonProps['buttonType'];
	subtext1?: string;
	/**
	 * @control richtext
	 */
	subtext2?: string;
}

export interface ICareAndSupportProps extends IBaseElementProps {
	heading?: string;
	/**
	 * @control richtext
	 */
	excerpt?: string;
	blocks?: ICareBlock[];
	cta?: IFuzionButtonProps;
	bgColor?:
		| 'bg-white'
		| 'bg-gradient-general-radial'
		| 'bg-gradient-rainbow'
		| 'bg-light'
		| 'bg-tertiary';
}

/**
 * Care And Support
 * @icon grid-view
 * @block
 */
const CareAndSupport: React.FC<ICareAndSupportProps> = ({
	heading,
	excerpt,
	blocks = [],
	className,
	classNames = [],
	cta,
	bgColor = 'bg-tertiary',
	id,
}) => {
	const accentColor =
		bgColor === 'bg-gradient-general-radial' ||
		bgColor === 'bg-gradient-rainbow'
			? colorPalette.tertiary.DEFAULT
			: colorPalette.secondary.DEFAULT;
	return (
		<div
			id={id}
			className={
				className ||
				cn(
					'care-and-support relative text-center',
					bgColor,
					...classNames,
				)
			}
		>
			<svg
				className="absolute left-0 top-0 max-h-[309px] max-w-[455px]"
				viewBox="0 0 455 309"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M-122.789 250.16L127.371 0.000152588"
					stroke={accentColor}
					strokeMiterlimit="10"
				/>
				<path
					d="M377.529 250.16L127.369 0.000152588"
					stroke={accentColor}
					strokeMiterlimit="10"
				/>
				<path
					d="M-8.01953 326.85L127.37 9.15527e-05"
					stroke={accentColor}
					strokeMiterlimit="10"
				/>
				<path
					d="M454.219 135.39L127.369 0.00012207"
					stroke={accentColor}
					strokeMiterlimit="10"
				/>
				<path
					d="M127.37 0.00012207L-199.48 135.39"
					stroke={accentColor}
					strokeMiterlimit="10"
				/>
			</svg>

			<div
				className={twMerge(
					'relative mx-auto max-w-8xl px-6 py-8 md:px-8 md:py-30',
					bgColor === 'bg-gradient-general-radial'
						? 'text-white'
						: 'text-primary',
				)}
			>
				{(heading || excerpt) && (
					<div className="mx-auto mb-6 flex max-w-[617px] flex-col gap-4 text-center sm:mb-20 md:mb-14 lg:mb-16">
						{heading && <h2 className="mb-0">{heading}</h2>}

						{excerpt && (
							<p
								className="mb-0"
								dangerouslySetInnerHTML={{
									__html: excerpt,
								}}
							/>
						)}
					</div>
				)}
				<div className="flex flex-wrap items-start justify-center gap-10 lg:flex-nowrap">
					{Array.isArray(blocks) &&
						blocks.length > 0 &&
						blocks.map(
							(
								{
									icon,
									title,
									description,
									cta,
									subtext1,
									subtext2,
									buttonType,
								},
								index,
							) => {
								return (
									<div
										key={index}
										className={twMerge(
											'flex flex-col items-center md:max-w-[437px] xl:basis-1/3',
										)}
									>
										{(icon || title) && (
											<div>
												{icon && (
													<ImageToSVG
														image={icon}
														classNames={[
															'h-[64px]',
															'w-[64px]',
															'mx-auto',
															'mb-2 lg:mb-4',
															bgColor ===
															'bg-gradient-general-radial'
																? 'text-white'
																: 'text-primary',
														]}
													/>
												)}
												{title && (
													<h3 className="mb-0 text-center text-lg lg:text-2xl">
														{title}
													</h3>
												)}
											</div>
										)}
										{description && (
											<p className="mb-0 mt-2 text-center md:px-8 lg:mt-4">
												{description}
											</p>
										)}
										{cta?.label && (
											<Button
												link={cta}
												classNames={[
													'mt-5 mx-auto mb-3 lg:mt-10',
												]}
												buttonType={buttonType}
											/>
										)}
										{subtext1 && subtext1 !== '<br>' && (
											<p className="mb-0  text-center text-sm">
												{subtext1}
											</p>
										)}
										{subtext2 && subtext2 !== '<br>' && (
											<p
												className="m-0 text-center [&_a]:text-primary [&_a]:no-underline [&_a]:hover:underline [&_a]:focus:underline"
												dangerouslySetInnerHTML={{
													__html: subtext2,
												}}
											/>
										)}
									</div>
								);
							},
						)}
				</div>
				{cta && (
					<Button
						{...cta}
						classNames={['mx-auto inline-block', 'mt-10']}
					/>
				)}
			</div>
		</div>
	);
};

export default CareAndSupport;
