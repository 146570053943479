import React from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Button, { IFuzionButtonProps } from '.';
import { twMerge } from 'tailwind-merge';

export interface IButtonListProps extends IBaseElementProps {
	buttons?: IFuzionButtonProps[];
	/**
	 * @noUI
	 */
	children?: any; // Expected to be <Button> modules
	orientation?: 'Horizontal' | 'Vertical';
}

const ButtonList: React.FC<IButtonListProps> = ({
	id,
	buttons = [],
	children,
	orientation = 'Horizontal',
	classNames = [],
	className,
}) => {
	if (buttons.length > 0 || children) {
		return (
			<div
				id={id}
				className={
					className ||
					twMerge(
						`button-list inline-flex flex-wrap gap-3 ${orientation === 'Vertical' ? 'flex-col items-start' : 'flex-row items-center'}`,
						...classNames,
					)
				}
			>
				{buttons &&
					buttons.map((button, key) => (
						<Button key={key} {...button} />
					))}
				{children}
			</div>
		);
	}

	return null;
};

export default ButtonList;
