import React from 'react';
import Image, { IImage } from 'tbk-components/src/components/Image';
import { ILink } from 'tbk-components/src/components/Button';
import SocialIcons, { ISocialIconsProps } from '../SocialIcons';
import { NavItem } from 'tbk-components/src/components/Nav';
import FooterNavColumn from './FooterNavColumn';
import Newsletter, { INewsletterProps } from '../Newsletter';
import { twMerge } from 'tailwind-merge';

export interface IFooterTopProps {
	logo?: IImage;
	logoLink?: ILink;
	socialIcons?: ISocialIconsProps;
	footerMainNav: NavItem[];
	newsletterProps?: INewsletterProps;
}

const FooterTop: React.FC<IFooterTopProps> = ({
	logo,
	logoLink,
	socialIcons,
	footerMainNav,
	newsletterProps,
}) => {
	return (
		<div className="bg-primary">
			<div className="container px-4 py-8 md:py-[120px] md:pl-8 md:pr-20 lg:flex lg:justify-between">
				<div
					className={twMerge(
						'block items-center justify-between xs:flex lg:basis-1/6 lg:flex-col lg:items-start lg:justify-start',
					)}
				>
					<div>
						{logo?.src && logoLink?.href && (
							<a
								href={logoLink.href}
								className="flex items-center justify-start xs:justify-center lg:justify-start"
							>
								<Image {...logo} className="max-w-[188px]" />
							</a>
						)}
					</div>
					{socialIcons?.platforms?.length > 0 && (
						<SocialIcons
							{...socialIcons}
							classNames={['mt-6 xs:mt-0 lg:mt-6']}
							ulClassNames={[
								'justify-start xs:justify-center lg:justify-start',
								'gap-2',
								'xs:gap-3',
							]}
						/>
					)}
				</div>
				<div className="footer__main-nav mb-16 mt-8 grid gap-6 xs:grid-cols-3 lg:mb-0 lg:mt-0 lg:basis-5/12 2xl:ml-[163px] 2xl:mr-[132px] 2xl:gap-[100px]">
					{footerMainNav.map((column, index) => {
						return (
							<FooterNavColumn key={index} navColumn={column} />
						);
					})}
				</div>
				<div className="lg:max-w-[274px]">
					{newsletterProps?.formId && newsletterProps?.children && (
						<Newsletter
							{...newsletterProps}
							className="newsletter__container mt-8 text-white lg:mt-0"
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default FooterTop;
