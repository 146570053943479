import React, { useEffect, useLayoutEffect, useRef } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Image, { IImage } from 'tbk-components/src/components/Image';
import ButtonList from '../Button/ButtonList';
import { IFuzionButtonProps } from '../Button';
import { IAvailableIconOptions } from '../IconSamples';
import Icon from '../Icon';
import { twMerge } from 'tailwind-merge';

export interface IBullet {
	/**
	 * @control richtext
	 */
	bulletText?: string;
	/**
	 * Bullet Icon (optional)
	 */
	bulletIcon?: IAvailableIconOptions['fuzionIconSelectValues'];
	className?: string;
}
export interface IBulletsProps extends IBaseElementProps {
	bullets?: IBullet[];
	iconColor?: 'primary' | 'secondary' | 'dark' | 'light' | 'medium';
	iconClassNames?: string[];
	bulletTextClassNames?: string[];
	bulletsGapSize?: '16px' | '8px' | '32px';
}

/**
 *
 * Bullets
 * @block
 * @icon editor-ul
 */
const Bullets: React.FC<IBulletsProps> = ({
	className,
	classNames = [],
	iconClassNames = [],
	bulletTextClassNames = [],
	bullets = [],
	iconColor = 'primary',
	id,
	bulletsGapSize = '16px',
}) => {
	return (
		<ul
			id={id}
			className={
				className ||
				twMerge('bullets flex flex-col gap-4', ...classNames)
			}
		>
			{bullets.length > 0 &&
				bullets.map((bullet, index) => (
					<li
						key={index}
						className={twMerge(
							'bullet flex items-start',
							bulletsGapSize === '8px'
								? 'gap-2'
								: bulletsGapSize === '32px'
									? 'gap-6'
									: 'gap-4',
						)}
					>
						<span
							className={twMerge(
								`text-${iconColor}`,
								'icon-wrapper h3 relative mb-0 block translate-y-1',
							)}
						>
							{bullet.bulletIcon ? (
								<Icon
									name={bullet.bulletIcon}
									classNames={[
										bullet?.bulletIcon === 'check'
											? 'sm:text-[2.5rem] sm:relative sm:bottom-[0.6rem]'
											: '',
										...iconClassNames,
									]}
								/>
							) : (
								<span className="block flex h-5 w-5 items-center justify-center">
									<span
										className={twMerge(
											'dot-icon block h-1 w-1 rounded-full',
											`bg-${iconColor}`,
										)}
									/>
								</span>
							)}
						</span>
						<p
							className={twMerge(
								'last:mb-0',
								...(bulletTextClassNames || 'mb-0'),
							)}
							dangerouslySetInnerHTML={{
								__html: bullet.bulletText,
							}}
						/>
					</li>
				))}
		</ul>
	);
};

export default Bullets;
