import React from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import BlogInnerHeader, { IBlogInnerHeaderProps } from './BlogInnerHeader';
import BlogsList, { IBlogsListProps, IBlogsListI18nProps } from '../BlogsList';
import Newsletter, { INewsletterProps } from '../Newsletter';
import ProductCard, { IProductCardProps } from '../ProductCard';
import { twMerge } from 'tailwind-merge';
import Image, { IImage } from 'tbk-components/src/components/Image';
import Spacer from '../Spacer';
import { ILink } from 'tbk-components/src/components/Button';
import Breadcrumb from '../Breadcrumb';
import { Generic } from 'react-structured-data';
import LazyJSONLD from '../LazyJSONLD';

export interface IBlogInnerProps extends IBaseElementProps {
	blogHeader?: IBlogInnerHeaderProps;
	featuredImage?: IImage;
	children?: any;
	featuredBlogs?: IBlogsListProps;
	newsletter?: INewsletterProps;
	featuredProductsTitle?: string;
	featuredProducts?: IProductCardProps[];
	gtmListName?: string;
	breadcrumbs?: ILink[];
	blogsListI18n?: IBlogsListI18nProps;
}

const BlogInner: React.FC<IBlogInnerProps> = ({
	id,
	className,
	classNames = [],
	blogHeader,
	featuredImage,
	children,
	featuredBlogs,
	newsletter,
	featuredProductsTitle,
	featuredProducts = [],
	gtmListName,
	breadcrumbs = [],
	blogsListI18n,
}) => {

	if ( featuredProducts.length > 0 ) {
		// @ts-ignore
		gtmViewItemList( gtmListName, featuredProducts );
	}

	return (
		<div
			id={id}
			className={
				className || twMerge('blog-inner__container', ...classNames)
			}
		>
			{breadcrumbs.length > 0 && (
				<div className="container p-4 lg:px-8 lg:pb-2 lg:pt-4">
					<Breadcrumb links={breadcrumbs} />
					<LazyJSONLD>
						<Generic
							type="page"
							jsonldtype="BreadcrumbList"
							schema={{ '@type': 'BreadcrumbList',
								'itemListElement': breadcrumbs.map((breadcrumb, index) => ({
									'@type': 'ListItem',
									'position': index + 1,
									'name': breadcrumb.label,
									'item': breadcrumb.href,
								})),
							}}
						/>
					</LazyJSONLD>
				</div>
			)}
			<div className="bg-light">
				{blogHeader?.postTitle && (
					<div className="container p-4 pt-6 md:py-8 lg:px-8 lg:pb-0 lg:pt-[84px]">
						<BlogInnerHeader {...blogHeader} />
					</div>
				)}
				<div className="container px-4 md:py-8 lg:flex lg:px-8">
					<div className="lg:w-8/12">
						{featuredImage && (
							<>
								<div>
									<Image
										{...featuredImage}
										className="aspect-video rounded-md object-cover object-top"
									/>
								</div>
								<Spacer size="Small" />
							</>
						)}
						<div>{children}</div>
						{featuredBlogs?.blogs?.length > 0 && (
							<>
								<hr className="mt-4 border-secondary md:mt-8 lg:mt-20" />
								<BlogsList
									{...featuredBlogs}
									i18n={blogsListI18n}
								/>
							</>
						)}
					</div>
					<div className="mb-0 xs:mb-8 lg:ml-auto lg:mr-0 lg:w-[calc(3/12*100%-2rem)]">
						{newsletter?.formId && newsletter?.children && (
							<Newsletter
								{...newsletter}
								classNames={[
									'aside-newsletter bg-primary text-white rounded-md overflow-hidden md:!py-16 text-center',
								]}
							/>
						)}
						{featuredProducts?.length > 0 && (
							<div className="mt-8 px-4 lg:mt-20">
								{featuredProductsTitle && (
									<h3 className="mb-4 text-center font-header text-2xl font-bold lg:mb-8">
										{featuredProductsTitle}
									</h3>
								)}
								{featuredProducts.map((product, index) => (
									<ProductCard
										key={index}
										{...product}
										gtmListName={gtmListName}
										enableProductCompare={false}
										excerpt={null}
									/>
								))}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default BlogInner;
