import React, { useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { IImage } from 'tbk-components/src/components/Image';
import Modal from 'tbk-components/src/components/Modal';
import Button from '../Button';
import { IIconSamplesProps } from '../IconSamples';
import Icon from '../Icon';
import { twMerge } from 'tailwind-merge';

export interface IVideo {
	src: string;
}

export interface IVideoProps extends IBaseElementProps {
	/**
	 * @noUI
	 */
	url?: IVideo;
	/**
	 * You can paste embed code from video streaming sites like YouTube or Vimeo here.
	 * @control textarea
	 */
	oembed?: string;
	poster?: IImage;
	playLabel?: string;
	playIcon?: string;
	autoPlay?: boolean;
	/**
	 * @noUI
	 */
	disableControls?: boolean;
	/**
	 * @noUI
	 */
	muted?: boolean;
	/**
	 * @noUI
	 */
	loop?: boolean;
	/**
	 * Forces video render even if oembed is given. This should be used with AdvancedMedia
	 * @noUI
	 */
	videoWallpaper?: boolean;
	/**
	 * To control modal from parent components
	 * @noUI
	 */
	viewModal?: boolean;
	/**
	 * @noUI
	 */
	hideVideoButton?: boolean;
}

export const getYoutubeIDFromURL = (url: string): string | boolean => {
	// https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url tsdorsey's answer
	var regExp =
		/(https?:\/\/)?(((m|www)\.)?(youtube(-nocookie)?|youtube.googleapis)\.com.*(v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i;
	var match = url.match(regExp);
	return match && match[8].length == 11 ? match[8] : false;
};

/**
 * Video
 * @block
 * @requiresHooks
 */
const Video: React.FC<IVideoProps> = ({
	id,
	className,
	classNames = [],
	url,
	oembed,
	poster,
	playIcon,
	playLabel = 'Play video',
	autoPlay = false,
	disableControls = false,
	muted = false,
	loop = false,
	videoWallpaper = false,
	viewModal = false,
	hideVideoButton = false,
}) => {
	const [isPlaying, setIsPlaying] = useState(autoPlay);
	const [showModal, setShowModal] = useState(viewModal);
	const ref = useRef(null);
	const videoModalRef = useRef(null);
	let embedURL = '';
	let thumbURL = poster ? poster.src : '';

	useEffect(() => {
		if (!ref || !ref.current) {
			return;
		}
		if (url && !oembed) {
			const video = ref.current! as HTMLVideoElement;
			video.addEventListener('play', () => {
				setIsPlaying(true);
			});
			video.addEventListener('pause', () => {
				setIsPlaying(false);
			});
		}
	}, [ref, url, oembed]);

	if (oembed) {
		const urlMatches = oembed!.match(/\bhttps?:\/\/\S+/gi);
		if (urlMatches && urlMatches.length > 0) {
			embedURL = urlMatches[0];

			if (
				embedURL.indexOf('youtube.com/') > -1 ||
				embedURL.indexOf('youtu.be/') > -1
			) {
				if (!thumbURL) {
					thumbURL = `https://i1.ytimg.com/vi/${getYoutubeIDFromURL(
						embedURL,
					)}/maxresdefault.jpg`;
				}
			}
		}
	}

	useEffect(() => {
		if (!ref.current) {
			return;
		}
		const videoEl = ref.current as HTMLVideoElement;

		if (videoEl) {
			if (isPlaying) {
				videoEl?.play();
			} else {
				videoEl?.pause();
			}
		}
	}, [isPlaying]);

	const addTabIndex = () => {
		setTimeout(() => {
			const iframe = videoModalRef.current.querySelector('iframe');

			if (!iframe.hasAttribute('tabindex')) {
				iframe.setAttribute('tabindex', '0');
			}
		}, 0);
	};

	return (
		<div
			id={id}
			className={
				className ||
				cn(
					'video relative z-0',
					isPlaying && 'video--playing',
					thumbURL && 'video--poster',
					videoWallpaper && 'video--wallpaper',
					...classNames,
				)
			}
			style={
				!isPlaying && thumbURL
					? { backgroundImage: `url("${thumbURL}")` }
					: {}
			}
		>
			{((videoWallpaper && url) ||
				(url && url.src && !oembed) ||
				poster?.src) && (
				<video
					ref={ref}
					controls={!disableControls}
					poster={poster?.src}
					autoPlay={false}
					muted={muted}
					loop={loop}
					playsInline={loop}
				>
					<source src={url?.src} type="video/mp4" />
					Your browser does not support the video tag.
				</video>
			)}

			{oembed && showModal && (
				<Modal
					className="modal--video"
					onCancel={() => setShowModal(false)}
					headerClassNames={[]}
					bodyClassNames={[]}
					showCancel={false}
					showSubmit={false}
					trapFocus
				>
					<div
						className="video__embed"
						dangerouslySetInnerHTML={{
							__html: `${oembed}`,
						}}
						ref={videoModalRef}
					/>
				</Modal>
			)}

			{!hideVideoButton && (videoWallpaper || !isPlaying) && (
				<Button
					buttonType="Primary"
					className={twMerge(
						'btn-play z-3 btn btn-primary absolute bottom-1/2 left-1/2 -translate-x-1/2 translate-y-1/2 p-3',
						playIcon &&
							'h-12 w-12 !bg-white p-0 !text-primary md:h-24 md:w-24',
					)}
					onClick={() => {
						if (oembed) {
							setShowModal(true);
							addTabIndex();
						} else {
							setIsPlaying((isPlaying) => !isPlaying);
						}
					}}
					aria-label={playLabel || 'Play video'}
				>
					{playIcon ? (
						<Icon
							name={playIcon}
							classNames={['text-[24px] md:text-[48px] mb-0']}
						/>
					) : playLabel ? (
						playLabel
					) : (
						'Play video'
					)}
				</Button>
			)}
		</div>
	);
};

export default Video;
