import React from 'react';
import { ColorTypes, colorPalette } from '../../../tailwind-colors';

type BgPrefix<T> = T extends string ? `bg-${T}` : never;
type BorderPrefix<T> = T extends string ? `border-${T}` : never;
type TextPrefix<T> = T extends string ? `text-${T}` : never;

export interface IBgColorsProps {
	options?: BgPrefix<keyof ColorTypes>;
}

export interface IBorderColorsProps {
	options?: BorderPrefix<keyof ColorTypes>;
}

export interface ITextColorsProps {
	options?: TextPrefix<keyof ColorTypes>;
}

const Colors = () => {
	return (
		<>
			<h2>Colour Palette</h2>
			<div className="grid grid-cols-8 gap-4">
				{Object.keys(colorPalette).map((color) => (
					<div
						key={color}
						className={`bg-${color} text-center text-${color}-text flex aspect-square items-center justify-center p-2`}
					>
						<code
							className={color === 'current' ? 'text-white' : ''}
						>
							{color}
						</code>
					</div>
				))}
			</div>
			<h2>Gradient</h2>
			<h3>Linear</h3>
			<div className="grid grid-cols-4 gap-4">
				<div className="bg-gradient-general flex aspect-square items-center justify-center p-2 text-center">
					<code>.bg-gradient-general</code>
				</div>
				<div className="bg-gradient-general bg-gradient-overlay flex aspect-square items-center justify-center p-2 text-center">
					<code>.bg-gradient-general.bg-gradient-overlay</code>
				</div>
			</div>
			<h3>Radial</h3>
			<div className="grid grid-cols-4 gap-4">
				<div className="bg-gradient-general-radial flex aspect-square items-center justify-center p-2 text-center">
					<code>.bg-gradient-general-radial</code>
				</div>
				<div className="bg-gradient-general-radial bg-gradient-overlay flex aspect-square items-center justify-center p-2 text-center">
					<code>.bg-gradient-general-radial.bg-gradient-overlay</code>
				</div>
				<div className="bg-gradient-rainbow text-dark flex aspect-square items-center justify-center p-2 text-center">
					<code>.bg-gradient-rainbow</code>
				</div>
				<div className="bg-gradient-rainbow bg-gradient-overlay text-dark flex aspect-square items-center justify-center p-2 text-center">
					<code>.bg-gradient-rainbow.bg-gradient-overlay</code>
				</div>
			</div>
			<h3>Product Type</h3>
			<div className="grid grid-cols-4 gap-4">
				<div className="bg-gradient-engineered-hardwood flex aspect-square items-center justify-center p-2 text-center">
					<code>.bg-gradient-engineered-hardwood</code>
				</div>
				<div className="bg-gradient-engineered-hardwood bg-gradient-overlay flex aspect-square items-center justify-center p-2 text-center">
					<code>
						.bg-gradient-engineered-hardwood.bg-gradient-overlay
					</code>
				</div>

				<div className="bg-gradient-luxury-vinyl text-dark flex aspect-square items-center justify-center p-2 text-center">
					<code>.bg-gradient-luxury-vinyl</code>
				</div>
				<div className="bg-gradient-luxury-vinyl bg-gradient-overlay text-dark flex aspect-square items-center justify-center p-2 text-center">
					<code>.bg-gradient-luxury-vinyl.bg-gradient-overlay</code>
				</div>

				<div className="bg-gradient-laminate text-dark flex aspect-square items-center justify-center p-2 text-center">
					<code>.bg-gradient-laminate</code>
				</div>
				<div className="bg-gradient-laminate bg-gradient-overlay text-dark flex aspect-square items-center justify-center p-2 text-center">
					<code>.bg-gradient-laminate.bg-gradient-overlay</code>
				</div>

				<div className="bg-gradient-carpet-tile text-dark flex aspect-square items-center justify-center p-2 text-center">
					<code>.bg-gradient-carpet-tile</code>
				</div>
				<div className="bg-gradient-carpet-tile bg-gradient-overlay text-dark flex aspect-square items-center justify-center p-2 text-center">
					<code>.bg-gradient-carpet-tile.bg-gradient-overlay</code>
				</div>

				<div className="bg-gradient-accessories flex aspect-square items-center justify-center p-2 text-center">
					<code>.bg-gradient-accessories</code>
				</div>

				<div className="bg-gradient-accessories bg-gradient-overlay flex aspect-square items-center justify-center p-2 text-center">
					<code>.bg-gradient-accessories.bg-gradient-overlay</code>
				</div>
			</div>
		</>
	);
};

export default Colors;
