import React, { useState } from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import cn from 'classnames';
import ContentSection, { IContentSectionProps } from '../ContentSection';
import { IImage } from 'tbk-components/src/components/Image';
import TriangleImage from '../TriangleImage';
import Button, { ILink } from '../Button';

interface IDealerLocatorBarI18nProps {
	inputPlaceholder?: string;
	inputLabel?: string;
}

export interface IDealerLocatorBarProps extends IBaseElementProps {
	/**
	 * Text
	 * @control richtext
	 */
	title?: string;
	excerpt?: string;
	image?: IImage;
	submitButton?: ILink;
	i18n?: IDealerLocatorBarI18nProps;
	disableForm?: boolean;
}

/**
 * Dealer Locator Bar
 * @block
 * @icon location
 */
const DealerLocatorBar: React.FC<IDealerLocatorBarProps> = ({
	id,
	className,
	classNames = [],
	title,
	excerpt,
	image,
	submitButton,
	i18n,
	disableForm,
}) => {
	const [postalCode, setPostalCode] = useState('');

	return (
		<div className="bg-gradient-rainbow">
			<div
				id={id}
				className={
					className ||
					cn(
						'store-locator-bar__container relative flex flex-col-reverse overflow-hidden md:grid md:grid-cols-12',
						...classNames,
					)
				}
			>
				{image && (
					<TriangleImage
						classNames={[
							'col-span-5',
							'lg:absolute lg:left-0',
							'sm:translate-x-0 md:-translate-x-[120px] lg:translate-x-0',
							'h-full',
						]}
						image={image}
						mask="Locator Area"
					/>
				)}
				<ContentSection
					title={title}
					excerpt={excerpt}
					classNames={[
						'col-span-6 px-4 md:px-8 py-8 md:py-30 md:justify-center max-w-[672px] md:block !gap-0 z-[1] lg:col-start-6',
					]}
					excerptClassName="md:leading-[32px] !m-0 [&>p]:!mb-0"
					titleClassName="mb-4 md:mb-8"
				>
					{!disableForm ? (
						<form
							className="mt-8 flex flex-wrap items-center gap-11 md:flex-nowrap lg:mt-20"
							onSubmit={(e) => {
								e.preventDefault();
								const url = `${submitButton.href}${postalCode && `?postal_code=${postalCode}`}`;

								if (submitButton?.target === '_blank') {
									window.open(url, '_blank');
								} else {
									window.location.href = url;
								}
							}}
						>
							<label htmlFor="postal_code" className="sr-only">
								{i18n?.inputLabel || 'Postal Code'}
							</label>
							<input
								type="text"
								id="postal_code"
								placeholder={
									i18n?.inputPlaceholder ||
									'City or Postal Code'
								}
								className="w-full max-w-[200px] rounded-none border-b border-t border-primary bg-transparent p-4 text-lg leading-none text-primary placeholder:text-primary"
								onChange={(e) => {
									setPostalCode(e.target.value);
								}}
							/>
							{(submitButton?.label || submitButton?.href) && (
								<input
									type="submit"
									className="btn btn-primary"
									value={submitButton.label}
								/>
							)}
						</form>
					) : (
						<Button
							link={submitButton}
							classNames={['mt-8', 'self-start']}
						></Button>
					)}
				</ContentSection>
			</div>
		</div>
	);
};

export default DealerLocatorBar;
