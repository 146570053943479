import React, { useState, useContext, useEffect, useRef } from 'react';
import withAppContext from 'tbk-components/src/hooks/withAppContext';
import { useHashAsState } from 'tbk-components/src/hooks/index';
import qs from 'qs';
import Loading from 'tbk-components/src/components/Loading/index';
import ElasticMapper from 'tbk-components/src/services/ElasticMapper';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import cn from 'classnames';
import { IImage } from 'tbk-components/src/components/Image';
import BlogRollFilterBar from './BlogRollFilterBar';
import ProductCards from '../ProductCards';
import BlogRollPagination from './BlogRollPagination';
import Icon from '../Icon';
import { twMerge } from 'tailwind-merge';
import ProductIndexAdBanner, {
	IProductIndexAdBannerProps,
} from '../ProductIndexAdBanner';
import Accordion from '../Accordion';
import Newsletter, { INewsletterProps } from '../Newsletter';
import { useTranslation } from 'react-i18next';
import Spacer from '../Spacer';
import PageBanner from '../PageBanner';
import useScreenSize from '../../hooks/useScreenSize';
import { ICustomCarouselI18n } from '../ProductCard/CustomCarouselWithImagePreviews';

export interface IIndexPageI18n {
	searchPlaceholder: string;
	searchFilters: [];
	beforePageSizes: string;
	afterPageSizes: string;
	sortBy: string;
	noRecordsFound: string;
	resetFilters: string;
	oneResultFound: string;
	manyResultsFound: string;
	filters: string;
	close?: string;
	displaying?: string;
	of?: string;
	result?: string;
	results?: string;
	noResults?: string;
	hideFilters?: string;
	openFilters?: string;
	advancedFilters?: string;
	hide?: string;
	show?: string;
	sortByLabel?: string;
	alphabeticalAZ?: string;
	alphabeticalZA?: string;
	priceLowToHigh?: string;
	priceHighToLow?: string;
	compareProducts?: string;
	viewComparison?: string;
	hideComparison?: string;
	comparisonLimitDisclaimer?: string; // Compare up to 3 products
	quickView?: string; //Quick View
	sku?: string; //SKU:
	compare?: string; //Compare
	carouselI18n?: ICustomCarouselI18n;
	productWidth?: string;
	clearAllFilters?: string;
}

export interface IProductIndexPageProps extends IBaseElementProps {
	/**
	 * @noUI
	 */
	context?: any;
	/**
	 * @noUI
	 */
	assets?: any;
	/**
	 * @noUI
	 */
	archivePage?: string;
	/**
	 * Style
	 */
	style: 'default' | 'index' | 'search';
	// tiles?: IBlogRollTiles[];
	/**
	 * @noUI
	 */
	initialAssets?: any;
	/**
	 * @noUI
	 */
	initialResults?: any;
	/**
	 * @noUI
	 */
	initialFilters?: any;
	showOnlyLatestPosts?: boolean;
	numberOfPostsToShow?: number;
	showPagination?: boolean;
	faqItems?: any;
	/**
	 * @noUI
	 */
	adBannerProps?: IProductIndexAdBannerProps;
	/**
	 * @noUI
	 */
	posts?: {
		total: number;
		hits: any[];
	};
	/**
	 * @noUI
	 */
	fetchArgs?: any;
	/**
	 * @noUI
	 * prevent WP user from accessing this option. Keeping it here just in case
	 */
	filterBarTargetUrl?: string;
	filterSearchPlaceholder?: string;
	filterLabel?: string;
	displaySearchTaxonomies?: boolean;
	displaySearchBar?: boolean;
	noResultsMessage?: string;
	enableStartScreen?: boolean;
	newsletter?: INewsletterProps;
	startScreenMessage?: string;
	placeholderImage?: IImage;
	i18n?: IIndexPageI18n;
}

const ProductIndex: React.FC<IProductIndexPageProps> = withAppContext(
	({
		context,
		className,
		classNames = [],
		id,
		initialResults,
		initialAssets,
		initialFilters,
		showOnlyLatestPosts = false,
		numberOfPostsToShow,
		showPagination = true,
		displaySearchBar = false,
		faqItems,
		posts,
		fetchArgs,
		adBannerProps,
		displaySearchTaxonomies = true,
		enableStartScreen = false,
		newsletter,
		children,
		i18n,
	}) => {
		const gtmListName = 'Product Index';
		const useEffectOnce = (callback: () => void, when: boolean) => {
			const hasRunOnce = React.useRef(false);
			React.useEffect(() => {
				if (when && !hasRunOnce.current) {
					callback();
					hasRunOnce.current = true;
				}
			}, [when]);
		};
		const [initialized, setInitialized] = React.useState(false);
		const isLgAndUp = useScreenSize() >= 1024;

		const useUnload = (fn: () => void) => {
			const cb = React.useRef(fn);

			useEffect(() => {
				const onUnload = cb.current;
				window.addEventListener('beforeunload', onUnload);
				return () => {
					window.removeEventListener('beforeunload', onUnload);
				};
			}, [cb]);
		};

		// check for product type view
		const { product_type_view: productTypeView, ...initialDefaults } =
			initialFilters;

		const { t } = useTranslation();

		// @ts-ignore
		const { assets: contextAssets, Api } = useContext(context);
		const [assets, setAssets] = useState<any>(null);

		const [mobileFiltersOpen, setMobileFiltersOpen] =
			useState<boolean>(false);

		const [productCards, setProductCards] = useState<any>(null);
		const [productCompareLink, setProductCompareLink] = useState<any>(null);
		const [total, setTotal] = useState<number | null>(null);

		const [termMap, setTermMap] = useState<any>(null);
		const [loading, setLoading] = useState<boolean>(false);
		const [searchResultsLoading, setSearchResultsLoading] =
			useState<boolean>(false);
		const [startScreen, setStartScreen] = useState<boolean>(false);
		const [displayAdBannerProps, setDisplayAdBannerProps] =
			useState<any>(adBannerProps);
		const [displayFaqItems, setDisplayFaqItems] = useState<any>(faqItems);
		const [startRecord, setStartRecord] = useState<number>(0);
		const [endRecord, setEndRecord] = useState<number>();
		const [pageBanner, setPageBanner] = useState<any>(null);

		const taxonomy_parameters = ((taxonomies) => {
			const t: any = {};
			(taxonomies || []).forEach((tax: any) => {
				t[tax.taxonomy] = 'csv';
			});
			return t;
		})(assets?.taxonomies);

		const advanced_taxonomy_parameters = ((taxonomies) => {
			const t: any = {};
			(taxonomies || []).forEach((tax: any) => {
				t[tax.taxonomy] = 'csv';
			});
			return t;
		})(assets?.advancedTaxonomies);

		const hashDefaults = {
			total: total,
			...initialDefaults,
			sortBy: 'alpha-asc',
		};

		const [tempHashDefaults, setTempHashDefaults] =
			useState<any>(hashDefaults);

		const [state, hash, updateState, normalizeState] = useHashAsState({
			parameters: {
				page: 'int',
				...taxonomy_parameters,
				...advanced_taxonomy_parameters,
				total: 'int',
				sortBy: 'string',
			},
			defaults: hashDefaults,
		});

		const setAssetsAndTermMap = (results: any) => {
			setAssets(results);
			const map: any = {};
			if (results.taxonomies) {
				results.taxonomies.forEach((taxonomy: any) => {
					taxonomy.terms.forEach((term: any) => {
						map[term.term_id] = term;
					});
				});
			}

			if (results.advancedTaxonomies) {
				results.advancedTaxonomies.forEach((taxonomy: any) => {
					taxonomy.terms.forEach((term: any) => {
						map[term.term_id] = term;
					});
				});
			}

			setTermMap(map);
		};

		const setProductResults = (results: any) => {
			let postsPerPage = assets?.posts_per_page || 12;
			let currentPage = state.page || 1;
			setDisplayAdBannerProps(null);
			setDisplayFaqItems(null);

			if (productTypeView) {
				// get the element that has class 'filter-accordion-product_type' and add the class 'hidden'
				setTimeout(() => {
					(
						document.querySelector(
							'.filter-accordion-product-type, .filter-accordion-type-de-produit',
						) as HTMLInputElement
					).hidden = true;
				}, 100);
			}

			if (results.adBannerProps) {
				setDisplayAdBannerProps(results.adBannerProps);
			}

			if (results.productCompareLink) {
				setProductCompareLink(results.productCompareLink);
			}

			if (results.faqItems) {
				setDisplayFaqItems(results.faqItems);
			}

			setTotal(results.products.total);
			setProductCards(results.products.hits);

			if (results.products.total > 0) {
				setStartRecord((currentPage - 1) * postsPerPage + 1);
			} else {
				setStartRecord(0);
			}
			setEndRecord(
				Math.min(currentPage * postsPerPage, results.products.total),
			);

			//@ts-ignore
			gtmViewItemList(gtmListName, results.products.hits);
		};

		// ref to pass down to pagination so it can force a scroll to top of blogRoll when user changes page.
		const topRef = useRef(null);

		useEffect(() => {
			if (assets) {
				updateState({ total: total });
			}
		}, [total, assets]);

		useEffectOnce(() => {
			setAssetsAndTermMap(initialAssets);
			setProductResults(initialResults);
		}, initialized);

		useEffect(() => {
			setInitialized(true);
		}, []);

		// Let's remove the overflow on the body when the mobile filters are open and then restore it if the user is on a larger screen or closes the mobile filters.
		useEffect(() => {
			if (!mobileFiltersOpen || isLgAndUp) {
				document.body.style.overflow = 'auto';
			} else {
				document.body.style.overflow = 'hidden';
			}
		}, [mobileFiltersOpen, isLgAndUp]);

		const { total: ignored, ...relevantArgs } = state;
		const relevantHash = qs.stringify(relevantArgs);
		// Here's the effect that actually gets the posts from the server whenever the search has changed.
		useEffect(() => {
			// if the state is identical to the hashDefault, don't do anything
			const { total: ignore, ...compareHash } = tempHashDefaults;
			if (relevantHash == qs.stringify(compareHash)) {
				setTempHashDefaults({ total: 0 });
				return;
			}

			if (posts && typeof posts === 'object' && 'total' in posts) {
				setTotal(posts.total);
				setProductCards(ElasticMapper(posts));
				if (posts.total > 0) {
					setStartRecord(1);
				}
				setEndRecord(posts.total);
				if (assets.posts_per_page > posts.total) {
					setEndRecord(assets.posts_per_page);
				}
				return;
			}
			if (!assets) {
				return;
			}
			let args = qs.parse(relevantHash);
			args.posts_per_page = assets?.posts_per_page;
			if (fetchArgs?.posts_per_page) {
				args.posts_per_page = fetchArgs.posts_per_page;
			}
			if (fetchArgs) {
				args = {
					...fetchArgs,
					...args,
				};
			}

			// override whatever is in args to only show posts
			if (showOnlyLatestPosts) {
				args.post_type = 'post';
			}
			if (numberOfPostsToShow) {
				args.posts_per_page = `${numberOfPostsToShow}`; // cast to string
			}

			setLoading(true);
			setSearchResultsLoading(true);

			// show the start screen if blogRoll is NOT set to show latest posts AND search state hasn't been changed
			if (
				enableStartScreen &&
				!showOnlyLatestPosts &&
				Object.keys(state).length == 2 &&
				state.search == '' &&
				state.page == 1
			) {
				setStartScreen(true);
				setLoading(false);
				setSearchResultsLoading(false);
			} else {
				let resetScreen = true;
				if (productTypeView) {
					args = {
						product_type_view: productTypeView,
						...args,
					};
				}
				Api.getProductResults(args)
					.then((results: any) => {
						setProductResults(results);
						resetScreen = true;
					})
					.finally(() => {
						setSearchResultsLoading(false);
						if (resetScreen) {
							setLoading(false);
							setStartScreen(false);
						}
					});
			}
		}, [
			relevantHash,
			fetchArgs,
			posts,
			showOnlyLatestPosts,
			numberOfPostsToShow,
		]);

		useEffect(() => {
			const assetReqArgs = { ...fetchArgs };

			if (contextAssets) {
				setAssetsAndTermMap(contextAssets);
			}

			if (!topRef.current) {
				return;
			}
		}, [contextAssets, Api, fetchArgs]);

		const spacer = <Spacer size="Medium" />;
		const loadingScreen = (
			<div className="loading-wrapper container mx-auto block flex justify-center p-12 text-center">
				<Loading />
			</div>
		);

		if (!assets) {
			return (
				<>
					{spacer}
					{spacer}
					{loadingScreen}
				</>
			);
		}

		return (
			<>
				<PageBanner {...assets.pageBanner} />
				<div
					id={id}
					className={
						className ||
						cn(
							'product-index relative block px-0 md:px-8 md:py-8',
							...classNames,
						)
					}
				>
					<div
						className={`product-index__container mx-auto flex w-full max-w-[1375px] flex-col-reverse gap-4 lg:flex-row lg:gap-8`}
					>
						<div
							className={twMerge(
								`product-index__aside relative block w-full lg:z-[1] lg:max-w-[320px]`,
							)}
						>
							<div
								className={twMerge(
									'product-index__aside--filters fixed left-0 top-0 z-[60] h-dvh w-full bg-white lg:relative lg:z-20 lg:h-auto lg:bg-transparent lg:p-0',
									'hidden lg:block',
									mobileFiltersOpen && 'block',
								)}
							>
								<div className="absolute right-0 top-0 z-[100] mb-0 flex w-full justify-end bg-gradient-to-b from-white to-[#ffffffb5] p-4 lg:hidden">
									<button
										onClick={() => {
											setMobileFiltersOpen(false);
										}}
										className="flex items-center gap-3 text-sm text-primary transition-opacity hover:opacity-50"
									>
										<Icon
											classNames={['text-lg scale-150']}
											name="close"
										/>
									</button>
								</div>
								<div
									className={twMerge(
										'relative z-10 h-full overflow-auto px-5 pb-16 pt-12 md:z-0 lg:p-0',
									)}
								>
									<BlogRollFilterBar
										searchTaxonomies={
											displaySearchTaxonomies
										}
										displaySearchBar={displaySearchBar}
										productTypeView={productTypeView}
										filters={state}
										assets={assets}
										onChange={updateState}
										onMobileFiltersClose={() => {
											setMobileFiltersOpen(false);
										}}
										i18n={i18n}
										total={total}
										searchResultsLoading={
											searchResultsLoading
										}
									/>
								</div>
							</div>
							<div className="aside-newsletter relative z-20 mt-8 block">
								{newsletter?.formId && newsletter?.children && (
									<Newsletter
										{...newsletter}
										classNames={[
											'aside-newsletter bg-primary text-white md:rounded-md overflow-hidden md:!py-16 text-center',
											mobileFiltersOpen && 'hidden',
										]}
									/>
								)}
							</div>
						</div>
						{searchResultsLoading && loadingScreen}
						{!searchResultsLoading && (
							<div
								className="product-index__content z-10 w-full"
								ref={topRef}
							>
								{displayAdBannerProps && (
									<ProductIndexAdBanner
										{...displayAdBannerProps}
									/>
								)}
								<div className="product-listing-wrapper px-4 md:px-0">
									<div className="products-sort-bar mt-4 flex flex-row items-center justify-between gap-3 lg:mt-10">
										{total && (
											<p className="mb-0 hidden text-sm lg:block">
												{i18n?.displaying ||
													t('Displaying')}{' '}
												<b>
													{startRecord} - {endRecord}
												</b>{' '}
												{i18n?.of || t('of')} {total}{' '}
												{total == 1
													? i18n?.result ||
														t('result')
													: i18n?.results ||
														t('results')}
											</p>
										)}
										{!total && (
											<h3 className="mb-0 hidden lg:block">
												{i18n?.noRecordsFound ||
													t('No results found')}
											</h3>
										)}
										<button
											className="h4 mb-0 flex items-center gap-2 text-medium lg:hidden"
											onClick={() => {
												setMobileFiltersOpen(true);
											}}
										>
											<span>
												{i18n?.filters ?? 'Filters'}
											</span>
											<Icon
												name="filters"
												classNames={['h3 mb-0']}
											/>
										</button>
										<div className="product-sort-by-filter relative flex gap-2 text-sm">
											<span className="text-medium">
												{i18n.sortByLabel || 'Sort by:'}
											</span>
											<div className="relative block">
												<select
													name="sortBy"
													id="sortBy"
													className="form-select text-sm"
													onChange={(e) => {
														updateState({
															sortBy: e.target
																.value,
														});
														document
															.querySelector(
																'body',
															)
															.scrollIntoView({
																behavior:
																	'smooth',
																block: 'start',
															});
													}}
												>
													<option
														value="alpha-asc"
														selected={
															state.sortBy ===
															'alpha-asc'
														}
													>
														{i18n.alphabeticalAZ ||
															'Alphabetical - A to Z'}
													</option>
													<option
														value="alpha-desc"
														selected={
															state.sortBy ===
															'alpha-desc'
														}
													>
														{i18n.alphabeticalZA ||
															'Alphabetical - Z to A'}
													</option>
													<option
														value="price-asc"
														selected={
															state.sortBy ===
															'price-asc'
														}
													>
														{i18n.priceLowToHigh ||
															'Price - Low to High'}
													</option>
													<option
														value="price-desc"
														selected={
															state.sortBy ===
															'price-desc'
														}
													>
														{i18n.priceHighToLow ||
															'Price - High To Low'}
													</option>
												</select>
												<span className="absolute -bottom-2.5 right-0 flex h-[1px] w-full items-center bg-secondary pr-2"></span>
											</div>
										</div>
									</div>
									{productCards?.length > 0 ? (
										<ProductCards
											classNames={['mt-10']}
											desktopGridSize={3}
											id={id}
											productCards={productCards}
											gtmListName={gtmListName}
											productCompareLink={
												productCompareLink || ''
											}
											compact={false}
											i18n={{
												compareProducts:
													i18n.compareProducts ||
													'Compare Products',
												viewComparison:
													i18n.viewComparison ||
													'View Comparison',
												hideComparison:
													i18n.hideComparison ||
													'Hide Comparison',
												comparisonLimitDisclaimer:
													i18n.comparisonLimitDisclaimer ||
													'Compare up to 3 products',
												quickView:
													i18n.quickView ||
													'Quick View',
												sku: i18n.sku || 'SKU',
												compare:
													i18n.compare || 'Compare',
												carouselI18n: i18n.carouselI18n,
												productWidth:
													i18n.productWidth ||
													'Width',
											}}
										/>
									) : (
										<>
											{!total && (
												<h3 className="my-10 block text-center lg:hidden">
													{i18n?.noRecordsFound ||
														t('No results found')}
												</h3>
											)}
										</>
									)}
									{showPagination &&
										productCards?.length > 0 && (
											<>
												<BlogRollPagination
													page={state.page || 1}
													perPage={
														fetchArgs?.posts_per_page ||
														assets?.posts_per_page
													}
													total={total}
													onChange={(page: any) =>
														updateState({
															page,
														})
													}
													topRef={topRef}
												/>
											</>
										)}
								</div>
								<div className="bottom-content block px-4 pb-12 pt-12 md:px-0 md:pt-[120px] lg:pb-[88px]">
									<div className="accordion-faq">
										<h3 className="h2 mb-5 lg:mb-10">
											FAQs
										</h3>
										<Accordion items={displayFaqItems} />
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</>
		);
	},
);
export default ProductIndex;
