import React, { useState, useMemo, useRef, useEffect } from 'react';
import AddressString from '../DealerLocator/AddressString';
import Button, { ILink } from '../Button';
import { genAddressStr } from '../DealerLocator/DealerCard';
import Map from '../Map';
import { defaultMapOptions } from '../DealerLocator';
import ImageToSVG from '../ImageToSVG';
import { kebabCase } from 'lodash';
import Collapse from 'bootstrap/js/dist/collapse';

// @ts-ignore
import lineBurstSrc from '../../assets/images/line-burst.svg';

interface ICollectionProps {
	name?: string;
	availability?: boolean;
	collections?: string[];
}

const lineBurst = {
	src: lineBurstSrc,
	alt: 'Line Burst',
	width: 0,
	height: 0,
};

interface IDealerI18nProps {
	direction?: string;
	details?: string;
	close?: string;
	authorized?: string;
	requestQuote?: string;
	visitWebsite?: string;
	collections?: string;
	notAvailable?: string;
}

export interface IDealerProps {
	id?: number;
	name?: string;
	searchIndex?: number;
	directionsUrl?: string;
	streetAddress?: string;
	city?: string;
	province?: string;
	postalCode?: string;
	country?: string;
	phone?: string;
	dealerCptUrl?: ILink;
	position?: google.maps.LatLngLiteral;
	isAuthorized?: boolean;
	dealerWebsiteUrl?: string;
	collections?: ICollectionProps[];
	note?: string;
	distance?: string;
	dealer_contacts?: any;
	salespersons?: any;
	i18n?: IDealerI18nProps;
}

const generateMarker = () => {
	const markerIcon = document.createElement('div');
	markerIcon.className =
		'flex items-center justify-center w-9 h-9 bg-primary rounded-full text-white font-body text-lg';
	markerIcon.innerHTML = '<i class="icon-map-pin text-xl"/>';

	return markerIcon;
};

const Dealer: React.FC<IDealerProps> = ({
	name,
	searchIndex,
	streetAddress,
	city,
	province,
	postalCode,
	country,
	phone,
	position,
	isAuthorized,
	dealerWebsiteUrl,
	collections = [],
	note,
	i18n,
}) => {
	const [map, setMap] = useState<google.maps.Map | null>(null);
	const combinedMapOptions = useMemo(() => {
		return { ...defaultMapOptions };
	}, [defaultMapOptions]);
	const ref = useRef(null);
	const listRef = useRef(null);

	useEffect(() => {
		if (!ref.current) return;

		const element = ref.current as HTMLSpanElement;
		const splitWords = element.innerText.split(' ');

		element.innerHTML = `<strong class="text-bold">${splitWords[0]}</strong> ${splitWords.slice(1).join(' ')}`;
	}, []);

	useEffect(() => {
		if (!listRef.current) return;

		const collapseList = [].slice.call(
			listRef.current.querySelectorAll('.dealer__collections'),
		);

		collapseList.map((collapse: HTMLElement) => {
			const collapseInstance = new Collapse(collapse, {
				toggle: false,
			});

			collapse.addEventListener('show.bs.collapse', () => {
				collapse.style.maxHeight = `${collapse.scrollHeight + 12}px`;
			});

			collapse.addEventListener('shown.bs.collapse', () => {
				collapse.classList.remove('collapse');
			});

			collapse.addEventListener('hide.bs.collapse', () => {
				collapse.style.maxHeight = '0';
			});

			return collapseInstance;
		});

		return () => {
			collapseList.map((collapse: HTMLElement) => {
				const collapseInstance = Collapse.getInstance(collapse);

				if (collapseInstance) {
					collapseInstance.dispose();
				}
			});
		};
	}, []);

	return (
		<div className="dealer__container">
			<div className="container p-4 md:px-8 lg:relative">
				<div className="lg:w-5/12">
					{isAuthorized && (
						<div className="bg-gradient-rainbow-radial inline-flex items-center justify-start gap-[7px] rounded-bl-md rounded-tl-md pr-5 font-header text-[10px] [clip-path:polygon(0_0,100%_0,92%_100%,0_100%)] lg:text-xs">
							<ImageToSVG
								image={lineBurst}
								classNames={['text-medium']}
							/>
							<span ref={ref}>
								{i18n?.authorized || 'Authorized Fuzion Dealer'}
							</span>
						</div>
					)}
					{name && <h1 className="lg:mt-2 lg:text-6xl">{name}</h1>}
					{(streetAddress || city || province || country) && (
						<p className="mb-0 mt-4 text-primary lg:mt-8 lg:text-2xl">
							<AddressString
								streetAddress={streetAddress}
								city={city}
								province={province}
								postalCode={postalCode}
								country={country}
							/>
						</p>
					)}
					{(streetAddress || city || province) && (
						<Button
							link={{
								href: `https://www.google.com/maps/dir/?api=1&origin=current+location&destination=${genAddressStr(
									streetAddress,
									city,
									province,
								)}`,
								label: i18n?.direction || 'Get Directions',
								target: '_blank',
							}}
							buttonType="Medium"
							size="underline"
							classNames={['text-sm !text-medium']}
						/>
					)}
					<div className="mt-6 flex flex-wrap gap-x-2 gap-y-4">
						{phone && (
							<Button
								link={{
									href: `tel:${phone.replace(/\W/g, '')}`,
									label: phone,
								}}
								buttonType="Primary"
								size="sm"
								classNames={[
									'!px-4 lg:!px-10 lg:!py-4 lg:!text-lg',
								]}
							/>
						)}
						{dealerWebsiteUrl && (
							<Button
								link={{
									href: dealerWebsiteUrl,
									label:
										i18n?.visitWebsite || 'Visit Website',
									target: '_blank',
								}}
								buttonType="Primary"
								size="underline"
								icon="link"
								classNames={[
									'flex-row-reverse text-sm text-primary lg:!text-base',
								]}
							/>
						)}
					</div>
					{position?.lat && position?.lng && (
						<div className="mt-10 h-[240px] lg:absolute lg:right-8 lg:top-4 lg:mt-0 lg:aspect-square lg:h-auto lg:max-h-full lg:w-[calc(6/12*100%-48px)]">
							<Map
								{...combinedMapOptions}
								markers={[
									// @ts-ignore
									{ position, content: generateMarker() },
								]}
								onMapLoaded={setMap}
								onMapClick={() => {
									const url = `https://www.google.com/maps/dir/?api=1&origin=current+location&destination=${genAddressStr(
										streetAddress,
										city,
										province,
									)}`;
									window.open(url, '_blank');
								}}
							/>
						</div>
					)}
					{collections?.length > 0 && (
						<div className="mt-8 lg:mt-20">
							<h2 className="mb-4 mb-8 text-[26px] lg:text-4xl">
								{i18n?.collections ||
									'Available Fuzion Collections'}
								{note && '*'}
							</h2>
							<ul
								ref={listRef}
								className="list-none border-b border-secondary"
							>
								{collections?.map((collection, index) => (
									<li key={index}>
										<button
											type="button"
											data-bs-toggle="collapse"
											data-bs-target={`#collapse-${kebabCase(collection?.name)}`}
											aria-expanded="false"
											aria-controls={`collapse-${kebabCase(collection?.name)}`}
											className="group/button flex w-full items-center justify-between border-t border-secondary py-3 font-header text-base lg:py-4 lg:text-2xl [&:disabled]:text-medium"
											disabled={
												collection?.availability ===
												false
											}
										>
											{collection?.name}
											{collection?.availability !==
												false && (
												<>
													<i className="icon-plus text-base group-[:not(.collapsed)]/button:hidden lg:text-2xl" />
													<i className="icon-minus text-base group-[.collapsed]/button:hidden lg:text-2xl" />
												</>
											)}
											{collection?.availability ===
												false && (
												<span className="btn btn-sm btn-outline !px-3 !py-[6px] !text-xs">
													{i18n?.notAvailable ||
														'Not Available'}
												</span>
											)}
										</button>
										{collection?.availability !== false && (
											<ul
												id={`collapse-${kebabCase(collection.name)}`}
												className="dealer__collections max-h-0 overflow-hidden pl-4 transition-all duration-300 ease-in-out"
											>
												{collection?.collections?.map(
													(collection, index) => (
														<li
															key={index}
															className="text-base before:!top-[9px] last:mb-3 lg:py-2 lg:text-xl lg:before:!top-[19px]"
														>
															{collection}
														</li>
													),
												)}
											</ul>
										)}
									</li>
								))}
							</ul>
							{note && (
								<p className="mb-0 mt-4 text-sm text-medium lg:mt-8 lg:text-base">
									*{note}
								</p>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Dealer;
