import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Button, { ILink } from '../Button';
import Image, { IImage } from 'tbk-components/src/components/Image';

export interface IARToolProps extends IBaseElementProps {
	imageOne: IImage;
	link: ILink;
	imageTwo: IImage;
	text?: string;
	productSku?: string;
}

/**
 * AR Tool
 * @icon admin-page
 * @block
 */
const ARTool: React.FC<IARToolProps> = ({
	text,
	imageOne,
	imageTwo,
	link,
	productSku,
	className,
	classNames = [],
}) => (
	<div
		className={className || cn('ar-tool relative bg-light', ...classNames)}
	>
		<svg
			className="absolute inset-0 top-64 max-h-[329px] max-w-[476px]"
			viewBox="0 0 476 329"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M-101.309 77.6904L148.851 327.85"
				stroke="#CEC7BE"
				strokeMiterlimit="10"
			/>
			<path
				d="M399.01 77.6904L148.85 327.85"
				stroke="#CEC7BE"
				strokeMiterlimit="10"
			/>
			<path
				d="M13.4609 1L148.851 327.85"
				stroke="#CEC7BE"
				strokeMiterlimit="10"
			/>
			<path
				d="M475.7 192.46L148.85 327.85"
				stroke="#CEC7BE"
				strokeMiterlimit="10"
			/>
			<path
				d="M148.85 327.85L-178 192.46"
				stroke="#CEC7BE"
				strokeMiterlimit="10"
			/>
		</svg>

		<div className="ar-tool__container relative mx-auto max-w-[906px] px-8 py-16 md:py-30">
			<div className="flex flex-col gap-8 sm:flex-row">
				{imageOne && (
					<div className="relative aspect-[3/4] h-full w-full overflow-hidden rounded-md">
						<Image
							className="!h-full w-full object-cover"
							{...imageOne}
						/>

						{(link?.href || link?.label) && (
							<div className="roomvo-container absolute bottom-8 left-1/2 min-w-[80%] -translate-x-1/2 transform">
								<Button
									classNames={['roomvo-stimr']}
									link={link}
									href="javascript:;"
									data-sku={productSku}
									style={{ visibility: 'hidden' }}
								/>
							</div>
						)}
					</div>
				)}
				{imageTwo && (
					<div className="aspect-[3/4] h-full w-full overflow-hidden rounded-md">
						<Image
							className="!h-full w-full object-cover"
							{...imageTwo}
						/>
					</div>
				)}
			</div>
			{text && <p className="mt-8 max-w-[672px] lg:mt-20">{text}</p>}
		</div>
	</div>
);

export default ARTool;
