import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Button, { ILink } from '../Button';
import Carousel, { CarouselItem } from 'tbk-components/src/components/Carousel';
import Image, { IImage } from 'tbk-components/src/components/Image';
import uniqueIdHash from '../../hooks/generateUniqueIdHash';
import Modal from 'tbk-components/src/components/Modal';
import Form from '../Form';
import TriangleImage from '../TriangleImage';

export interface IShowcaseYourSpaceItem {
	image: IImage;
	title?: string;
}

export interface IShowcaseI18n {
	close?: string;
}
export interface IShowcaseYourSpaceProps extends IBaseElementProps {
	heading?: string;
	subheading?: string;
	cta?: ILink;
	images?: IShowcaseYourSpaceItem[];
	/**
	 * @control uniqueid
	 * @prefix showcase-your-space
	 */
	id?: string;
	formId?: string;
	children?: any;
	i18n?: IShowcaseI18n;
	modalTitle?: string;
	modalSubTitle?: string;
	modalHeaderImage?: IImage;
}

/**
 * Showcase Your Space
 * @block
 * @icon slides
 */
const ShowcaseYourSpace: React.FC<IShowcaseYourSpaceProps> = ({
	id,
	heading,
	subheading,
	cta,
	images = [],
	className,
	classNames = [],
	formId,
	children,
	i18n,
	modalTitle,
	modalSubTitle,
	modalHeaderImage,
}) => {
	const genId = id || `show-your-space-${uniqueIdHash()}`;
	const [showModal, setShowModal] = useState(false);
	const formRef = null;
	const showCaseModal = (
		<Modal
			onCancel={() => {
				setShowModal(false);
			}}
			modalClassNames={[
				'lg:max-w-[970px] lg:flex lg:items-center lg:justify-center lg:h-full',
			]}
			headerClassNames={[]}
			bodyClassNames={[]}
			showClose={false}
			showCancel={false}
			showSubmit={false}
			trapFocus
		>
			<div className="get-a-quote__container showcase-your-room-modal mx-auto mt-8 w-full max-w-[calc(100%-2rem)] overflow-hidden rounded-md bg-white lg:mt-0 lg:max-w-full">
				<div className="get-a-quote__header bg-gradient-rainbow-radial relative p-4 pt-8 lg:px-8 lg:py-14">
					<div>
						{modalTitle && <h2 className="mb-0">{modalTitle}</h2>}
						{modalSubTitle && (
							<p className="lead mb-0 lg:mt-3">{modalSubTitle}</p>
						)}
					</div>
					<button
						type="button"
						aria-label={i18n?.close || 'Close get a quote modal'}
						className="absolute right-0 top-0 z-10 flex h-11 w-11 items-center justify-center lg:right-2 lg:top-2"
						onClick={() => {
							setShowModal(false);
						}}
					>
						<i className="icon-close text-2xl lg:text-3xl" />
					</button>
					{modalHeaderImage && (
						<TriangleImage
							classNames={[
								'hidden lg:block absolute bottom-0 -right-12',
							]}
							image={modalHeaderImage}
							mask="Get A Quote Modal"
						/>
					)}
				</div>
				<div
					className="get-a-quote__body p-4 lg:px-8 lg:py-10 lg:pt-0"
					ref={formRef}
				>
					{formId && children && (
						<Form theme="light">{children}</Form>
					)}
				</div>
			</div>
		</Modal>
	);
	return (
		<div className={className || cn('showcase-your-space', ...classNames)}>
			<div className="relative mx-auto max-w-8xl px-8 py-16 sm:pr-0 md:py-30">
				<div>
					<div className="flex flex-wrap items-center gap-x-[104px] gap-y-10">
						<h2 className="mb-0 max-w-[483px] text-4xl">
							{heading}
						</h2>
						<p className="mb-0 max-w-[672px]">{subheading}</p>
					</div>
					{cta && (
						<Button
							link={cta}
							onClick={(e) => {
								e.preventDefault();
								setShowModal(true);
							}}
							classNames={['mt-10']}
						/>
					)}
				</div>
				<Carousel
					options={{
						perView: 4.4,
						type: 'carousel',
						gap: '32px',
						breakpoints: {
							640: {
								perView: 1.4,
							},
						},
					}}
					showArrows={true}
					showBullets={false}
					classNames={['mt-20']}
					id={genId}
				>
					{images.map(({ image, title }, index) => (
						<CarouselItem key={index}>
							<div className="rounded-lg sm:max-w-[292px]">
								<Image
									{...image}
									className="aspect-square h-full w-full rounded-lg object-cover object-center"
								/>
							</div>
							{title && (
								<p className="mb-0 mt-4 max-w-[247px] text-base">
									{title}
								</p>
							)}
						</CarouselItem>
					))}
				</Carousel>
			</div>
			{showModal && showCaseModal}
		</div>
	);
};

export default ShowcaseYourSpace;
