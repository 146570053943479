import React, { useEffect, useRef } from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import cn from 'classnames';
import { kebabCase } from 'lodash';
import Collapse from 'bootstrap/js/dist/collapse';
import { useTranslation } from 'react-i18next';

export interface AccordionItemProps {
	title?: string;
	/**
	 * @control richtext
	 */
	content?: string;
	/**
	 * Used to link directly to the accordion item
	 */
	anchorText?: string;
}

interface IAccordionI18nProps {
	anchorFor?: string;
}

export interface IAccordionProps extends IBaseElementProps {
	items: AccordionItemProps[];
	i18n?: IAccordionI18nProps;
}

/**
 * Accordion
 * @block
 * @icon list-view
 */
const Accordion: React.FC<IAccordionProps> = ({
	id,
	className,
	classNames = [],
	items,
	i18n,
}) => {
	const { t } = useTranslation();
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!ref.current) return;

		const collapseList = [].slice.call(
			ref.current.querySelectorAll('.accordion__content'),
		);

		collapseList.map((collapse: HTMLElement) => {
			const collapseInstance = new Collapse(collapse, {
				toggle: false,
			});

			collapse.addEventListener('show.bs.collapse', () => {
				collapse.style.maxHeight = `${collapse.scrollHeight}px`;
			});

			collapse.addEventListener('shown.bs.collapse', () => {
				collapse.classList.remove('collapse');
			});

			collapse.addEventListener('hide.bs.collapse', () => {
				collapse.style.maxHeight = '0';
			});

			return collapseInstance;
		});

		return () => {
			collapseList.map((collapse: HTMLElement) => {
				const collapseInstance = Collapse.getInstance(collapse);

				if (collapseInstance) {
					collapseInstance.dispose();
				}
			});
		};
	}, []);

	return (
		<div
			id={id}
			className={className || cn('accordion__container', ...classNames)}
			ref={ref}
		>
			{items?.length > 0 && (
				<ul className="accordion_list">
					{items.map((item, index) => (
						<li key={index} className="group/item">
							{item.anchorText && (
								<a
									id={item.anchorText}
									title={`${i18n?.anchorFor || t('Anchor For')} ${item.title}`}
									className="anchor"
								/>
							)}
							<div className="accordion__item border-t border-secondary group-[:nth-last-child(1)]/item:border-b">
								<button
									className="accordion__button group/button flex w-full items-center justify-between py-4 text-left text-lg leading-[1.4] text-primary lg:text-2xl"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target={`#collapse-${id + '-' + kebabCase(item.title)}`}
									aria-expanded="false"
									aria-controls={`collapse-${id + '-' + kebabCase(item.title)}`}
								>
									{item.title}
									<i className="icon-plus group-[:not(.collapsed)]/button:hidden"></i>
									<i className="icon-minus group-[.collapsed]/button:hidden"></i>
								</button>
								<div
									id={`collapse-${id + '-' + kebabCase(item.title)}`}
									className="accordion__content max-h-0 overflow-hidden transition-all duration-300 ease-in-out"
								>
									<div
										className="accordion__content-inner py-4 lg:pb-8"
										dangerouslySetInnerHTML={{
											__html: item.content,
										}}
									/>
								</div>
							</div>
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default Accordion;
