import React from 'react';
import cn from 'classnames';
import { kebabCase } from 'lodash';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';

export interface IProductTabProps extends IBaseElementProps {
	children?: any;
	title?: string;
	id?: string;
}

const ProductTab: React.FC<IProductTabProps> = ({
	title,
	id,
	children,
	className,
	classNames = [],
}) => {
	const tabID = id || kebabCase(title);
	return (
		<div
			className={className || cn('tab-pane hidden', ...classNames)}
			data-title={title}
			id={tabID}
			role="tabpanel"
			aria-labelledby={`${tabID}-tab`}
		>
			{children}
		</div>
	);
};

export default ProductTab;
