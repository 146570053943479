import React from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import cn from 'classnames';
import Button, { ILink } from '../Button';
import { twMerge } from 'tailwind-merge';
import { IAvailableIconOptions } from '../IconSamples';
import Icon from '../Icon';

export interface IEventTileRow {
	eyebrow?: string;
	heading?: string;
	/**
	 * Excerpt
	 * @control richtext
	 */
	excerpt?: string;
	cta?: ILink;
	ctaIcon?: IAvailableIconOptions['fuzionIconSelectValues'];
}
export interface ICtaRow {
	heading?: string;
	phoneNumber?: string;
	email?: string;
}

export interface IUpcomingTile {
	title?: string;
	icon?: IAvailableIconOptions['fuzionIconSelectValues'];
	/**
	 * Excerpt
	 * @control richtext
	 */
	excerpt?: string;
	/**
	 * The event & cta rows are meant to be used in separate tiles. If both are used Event Rows will be listed first.
	 */
	eventRows?: IEventTileRow[];
	ctaRows?: ICtaRow[];
}
export interface IUpcomingEventsProps extends IBaseElementProps {
	tiles?: IUpcomingTile[];
}

/**
 * Upcoming Events & Commercial Support
 * @block
 * @icon align-full-width
 */
const UpcomingEvents: React.FC<IUpcomingEventsProps> = ({
	id,
	className,
	classNames = [],
	tiles = [],
}) => {
	return (
		<div
			id={id}
			className={
				className ||
				cn('bg-tertiary px-3 py-3 md:px-6 md:py-30', ...classNames)
			}
		>
			{tiles?.length > 0 && (
				<div className="mx-auto flex max-w-[1340px] flex-col gap-3 md:gap-6 lg:flex-row">
					{tiles.map((tile, index) => (
						<div
							className="tile relative block basis-1/2 rounded-md bg-white px-5 py-12 md:py-20"
							key={index}
						>
							<div className="tile--content mx-auto max-w-[502px] xl:px-4">
								<div className="tile--header">
									<div className="flex flex-col gap-2 sm:flex-row sm:items-center sm:gap-4 xl:px-4">
										{tile?.icon && (
											<Icon
												name={tile.icon}
												classNames={[
													'text-[32px] lg:text-[48px]',
												]}
											/>
										)}
										{tile?.title && (
											<h2 className="mb-0">
												{tile.title}
											</h2>
										)}
									</div>
									{tile?.excerpt && (
										<p
											dangerouslySetInnerHTML={{
												__html: tile.excerpt,
											}}
											className="mb-0 mt-8 xl:px-4"
										/>
									)}
								</div>
								{tile?.eventRows?.length > 0 && (
									<div className="event-rows mt-10">
										{tile.eventRows.map(
											(eventRow, index) => (
												<div
													className={twMerge(
														'event-row border-t border-tertiary py-7 md:py-8 xl:px-4',
														tile?.eventRows
															.length ===
															index + 1 &&
															'border-b',
													)}
													key={index}
												>
													{eventRow.eyebrow && (
														<p className="mb-0 text-base text-medium">
															{eventRow.eyebrow}
														</p>
													)}
													{eventRow.heading && (
														<h3 className="mb-0">
															{eventRow.heading}
														</h3>
													)}
													{eventRow.excerpt && (
														<p className="mb-0 mt-2 text-base text-medium">
															{eventRow.excerpt}
														</p>
													)}
													{eventRow.cta && (
														<Button
															link={eventRow.cta}
															buttonType="Outline"
															icon={
																eventRow.ctaIcon
															}
															size="sm"
															classNames={[
																'mt-10 flex-row-reverse [&_i]:text-[24px]',
															]}
														/>
													)}
												</div>
											),
										)}
									</div>
								)}
								{tile?.ctaRows?.length > 0 && (
									<div className="event-rows mt-10">
										{tile?.ctaRows.map((ctaRow, index) => (
											<div
												className={twMerge(
													'event-row border-t border-tertiary py-7 md:py-8 xl:px-4',
													tile?.ctaRows?.length ===
														index + 1 && 'border-b',
												)}
												key={index}
											>
												{ctaRow.heading && (
													<h4 className="h5 mb-7 text-medium">
														{ctaRow.heading}
													</h4>
												)}
												{ctaRow.phoneNumber && (
													<a
														href={`tel:${ctaRow.phoneNumber}`}
														className="text-decoration-none lead mb-0 block hover:underline"
													>
														{ctaRow.phoneNumber}
													</a>
												)}
												{ctaRow.email && (
													<a
														href={`mailto:${ctaRow.email}`}
														className="mb-0 block text-medium hover:underline"
													>
														{ctaRow.email}
													</a>
												)}
											</div>
										))}
									</div>
								)}
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default UpcomingEvents;
