import React, { useState, useEffect, useRef } from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Image, { IImage } from 'tbk-components/src/components/Image';
import { twMerge } from 'tailwind-merge';
import Typesense from 'typesense';
import { debounce } from 'lodash';
import { IGetAQuoteProps } from '../GetAQuote/';
import { kebabCase } from 'lodash';

export interface ISearchBarI18nProps {
	searchResultsFor?: string;
	placeholderText?: string;
	clearButtonText?: string;
	species?: string;
	collection?: string;
	width?: string;
	texture?: string;
	price?: string;
	warranty?: string;
	description?: string;
	about?: string;
	viewProduct?: string;
	orderSample?: string;
}

export interface ISearchBarProps extends IBaseElementProps {
	logo?: IImage;
	logoHref?: string;
	inHeaderDropdown?: boolean;
	/**
	 * @noUI
	 */
	onChange?: () => void;
	/**
	 * @noUI
	 */
	onProductSearch?: (
		searchQuery: string | '',
		value: any,
		total: number | 0,
	) => void;
	/**
	 * @noUI
	 */
	onResourceSearch?: (searchQuery: string | '', value: any) => void;
	/**
	 * @noUI
	 */
	onBlogSearch?: (searchQuery: string | '', value: any) => void;
	/**
	 * @noUI
	 */
	displaySearchBar?: boolean;
	searchFromUrl?: string;
	currentPage?: number;
	wooCommCartUrl?: string;
	defaultImageUrl?: string;
	getAQuote?: IGetAQuoteProps;
	i18n?: ISearchBarI18nProps;
	setSearchPerformed?: (value: boolean) => void;
}

const renameProperty = (obj, fromKey, toKey) => {
	obj[toKey] = obj[fromKey];
	delete obj[fromKey];
};

export const getCurrentRegion = () => {
	const lang = document.documentElement.lang;

	if (!lang) return '';

	const locale = new Intl.Locale(document.documentElement.lang);
	// get from all regions if region is not available
	const region = locale.region ?? '';

	return region;
};

/**
 * Search Bar
 * @block
 * @icon search
 */
const SearchBar: React.FC<ISearchBarProps> = ({
	id,
	className,
	classNames = [],
	logo,
	logoHref,
	inHeaderDropdown = true,
	onChange,
	onProductSearch,
	onResourceSearch,
	onBlogSearch,
	displaySearchBar = true,
	searchFromUrl,
	currentPage = 1,
	wooCommCartUrl,
	defaultImageUrl,
	getAQuote,
	i18n,
	setSearchPerformed,
}) => {
	const [searchQuery, setSearchQuery] = useState('');

	const inputRef = useRef(null);
	const clearBtnRef = useRef(null);

	const getFullImageURL = (imageURL: string) => {
		if (!imageURL) {
			return defaultImageUrl;
		}

		// @ts-ignore
		const imagesBaseURL =
			// @ts-ignore
			typeof window !== 'undefined' ? window.FUZE_IMAGES_URL : '';
		const fullUrl = `${imagesBaseURL}${imageURL}${!imageURL?.endsWith('.jpg') ? '.jpg' : ''}`;
		return fullUrl;
	};
	const getCurrentLanguage = () => {
		// @ts-ignore
		const curLang = typeof window !== 'undefined' ? window.CUR_LANG : 'en';
		return curLang.substring(0, 2).toUpperCase();
	};
	const getResultURL = (
		productType: string,
		collection: string,
		color: string,
	) => {
		const productsStr =
			getCurrentLanguage() === 'EN' ? 'products' : 'produits';
		// @ts-ignore
		const baseURL = typeof window !== 'undefined' ? window.SITE_URL : '';
		return `${baseURL.replace(/\/$/, '')}/${productsStr}/${productType}/${collection}/${color}`;
	};

	const resetSearch = () => {
		onProductSearch && onProductSearch('', [], 0);
		setSearchPerformed && setSearchPerformed(false);
		onResourceSearch && onResourceSearch('', []);
		onBlogSearch && onBlogSearch('', []);
	};

	useEffect(() => {
		if (!inputRef.current && !clearBtnRef.current) return;

		const handleClearClick = () => {
			inputRef.current.value = '';
			clearBtnRef.current.style.display = 'none';
			setSearchQuery('');
			resetSearch();
			setSearchPerformed && setSearchPerformed(false);
			inputRef?.current.focus();
		};

		const handleInput = () => {
			clearBtnRef.current.style.display = inputRef.current.value
				? 'flex'
				: 'none';
		};

		clearBtnRef.current.addEventListener('click', handleClearClick);
		inputRef.current.addEventListener('input', handleInput);

		return () => {
			clearBtnRef?.current?.removeEventListener(
				'click',
				handleClearClick,
			);
			inputRef?.current?.removeEventListener('input', handleInput);
		};
	}, [inputRef, clearBtnRef]);

	useEffect(() => {
		if (!searchQuery) {
			return;
		}
		// @ts-ignore
		const tsURL = new URL(window.TYPESENSE_API_URL);
		const typesense = new Typesense.Client({
			nodes: [
				{
					host: tsURL.hostname,
					port: tsURL.port ? Number(tsURL.port) : 443,
					protocol: tsURL.protocol.replace(':', ''),
				},
			],
			// @ts-ignore
			apiKey: window.TYPESENSE_API_KEY,
		});

		const performSearch = () => {
			const searchRequests = {
				searches: [
					{
						collection: 'products',
						q: searchQuery,
						query_by:
							'title,color_tone,flooringType.name,species,style_vibe,width,why_we_love,description,features,collection,thickness,installation,surface_texture,location,sku,finish,edge_detail,warranty',
						page: currentPage,
						per_page: 12,
						filter_by: `lang:=${getCurrentLanguage()}&&country:=${getCurrentRegion()}`,
					},
					{
						collection: 'resource',
						q: searchQuery,
						query_by: 'post_title,post_content',
						per_page: 250,
						filter_by: `lang:${getCurrentLanguage()}`,
					},
					{
						collection: 'post',
						q: searchQuery,
						query_by: 'post_title,post_content',
						per_page: 250,
						filter_by: `lang:${getCurrentLanguage()}`,
					},
				],
			};

			const commonSearchParams = {};

			typesense.multiSearch
				.perform(searchRequests, commonSearchParams)
				.then((response) => {
					resetSearch();
					const res: any = response.results;
					const resProduct = res[0];
					const resResource = res[1];
					const resBlog = res[2];

					// Product search
					const products = [];
					let total = 0;
					if (resProduct?.hits?.length > 0) {
						total = resProduct.found;

						for (let i = 0; i < resProduct.hits.length; i++) {
							const hit = resProduct.hits[i];
							products.push(hit.document);
						}

						products.forEach((p) => {
							// flooringType.name returns undefined if the product is an accessory in english sites
							// so we use this to determine if the product is an accessory
							const isAccessory =
								!p?.flooringType?.name ||
								p?.flooringType?.name === 'Accessories' ||
								p?.flooringType?.name === 'Accessoires';

							renameProperty(
								p,
								'pricing_range',
								'dollarIndicator',
							);
							renameProperty(p, 'width', 'productWidth');

							p.subtitle = p?.flooringType?.name;
							delete p.flooringType;

							p.pageLink = {};
							if (!isAccessory) {
								p.pageLink.href = getResultURL(
									kebabCase(p?.subtitle),
									// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent#encoding_for_rfc3986
									encodeURIComponent(
										p?.collection?.name,
									).replace(
										/[!'()*]/g,
										(c) =>
											`%${c.charCodeAt(0).toString(16).toUpperCase()}`,
									),
									encodeURIComponent(p?.color).replace(
										/[!'()*]/g,
										(c) =>
											`%${c.charCodeAt(0).toString(16).toUpperCase()}`,
									),
								);
								delete p.url;
							}

							p.image = {
								// Some of the strings have leading/trailing spaces
								src: getFullImageURL(
									p?.media_paths?.swatches?.trim(),
								),
								alt: p.color,
								width: 600,
								height: 600,
							};

							if (isAccessory && p?.media_paths?.image?.trim()) {
								p.image.src = getFullImageURL(
									p?.media_paths?.image?.trim(),
								);
							}

							p.image_alt = getFullImageURL(
								p?.media_paths?.roomscene?.trim() ??
									defaultImageUrl,
							);
							if (!isAccessory) {
								p.hoverImage = {};
								p.hoverImage = {
									src: p.image_alt,
									alt: p.description,
									width: 600,
									height: 600,
								};
							}

							p.collectionSpec = `${p?.collection?.name} ${
								i18n?.collection ?? 'Collection'
							}`;

							p.quickViewDetails = {};
							if (!isAccessory) {
								p.quickViewDetails = {
									fullSpecs: [
										{
											label: i18n?.species ?? 'Species',
											value: p.species,
										},
										{
											label:
												i18n?.collection ??
												'Collection',
											value: p?.collection?.name,
										},
										{
											label: i18n?.width ?? 'Width',
											value: `${p.productWidth}"`,
										},
										{
											label: i18n?.texture ?? 'Texture',
											value: p.surface_texture,
										},
										{
											label: i18n?.price ?? 'Price Range',
											value: p.dollarIndicator,
										},
										{
											label: i18n?.warranty ?? 'Warranty',
											value: p.warranty,
										},
									],
									detailLinks: [
										{
											bulletText: `<a href="${p.pageLink.href}">${i18n?.viewProduct ?? 'View Product'}</a>`,
											bulletIcon: 'eye',
										},
										{
											bulletText: `<a href="${wooCommCartUrl ? `${wooCommCartUrl}&productID=${p.id}` : '#'}">${i18n?.orderSample ?? 'Order Sample'}</a>`,
											bulletIcon: 'shopping-cart',
										},
									],
									images: [
										{
											src: p?.image?.src,
											alt: p.color,
										},
										{
											src: p?.hoverImage?.src,
											alt: p.description,
										},
									],
									sku: p.sku,
								};
							} else {
								p.quickViewDetails = {
									fullSpecs: [
										{
											label:
												i18n?.collection ??
												'Collection',
											value: p?.collection?.name,
										},
										{
											label:
												i18n?.about ??
												'About This Product',
											value: p?.collection?.description,
										},
										{
											label:
												i18n?.description ??
												'Description',
											value: `${p.description}`,
										},
									],
									detailLinks: [],
									images: [
										{
											src: p?.image?.src,
											alt: p.color,
										},
									],
									sku: p.sku,
								};
							}

							// Remove empty values
							p.quickViewDetails.fullSpecs =
								p.quickViewDetails.fullSpecs.filter(
									(item) => item.value,
								);

							if (!isAccessory) {
								p.textButton = {};
								p.textButton = {
									label: i18n?.orderSample ?? 'Order Sample',
									href: wooCommCartUrl
										? `${wooCommCartUrl}&productID=${p.id}`
										: '#',
								};
							}

							p.enableProductCompare = !isAccessory
								? true
								: false;
							p.enableQuickViewModal = true;
							p.getAQuote = {
								...getAQuote,
								productId: p.id,
								flooringType: p.subtitle,
								productColor: p.color,
							};
						});
					}
					onProductSearch &&
						onProductSearch(searchQuery, products, total);
					setSearchPerformed && setSearchPerformed(true);

					// Resource search
					const resources = [];
					if (resResource?.hits?.length > 0) {
						for (let i = 0; i < resResource.hits.length; i++) {
							const hit = resResource.hits[i];

							// Pass the highlighted post_title if it exists
							if (hit.highlight.post_title.snippet) {
								hit.document.highlight =
									hit.highlight.post_title.snippet;
							}

							resources.push(hit.document);
						}

						resources.forEach((resource) => {
							renameProperty(resource, 'post_title', 'name');
							renameProperty(resource, 'document', 'url');
							renameProperty(resource, 'type', 'resourceType');

							switch (resource.resourceType) {
								case 'pdf':
									resource.iconName = 'download';
									break;
								case 'faq':
									resource.iconName = 'question-mark';
									break;
								case 'blog':
									resource.iconName = 'blog';
									break;
							}
						});
					}
					onResourceSearch &&
						onResourceSearch(searchQuery, resources);

					// Blog search
					const blogs = [];
					if (resBlog?.hits?.length > 0) {
						for (let i = 0; i < resBlog.hits.length; i++) {
							const hit = resBlog.hits[i];
							blogs.push(hit.document);
						}

						blogs.forEach((blog) => {
							renameProperty(blog, 'post_title', 'blogTitle');
							renameProperty(blog, 'permalink', 'blogURL');
							renameProperty(blog, 'category', 'categories');

							blog.featuredImage = {};
							blog.featuredImage.src = blog.post_thumbnail;
							delete blog.post_thumbnail;
						});
					}
					onBlogSearch && onBlogSearch(searchQuery, blogs);
				})
				.catch((error) => console.error(error));
		};

		const debouncedSearch = debounce(performSearch, 300);

		debouncedSearch();

		return () => {
			debouncedSearch.cancel();
		};
	}, [searchQuery, currentPage]);

	// Focus on the input field when the component mounts
	useEffect(() => {
		if (!inputRef.current) return;

		inputRef.current.focus();
	}, []);

	useEffect(() => {
		if (!searchFromUrl) return;

		setSearchQuery(searchFromUrl);
	}, [searchFromUrl]);

	useEffect(() => {
		if (!searchQuery) {
			resetSearch();
		}
	}, [searchQuery]);

	return (
		displaySearchBar && (
			<div
				id={id}
				className={
					className ||
					twMerge(
						'search-bar',
						inHeaderDropdown
							? 'bg-medium'
							: 'bg-medium lg:bg-light',
						...classNames,
					)
				}
			>
				<div
					className={`container p-4 py-[18px] md:px-8 lg:flex lg:items-center lg:justify-between lg:py-3 ${!inHeaderDropdown && '!py-0 lg:!py-10'}`}
				>
					{inHeaderDropdown && logo?.src && (
						<div className="hidden lg:block">
							<a
								href={logoHref || '/'}
								aria-label="Back to homepage"
								className="block max-w-[160px] md:max-w-full"
							>
								<Image {...logo} />
							</a>
						</div>
					)}
					<form
						className={twMerge(
							'search-bar-form relative before:absolute before:left-0 before:top-1/2 before:flex before:translate-y-[-50%] before:font-icomoon before:text-xl before:text-white lg:w-[calc(2/3*100%-34px)] lg:before:text-[26px] lg:before:text-secondary',
							!inHeaderDropdown &&
								'lg:flex lg:grow lg:items-center lg:justify-between lg:before:!left-[calc(1/4*100%)] lg:before:!text-primary',
						)}
						method="get"
						onSubmit={(e) => e.preventDefault()}
					>
						<label
							htmlFor="header-search-box"
							className={
								inHeaderDropdown
									? 'sr-only'
									: 'hidden lg:block lg:text-2xl'
							}
						>
							{i18n?.searchResultsFor || 'Search Results for:'}
						</label>
						<input
							onChange={(e) => {
								setSearchQuery(e.target.value);
								onChange && onChange();
							}}
							id="header-search-box"
							className={twMerge(
								'w-full !rounded-none border-b border-t border-secondary bg-transparent py-[15px] pl-7 text-base leading-none text-white placeholder-white lg:pl-11 lg:text-lg',
								!inHeaderDropdown &&
									'!border-transparent lg:!w-3/4 lg:!border-primary lg:!text-primary lg:placeholder:!text-primary',
							)}
							type="search"
							placeholder={
								i18n?.placeholderText ||
								'What can we help you find?'
							}
							ref={inputRef}
							value={searchQuery}
						/>
						<button
							id="clear"
							type="button"
							className={twMerge(
								'clear-button absolute right-2 top-1/2 hidden translate-y-[-50%] items-center justify-center text-xl text-white',
								!inHeaderDropdown && 'lg:!text-primary',
							)}
							ref={clearBtnRef}
							aria-label={
								i18n?.clearButtonText || 'Clear search box'
							}
						>
							<i className="icon-close" />
						</button>
					</form>
				</div>
			</div>
		)
	);
};

export default SearchBar;
