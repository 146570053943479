import React, { useRef } from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { twMerge } from 'tailwind-merge';
import ProductCards, { IProductCardsProps } from '../ProductCards';
import { useTranslation } from 'react-i18next';

export interface IProductsProps {
	name?: string;
}

interface IIndexSearchProductsI18nProps {
	noProductsFound?: string;
}

export interface IIndexSearchProductsProps extends IBaseElementProps {
	sectionTitle?: string;
	products?: IProductCardsProps;
	i18n?: IIndexSearchProductsI18nProps;
}

const IndexSearchProducts: React.FC<IIndexSearchProductsProps> = ({
	id,
	className,
	classNames = [],
	sectionTitle,
	products,
	i18n,
}) => {
	const topRef = useRef(null);
	const { t } = useTranslation();

	return (
		<div
			id={id}
			className={
				className ||
				twMerge('products-list__container bg-white', ...classNames)
			}
			ref={topRef}
		>
			<div className="container px-4 py-8 md:px-8 lg:py-20">
				{sectionTitle && (
					<div className="mb-6 flex items-center justify-start gap-4 lg:mb-10">
						<h2 className="mb-0 lg:text-4xl">{sectionTitle}</h2>
						{products?.productCards?.length > 0 ? (
							<span className="flex h-[26px] w-[26px] items-center justify-center rounded-full bg-tertiary text-sm">
								{products?.productCards?.length}
							</span>
						) : (
							<span>
							{i18n?.noProductsFound || t('No Products Found')}
							</span>
						) }
					</div>
				)}
				{products?.productCards?.length > 0 && (
					<ProductCards {...products} />
				)}
			</div>
		</div>
	);
};

export default IndexSearchProducts;
