import React from 'react';
import Nav, { NavItem } from 'tbk-components/src/components/Nav';
import { useTranslation } from 'react-i18next';

export interface IFooterBottomProps {
	copyrightText?: string;
	bottomMenu?: NavItem[];
}

const FooterBottom: React.FC<IFooterBottomProps> = ({
	copyrightText,
	bottomMenu,
}) => {
	const { t } = useTranslation();

	return (
		<div className="bg-white">
			<div className="container p-4 md:flex md:items-center md:justify-between md:px-8 2xl:py-10">
				<div className="text-center md:flex md:items-center md:justify-start md:gap-4">
					<p className="m-0 text-sm text-primary">
						&copy;{' '}
						{`${new Date().getFullYear()} ${copyrightText ? copyrightText : t('Fuzion Flooring')}.`}
					</p>
					{bottomMenu.length > 0 && (
						<Nav
							navItems={bottomMenu}
							classNames={[
								'flex',
								'items-center',
								'justify-center',
								'text-sm',
								'text-primary',
								'underline',
								'[&>li:not(:last-child)]:after:content-["·"]',
								'[&>li:not(:last-child)]:after:inline-block',
								'[&>li:not(:last-child)]:after:mx-1',
							]}
						/>
					)}
				</div>
				<div className="mt-4 flex items-center justify-center gap-4 text-center md:mt-0">
					<a
						href="https://tbkcreative.com/?ref=fuzion-flooring"
						target="_blank"
						rel="noreferrer noopener"
						className="inline-flex items-center gap-4 text-sm text-primary no-underline"
					>
						<span>{t('Web Design & Digital Marketing')} </span>
						<i className="icon-tbk-logo text-[23px]" />
					</a>
				</div>
			</div>
		</div>
	);
};

export default FooterBottom;
