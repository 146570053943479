import React, { useEffect, useContext, useState } from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import cn from 'classnames';
import Button, { ILink } from '../Button';
import { twMerge } from 'tailwind-merge';
import { IAvailableIconOptions } from '../IconSamples';
import Icon from '../Icon';
import { IImage } from 'tbk-components/src/components/Image';
import Carousel, { CarouselItem } from 'tbk-components/src/components/Carousel';
import AdvancedMedia from '../AdvancedMedia';
import withAppContext from 'tbk-components/src/hooks/withAppContext';

export interface IProjectCategory {
	value?: string;
	label?: string;
}

export interface IProjectTile {
	image?: IImage;
	header?: string;
	subtitle?: string;
	excerpt?: string;
	location?: string;
	link?: ILink;
}

export interface IProjectShowcaseProps extends IBaseElementProps {
	title?: string;
	/**
	 * Excerpt
	 * @control richtext
	 */
	excerpt?: string;
	projects?: IProjectTile[];
	categories?: IProjectCategory[];
	//Setting this incase there is need to have an activeCategory on page refresh
	activeCategory?: IProjectCategory;
	allLabel?: string;
}

/**
 * Project Showcase
 * @block
 * @icon align-full-width
 */
const ProjectShowcase: React.FC<IProjectShowcaseProps> = withAppContext(
	({
		id,
		className,
		classNames = [],
		context,
		title,
		excerpt,
		categories = [],
		projects = [],
		activeCategory,
		allLabel = 'All Industries',
	}) => {
		// @ts-ignore
		const { assets: contextAssets, Api } = useContext(context);
		const [selectedCategory, setSelectedCategory] =
			useState<IProjectCategory>(activeCategory || categories?.[0]);
		const [carouselProjects, setCarouselProjects] = useState(projects);

		const categoryFilters = {
			page: 'int',
			category: selectedCategory,
			search: 'string',
			total: 'int',
		};

		//This is just a conceptualized version of pulling in the data from the API.
		//B/E will have to collaborate and sync up for this.
		useEffect(() => {
			if (Api.getProjectShowcase) {
				Api.getProjectShowcase(categoryFilters).then((results: any) => {
					setCarouselProjects(results);
				});
			}
		}, [selectedCategory]);

		//Import bootstrap dropdown
		useEffect(() => {
			import('bootstrap/js/dist/dropdown');
		}, []);

		let theCategories = categories;
		let theProjects = carouselProjects;

		if(!Api.getProjectShowcase) {
			// Infer categories from projects
			const kvCategories = projects.reduce((acc: any, project) => {
				if (project.subtitle) {
					acc[project.subtitle] = project.subtitle;
				}
				return acc;
			}, {
				[allLabel]: allLabel,
			});

			theCategories = Object.keys(kvCategories).map((key) => ({
				value: key,
				label: key,
			}));

			// Filter projects by category
			if (selectedCategory && selectedCategory.value && theProjects.some((project) => project.subtitle === selectedCategory.value)) {
				theProjects = theProjects.filter((project) => project.subtitle === selectedCategory.value);
			}
		}

		return (
			<div
				id={id}
				className={
					className ||
					cn(
						'project-showcase bg-light px-3 py-10 pr-0 md:px-6 md:py-30',
						...classNames,
					)
				}
				key={`cat-${selectedCategory?.value}`}
			>
				<div className="mx-auto max-w-[1340px]">
					<div className="project-showcase--head flex flex-col items-start gap-6 pr-3 md:pr-0 lg:flex-row">
						<div className="project-showcase--header relative z-[1] inline-flex flex-col gap-4 sm:max-w-[437px]">
							{title && <h2 className="mb-0">{title}</h2>}
							{excerpt && (
								<p
									dangerouslySetInnerHTML={{
										__html: excerpt,
									}}
									className="subheading mb-0"
								/>
							)}
						</div>
						{selectedCategory && (
							<div className="nav-item dropdown relative relative z-10 mb-0 mt-auto">
								<a
									className="nav-link dropdown-toggle project-showcase--categories block flex min-w-[200px] appearance-none items-center justify-between gap-2 rounded-none border-b border-t border-primary bg-transparent px-4 py-4 text-[18px]"
									href="#"
									aria-expanded="false"
									role="button"
									data-bs-toggle="dropdown"
									data-bs-auto-close="true"
								>
									{selectedCategory.label}
									<Icon name="chevron-down" />
								</a>
								{theCategories && theCategories.length > 0 && (
									<ul className="dropdown-menu fuzion-dropdown absolute bottom-full z-[2] !mt-1 hidden min-w-[180px] rounded-md bg-tertiary px-5 py-3">
										{theCategories.map((category, index) => {
											return (
												<li key={index}>
													<a
														className="dropdown-item bg-transparent text-xs uppercase tracking-[1.08px] text-primary transition-opacity hover:opacity-75"
														href="#"
														onClick={(e) => {
															e.preventDefault();
															setSelectedCategory(
																category,
															);
														}}
													>
														{category.label}
													</a>
												</li>
											);
										})}
									</ul>
								)}
							</div>
						)}
					</div>

					{theProjects?.length > 0 && (
						<Carousel
							options={{
								perView: theProjects.length < 2 ? 1 : 2,
								type: 'carousel',
								gap: '32px',
								breakpoints: {
									768: {
										perView: theProjects.length < 2 ? 1 : 1.5,
									},
								},
							}}
							showBullets={false}
							classNames={['mt-5 md:mt-10']}
						>
							{theProjects.map((project, index) => (
								<CarouselItem key={index}>
									<div
										className={twMerge(
											"project-tile w-full",
											theProjects.length < 2 && 'lg:max-w-[654px]'
										)}
										key={index}
									>
										{project.image &&
											React.createElement(
												project?.link?.href
													? 'a'
													: 'div',
												{
													href: project?.link?.href,
													className:
														project?.link?.href &&
														'group',
													children: (
														<>
															<AdvancedMedia
																size="4/3"
																rounded
																image={
																	project.image
																}
																classNames={[
																	'group-hover:[&_img]:scale-[1.02] [&_img]:transition-transform',
																]}
															/>
														</>
													),
												},
											)}
										{React.createElement(
											project?.link?.href ? 'a' : 'div',
											{
												href: project?.link?.href,
												className: twMerge(
													'project-tile--content mt-7 flex flex-col gap-col-6 lg:flex-row lg:justify-between',
													project?.link?.href &&
														'hover:opacity-75 transition-opacity',
												),
												children: (
													<>
														<div>
															{project.header && (
																<h3 className="mb-0">
																	{
																		project.header
																	}
																</h3>
															)}
															{project.subtitle && (
																<p>
																	{
																		project.subtitle
																	}
																</p>
															)}
														</div>
														<div className="max-w-[320px] lg:text-right">
															{(project.excerpt ||
																project.location) && (
																<p className="mb-0 text-base leading-[1.6]">
																	{`${project?.excerpt} ${project?.location}`}
																</p>
															)}
														</div>
													</>
												),
											},
										)}
									</div>
								</CarouselItem>
							))}
						</Carousel>
					)}
				</div>
			</div>
		);
	},
);

export default ProjectShowcase;
