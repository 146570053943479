import React from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { IBoxNavProps } from '../MegaMenu';
import { twJoin, twMerge } from 'tailwind-merge';

export interface ICategoryListingsProps extends IBaseElementProps {
	categories?: IBoxNavProps[];
}

/* We're encapsulating the megamenu code for listing categories to reuse it in the Featured Categories component. */
const CategoryListings: React.FC<ICategoryListingsProps> = ({
	className,
	classNames = [],
	categories = [],
}) =>
	categories?.length > 0 && (
		<ul
			className={
				className ||
				twMerge(
					'box-nav__list grid gap-1 max-lg:!grid-cols-none lg:basis-[65%] lg:gap-4',
					...classNames,
				)
			}
			style={{
				gridTemplateColumns: `repeat(${categories?.length}, minmax(0, 1fr))`,
			}}
		>
			{categories.map((category, index) => (
				<li key={index} className="!mb-0">
					{category.link && (
						<a
							href={category.link.href}
							target={category.link.target}
							className={twJoin(
								'group relative flex items-center justify-start overflow-hidden rounded-md text-sm lg:h-[325px] lg:items-start lg:justify-start lg:rounded-none lg:text-base',
								category?.featuredImage?.src &&
									category?.navLabelPosition !== 'bottom' &&
									'before:absolute before:inset-0 before:z-[1] before:[background:linear-gradient(270deg,#6e768a_0%,#484e62_55%,#282835_100%);] before:lg:[background:linear-gradient(180deg,#484E62_0%,rgba(72,78,98,0)100%)]',
								category?.navLabelPosition === 'bottom' &&
									'!items-end',
								Array.isArray(category.classNames)
									? category.classNames.join(' ')
									: category.classNames,
							)}
						>
							{category?.featuredImage?.src && (
								<img
									src={category.featuredImage.src}
									alt={category.featuredImage.alt}
									className="absolute left-0 top-0 z-0 !h-full w-full object-cover transition-all duration-300 ease-in-out group-hover:scale-110 group-focus:scale-110"
								/>
							)}
							{category?.link?.label && (
								<span
									className={`transition-color relative z-10 w-full px-4 py-6 pr-24 font-header text-base duration-300 ease-in-out group-hover:bg-[rgba(51,53,67,0.7)] group-hover:text-white group-focus:bg-[rgba(51,53,67,0.7)] group-focus:text-white lg:py-4 lg:pr-4 lg:text-[11px] lg:leading-none xl:text-[16px] ${category?.featuredImage?.src && category?.navLabelPosition !== 'bottom' && '!text-white'} ${category?.navLabelPosition === 'bottom' && 'block bg-tertiary p-4 !text-primary group-hover:!bg-tertiary group-focus:!bg-tertiary lg:flex lg:h-[173px] lg:flex-col lg:justify-between lg:!text-base'}`}
								>
									{category.link.label && (
										<span>{category.link.label}</span>
									)}
									{category?.navLabelPosition ===
										'bottom' && (
										<i className="icon-chevron-right flex hidden h-8 w-8 items-center justify-center rounded-full bg-white lg:flex" />
									)}
								</span>
							)}
							{category?.graphic?.src && (
								<img
									src={category.graphic.src}
									alt={category.graphic.alt}
									className={twJoin(
										'absolute right-5 top-1 z-10 w-[80px] transition-all duration-300 ease-in-out group-hover:scale-125 group-focus:scale-125 lg:-right-6 lg:bottom-[-3rem] lg:top-[unset] lg:min-w-[182px]',
										Array.isArray(
											category.graphicClassNames,
										)
											? category.graphicClassNames.join(
													' ',
												)
											: category.graphicClassNames,
									)}
								/>
							)}
							{category?.hoverImage?.src && (
								<img
									src={category.hoverImage.src}
									alt={category.hoverImage.alt}
									className="invisible absolute left-0 top-0 !h-full w-full object-cover opacity-0 transition-opacity duration-300  group-hover:visible group-hover:opacity-100 group-focus:visible group-focus:opacity-100"
								/>
							)}
						</a>
					)}
				</li>
			))}
		</ul>
	);

export default CategoryListings;
