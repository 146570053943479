import React, { useState, useRef, useEffect, useContext } from 'react';
import Button, { IFuzionButtonProps } from '../Button';
import { IImage } from 'tbk-components/src/components/Image';
import TriangleImage from '../TriangleImage';
import Form from '../Form';
import Modal from 'tbk-components/src/components/Modal';
import withAppContext from 'tbk-components/src/hooks/withAppContext';
import { IDealerProps } from '../Dealer';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { twMerge } from 'tailwind-merge';

interface IGetAQuoteI18nProps {
	close?: string;
}

export interface IGetAQuoteProps extends IBaseElementProps {
	modalTitle?: string;
	modalSubTitle?: string;
	modalHeaderImage?: IImage;
	toggleButton?: IFuzionButtonProps;
	formId?: number;
	children?: any;
	productId?: number;
	flooringType?: string;
	productColor?: string;
	i18n?: IGetAQuoteI18nProps;
	context?: any;
	triggerButtonId?: string;
	triggerButton?: boolean;
}

const genAddressStr = (streetAddress, city, province, postalCode) => {
	const addressComponents = [streetAddress, city, province].filter(Boolean);
	const addressString = addressComponents.join(', ');
	const p = postalCode ? ` ${postalCode}` : '';

	return addressString + p;
};

const GetAQuote: React.FC<IGetAQuoteProps> = withAppContext(
	({
		id,
		className,
		classNames = [],
		modalTitle,
		modalSubTitle,
		modalHeaderImage,
		toggleButton,
		formId,
		children,
		productId,
		flooringType,
		productColor,
		i18n,
		context,
		triggerButtonId,
		triggerButton = false,
	}) => {
		const lang = document.documentElement.lang.toLowerCase();
		const isCanada = lang.includes('-ca');
		const distanceUnit = isCanada ? 'km' : 'miles';

		// @ts-ignore
		const { assets: contextAssets, Api } = useContext(context);

		const [showModal, setShowModal] = useState(false);
		const [dealers, setDealers] = useState<IDealerProps[]>([]);
		const [isLoaded, setIsLoaded] = useState(false);
		const [productInfo, setProductInfo] = useState(null);

		const formRef = useRef(null);

		const getAQuoteModal = (
			<Modal
				onCancel={() => {
					setShowModal(false);
				}}
				modalClassNames={[
					'lg:max-w-[970px] lg:flex lg:items-center lg:justify-center lg:h-full',
				]}
				headerClassNames={[]}
				bodyClassNames={[]}
				showClose={false}
				showCancel={false}
				showSubmit={false}
				trapFocus
			>
				<div className="get-a-quote__container mx-auto mt-8 w-full max-w-[calc(100%-2rem)] overflow-hidden rounded-md bg-white lg:mt-0 lg:max-w-full">
					<div className="get-a-quote__header bg-gradient-rainbow-radial relative p-4 pt-8 lg:px-8 lg:py-8">
						<div className="lg:max-w-[700px]">
							{modalTitle && (
								<h2 className="mb-0">{modalTitle}</h2>
							)}
							{modalSubTitle && (
								<p className="lead mb-0 lg:mt-3">
									{modalSubTitle}
								</p>
							)}
						</div>
						<button
							type="button"
							aria-label={
								i18n?.close || 'Close get a quote modal'
							}
							className="absolute right-0 top-0 z-10 flex h-11 w-11 items-center justify-center lg:right-2 lg:top-2"
							onClick={() => {
								setShowModal(false);
							}}
						>
							<i className="icon-close text-2xl lg:text-3xl" />
						</button>
						{modalHeaderImage && (
							<TriangleImage
								classNames={[
									'hidden lg:block absolute bottom-0 -right-12',
								]}
								image={modalHeaderImage}
								mask="Get A Quote Modal"
							/>
						)}
					</div>
					<div
						className="get-a-quote__body p-4 lg:px-8 lg:py-10 lg:pt-0"
						ref={formRef}
					>
						{formId && children && (
							<Form theme="light">{children}</Form>
						)}
					</div>
				</div>
			</Modal>
		);

		const getDealers = async (postal_code) => {
			if (!isLoaded && !window.google.maps.Geocoder) return;

			const service = await new window.google.maps.Geocoder();

			const suffix = isCanada ? ' Canada' : '';

			service.geocode(
				{ address: postal_code + suffix },
				(results, status) => {
					if (status === 'OK') {
						const { lat, lng } = results[0].geometry.location;
						// convert miles to km
						const radius = isCanada ? 100 : 100 / 0.621371;
						const args = {
							lat: lat(),
							lng: lng(),
							radius: radius * 1000,
							num_results: 3,
						};

						if (Api.getDealerResults) {
							Api.getDealerResults(args).then((response) => {
								if (response && Array.isArray(response)) {
									const resData = response.map(
										(item: any) => {
											return {
												...item,
												name: item.name.toLowerCase(),
												position: {
													lat: Number(item.lat),
													lng: Number(item.long),
												},
												distance:
													(isCanada
														? Math.round(
																item.distance /
																	1000,
															)
														: Math.round(
																item.distance /
																	0.621371 /
																	1000,
															)) +
													` ${distanceUnit}`,
												streetAddress:
													item.street_address.toLowerCase(),
												city: item.city.toLowerCase(),
												province: item.state,
												postalCode: item.postal,
											};
										},
									);
									setDealers(resData);
								}
							});
						}
					}
				},
			);
		};

		useEffect(() => {
			if (!showModal) return;

			const handleBtnClick = (e) => {
				if (
					((e.target.closest('.user-postal-code') &&
						e.key === 'Enter') ||
						e.target.className ===
							'gform_next_button gform-theme-button button' ||
						e.target.className === 'gform_button button') &&
					e.target.closest('.modal.fade.show')
				) {
					// `user-postal-code` class name is defined in Gravity Form settings
					const postalCodeInput = e.target
						.closest('form')
						.querySelector(
							'.user-postal-code input',
						) as HTMLInputElement;

					if (postalCodeInput.value)
						getDealers(postalCodeInput.value);
				}
			};

			document.body.addEventListener('click', handleBtnClick);
			window.addEventListener('keyup', handleBtnClick);

			return () => {
				document.body.removeEventListener('click', handleBtnClick);
				window.removeEventListener('keyup', handleBtnClick);
			};
		}, [showModal]);

		useEffect(() => {
			if (!formRef.current) return;

			// `local-dealers`, `dealer-email`, and `sales-rep-email` class names are defined in Gravity Form settings
			const localDealerRadios = formRef.current.querySelectorAll(
				'.local-dealers .ginput_container_radio .gfield_radio .gchoice',
			) as NodeListOf<HTMLDivElement>;

			const dealerEmail = formRef.current.querySelector(
				'.dealer-email .ginput_container_email input[type="email"]',
			) as HTMLInputElement;

			const salesRepEmail = formRef.current.querySelector(
				'.sales-rep-email .ginput_container_email input[type="email"]',
			) as HTMLInputElement;

			const eventHandlers = [];

			if (localDealerRadios.length > 0 && dealers.length > 0) {
				for (let i = 0; i < localDealerRadios.length; i++) {
					const radio = localDealerRadios[i];
					const dealer = dealers[i];
					const radioLabel = radio.querySelector('label');
					const radioInput = radio.querySelector('input');

					if (dealer) {
						radioInput.value = dealer?.dealer_contacts?.email;
						radioLabel.innerHTML = `<span class="block font-header capitalize">${dealer.name}</span><span class="block text-sm leading-[1.6] text-medium">${dealer.distance}<span class="inline-block text-primary mx-[6px]">&middot;</span><span class="capitalize">${genAddressStr(dealer.streetAddress, dealer.city, dealer.province, dealer.postalCode)}</span></span>`;

						if (dealerEmail && salesRepEmail) {
							const handleChange = () => {
								dealerEmail.value =
									dealer?.dealer_contacts?.email;
								salesRepEmail.value =
									dealer?.salespersons?.email;
							};
							radioInput.addEventListener('change', handleChange);
							eventHandlers.push([
								radioInput,
								'change',
								handleChange,
							]);
						}
					}
				}
			}

			return () => {
				for (const i of eventHandlers) {
					const [el, eventName, handler] = i;
					el.removeEventListener(eventName, handler);
				}
			};
		}, [formRef, dealers]);

		useEffect(() => {
			if (!showModal || !formRef.current) {
				return;
			}

			const formEl = document.querySelector('#get-quote-form');

			if (!formEl) {
				return;
			}

			// Clone formEl inside form wrapper
			// @ts-ignore
			const formWrapper = formRef.current.querySelector('.form-wrapper');
			const gform = formEl.querySelector('.gform_wrapper');
			// @ts-ignore
			const clone = jQuery(gform).clone(true);
			// @ts-ignore
			jQuery(formWrapper).empty().append(gform);

			// `product-id`, `flooring-type` and `product-color` class names are defined in Gravity Form settings
			const productIdInput = formRef.current.querySelector(
				'.product-id .ginput_container input[type="text"]',
			) as HTMLInputElement;
			const flooringTypeInput = formRef.current.querySelector(
				'.flooring-type .ginput_container input[type="text"]',
			) as HTMLInputElement;
			const productColorInput = formRef.current.querySelector(
				'.product-color .ginput_container input[type="text"]',
			) as HTMLInputElement;

			if (!productIdInput || !flooringTypeInput || !productColorInput)
				return;

			productIdInput.value = productInfo.productId;
			flooringTypeInput.value = productInfo.productColor;
			productColorInput.value = productInfo.productCollection;

			productIdInput.closest('.gfield').classList.add('input-filled');
			flooringTypeInput.closest('.gfield').classList.add('input-filled');
			productColorInput.closest('.gfield').classList.add('input-filled');

			return () => {
				// Restore form
				// @ts-ignore
				jQuery(formEl).append(clone);
			};
		}, [formRef, showModal, productInfo]);

		useEffect(() => {
			if (!formRef.current || !showModal) {
				return;
			}

			// Hide the "Amount required" field if product is an accessoty
			const amountRequiredInput = formRef.current.querySelector(
				'.field-amount-required',
			) as HTMLSelectElement;
			const amountRequiredGField =
				amountRequiredInput &&
				(amountRequiredInput.closest('.gfield') as HTMLDivElement);
			if (
				productInfo &&
				['Accessories', 'Accessoire'].includes(
					productInfo.productColor,
				) &&
				amountRequiredGField
			) {
				amountRequiredGField.style.display = 'none';
			} else if (amountRequiredGField) {
				amountRequiredGField.style.display = '';
			}
		}, [formRef, showModal, productInfo]);

		return (
			<Wrapper
				apiKey={
					// @ts-ignore
					window.GOOGLE_MAPS_API_KEY
				}
				callback={(status: Status) => {
					if (status === 'SUCCESS') {
						setIsLoaded(true);
					}
				}}
			>
				<div
					id={id}
					className={className || twMerge('pl-0', ...classNames)}
					// To prevent floating cta bar being toggled every time the modal element is clicked
					onClick={(e) => {
						e.stopPropagation();

						//stopPropagation makes it so we cant close the modal by clicking outside, so we need to manually look for that type of click.
						const target = e.target as HTMLElement;
						if (
							!target.closest('.get-a-quote__container') &&
							showModal
						) {
							setShowModal(false);
						}
					}}
				>
					{toggleButton?.link?.label && (
						<Button
							{...toggleButton}
							id={triggerButtonId}
							classNames={['btn-sm']}
							onClick={() => {
								if (!triggerButton) {
									if (
										productId &&
										flooringType &&
										productColor
									) {
										setProductInfo({
											productId: productId,
											productColor: flooringType,
											productCollection: productColor,
										});
									}
									setShowModal(true);
								} else {
									const btn = document.querySelector(
										'#floating-cta-get-a-quote',
									) as HTMLButtonElement;

									if (btn) {
										btn.click();
									}
								}
							}}
						/>
					)}

					{showModal && getAQuoteModal}
				</div>
			</Wrapper>
		);
	},
);

export default GetAQuote;
