import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Image, { IImage } from 'tbk-components/src/components/Image';

export interface ILogoBarProps extends IBaseElementProps {
	title?: string;
	/**
	 * @control richtext
	 */
	excerpt?: string;
	logos?: IImage[];
}

/**
 * Fuzion Logo Bar
 * @icon schedule
 * @block
 */
const LogoBar: React.FC<ILogoBarProps> = ({
	id,
	className,
	classNames = [],
	title,
	excerpt,
	logos = [],
}) => {
	return (
		<div
			id={id}
			className={
				className ||
				cn('fuzion-logo-bar px-4 py-12 md:px-6 md:py-30', ...classNames)
			}
		>
			<div className="mx-auto flex max-w-[1142px] flex-col gap-10 text-center">
				<div className="flex flex-col gap-3">
					{title && <h2 className="mb-0">{title}</h2>}
					{excerpt && (
						<p
							className="mb-0"
							dangerouslySetInnerHTML={{
								__html: excerpt,
							}}
						/>
					)}
				</div>
				{logos?.length > 0 && (
					<div className="flex flex-wrap items-center justify-center gap-10 sm:justify-between">
						{logos.map((logo, index) => (
							<Image
								{...logo}
								key={index}
								className="h-auto max-h-[80px] max-w-[100px] object-contain sm:max-w-[184px]"
							/>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

export default LogoBar;
