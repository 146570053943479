import React, { useLayoutEffect, useRef } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import bgImage from '../../assets/images/text-reveal-star-line-burst.svg';
import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { twMerge } from 'tailwind-merge';
gsap.registerPlugin(SplitText, ScrollTrigger);
export interface ITextRevealProps extends IBaseElementProps {
	text?: string;
}

// Imported function provided directly by GSAP.
// https://codepen.io/GreenSock/pen/KKaQoLq
function distributeByPosition(vars) {
	var ease = vars.ease,
		from = vars.from || 0,
		base = vars.base || 0,
		axis = vars.axis,
		ratio = { center: 0.5, end: 1, edges: 0.5 }[from] || 0,
		distances;
	return function (i, target, a) {
		var l = a.length,
			originX,
			originY,
			x,
			y,
			d,
			j,
			minX,
			maxX,
			minY,
			maxY,
			positions;
		if (!distances) {
			distances = [];
			minX = minY = Infinity;
			maxX = maxY = -minX;
			positions = [];
			for (j = 0; j < l; j++) {
				d = a[j].getBoundingClientRect();
				x = (d.left + d.right) / 2; //based on the center of each element
				y = (d.top + d.bottom) / 2;
				if (x < minX) {
					minX = x;
				}
				if (x > maxX) {
					maxX = x;
				}
				if (y < minY) {
					minY = y;
				}
				if (y > maxY) {
					maxY = y;
				}
				positions[j] = { x: x, y: y };
			}
			originX = isNaN(from)
				? minX + (maxX - minX) * ratio
				: positions[from].x || 0;
			originY = isNaN(from)
				? minY + (maxY - minY) * ratio
				: positions[from].y || 0;
			maxX = 0;
			minX = Infinity;
			for (j = 0; j < l; j++) {
				x = positions[j].x - originX;
				y = originY - positions[j].y;
				distances[j] = d = !axis
					? Math.sqrt(x * x + y * y)
					: Math.abs(axis === 'y' ? y : x);
				if (d > maxX) {
					maxX = d;
				}
				if (d < minX) {
					minX = d;
				}
			}
			distances.max = maxX - minX;
			distances.min = minX;
			distances.v = l =
				(vars.amount || vars.each * l || 0) *
				(from === 'edges' ? -1 : 1);
			distances.b = l < 0 ? base - l : base;
		}
		l = (distances[i] - distances.min) / distances.max;
		return distances.b + (ease ? ease.getRatio(l) : l) * distances.v;
	};
}

/**
 * Text Reveal
 * @block
 * @icon editor-textcolor
 */
const TextReveal: React.FC<ITextRevealProps> = ({
	id,
	className,
	classNames = [],
	text,
}) => {
	const ref = useRef(null);

	useLayoutEffect(() => {
		const textRevealContainer = ref.current;
		let tl;
		let ctx = gsap.context(() => {
			const split = new SplitText(ref.current.querySelectorAll('p'), {
				type: 'chars, lines',
				charsClass: 'char !inline',
				linesClass: '!inline',
			});

			if (textRevealContainer) {
				tl = gsap.timeline({
					scrollTrigger: {
						trigger: textRevealContainer,
						scrub: true,
						start: '45% bottom',
						end: 'center center',
					},
				});
			}
			gsap.matchMedia().add(
				{
					md: '(min-width: 768px)',
					reduced: '(prefers-reduced-motion: reduce)',
				},
				() => {
					if (tl) {
						tl.set(
							ref.current.querySelectorAll(
								'.secondary-layer .char',
							),
							{
								autoAlpha: 1,
								stagger: distributeByPosition({
									amount: 2,
								}),
							},
							0.1,
						).set(
							ref.current.querySelectorAll('.orig .char'),
							{
								autoAlpha: 0,
								stagger: distributeByPosition({
									amount: 2,
								}),
							},
							0.1,
						);
					}
				},
			);
		}, ref);

		return () => {
			ctx.revert();
		};
	}, []);

	return (
		<div
			id={id}
			ref={ref}
			className={
				className ||
				cn(
					'text-reveal__container bg-gradient-general-radial relative ',
					...classNames,
				)
			}
		>
			<div
				className="absolute bottom-0 left-0 h-full max-h-[326px] w-full max-w-[654px]"
				style={{
					background: `url(${bgImage}) bottom left / contain no-repeat`,
				}}
			/>
			<div className="container relative px-4 py-16 md:py-32 lg:py-40">
				{text && (
					<div
						className="relative md:mx-auto md:w-1/2"
						aria-label={text}
					>
						<p
							className={twMerge(
								'text-to-reveal orig m-0 hidden font-header text-2xl font-light md:inline-block lg:text-3xl',
							)}
							aria-hidden="true"
						>
							{text}
						</p>
						<p
							className={twMerge(
								'secondary-layer relative bottom-0 end-0 start-0 top-0 block font-header text-2xl font-light text-white md:absolute lg:text-3xl',
							)}
							aria-hidden="true"
						>
							{text}
						</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default TextReveal;
