import React from 'react';
import { IBlogCardProps } from '../BlogCard';
import AlternatingContent, {
	IAlternatingContentProps,
} from '../AlternatingContent';
import { useTranslation } from 'react-i18next';

export interface IBlogBarsListProps {
	blogBars: IBlogCardProps[];
}

const BlogBarsList: React.FC<IBlogBarsListProps> = ({ blogBars = [] }) => {
	const { t } = useTranslation();

	return (
		<div>
			{blogBars.map((blog, index) => {
				let style, reverseColumn, bgColor;

				if (blog.blogBarLayout) {
					switch (blog.blogBarLayout) {
						case 'Narrow Width / Image Right':
							style = 'Narrow';
							reverseColumn = true;
							bgColor = 'bg-light';
							break;
						case 'Medium Width / Image Right':
							style = 'Default';
							reverseColumn = true;
							bgColor = 'bg-gradient-general-radial';
							break;
						case 'Wide Width / Image Left':
							style = 'Wide';
							reverseColumn = false;
							bgColor = 'bg-gradient-rainbow';
							break;
						default:
							return 'Default';
					}
				}

				return (
					<AlternatingContent
						key={index}
						title={blog.blogTitle}
						excerpt={blog.blogExcerpt}
						eyebrow={blog.categories[0]}
						buttons={[
							{
								link: {
									label:
										blog.i18n?.readMore || t('Read More'),
									href: blog.blogURL,
								},
								buttonType: 'Secondary',
							},
						]}
						imagePrimary={blog.featuredImage}
						imageSecondary={blog.featuredImageSecondary}
						style={style}
						reverseColumn={reverseColumn}
						bgColor={bgColor}
					/>
				);
			})}
		</div>
	);
};

export default BlogBarsList;
