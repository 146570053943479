import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Form from '../Form';
import { twMerge } from 'tailwind-merge';
import LineAccent from '../LineAccent';

export interface INewsletterProps extends IBaseElementProps {
	sectionTitle?: string;
	formTitle?: string;
	/**
	 * ID of the Gravity Form to embed
	 */
	formId?: number;
	children?: any;
	footerText?: string;
	isNewsletterBar?: boolean;
	showLineAccent?: boolean;
}

/**
 * Newsletter
 * @block
 * @icon admin-site
 */
const Newsletter: React.FC<INewsletterProps> = ({
	id,
	className,
	classNames = ['bg-gradient-general-radial'],
	sectionTitle,
	formTitle,
	formId,
	children,
	footerText,
	isNewsletterBar = false,
	showLineAccent = false,
}) => {
	return (
		<div
			id={id}
			className={
				className ||
				cn(
					'newsletter__container relative overflow-hidden px-4 py-8 md:px-8 md:py-[120px]',
					...classNames,
				)
			}
		>
			<div className="container relative z-10">
				<div className="lg:flex lg:items-start lg:justify-between">
					{sectionTitle && (
						<h2 className="lg:m-0 lg:max-w-[calc(5/12*100%)] lg:text-[3rem]">
							{sectionTitle}
						</h2>
					)}
					<div
						className={cn(
							!isNewsletterBar && 'max-w-full',
							isNewsletterBar &&
								'lg:mr-[calc(1/12*100vw)] lg:max-w-[calc(6/12*100%)] lg:basis-[555px]',
						)}
					>
						{formTitle && (
							<h3
								className={`font-header font-light capitalize ${!isNewsletterBar && 'text-lg'}`}
								dangerouslySetInnerHTML={{ __html: formTitle }}
							/>
						)}
						{formId && children && (
							<Form
								style="double-stroke"
								theme="light"
								alignment="left"
								inlineForm={isNewsletterBar}
							>
								{children}
							</Form>
						)}
						{footerText && (
							<p
								className={`mb-0 mt-4 font-bodyLight text-sm [&_p]:mb-0 [&_p]:mt-4 [&_p]:font-bodyLight [&_p]:text-sm col-beige`}
								dangerouslySetInnerHTML={{
									__html: footerText,
								}}
							/>
						)}
					</div>
				</div>
			</div>
			{showLineAccent && (
				<LineAccent
					color="primary-medium"
					classNames={['absolute max-w-full bottom-0 left-0 z-0 ']}
				/>
			)}
		</div>
	);
};

export default Newsletter;
