export type ColorTypes = {
	transparent: { DEFAULT: string };
	current: { DEFAULT: string };
	white: { DEFAULT: string; text: string };
	primary: { DEFAULT: string; text: string };
	'primary-medium': { DEFAULT: string; text: string };
	secondary: { DEFAULT: string; text: string };
	tertiary: { DEFAULT: string; text: string };
	medium: { DEFAULT: string; text: string };
	light: { DEFAULT: string; text: string };
	'engineered-hardwood': { DEFAULT: string; text: string };
	'bois-dur-d-ingenierie': { DEFAULT: string; text: string };
	'luxury-vinyl': { DEFAULT: string; text: string };
	'vinyle-de-luxe': { DEFAULT: string; text: string };
	laminate: { DEFAULT: string; text: string };
	stratifie: { DEFAULT: string; text: string };
	'carpet-tile': { DEFAULT: string; text: string };
	'carreaux-de-tapis': { DEFAULT: string; text: string };
	accessories: { DEFAULT: string; text: string };
	accessoires: { DEFAULT: string; text: string };
	error: { DEFAULT: string; text: string };
	'error-dark': { DEFAULT: string; text: string };
};

export const colorPalette = {
	transparent: { DEFAULT: 'transparent' },
	current: { DEFAULT: 'currentColor' },
	white: { DEFAULT: '#FFFFFF', text: '#333543' },
	primary: { DEFAULT: '#333543', text: '#FFFFFF' },
	'primary-medium': { DEFAULT: '#484E62', text: '#FFFFFF' },
	secondary: { DEFAULT: '#CEC7BE', text: '#333543' },
	tertiary: { DEFAULT: '#E8E5E0', text: '#333543' },
	medium: { DEFAULT: '#75706A', text: '#FFFFFF' },
	light: { DEFAULT: '#F9F7F3', text: '#333543' },
	'engineered-hardwood': { DEFAULT: '#9B5C43', text: '#FFFFFF' },
	'bois-dur-d-ingenierie': { DEFAULT: '#9B5C43', text: '#FFFFFF' },
	'bois-franc-dingenierie': { DEFAULT: '#9B5C43', text: '#FFFFFF' },
	'luxury-vinyl': { DEFAULT: '#C8A798', text: '#333543' },
	'vinyle-de-luxe': { DEFAULT: '#C8A798', text: '#333543' },
	laminate: { DEFAULT: '#A1B3D1', text: '#333543' },
	stratifie: { DEFAULT: '#A1B3D1', text: '#333543' },
	'carpet-tile': { DEFAULT: '#CA9654', text: '#333543' },
	'carreaux-de-tapis': { DEFAULT: '#CA9654', text: '#333543' },
	'dalle-de-moquette': { DEFAULT: '#CA9654', text: '#333543' },
	accessories: { DEFAULT: '#73757F', text: '#FFFFFF' },
	accessoires: { DEFAULT: '#73757F', text: '#FFFFFF' },
	error: { DEFAULT: '#E20036', text: '#FFFFFF' },
	'error-dark': { DEFAULT: '#FFB3C5', text: '#FFFFFF' },
};
