import React from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { IImage } from 'tbk-components/src/components/Image';
import { colorPalette } from '../../../../tailwind-colors';
import cn from 'classnames';
import uniqueIdHash from '../../../hooks/generateUniqueIdHash';

export interface IGetAQuoteTriangleMaskProps extends IBaseElementProps {
	image?: IImage;
}
const GetAQuoteTriangleMask: React.FC<IGetAQuoteTriangleMaskProps> = ({
	image,
	id = 'triangle-canvas-get-a-quote',
	className,
	classNames = [],
}) => {
	const idHash = uniqueIdHash();
	const clipPathId = `clippath-${idHash}`;

	return (
		<svg
			id={id}
			data-name="Layer 1"
			xmlns="http://www.w3.org/2000/svg"
			width="501"
			height="250"
			viewBox="0 0 501 250"
			className={className || cn(...classNames)}
		>
			<defs>
				<clipPath id={clipPathId}>
					<polygon
						points="250.94 .39 .88 249.74 501 249.74 250.94 .39"
						fill="none"
						strokeWidth="0px"
					/>
				</clipPath>
			</defs>
			<g clipPath={`url(#${clipPathId})`}>
				<image width="501" height="250" xlinkHref={image?.src} />
			</g>
			<path
				d="M501,250L250.94.39"
				fill="none"
				stroke={colorPalette.light.DEFAULT}
				strokeWidth="1px"
			/>
			<path
				d="M1.5,250L250.94.39"
				fill="none"
				stroke={colorPalette.light.DEFAULT}
				strokeWidth="1px"
			/>
			<path
				d="M356.96,249.74L250.94.39"
				fill="none"
				stroke={colorPalette.light.DEFAULT}
				strokeWidth="1px"
			/>
			<path
				d="M1.5,103.79L250.94.39"
				fill="none"
				stroke={colorPalette.light.DEFAULT}
				strokeWidth="1px"
			/>
			<path
				d="M250.94.39l231.21,95.77"
				fill="none"
				stroke={colorPalette.light.DEFAULT}
				strokeWidth="1px"
			/>
		</svg>
	);
};

export default GetAQuoteTriangleMask;
