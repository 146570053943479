import React, { useRef, useState, useEffect } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Image, { IImage } from 'tbk-components/src/components/Image';
import Video, { IVideoProps } from '../Video';
import Icon from '../Icon';
import defaultImg from '../../assets/images/fzn-square.svg';

export interface IAdvancedMediaProps extends IBaseElementProps {
	caption?: string;
	/*
	 * This will position the caption in the corners of the image.
	 * If 'default' is selected it is up to the user to position the caption externally.
	 */
	captionPosition?:
		| 'top left'
		| 'top right'
		| 'bottom left'
		| 'bottom right'
		| 'default';
	image?: IImage;
	video?: IVideoProps;
	mediaSize?: 'cover' | 'contain';
	rounded?: boolean;
	/*
	 * These are common aspect ratios for images. Any custom sizing will have to be done with code or the component will have sizing logic.
	 */
	size?: 'Default' | '1/1' | '4/3' | '3/4' | '3/2' | '2/3' | '16/9' | '9/16';
	/*
	 * The play-pause controls and caption can have the same position and overlap.
	 * It is up to the user to make sure that doesn't overlap.
	 */
	playControlsPosition?:
		| 'top left'
		| 'top right'
		| 'bottom left'
		| 'bottom right'
		| 'default';
	/*
	 * Handy to control the video play state from parent components.
	 */
	playing?: boolean;
	playControlClass?: string;
	captionClass?: string;
	disablePlayControls?: boolean;
	playPauseLabel?: string;
}

const AdvancedMedia: React.FC<IAdvancedMediaProps> = ({
	image,
	video,
	mediaSize = 'cover',
	className,
	caption,
	size = 'Default',
	captionPosition = 'bottom right',
	playControlsPosition = 'bottom left',
	classNames = [],
	rounded = false,
	playing = true,
	id,
	playControlClass,
	captionClass,
	disablePlayControls = false,
	playPauseLabel = 'Play/Pause',
}) => {
	if (!image && !video) {
		return null;
	}
	const el = useRef(null);
	const [isPlaying, setIsPlaying] = useState<boolean>(playing);

	const calcPositionClass = (position: string) => {
		switch (position) {
			case 'top right':
				return 'top-0 right-0 mt-2 mr-2';
			case 'top left':
				return 'top-0 left-0 mt-2 ml-2';
			case 'bottom right':
				return 'bottom-0 right-0 mb-2 mr-2';
			case 'bottom left':
				return 'bottom-0 left-0 mb-2 ml-2';
			default:
				return '';
		}
	};
	const captionPositionClasses =
		!captionClass && calcPositionClass(captionPosition);
	const playControlsPositionClasses =
		!playControlClass && calcPositionClass(playControlsPosition);

	const aspectRatioClass = () => {
		switch (size) {
			case '1/1':
				return 'aspect-[1/1]';
			case '4/3':
				return 'aspect-[4/3]';
			case '3/4':
				return 'aspect-[3/4]';
			case '3/2':
				return 'aspect-[3/2]';
			case '2/3':
				return 'aspect-[2/3]';
			case '16/9':
				return 'aspect-[16/9]';
			case '9/16':
				return 'aspect-[9/16]';
			default:
				return '';
		}
	};

	const imageRender = image && (
		<div className="relative h-full w-full">
			<div className="absolute h-full w-full animate-pulse bg-secondary" />
			<Image
				className={cn(
					'block',
					'w-full',
					'h-full',
					`object-${mediaSize}`,
					'relative',
					'hidden',
				)}
				{...image}
			/>
		</div>
	);

	const videoRender = video && (
		<div className={cn('advanced-media__video h-full w-full')}>
			{!disablePlayControls && (
				<button
					className={cn(
						`advanced-media__video-play-control`,
						playControlsPositionClasses,
						playControlsPositionClasses.length > 0 &&
							!playControlClass &&
							'mw-36px z-3 aspect-1-1 absolute block h-auto w-full border-0 bg-transparent py-1 text-white',
						playControlClass,
					)}
					aria-label={playPauseLabel || 'Play/Pause'}
					onClick={() => {
						setIsPlaying((isPlaying) => !isPlaying);
					}}
				>
					<Icon name={isPlaying ? 'pause1' : 'play1'} />
				</button>
			)}
			<Video
				classNames={[
					'block',
					'w-full',
					'h-full',
					`object-${mediaSize}`,
					aspectRatioClass(),
					rounded && 'rounded-md',
					'[&_video]:block',
					'[&_video]:w-full',
					'[&_video]:h-full',
					mediaSize === 'cover'
						? `[&_video]:object-cover`
						: `[&_video]:object-contain`,
				]}
				{...video}
				disableControls
				muted
				loop
				playIcon="play"
				poster={image}
				autoPlay={isPlaying}
				videoWallpaper
				hideVideoButton={!video.oembed}
			></Video>
		</div>
	);

	const mediaRender = video?.url || video?.oembed ? videoRender : imageRender;

	useEffect(() => {
		if (!el.current) {
			return;
		}
		const videoEl = el.current.querySelector('video');

		if (videoEl) {
			if (isPlaying) {
				videoEl?.play();
			} else {
				videoEl?.pause();
			}
		}
	}, [isPlaying]);

	useEffect(() => {
		if (!el.current || video?.url || video?.oembed) return;

		const imageEl = el.current.querySelector('img');

		const handleLoad = (e) => {
			(e.target as HTMLImageElement).classList.remove('hidden');
		};
		const handleErr = (e) => {
			e.target.src = defaultImg;
		};

		if (imageEl) {
			imageEl?.addEventListener('load', handleLoad);
			imageEl?.addEventListener('error', handleErr);
		}

		if (imageEl.complete) {
			handleLoad({ target: imageEl });
		}

		return () => {
			imageEl?.removeEventListener('load', handleLoad);
			imageEl?.removeEventListener('error', handleErr);
		};
	}, [el, video, image]);

	return (
		<div
			id={id}
			className={cn(
				className,
				'advanced-media',
				'block',
				'overflow-hidden',
				'relative h-auto w-full',
				rounded && 'rounded-md',
				aspectRatioClass(),
				...classNames,
			)}
			ref={el}
		>
			{caption && (
				<div
					className={cn(
						'advanced-media__bubble',
						captionPositionClasses,
						captionPositionClasses.length > 0 &&
							!captionClass &&
							'mw-70 h6 absolute z-[1] rounded-full bg-primary px-2 py-3 text-xs text-white',
						captionClass,
					)}
				>
					<span className={cn('inline-block', 'm-0')}>{caption}</span>
				</div>
			)}
			{mediaRender}
		</div>
	);
};

export default AdvancedMedia;
