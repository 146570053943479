import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { IBorderColorsProps, ITextColorsProps } from '../Colors';
import IconText, { IIconTextProps } from './IconText';
import { twMerge } from 'tailwind-merge';

export interface IIconTextGridProps extends IBaseElementProps {
	orientation?: 'horizontal' | 'vertical';
	borderColor?: IBorderColorsProps['options'];
	iconColor?: ITextColorsProps['options'];
	textColor?: ITextColorsProps['options'];
	iconTexts: IIconTextProps[];
	largeDesktopGrid?: boolean;
	numbOfItems?: number;
}

const IconTextGrid: React.FC<IIconTextGridProps> = ({
	id,
	className,
	classNames = [],
	orientation,
	borderColor,
	iconColor,
	textColor,
	iconTexts = [],
	largeDesktopGrid = false,
	numbOfItems,
}) => {
	return (
		<div
			id={id}
			className={
				className || cn('icon-text-grid__container', ...classNames)
			}
		>
			<div
				className={twMerge(
					'grid grid-cols-2 sm:grid-cols-3',
					largeDesktopGrid && `lg:grid-cols-${numbOfItems || 5}`,
				)}
			>
				{iconTexts.length > 0 &&
					iconTexts.map(
						(iconText, index) =>
							(iconText?.text || iconText?.image?.src) && (
								<IconText
									largeDesktopGrid={largeDesktopGrid}
									key={index}
									{...iconText}
									orientation={orientation}
									borderColor={borderColor}
									iconColor={iconColor}
									textColor={textColor}
									nbItems={iconTexts.length}
								/>
							),
					)}
			</div>
		</div>
	);
};

export default IconTextGrid;
