import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Image, { IImage } from 'tbk-components/src/components/Image';
import Button, { ILink } from '../Button';
import Carousel, { CarouselItem } from 'tbk-components/src/components/Carousel';
import uniqueIdHash from '../../hooks/generateUniqueIdHash';
import AdvancedMedia from '../AdvancedMedia';
import { twMerge } from 'tailwind-merge';

//Developer Note:
//This component is not a WP Gutenberg block and is being rendered inside the Smash Balloons Instagram Reel plugin.
//You can see this block being rendering in /themes/fuzion-flooring/sbi/feed.php

export interface IInstagramFeedI18nProps {
	openLinkLabel?: string;
}

export interface IInstagramTile extends IBaseElementProps {
	image?: IImage;
	excerpt?: string;
	link?: ILink;
}

export interface IInstagramFeedProps extends IBaseElementProps {
	title?: string;
	cta?: ILink;
	tiles?: IInstagramTile[];
	avatarLogo?: IImage;
	i18n?: IInstagramFeedI18nProps;
}

const InstagramFeed: React.FC<IInstagramFeedProps> = ({
	id,
	className,
	classNames = [],
	title,
	cta,
	avatarLogo,
	tiles = [],
	i18n,
}) => {
	const genId = id || `fuzion-instagram-feed-${uniqueIdHash()}`;

	return (
		<div
			id={id}
			className={
				className ||
				cn(
					'fuzion-instagram-feed bg-tertiary px-4 py-8 md:px-6 md:py-30',
					...classNames,
				)
			}
		>
			<div className="relative z-[1] mx-auto w-full max-w-[1240px] flex-col gap-4 md:flex md:flex-row md:justify-between">
				{title && <h2 className="mb-0">{title}</h2>}
				{cta?.label && (
					<div className="mt-4 inline-block md:mt-0">
						<Button
							link={cta}
							buttonType="Primary"
							icon="instagram"
							classNames={['flex-row-reverse']}
							size="lg"
						/>
					</div>
				)}
			</div>
			{tiles?.length > 0 && (
				<Carousel
					showArrows={true}
					showBullets={false}
					id={genId}
					classNames={['mt-10 -mr-4 md:-mr-6 md:mt-18 xl:mt-20']}
					options={{
						perView: 4.5,
						type: 'carousel',
						gap: '16px',
						breakpoints: {
							568: {
								perView: 1.3,
							},
							768: {
								perView: 2.5,
							},
							1200: {
								perView: 3.5,
							},
						},
					}}
				>
					{tiles.map((tile, index) => (
						<CarouselItem key={index}>
							<div
								className={twMerge(
									'relative block',
									index % 2 === 0 && 'md:mt-8',
								)}
							>
								{tile.image && (
									<div className="relative z-0 md:pr-6">
										<AdvancedMedia
											image={tile.image}
											rounded
											size="1/1"
										/>
									</div>
								)}
								<div className="relative z-[1] -mt-4 ml-auto mr-0 flex w-[calc(100%-16px)] items-start justify-start gap-3 rounded-md bg-white p-4 md:ml-2 md:w-full">
									{avatarLogo?.src && (
										<AdvancedMedia
											image={avatarLogo}
											size="1/1"
											classNames={[
												'border hidden md:block max-w-10 h-auto flex-shrink-0 border-light rounded-full',
											]}
										/>
									)}
									{tile?.excerpt && (
										<p className="mb-0 text-sm leading-[1.6]">
											{tile.excerpt.length > 100
												? `${tile.excerpt.split(' ').splice(0, 10).join(' ')}...`
												: tile.excerpt}
										</p>
									)}
								</div>
								{tile?.link?.href && (
									<a
										href={tile?.link.href}
										target="_blank"
										className="absolute inset-0 z-[1] h-full w-full"
										aria-label={
											i18n?.openLinkLabel ||
											'Visit Instagram Post'
										}
										tabIndex={0}
									/>
								)}
							</div>
						</CarouselItem>
					))}
				</Carousel>
			)}
		</div>
	);
};

export default InstagramFeed;
