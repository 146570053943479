import React, { useRef, useLayoutEffect } from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Button, { ILink } from 'tbk-components/src/components/Button';
import Image, { IImage } from 'tbk-components/src/components/Image';
import ImageToSVG from '../ImageToSVG';
import { kebabCase } from 'lodash';
import { twMerge } from 'tailwind-merge';
import { gsap } from 'gsap';
import { IBoxNavProps } from '../MegaMenu';
import CategoryListings from './CategoryListings';
import useScreenSize from '../../hooks/useScreenSize';

export interface ICategoryProps {
	title?: string;
	link?: ILink;
	categoryGraphic?: IImage;
	hoverImage?: IImage;
}

export interface IFeaturedCategoriesProps extends IBaseElementProps {
	title?: string;
	subtitle?: string;
	categories?: IBoxNavProps[];
}

/**
 * Featured Categories
 * @block
 * @icon editor-justify
 */
const FeaturedCategories: React.FC<IFeaturedCategoriesProps> = ({
	id,
	className,
	classNames = [],
	title,
	subtitle,
	categories = [],
}) => {
	const ref = useRef(null);
	const viewportWidth = useScreenSize();
	useLayoutEffect(() => {
		let ctx = gsap.context(() => {
			const featuredCategoriesList = ref.current.querySelectorAll(
				'.featured-categories__category',
			);
			let tl;
			if (featuredCategoriesList) {
				tl = gsap.timeline({
					scrollTrigger: {
						trigger: ref.current,
						start: 'top center',
					},
				});
			}
			gsap.matchMedia().add(
				{
					md: '(min-width: 768px)',
					reduced: '(prefers-reduced-motion: reduce)',
				},
				() => {
					if (tl) {
						tl.fromTo(
							featuredCategoriesList,
							{
								opacity: 0,
								y: 80,
							},
							{
								duration: 1,
								opacity: 1,

								y: 0,
								stagger: {
									each: 0.15,
									axis: 'y',
									grid: 'auto',
									ease: 'power2.inOut',
								},
							},
						);
					}
				},
			);
		}, ref);

		return () => {
			ctx.revert();
		};
	}, [viewportWidth]);

	return (
		<div
			id={id}
			ref={ref}
			className={
				className ||
				twMerge('featured-categories__container', ...classNames)
			}
		>
			<div className="container px-4 py-8 md:py-24 lg:py-30">
				{(title || subtitle) && (
					<div className="text-start text-primary md:mx-auto md:w-2/3 lg:flex lg:items-end lg:justify-between lg:gap-8">
						{title && <h2 className="h2-large lg:mb-0">{title}</h2>}
						{subtitle && <p className="lead mb-0">{subtitle}</p>}
					</div>
				)}
				{categories?.length > 0 && (
					<ul className="mt-8 hidden sm:block lg:mt-20">
						{categories.map((category, index) => (
							<li
								key={index}
								className="featured-categories__category !mb-0"
							>
								{category?.link?.href && (
									<Button
										href={category?.link?.href}
										className={twMerge(
											`group relative flex items-center justify-between overflow-hidden rounded-md border-b border-tertiary px-3 duration-300 ease-in-out md:px-0`,
										)}
									>
										<div className="w-2/3 md:mx-auto">
											{category?.link?.label && (
												<span
													className={`h2 mb-0 block py-9 lg:max-w-[calc(3/5*100%)] group-hover:text-${kebabCase(category?.link?.label)}-text group-focus:text-${kebabCase(category?.link?.label)}-text`}
												>
													{category?.link?.label}
												</span>
											)}
										</div>
										{(category?.hoverImage?.src ||
											category?.graphic) && (
											<div
												className={`absolute right-0 flex h-full max-h-full w-1/3 items-center justify-center py-0 [clip-path:polygon(0_0,100%_0,100%_100%,18%_100%)] lg:w-5/12`}
											>
												{category?.hoverImage?.src && (
													<Image
														{...category.hoverImage}
														className="h-full w-full object-cover transition-opacity duration-300 ease-in-out group-hover:opacity-100 group-focus:opacity-100 md:opacity-0"
													/>
												)}
												{category?.graphic?.src && (
													<Image
														{...category.graphic}
														className={twMerge(
															'absolute',
															'top-1/2',
															'left-1/2',
															'translate-x-[-50%]',
															'translate-y-[-50%]',
															'w-16',
															'h-auto',
															'grow-0',
															'shrink-0',
															'max-md:text-white',
															`text-${kebabCase(category?.link?.label)}`,
															'group-hover:text-white',
															'group-hover:scale-[3]',
															'group-focus:text-white',
															'group-focus:scale-[3]',
															'transition-transform',
															'duration-300',
															'ease-in-out',
															'group-focus:invert',
															'group-hover:invert',
															'group-hover:brightness-0',
															'group-focus:brightness-0',
														)}
													/>
												)}
											</div>
										)}
										<span
											className={twMerge(
												`absolute start-0 top-0 -z-[1] h-full w-full transition-opacity duration-300 group-hover:opacity-100 group-focus:opacity-100 md:opacity-0 bg-${kebabCase(category?.link?.label)}`,
											)}
										></span>
									</Button>
								)}
							</li>
						))}
					</ul>
				)}
				<CategoryListings
					categories={categories}
					classNames={['mt-8 lg:mt-20 sm:hidden']}
				/>
			</div>
		</div>
	);
};

export default FeaturedCategories;
