import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';

export interface IStatProps extends IBaseElementProps {
	count?: number;
	prefix?: string;
	suffix?: string;
	countUp?: boolean;
	countUpDuration?: number;
	countClassName?: string;
	textClassName?: string;
}

const Stat: React.FC<IStatProps> = ({
	count,
	prefix,
	suffix,
	countUp = true,
	countUpDuration = 2000,
	countClassName,
	className,
	classNames = [],
}) => {
	const ref = useRef(null);

	useEffect(() => {
		if (countUp !== true || !ref.current) {
			return;
		}

		const counterEl = ref.current! as HTMLSpanElement;
		const frameDuration = 1000 / 60;
		const totalFrames = Math.round(countUpDuration / frameDuration);
		const easeOutQuad = (t: number) => t * (2 - t);
		const countTo = parseInt(counterEl.innerHTML, 10);
		let wasAnimated = false;

		const animateCountUp = () => {
			let frame = 0;
			const counter = setInterval(() => {
				frame++;

				const progress = easeOutQuad(frame / totalFrames);
				const currentCount = Math.round(countTo * progress);

				if (parseInt(counterEl.innerHTML, 10) !== currentCount) {
					counterEl.innerHTML = `${currentCount}`;
				}

				if (frame === totalFrames) {
					clearInterval(counter);
				}
			}, frameDuration);
		};

		const isElementInViewport = (el: HTMLSpanElement) => {
			const rect = el.getBoundingClientRect();

			return (
				rect.top >= 0 &&
				rect.left >= 0 &&
				rect.bottom <=
					(window.innerHeight ||
						document.documentElement.clientHeight) &&
				rect.right <=
					(window.innerWidth || document.documentElement.clientWidth)
			);
		};

		const handleScroll = () => {
			if (wasAnimated === false && isElementInViewport(counterEl)) {
				animateCountUp();
				wasAnimated = true;
			}
		};

		window.addEventListener('scroll', handleScroll);

		counterEl.innerHTML = '0';
		handleScroll();

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className={className || cn('stat', ...classNames)}>
			<span className={countClassName || 'stat-count'}>
				{prefix && <span className="stat-count__prefix">{prefix}</span>}
				{count && (
					<span className="stat-count__number" ref={ref}>
						{count}
					</span>
				)}
				{suffix && <span className="stat-count__suffix">{suffix}</span>}
			</span>
		</div>
	);
};

export default Stat;
