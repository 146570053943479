import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { IImage } from 'tbk-components/src/components/Image';
import { ISpec } from '../ProductCard';
import Button, { ILink } from '../Button';
import AdvancedMedia from '../AdvancedMedia';
import { twMerge } from 'tailwind-merge';
import PriceRangeIndicator from '../PriceRangeIndicator';

/*
 * Developer Notes for B/E
 * For each product spec, F/E will expect the array length for each product to be the same.
 * On top of that, it is expected that the keys for each Spec object will be the same. Even if one product does not have a value for that spec.
 * So for example, if the first product has a spec of {'label': 'Species', 'value': 'European Oak'},
 * Then the second product, even if it does not have a value, should include a spec of {'label': 'Species', 'value': ''}.
 * That way F/E can consistently map over the array of specs and display the values in a table format.
 */
export interface IProductCompareItem {
	image?: IImage;
	label?: string;
	specs?: ISpec[];
	cta?: ILink;
}

export interface IProductComparisonTableProps extends IBaseElementProps {
	/*
	 * Layout supports up to 3 products
	 */
	products?: IProductCompareItem[];
}

const generatedSpecValue = (value: string) => {
	//If value has just dollar signs
	if (String(value).match(/^\$+$/)) {
		return <PriceRangeIndicator priceRange={value} />;
	}
	return value;
};

const ProductComparisonTable: React.FC<IProductComparisonTableProps> = ({
	id,
	className,
	classNames = [],
	products = [],
}) => {
	const [comparisonTableRows, setComparisonTableRows] = useState<{
		headers: { image?: IImage; label?: string }[];
		specRows: { label: string; values: string[] }[];
		ctas: ILink[];
	}>();
	const tableClasses = {
		gutterSpacingX: 'px-3 md:px-6',
		gutterSpacingY: 'py-2',
	};
	useEffect(() => {
		//This essentially divides up the products into table rows and columns.
		//It creates a header row with the product titles and images
		//It creates a spec row with the product specs and matches them based off the spec label
		//It creates a cta row with the product ctas
		if (!products || products.length === 0) {
			return;
		}
		const headers = [];
		const specRows = [];
		const ctas = [];
		const productSpecs = products.map((product) => product.specs);
		const productLabels = products.map((product) => product.label);
		const productImages = products.map((product) => product.image);
		const productCtas = products.map((product) => product.cta);

		// Empty row for the headers to consider the Spec column
		headers.push({});

		productLabels.forEach((label, index) => {
			headers.push({
				image: productImages[index],
				label: label ?? '',
			});
		});

		// Spec Rows
		const allSpecs = productSpecs.flat();
		const specLabels = allSpecs.map((spec) => spec.label);
		const uniqueSpecLabels = [...new Set(specLabels)];
		uniqueSpecLabels.forEach((label) => {
			const values = productSpecs.map((specs) => {
				const spec = specs.find((spec) => spec.label === label);
				return spec ? spec.value : '';
			});
			specRows.push({
				label: label,
				values: values,
			});
		});
		// CTAs
		// Empty row for the headers to consider the Spec column
		ctas.push({});

		productCtas.forEach((cta) => {
			ctas.push(cta);
		});
		setComparisonTableRows({
			headers: headers,
			specRows: specRows,
			ctas: ctas,
		});
	}, []);

	return (
		<div
			id={id}
			className={
				className ||
				cn(
					'product-comparison border-1 mx-auto w-full overflow-scroll border-primary pb-12 pt-5 md:max-w-[1376px] md:pb-20 md:pt-10 lg:pb-30 xl:overflow-auto',
					...classNames,
				)
			}
		>
			<table className="product-comparison__table border-1 w-full min-w-[1080px] border-primary sm:table-fixed">
				<thead>
					<tr>
						{comparisonTableRows?.headers.map((header, index) => (
							<th key={index}>
								<div
									className={twMerge(
										'flex flex-col items-center justify-center gap-5 pb-10 text-center',
										tableClasses.gutterSpacingX,
									)}
								>
									{header.image && (
										<div className="h-[120px] w-[120px] max-w-full md:h-auto md:w-full">
											<AdvancedMedia
												image={header.image}
												rounded
												size="1/1"
												classNames={['mx-auto']}
											/>
										</div>
									)}
									{header.label && (
										<h3 className="mb-0 font-normal">
											{header.label}
										</h3>
									)}
								</div>
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{comparisonTableRows?.specRows.map((specRow, index) => (
						<tr
							key={index}
							className={twMerge(
								index % 2 === 0 ? 'bg-light' : 'bg-white',
							)}
						>
							<td
								className={twMerge(
									tableClasses.gutterSpacingX,
									tableClasses.gutterSpacingY,
									'text-right',
									'text-medium',
									'text-base',
									'w-[150px] sm:w-auto',
								)}
							>
								{specRow.label}:
							</td>
							{specRow?.values.map((value, index) => (
								<td
									key={index}
									className={twMerge(
										tableClasses.gutterSpacingX,
										tableClasses.gutterSpacingY,
										'text-primary',
										'text-base',
									)}
								>
									{generatedSpecValue(value)}
								</td>
							))}
						</tr>
					))}
				</tbody>
				<tfoot>
					<tr>
						{comparisonTableRows?.ctas.map((cta, index) => (
							<td
								key={index}
								className={twMerge(
									tableClasses.gutterSpacingX,
									tableClasses.gutterSpacingY,
									'pt-6',
								)}
							>
								{cta?.href && (
									<Button
										link={cta}
										buttonType="Primary"
										size="sm"
									/>
								)}
							</td>
						))}
					</tr>
				</tfoot>
			</table>
		</div>
	);
};

export default ProductComparisonTable;
