import React from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import cn from 'classnames';
import ProductCard, { i18nCard, IProductCardProps } from '../../ProductCard';
import PriceRangeIndicator from '../../PriceRangeIndicator';
import Bullets, { IBullet } from '../../Bullets';
import ProductCards, { i18nCards } from '../../ProductCards';
export interface IAccessoriesTabProps extends IBaseElementProps {
	tabLabel?: string;
	products?: IProductCardProps[];
	i18n?: i18nCards;
}
const AccessoriesTab: React.FC<IAccessoriesTabProps> = ({
	id,
	className,
	classNames = [],
	products = [],
	i18n,
}) => {
	return (
		<div
			id={id}
			className={className || cn('accessories-tab', ...classNames)}
		>
			{products?.length > 0 && (
				<ProductCards
					productCards={products}
					cardTitleClassName="h5"
					stayAsColumnCard
					i18n={i18n}
					productListingClasses={[
						'product-cards-listing grid grid-cols-2 gap-3 lg:grid-cols-3 md:gap-8 lg:gap-10 xl:gap-16 xl:grid-cols-5',
					]}
				/>
			)}
		</div>
	);
};

export default AccessoriesTab;
