import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Image, { IImage } from 'tbk-components/src/components/Image';
import Button, { ILink, IFuzionButtonProps } from '../Button';
import Nav, { NavItem } from 'tbk-components/src/components/Nav';
import Offcanvas from 'bootstrap/js/dist/offcanvas';
import { kebabCase } from 'lodash';
import SocialIcons, { ISocialIconsProps } from '../SocialIcons';
import GetAQuote, { IGetAQuoteProps } from '../GetAQuote';
import Icon from '../Icon';
import Search, { ISearchProps } from '../Search';
import useScreenSize from '../../hooks/useScreenSize';
import { twMerge } from 'tailwind-merge';
import { getCurrentRegion } from '../Search/SearchBar';

// Represents a link in the language selector drop-down
export type LanguageNavItem = {
	href: string;
	label: string;
	target?: string;
	icon?: string;
	code?: string;
	locale?: string;
};

interface IHeaderI18nProps {
	selectLanguage?: string;
	toggleSearch?: string;
	back?: string;
	toggleMobileMenu?: string;
}

export interface IHeaderProps extends IBaseElementProps {
	languages?: LanguageNavItem[];
	leftLinks?: ILink[];
	rightLinks?: ILink[];
	hasCartItems?: boolean;
	logo?: IImage;
	logoURL?: ILink;
	mainNav?: NavItem[];
	stickyHeader?: boolean;
	socialIcons?: ISocialIconsProps;
	onProductPage?: boolean;
	mobileCtaText?: string;
	headerCta?: IFuzionButtonProps;
	getAQuote?: IGetAQuoteProps;
	activeLanguage?: LanguageNavItem;
	enableSearch?: boolean;
	search?: ISearchProps;
	theme?: 'light' | 'dark';
	i18n?: IHeaderI18nProps;
}

const Header: React.FC<IHeaderProps> = ({
	id,
	className,
	classNames = [],
	languages = [],
	leftLinks = [],
	rightLinks = [],
	logo,
	logoURL,
	mainNav = [],
	onProductPage,
	mobileCtaText,
	headerCta,
	getAQuote,
	stickyHeader,
	socialIcons,
	hasCartItems,
	activeLanguage,
	enableSearch = false,
	search,
	theme = 'dark',
	i18n,
}) => {
	const headerRef = useRef(null);
	const offcanvasRef = useRef(null);
	const viewportSize = useScreenSize();
	let isXsAndLower = viewportSize < 480;

	const [headerTheme, setHeaderTheme] = useState<string>(theme);
	const [showSearch, setShowSearch] = useState<any>(null);
	const [languageLink, setLanguageLink] = useState<string>('');
	const languageChange = (event) => {
		const link = event.target.value;
		setLanguageLink(link);
		if (link) {
			window.location.href = link; // Navigate to the selected link
		}
	};

	const iconMassage = (label: string) => {
		const kebabLabel = kebabCase(label);
		return kebabLabel === 'panier'
			? 'shopping-cart'
			: kebabLabel === 'mon-compte'
				? 'user'
				: kebabLabel === 'recherche'
					? 'search'
					: kebabLabel;
	};

	useLayoutEffect(() => {
		// Certain sections will simply change the filters of the Index page and not cause a page refresh.
		// This will scan for a button click and close the menu to provide a better UX.

		const allNavLinks = document.querySelectorAll(
			'#main-header-canvas .nav-item a:not(.dropdown-toggle)',
		);
		if (!allNavLinks?.length) return;
		// create an empty array for new event handlers
		const eventHandlers = [];
		const handleClick = (e) => {
			const offcanvas = document.querySelector('.offcanvas');
			const href = e?.currentTarget?.getAttribute('href');
			const backDrop = document.querySelector('.offcanvas-backdrop');

			if (offcanvas && href && href !== '#') {
				offcanvas.classList.remove('show');
				offcanvas.setAttribute('aria-hidden', 'true');
				backDrop.classList.remove('show');
			}
		};

		allNavLinks.forEach((link) => {
			link.addEventListener('click', handleClick);
			// push newly added event listener to the array
			// iterate them later in the return function to cleanup
			eventHandlers.push([link], 'click', handleClick);
		});

		return () => {
			for (const item of eventHandlers) {
				const [el, evName, handler] = item;
				el.removeEventListener(evName, handler);
			}
		};
	}, []);

	useEffect(() => {
		if (!headerRef.current || !offcanvasRef.current) return;

		const offcanvasEl = offcanvasRef.current;
		// @ts-ignore
		const offcanvas = new Offcanvas(offcanvasEl);
		const offCanvasEvents = ['show.bs.offcanvas', 'hide.bs.offcanvas'];
		const specificArchivePages = [
			'products/engineered-hardwood/',
			'products/accessories/',
			'produits/bois-franc-dingenierie/',
			'produits/Accessoires/',
			'produits/bois-dur-d-ingenierie/',
			'produits/accessoires/',
		];
		const onSpecificArchivePages = specificArchivePages.some((page) =>
			window.location.pathname.endsWith(page),
		);

		if (onSpecificArchivePages) setHeaderTheme('light');

		const setAriaHidden = (
			eventType: 'addEventListener' | 'removeEventListener',
		) => {
			offCanvasEvents.forEach((event) => {
				offcanvasEl[eventType](event, () => {
					offcanvasEl.setAttribute(
						'aria-hidden',
						offcanvasEl.getAttribute('aria-hidden') === 'true'
							? 'false'
							: 'true',
					);
				});
			});
		};

		let prevScrollPos = window.scrollY;
		const showHideHeader = () => {
			let currentScrollPos = window.scrollY;
			const alertBar = headerRef.current
				.closest('body')
				.querySelector('[data-component="AlertBar"]') as HTMLDivElement;
			const initPos = alertBar ? alertBar.offsetHeight + 'px' : 0;

			/* if the header is sticky from the beginning there is no need to hide it
			on scroll down, it'll always be visible */
			if (stickyHeader) {
				if (currentScrollPos > 0) {
					setHeaderTheme('dark');
					headerRef.current.classList.add('bg-white');
				} else {
					if (onSpecificArchivePages) {
						setHeaderTheme('light');
					} else {
						setHeaderTheme(theme);
					}
					headerRef.current.classList.remove('bg-white');
				}

				if (!isXsAndLower) {
					if (currentScrollPos > prevScrollPos) {
						headerRef.current.style.top =
							headerRef.current.offsetHeight * -1 + 'px';
					} else {
						if (currentScrollPos !== 0) {
							headerRef.current.style.top = 0;
						} else {
							headerRef.current.style.top = initPos;
						}
					}
				}
			} else {
				// if the header is sticky only when scrolling up
				const mainWrapper = document.querySelector(
					'#main',
				) as HTMLDivElement;

				// set the header background color to white from the beginning
				headerRef.current.classList.add('bg-white');

				// when scrolling up, reveal the header
				if (currentScrollPos < prevScrollPos) {
					// only apply the sticky header when current scroll position is greater than the header height
					if (currentScrollPos > headerRef.current.offsetHeight) {
						setHeaderTheme('dark');
						headerRef.current.style = 'position:fixed;top:0;';
					}

					// when scroll back to the top, remove the sticky header
					if (currentScrollPos === 0) {
						if (onSpecificArchivePages) {
							setHeaderTheme('light');
						} else {
							setHeaderTheme(theme);
						}
						mainWrapper.style.paddingTop = '';
						headerRef.current.style = '';
					}
				} else {
					// initialize the header position when scrolling down
					// this will make the header be in a ready position when the user scrolls up
					// so the header will be slide down instead of shows up instantly
					if (currentScrollPos > headerRef.current.offsetHeight) {
						// we need to apply top padding to #main to prevent a visual jump when the header becomes fixed
						mainWrapper.style.paddingTop = `${headerRef.current.offsetHeight}px`;
						headerRef.current.style = `position:fixed;top:${headerRef.current.offsetHeight * -1}px;`;
					}
				}
			}

			prevScrollPos = currentScrollPos;
		};
		const resetCanvas = () => {
			if (window.innerWidth >= 1024) offcanvas.hide();
		};
		const dropdowns = offcanvasRef.current.querySelectorAll(
			'.mobile-main-nav .nav-link.dropdown-toggle',
		);
		const evHandlers = [];

		for (let i = 0; i < dropdowns.length; i++) {
			const showHandler = (e) => {
				offcanvasRef.current.classList.add('mega-menu-show');
			};
			dropdowns[i].addEventListener('show.bs.dropdown', showHandler);
			evHandlers.push([dropdowns[i], 'show.bs.dropdown', showHandler]);

			const hiddenHandler = (e) => {
				offcanvasRef.current.classList.remove('mega-menu-show');
			};
			dropdowns[i].addEventListener('hidden.bs.dropdown', hiddenHandler);
			evHandlers.push([
				dropdowns[i],
				'hidden.bs.dropdown',
				hiddenHandler,
			]);
		}

		const clickOutsideHandler = (e) => {
			const target = e.target as HTMLElement;

			if (!target.closest('.header__container .search__container')) {
				setShowSearch(null);
			}
		};

		setAriaHidden('addEventListener');
		showHideHeader();
		window.addEventListener('resize', resetCanvas);
		window.addEventListener('scroll', showHideHeader);
		window.addEventListener('click', clickOutsideHandler);

		return () => {
			for (const item of evHandlers) {
				const [el, evName, handler] = item;
				el.removeEventListener(evName, handler);
			}

			setAriaHidden('removeEventListener');
			window.removeEventListener('resize', resetCanvas);
			window.removeEventListener('scroll', showHideHeader);
			window.removeEventListener('click', clickOutsideHandler);
		};
	}, []);

	// We store the products the user wants to compare in localStorage but that will be inconsistent if the user changes the language.
	// So we need to remove them.
	useLayoutEffect(() => {
		const languageLinks = headerRef.current.querySelectorAll(
			'.fuzion__language-select-nav .dropdown-item',
		);

		const eventHandlers = [];
		const handleLanguageSwitch = (e) => {
			if (localStorage.getItem('productToCompare')) {
				localStorage.removeItem('productToCompare');
			}
		};

		languageLinks.forEach((link) => {
			link.addEventListener('click', handleLanguageSwitch);

			eventHandlers.push([link], 'click', handleLanguageSwitch);
		});

		return () => {
			for (const item of eventHandlers) {
				const [el, evName, handler] = item;
				el.removeEventListener(evName, handler);
			}
		};
	}, []);

	useEffect(() => {
		if (showSearch?.headerTopMobile) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
	}, [showSearch]);

	const searchPiece = (btnClass: string, tagName: string) => {
		return (
			enableSearch && (
				<div>
					{search && (
						<Button
							icon="search"
							id="header-search-toggle"
							className={btnClass}
							type="button"
							onClick={(e) => {
								e.stopPropagation();

								if (!showSearch?.[tagName]) {
									//Because our search toggle isn't part of Bootstrap, the Mega Menus don't "detect" that another modal is open.
									//Manually scan for them and mimic that behavior.
									const megaMenuDropdowns =
										document.querySelectorAll(
											'.dropdown-mega-menu.dropdown-open',
										);
									megaMenuDropdowns.forEach(
										(megaMenuDropdown) => {
											megaMenuDropdown.classList.remove(
												'dropdown-open',
											);

											const dropdownLink =
												megaMenuDropdown.querySelector(
													'.dropdown-toggle',
												);
											dropdownLink.setAttribute(
												'aria-expanded',
												'false',
											);
											dropdownLink.classList.remove(
												'show',
											);

											const dropdownMenu =
												megaMenuDropdown.querySelector(
													'.dropdown-menu',
												);
											dropdownMenu.classList.remove(
												'show',
											);
										},
									);
								}
								setShowSearch({
									...showSearch,
									[tagName]:
										showSearch?.[tagName] === null ||
										showSearch?.[tagName] === undefined
											? true
											: !showSearch?.[tagName],
								});
							}}
							aria-label={i18n?.toggleSearch || 'Toggle search'}
							aria-expanded={showSearch?.[tagName]}
							aria-controls="header-search-panel"
						/>
					)}
					{showSearch?.[tagName] && (
						<div
							id="header-search-panel"
							className="absolute left-0 top-full z-10 w-full"
							aria-hidden={!showSearch?.[tagName]}
						>
							<Search {...search} />
						</div>
					)}
				</div>
			)
		);
	};

	const headerCTABtn = () => {
		return (
			<div className="pl-4 lg:pl-0">
				{!onProductPage && headerCta?.link?.href && (
					<Button
						{...headerCta}
						link={{
							href: headerCta.link.href,
							target: headerCta.link.target,
						}}
						classNames={['btn-sm']}
					>
						{mobileCtaText && (
							<span className="lg:hidden">{mobileCtaText}</span>
						)}
						{headerCta?.link?.label && (
							<span
								className={mobileCtaText && 'hidden lg:inline'}
							>
								{headerCta?.link?.label}
							</span>
						)}
					</Button>
				)}
			</div>
		);
	};

	return (
		<>
			<header
				ref={headerRef}
				id={id}
				className={
					className ||
					cn(
						'header__container relative z-50 w-full transition-all duration-300 ease-in-out',
						stickyHeader && '!fixed',
						...classNames,
					)
				}
			>
				<div className="container px-4 py-[10px] md:px-8 md:pl-8 lg:max-w-full lg:p-0">
					<div className="flex items-center justify-between gap-2 lg:block lg:gap-4">
						<div className="header__top bg-gradient-general relative hidden lg:block">
							<div className="container p-4 md:px-8 lg:flex lg:items-stretch lg:justify-between lg:py-0">
								<div className="flex items-center gap-3 border-r border-primary-medium py-3 pe-8">
									{activeLanguage && (
										<ul className="nav justify-content-start fuzion__language-select-nav relative">
											<li className="nav-item dropdown">
												<a
													className="nav-link dropdown-toggle flex items-center gap-2 bg-transparent text-xs uppercase tracking-[1.08px] text-secondary transition-opacity hover:opacity-75"
													href="#"
													aria-expanded="false"
													role="button"
													data-bs-toggle="dropdown"
													data-bs-auto-close="true"
												>
													{activeLanguage.label}
													<Icon name="chevron-down" />
												</a>
												{languages &&
													languages.length > 0 && (
														<ul className="dropdown-menu fuzion-dropdown absolute bottom-full z-20 !-ml-3 !mt-4 hidden w-[180px] rounded-md bg-primary px-5 py-3">
															{languages.map(
																(
																	languageNavItem,
																	index,
																) => {
																	return (
																		<li
																			key={
																				languageNavItem.code +
																				'-' +
																				index
																			}
																		>
																			<a
																				className="dropdown-item bg-transparent text-xs uppercase tracking-[1.08px] text-white transition-opacity hover:opacity-75"
																				href={
																					languageNavItem.href
																				}
																				target={
																					languageNavItem.target
																						? languageNavItem.target
																						: '_self'
																				}
																			>
																				{languageNavItem.icon && (
																					<i
																						className={
																							'icon ' +
																							languageNavItem.icon
																						}
																					></i>
																				)}
																				{
																					languageNavItem.label
																				}
																			</a>
																		</li>
																	);
																},
															)}
														</ul>
													)}
											</li>
										</ul>
									)}
								</div>
								<div className="flex grow items-center justify-between py-3 ps-8">
									{leftLinks?.length > 0 && (
										<div>
											{leftLinks.map((link, index) => (
												<Button
													key={index}
													link={link}
													className="block text-sm text-secondary hover:underline focus:underline"
												/>
											))}
										</div>
									)}
									{rightLinks?.length > 0 && (
										<div
											className={twMerge(
												'flex items-center justify-start gap-6',
												(!leftLinks ||
													leftLinks?.length === 0) &&
													'ml-auto',
											)}
										>
											{getCurrentRegion() !== 'US' &&
												rightLinks.map(
													(link, index) => (
														<span
															className="relative"
															key={index}
														>
															{hasCartItems &&
																iconMassage(
																	link.label,
																) ===
																	'shopping-cart' && (
																	<div className="absolute right-0 top-0 -mr-1 -mt-1 flex h-2 w-2 items-center justify-center rounded-full bg-secondary font-bold"></div>
																)}
															<Button
																key={index}
																link={link}
																icon={iconMassage(
																	link.label,
																)}
																className="transition-color relative flex items-center justify-center text-[0] leading-[1] text-white duration-300 ease-in-out hover:text-secondary focus:text-secondary [&_i]:flex [&_i]:items-center [&_i]:justify-center [&_i]:text-[20px]"
															></Button>
														</span>
													),
												)}
											{searchPiece(
												'transition-color relative flex items-center justify-center text-[0] leading-[1] text-white duration-300 ease-in-out hover:text-secondary focus:text-secondary [&_i]:flex [&_i]:items-center [&_i]:justify-center [&_i]:text-[20px]',
												'headerTopDesktop',
											)}
										</div>
									)}
								</div>
							</div>
						</div>
						<div
							className={`header__bottom relative ${headerTheme}`}
						>
							<div className="lg:container lg:flex lg:items-center lg:justify-between lg:gap-8 lg:px-8">
								{logo?.src && (
									<div>
										<a
											href={logoURL?.href || '/'}
											aria-label={
												i18n?.back || 'Back to homepage'
											}
											className="block max-w-[160px] md:max-w-full"
										>
											<Image
												{...logo}
												src={
													headerTheme === 'light'
														? search?.searchBar
																?.logo?.src &&
															search?.searchBar
																?.logo?.src
														: logo.src
												}
												width={
													headerTheme === 'light'
														? search?.searchBar
																?.logo?.width &&
															search?.searchBar
																?.logo?.width
														: logo.width
												}
												height={
													headerTheme === 'light'
														? search?.searchBar
																?.logo
																?.height &&
															search?.searchBar
																?.logo?.height
														: logo.height
												}
											/>
										</a>
									</div>
								)}
								<div className="hidden lg:block">
									{mainNav?.length > 0 && (
										<Nav
											navItems={mainNav}
											classNames={[
												'flex',
												'items-center',
												'gap-16',
												'font-bodyLight',
												'text-lg',
												'leading-none',
												`${headerTheme === 'light' ? 'text-white' : 'text-primary'}`,
											]}
										/>
									)}
								</div>
								<div className="hidden lg:block xl:shrink-0">
									{headerCTABtn()}
									{onProductPage && (
										<GetAQuote {...getAQuote} />
									)}
								</div>
							</div>
						</div>
						{rightLinks?.length > 0 && (
							<div className="m-0 ml-auto flex items-center justify-start gap-2 lg:hidden lg:gap-4">
								{getCurrentRegion() !== 'US' &&
									rightLinks.map((link, index) => (
										<span className="relative" key={index}>
											{hasCartItems &&
												iconMassage(link.label) ===
													'shopping-cart' && (
													<div className="absolute right-0 top-0 -mr-1 -mt-1 flex h-2 w-2 items-center justify-center rounded-full bg-primary font-bold md:mr-1 md:mt-1"></div>
												)}
											<Button
												key={index}
												link={link}
												icon={iconMassage(link.label)}
												className={`flex h-[24px] w-[24px] items-center justify-center text-[0] leading-[1] md:h-[44px] md:w-[44px] [&_i]:flex [&_i]:items-center [&_i]:justify-center [&_i]:text-[20px] md:[&_i]:text-[22px] ${headerTheme === 'light' ? 'text-white' : 'text-primary'}`}
											/>
										</span>
									))}
								{searchPiece(
									`flex h-[24px] w-[24px] items-center justify-center text-[0] leading-[1] md:h-[44px] md:w-[44px] [&_i]:flex [&_i]:items-center [&_i]:justify-center [&_i]:text-[20px] md:[&_i]:text-[22px] ${headerTheme === 'light' ? 'text-white' : 'text-primary'}`,
									'headerTopMobile',
								)}
							</div>
						)}
						<button
							type="button"
							data-bs-toggle="offcanvas"
							data-bs-target=".offcanvas"
							aria-label={
								i18n?.toggleMobileMenu || 'Toggle mobile menu'
							}
							aria-controls="main-header-canvas"
							className={`h-[24px] w-[24px] shrink-0 md:h-[44px] md:w-[44px] lg:hidden ${headerTheme === 'light' ? 'text-white' : 'text-primary'}`}
						>
							<i className="icon-menu block text-2xl" />
						</button>
					</div>
				</div>
				{/* Slide-out mobile canvas */}
				<div
					ref={offcanvasRef}
					id="main-header-canvas"
					className="offcanvas invisible fixed left-full top-0 z-[1] h-dvh w-full overflow-y-auto overflow-x-hidden bg-white transition-all duration-300 ease-in-out md:max-w-[480px] [&.show]:visible [&.show]:left-0 md:[&.show]:left-[calc(100%-480px)]"
					aria-hidden="true"
				>
					<div className="flex h-full flex-col">
						<div className="relative flex items-center justify-between gap-2 px-4 py-[10px]">
							{logo?.src && (
								<div>
									<a
										href={logoURL?.href || '/'}
										aria-label={
											i18n?.back || 'Back to homepage'
										}
										className="block max-w-[160px]"
									>
										<Image {...logo} />
									</a>
								</div>
							)}
							{rightLinks?.length > 0 && (
								<div className="m-0 ml-auto flex items-center justify-start gap-2">
									{getCurrentRegion() !== 'US' &&
										rightLinks.map((link, index) => (
											<span
												className="relative"
												key={index}
											>
												{hasCartItems &&
													iconMassage(link.label) ===
														'shopping-cart' && (
														<div className="absolute right-0 top-0 -mr-1 -mt-1 flex h-2 w-2 items-center justify-center rounded-full bg-primary font-bold md:mr-1 md:mt-1"></div>
													)}
												<Button
													key={index}
													link={link}
													icon={iconMassage(
														link.label,
													)}
													className="flex h-[24px] w-[24px] items-center justify-center text-[0] leading-[1] text-primary md:h-[44px] md:w-[44px] [&_i]:flex [&_i]:items-center [&_i]:justify-center [&_i]:text-[20px] md:[&_i]:text-[22px]"
												></Button>
											</span>
										))}
									{searchPiece(
										'flex h-[24px] w-[24px] items-center justify-center text-[0] leading-[1] text-primary md:h-[44px] md:w-[44px] [&_i]:flex [&_i]:items-center [&_i]:justify-center [&_i]:text-[20px] md:[&_i]:text-[22px]',
										'slideOutMobile',
									)}
								</div>
							)}
							<button
								type="button"
								data-bs-toggle="offcanvas"
								data-bs-target=".offcanvas"
								aria-label={
									i18n?.toggleMobileMenu ||
									'Toggle mobile menu'
								}
								aria-controls="main-header-canvas"
								className="h-[24px] w-[24px] shrink-0 text-[22px] md:h-[44px] md:w-[44px] md:text-[24px] lg:hidden"
							>
								<i className="icon-close block"></i>
							</button>
						</div>
						{mainNav?.length > 0 && (
							<div className="relative border-t border-tertiary px-4 pb-4 pt-8">
								<Nav
									navItems={mainNav}
									classNames={[
										'mobile-main-nav',
										'text-primary',
										'text-lg',
									]}
								/>
							</div>
						)}
						{headerCTABtn()}
						{onProductPage && <GetAQuote {...getAQuote} />}
						<div className="mt-auto flex items-center justify-start gap-4 border-b border-t border-tertiary px-4 py-3">
							{activeLanguage && (
								<ul className="nav justify-content-start fuzion__language-select-nav relative">
									<li className="nav-item dropdown">
										<a
											className="nav-link dropdown-toggle  flex items-center gap-2 bg-transparent bg-transparent text-xs text-xs uppercase text-primary transition-opacity hover:opacity-75"
											href="#"
											aria-expanded="false"
											role="button"
											data-bs-toggle="dropdown"
											data-bs-auto-close="true"
										>
											{activeLanguage.label}
											<Icon name="chevron-down" />
										</a>
										{languages && languages.length > 0 && (
											<ul className="dropdown-menu fuzion-dropdown absolute top-full z-[2] !-ml-3 !mb-4 hidden w-[180px] rounded-md bg-tertiary px-5 py-3">
												{languages.map(
													(
														languageNavItem,
														index,
													) => {
														return (
															<li
																key={
																	languageNavItem.code +
																	'-' +
																	index
																}
															>
																<a
																	className="dropdown-item bg-transparent text-xs uppercase tracking-[1.08px] text-primary transition-opacity hover:opacity-75"
																	href={
																		languageNavItem.href
																	}
																	target={
																		languageNavItem.target
																			? languageNavItem.target
																			: '_self'
																	}
																>
																	{languageNavItem.icon && (
																		<i
																			className={
																				'icon ' +
																				languageNavItem.icon
																			}
																		></i>
																	)}
																	{
																		languageNavItem.label
																	}
																</a>
															</li>
														);
													},
												)}
											</ul>
										)}
									</li>
								</ul>
							)}
							{leftLinks?.length > 0 && (
								<div>
									{leftLinks.map((link, index) => (
										<Button
											key={index}
											link={link}
											className="block text-xs text-primary"
										/>
									))}
								</div>
							)}
						</div>
						{socialIcons?.platforms?.length > 0 && (
							<SocialIcons
								{...socialIcons}
								classNames={['my-4 px-4']}
								ulClassNames={['!justify-start']}
								iconLinkClassNames={[
									'text-medium',
									'hover:text-primary',
									'focus:text-primary',
								]}
							/>
						)}
					</div>
				</div>
			</header>
		</>
	);
};

export default Header;
