import Accordion from './src/components/Accordion/index';
import AdvancedMedia from './src/components/AdvancedMedia/index';
import AlertBar from './src/components/AlertBar/index';
import AlternatingContent from './src/components/AlternatingContent/index';
import ARTool from './src/components/ARTool/index';
import Blockquote from './src/components/Blockquote/index';
import BlogBarsList from './src/components/BlogBarsList/index';
import BlogCard from './src/components/BlogCard/index';
import BlogInnerHeader from './src/components/BlogInner/BlogInnerHeader';
import BlogInner from './src/components/BlogInner/index';
import BlogsList from './src/components/BlogsList/index';
import Breadcrumb from './src/components/Breadcrumb/index';
import Bullets from './src/components/Bullets/index';
import ButtonList from './src/components/Button/ButtonList';
import Button from './src/components/Button/index';
import CallToAction from './src/components/CallToAction/index';
import CareAndSupport from './src/components/CareAndSupport/index';
import Colors from './src/components/Colors/index';
import ContactTabs from './src/components/ContactTabs/index';
import Office from './src/components/ContactTabs/Office';
import ContainerAccent from './src/components/Container/ContainerAccent';
import Container from './src/components/Container/index';
import ContentMedia from './src/components/ContentMedia/index';
import ContentSection from './src/components/ContentSection/index';
import ContentWithNumbers from './src/components/ContentWithNumbers/index';
import Dealer from './src/components/Dealer/index';
import AddressString from './src/components/DealerLocator/AddressString';
import DealerCard from './src/components/DealerLocator/DealerCard';
import DealerLocator from './src/components/DealerLocator/index';
import Popup from './src/components/DealerLocator/Popup';
import DealerLocatorBar from './src/components/DealerLocatorBar/index';
import DealerLocatorPageBanner from './src/components/DealerLocatorPageBanner/index';
import CategoryListings from './src/components/FeaturedCategories/CategoryListings';
import FeaturedCategories from './src/components/FeaturedCategories/index';
import FilterAccordion from './src/components/FilterAccordion/index';
import FloatingCTABar from './src/components/FloatingCTABar/index';
import FloorAndFeatures from './src/components/FloorAndFeatures/index';
import FloorLengthWidth from './src/components/FloorLengthWidth/index';
import FloorProsCons from './src/components/FloorProsCons/index';
import FooterBottom from './src/components/Footer/FooterBottom';
import FooterNavColumn from './src/components/Footer/FooterNavColumn';
import FooterTop from './src/components/Footer/FooterTop';
import Footer from './src/components/Footer/index';
import Form from './src/components/Form/index';
import FullWidthGallery from './src/components/FullWidthGallery/index';
import FuzionForProfessionals from './src/components/FuzionForProfessionals/index';
import GetAQuote from './src/components/GetAQuote/index';
import Header from './src/components/Header/index';
import HomeHeroBanner from './src/components/HomeHeroBanner/index';
import Icon from './src/components/Icon/index';
import IconSamples from './src/components/IconSamples/index';
import IconText from './src/components/IconTextGrid/IconText';
import IconTextGrid from './src/components/IconTextGrid/index';
import ImageToSVG from './src/components/ImageToSVG/index';
import InspirationCTA from './src/components/InspirationCTA/index';
import InstagramFeed from './src/components/InstagramFeed/index';
import LazyJSONLD from './src/components/LazyJSONLD/index';
import LineAccent from './src/components/LineAccent/index';
import LogoBar from './src/components/LogoBar/index';
import Map from './src/components/Map/index';
import MegaMenu from './src/components/MegaMenu/index';
import MultiImageCTA from './src/components/MultiImageCTA/index';
import NewImage from './src/components/NewImage/NewImage';
import Newsletter from './src/components/Newsletter/index';
import NextSteps from './src/components/NextSteps/index';
import PageBanner from './src/components/PageBanner/index';
import PersonalizedSupport from './src/components/PersonalizedSupport/index';
import PriceRangeIndicator from './src/components/PriceRangeIndicator/index';
import CustomCarouselWithImagePreviews from './src/components/ProductCard/CustomCarouselWithImagePreviews';
import ProductCard from './src/components/ProductCard/index';
import ProductCards from './src/components/ProductCards/index';
import ProductComparisonTable from './src/components/ProductComparisonTable/index';
import ProductDetailsTabs from './src/components/ProductDetailsTabs/index';
import ProductTab from './src/components/ProductDetailsTabs/ProductTab/index';
import ProductTabs from './src/components/ProductDetailsTabs/ProductTabs/index';
import AccessoriesTab from './src/components/ProductDetailsTabs/tabs/AccessoriesTab';
import CommercialTab from './src/components/ProductDetailsTabs/tabs/CommercialTab';
import DownloadsTab from './src/components/ProductDetailsTabs/tabs/DownloadsTab';
import FeaturesTab from './src/components/ProductDetailsTabs/tabs/FeaturesTab';
import BlogRollFilterBar from './src/components/ProductIndex/BlogRollFilterBar';
import BlogRollPagination from './src/components/ProductIndex/BlogRollPagination';
import ProductIndex from './src/components/ProductIndex/index';
import ProductIndexAdBanner from './src/components/ProductIndexAdBanner/index';
import ProInsiderModal from './src/components/ProInsiderModal/index';
import ProjectShowcase from './src/components/ProjectShowcase/index';
import RelatedProducts from './src/components/RelatedProducts/index';
import ResourcesList from './src/components/ResourcesList/index';
import HeaderSearchResults from './src/components/Search/HeaderSearchResults';
import Search from './src/components/Search/index';
import IndexSearchHeading from './src/components/Search/IndexSearchHeading';
import IndexSearchProducts from './src/components/Search/IndexSearchProducts';
import SearchBar from './src/components/Search/SearchBar';
import SearchPage from './src/components/Search/SearchPage';
import ShowcaseYourSpace from './src/components/ShowcaseYourSpace/index';
import SimpleImageBar from './src/components/SimpleImageBar/index';
import SocialIcons from './src/components/SocialIcons/index';
import Spacer from './src/components/Spacer/index';
import Stat from './src/components/Stat/index';
import Tabs from './src/components/Tabs/index';
import TestimonialCarousel from './src/components/TestimonialCarousel/index';
import TextReveal from './src/components/TextReveal/index';
import TriangleImage from './src/components/TriangleImage/index';
import GetAQuoteModal from './src/components/TriangleImage/Masks/GetAQuoteModal';
import Hero from './src/components/TriangleImage/Masks/Hero';
import LocatorArea from './src/components/TriangleImage/Masks/LocatorArea';
import UpsideDownHero from './src/components/TriangleImage/Masks/UpsideDownHero';
import WhyFuzion from './src/components/TriangleImage/Masks/WhyFuzion';
import UpcomingEvents from './src/components/UpcomingEvents/index';
import Video from './node_modules/tbk-components/src/components/Video/index.js';
import Carousel from './node_modules/tbk-components/src/components/Carousel/index.js';
import Nav from './node_modules/tbk-components/src/components/Nav/index.js';
import NavItem from './node_modules/tbk-components/src/components/Nav/NavItem.js';
import RawHTML from './node_modules/tbk-components/src/components/RawHTML/index.js';

const components = {
	Accordion,
	AdvancedMedia,
	AlertBar,
	AlternatingContent,
	ARTool,
	Blockquote,
	BlogBarsList,
	BlogCard,
	BlogInnerHeader,
	BlogInner,
	BlogsList,
	Breadcrumb,
	Bullets,
	ButtonList,
	Button,
	CallToAction,
	CareAndSupport,
	Colors,
	ContactTabs,
	Office,
	ContainerAccent,
	Container,
	ContentMedia,
	ContentSection,
	ContentWithNumbers,
	Dealer,
	AddressString,
	DealerCard,
	DealerLocator,
	Popup,
	DealerLocatorBar,
	DealerLocatorPageBanner,
	CategoryListings,
	FeaturedCategories,
	FilterAccordion,
	FloatingCTABar,
	FloorAndFeatures,
	FloorLengthWidth,
	FloorProsCons,
	FooterBottom,
	FooterNavColumn,
	FooterTop,
	Footer,
	Form,
	FullWidthGallery,
	FuzionForProfessionals,
	GetAQuote,
	Header,
	HomeHeroBanner,
	Icon,
	IconSamples,
	IconText,
	IconTextGrid,
	ImageToSVG,
	InspirationCTA,
	InstagramFeed,
	LazyJSONLD,
	LineAccent,
	LogoBar,
	Map,
	MegaMenu,
	MultiImageCTA,
	NewImage,
	Newsletter,
	NextSteps,
	PageBanner,
	PersonalizedSupport,
	PriceRangeIndicator,
	CustomCarouselWithImagePreviews,
	ProductCard,
	ProductCards,
	ProductComparisonTable,
	ProductDetailsTabs,
	ProductTab,
	ProductTabs,
	AccessoriesTab,
	CommercialTab,
	DownloadsTab,
	FeaturesTab,
	BlogRollFilterBar,
	BlogRollPagination,
	ProductIndex,
	ProductIndexAdBanner,
	ProInsiderModal,
	ProjectShowcase,
	RelatedProducts,
	ResourcesList,
	HeaderSearchResults,
	Search,
	IndexSearchHeading,
	IndexSearchProducts,
	SearchBar,
	SearchPage,
	ShowcaseYourSpace,
	SimpleImageBar,
	SocialIcons,
	Spacer,
	Stat,
	Tabs,
	TestimonialCarousel,
	TextReveal,
	TriangleImage,
	GetAQuoteModal,
	Hero,
	LocatorArea,
	UpsideDownHero,
	WhyFuzion,
	UpcomingEvents,
	Video,
	Carousel,
	Nav,
	NavItem,
	RawHTML
};

export default components;
