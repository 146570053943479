import React from 'react';
import cn from 'classnames';
import ContainerAccent from './ContainerAccent';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';

export interface IContainerProps extends IBaseElementProps {
	size?: 'default' | 'container-xs' | 'container-sm' | 'container-full';
	textAlign?: 'start' | 'center' | 'end';
	bgColor?:
		| 'bg-transparent'
		| 'bg-white'
		| 'bg-gradient-general-radial'
		| 'bg-gradient-rainbow'
		| 'bg-light';
	children?: any;
	enableAccent?: boolean;
}
const computedAccentStrokeColor = (bgColor: string) => {
	switch (bgColor) {
		case 'bg-gradient-rainbow':
			return 'white';
		case 'bg-gradient-general-radial':
			return 'primary-medium';
		default:
			return 'secondary';
	}
};
/**
 * Container
 * @block
 */
const Container: React.FC<IContainerProps> = ({
	id,
	className,
	classNames = [],
	size = 'default',
	textAlign,
	children,
	bgColor = 'bg-transparent',
	enableAccent = false,
}) => (
	<div
		id={id}
		className={
			className ||
			cn(
				size === 'container-full' ? 'container-fluid' : 'container',
				'relative overflow-hidden px-4 md:px-8',
				size,
				textAlign && `text-${textAlign}`,
				bgColor,
				...classNames,
			)
		}
	>
		{enableAccent && (
			<ContainerAccent
				strokeColor={computedAccentStrokeColor(bgColor)}
				className={'absolute bottom-0 left-0 z-0'}
			/>
		)}
		{children}
	</div>
);

export default Container;
