import React from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import cn from 'classnames';
import { ISpec } from '../../ProductCard';
import PriceRangeIndicator from '../../PriceRangeIndicator';
import Bullets, { IBullet } from '../../Bullets';
export interface IDownloadsTabProps extends IBaseElementProps {
	tabLabel?: string;
	downloads?: IBullet[];
}

const DownloadsTab: React.FC<IDownloadsTabProps> = ({
	id,
	className,
	classNames = [],
	downloads = [],
}) => {
	return (
		<div
			id={id}
			className={className || cn('downloads-tab', ...classNames)}
		>
			{downloads?.length > 0 && (
				<Bullets
					iconColor="primary"
					className="bullets flex flex-col gap-2"
					iconClassNames={['text-[32px]']}
					bulletsGapSize="32px"
					bulletTextClassNames={[
						'[&_a]:text-primary top-1 relative [&_a]:no-underline [&_a]:text-md [&_a]:transition-opacity [&_a]:hover:opacity-50 mb-0',
					]}
					bullets={downloads}
				/>
			)}
		</div>
	);
};

export default DownloadsTab;
