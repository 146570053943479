import React, {
	useState,
	useMemo,
	useRef,
	useEffect,
	useCallback,
	useContext,
} from 'react';
import withAppContext from 'tbk-components/src/hooks/withAppContext';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { twMerge } from 'tailwind-merge';
import DealerCard from './DealerCard';
import { IDealerProps } from '../Dealer';
import Map, { IMapProps } from '../Map';
import LineAccent from '../LineAccent';
import { debounce } from 'lodash';
import Popup from './Popup';
import { ILink } from '../Button';

interface IDealerLocatorI18nProps {
	direction?: string;
	details?: string;
	close?: string;
	postalCodePlaceholder?: string;
	searchRadius?: string;
	numOfResults?: string;
	submitButton?: string;
	map?: string;
	list?: string;
	enterText?: string;
	noDealer?: string;
}

export interface IDealerLocatorProps extends IBaseElementProps {
	sectionTitle?: string;
	/**
	 * @richtext
	 */
	introText?: string;
	dealerDetailsURL?: ILink;
	i18n?: IDealerLocatorI18nProps;
	/**
	 * @noUI
	 */
	context?: any;
}

export const defaultMapOptions: IMapProps = {
	// Canada coordinates
	center: { lat: 60.0, lng: -100.0 },
	zoom: 4,
};
const labelClassNames = [
	'absolute left-4 top-1/2 flex -translate-y-1/2 items-center gap-2 font-bodyBold text-base transition-all duration-300 ease-in-out',
	'[&.input-focused]:top-3 lg:[&.input-focused]:top-4 [&.input-focused]:font-body [&.input-focused]:text-sm',
	'[&.input-filled]:top-3 lg:[&.input-filled]:top-4 [&.input-filled]:font-body [&.input-filled]:text-sm',
	'[&.input-contains-placeholder]:top-3 lg:[&.input-contains-placeholder]:top-4 [&.input-contains-placeholder]:font-body [&.input-contains-placeholder]:text-sm',
];
// We're adding the chevron down icon as base64 encoded SVG to the select element.
const selectClassNames = [
	'h-[44px] w-full appearance-none border-b border-primary bg-light px-4 pb-1 pt-6 !leading-[1.1] md:text-base lg:h-auto lg:pb-2 lg:pt-7 lg:text-lg',
	'bg-[12px] bg-[right_1rem_center] bg-no-repeat',
	'rounded-none',
	"[background-image:url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJ1dWlkLWQ5ZWJkZGI2LTViOTUtNDE1ZC05YjIwLWM2YTljZDkxNTE2OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiI+PHJlY3Qgd2lkdGg9IjEyIiBoZWlnaHQ9IjEyIiBzdHlsZT0iZmlsbDpub25lOyBzdHJva2Utd2lkdGg6MHB4OyIvPjxwYXRoIGQ9Ik02LDguMzhjLS4xMywwLS4yNi0uMDUtLjM1LS4xNWwtMy43NS0zLjc1Yy0uMi0uMi0uMi0uNTEsMC0uNzFzLjUxLS4yLjcxLDBsMy40LDMuNCwzLjQtMy40Yy4yLS4yLjUxLS4yLjcxLDBzLjIuNTEsMCwuNzFsLTMuNzUsMy43NWMtLjEuMS0uMjMuMTUtLjM1LjE1WiIgc3R5bGU9ImZpbGw6IzMzMzU0Mzsgc3Ryb2tlLXdpZHRoOjBweDsiLz48L3N2Zz4=');]",
];
/**
 * Utility functions
 */
const generateMarker = (index) => {
	const markerIcon = document.createElement('div');
	markerIcon.className =
		'flex items-center justify-center w-9 h-9 bg-primary rounded-full text-white font-body text-lg';
	markerIcon.innerHTML = index + 1;

	return markerIcon;
};
const assignInputClasses = (target) => {
	const { value, placeholder } = target;
	const el = target.previousSibling as HTMLLabelElement;

	if (value) {
		el?.classList.add('input-filled');
	} else if (placeholder) {
		el?.classList.add('input-contains-placeholder');
	} else {
		el?.classList.remove('input-filled');
		el?.classList.remove('input-contains-placeholder');
	}
};
const handleFocusBlur = (event) => {
	const target = event.target.previousSibling as HTMLLabelElement;

	if (event.type === 'focus') {
		target?.classList.add('input-focused');
	} else {
		target?.classList.remove('input-focused');
	}
};
const handleChange = (event) => {
	const target = event.target as HTMLInputElement;
	assignInputClasses(target);
};
/**
 * Utility functions
 */

/**
 * Dealer Locator
 * @block
 * @icon location
 */
const DealerLocator: React.FC<IDealerLocatorProps> = withAppContext(
	({
		id,
		className,
		classNames = [],
		sectionTitle,
		introText,
		dealerDetailsURL,
		i18n,
		context,
	}) => {
		const params = new URLSearchParams(document.location.search);

		// @ts-ignore
		const { assets: contextAssets, Api } = useContext(context);

		const [isLoading, setIsLoading] = useState(false);
		const [isLoaded, setIsLoaded] = useState(false);
		const [dealers, setDealers] = useState<IDealerProps[]>([]);
		const [map, setMap] = useState<google.maps.Map | null>(null);
		const [states, setStates] = useState({
			postal_code: params.has('postal_code')
				? decodeURIComponent(params.get('postal_code'))
				: '',
			radius: '10',
			num_results: '10',
		});

		const ref = useRef<HTMLDivElement>(null);
		const formRef = useRef<HTMLFormElement>(null);
		const buttonRef = useRef<HTMLDivElement>(null);
		const listRef = useRef<HTMLDivElement>(null);
		const mapRef = useRef<HTMLDivElement>(null);

		const lang = document.documentElement.lang.toLowerCase();
		const isCanada = lang.includes('-ca');
		const distanceUnit = isCanada ? 'km' : 'miles';

		const [popupPosition, setPopupPosition] =
			useState<google.maps.LatLng | null>(null);
		const [popupContent, setPopupContent] = useState<string>('');

		const combinedMapOptions = useMemo(() => {
			return { ...defaultMapOptions };
		}, [defaultMapOptions]);

		const markers = useMemo(() => {
			const _markers =
				dealers?.length > 0
					? dealers.map(({ position: { lat, lng } }, index) => {
							return {
								position: {
									lat: Number(lat),
									lng: Number(lng),
								},
								content: generateMarker(index),
								onClick: () => showInfoWindow(index),
							};
						})
					: [];
			return _markers;
		}, [dealers]);

		useEffect(() => {
			if (!formRef.current) return;

			const inputs = formRef.current.querySelectorAll<
				HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
			>('input:not([type=checkbox]):not([type=radio]), textarea, select');

			if (inputs.length === 0) return;

			inputs.forEach((input) => {
				assignInputClasses(input);

				input.addEventListener('focus', handleFocusBlur);
				input.addEventListener('blur', handleFocusBlur);
				input.addEventListener('input', handleChange);
			});

			return () => {
				inputs.forEach((input) => {
					input.removeEventListener('focus', handleFocusBlur);
					input.removeEventListener('blur', handleFocusBlur);
					input.removeEventListener('input', handleChange);
				});
			};
		}, [formRef]);

		const selectButton = useCallback((event) => {
			const target = event.currentTarget;
			const buttons = buttonRef.current.querySelectorAll('button');

			buttons.forEach((button) => {
				button.classList.remove('selected');
				button.setAttribute('aria-selected', 'false');
			});

			target.classList.add('selected');
			target.setAttribute('aria-selected', 'true');

			buttonRef.current.classList.toggle('before:left-1/2');
			mapRef.current.classList.toggle('hidden');
			mapRef.current.setAttribute(
				'aria-hidden',
				mapRef.current.getAttribute('aria-hidden') === 'true'
					? 'false'
					: 'true',
			);
			listRef.current.classList.toggle('hidden');
			listRef.current.setAttribute(
				'aria-hidden',
				mapRef.current.getAttribute('aria-hidden') === 'true'
					? 'false'
					: 'true',
			);
		}, []);

		const changeAriaHidden = useCallback((element) => {
			return window.getComputedStyle(element).display === 'none'
				? 'true'
				: 'false';
		}, []);

		const updateAria = useCallback(() => {
			mapRef.current.setAttribute(
				'aria-hidden',
				changeAriaHidden(mapRef.current),
			);
			listRef.current.setAttribute(
				'aria-hidden',
				changeAriaHidden(listRef.current),
			);
		}, [changeAriaHidden]);

		useEffect(() => {
			if (!buttonRef.current || !mapRef.current || !listRef.current)
				return;

			const buttons = buttonRef.current.querySelectorAll('button');
			const debouncedUpdateAria = debounce(updateAria, 300);

			buttons.forEach((button) => {
				button.addEventListener('click', selectButton);
			});

			updateAria();
			window.addEventListener('resize', debouncedUpdateAria);

			return () => {
				buttons.forEach((button) => {
					button.removeEventListener('click', selectButton);
				});
				window.removeEventListener('resize', debouncedUpdateAria);
				debouncedUpdateAria.cancel();
			};
		}, [buttonRef, mapRef, listRef, selectButton, updateAria]);

		const showInfoWindow = (locationIndex: number) => {
			const { lat, lng } = dealers[locationIndex].position;
			const content = listRef.current.querySelector(
				`#dealer-card__container-${locationIndex}`,
			).innerHTML;

			setPopupPosition(new google.maps.LatLng(lat, lng));
			setPopupContent(content);
			map.panTo(new google.maps.LatLng(lat, lng));
		};

		const handleSubmit = async () => {
			if (!window.google.maps.Geocoder) return;

			setIsLoading(true);
			setIsLoaded(false);

			const service = await new window.google.maps.Geocoder();

			const suffix = isCanada ? ' Canada' : '';
			service.geocode(
				{ address: states.postal_code + suffix },
				(results, status) => {
					if (status === 'OK') {
						const { lat, lng } = results[0].geometry.location;
						// convert miles to km
						const radius = isCanada
							? Number(states.radius) * 1000
							: (Number(states.radius) / 0.621371) * 1000;
						const args = {
							lat: lat(),
							lng: lng(),
							radius: radius,
							num_results: Number(states.num_results),
						};

						if (Api.getDealerResults) {
							Api.getDealerResults(args)
								.then((response) => {
									if (response && Array.isArray(response)) {
										const resData = response.map(
											(item: any) => {
												return {
													...item,
													name: item.name.toLowerCase(),
													position: {
														lat: Number(item.lat),
														lng: Number(item.long),
													},
													distance:
														(isCanada
															? Math.round(
																	item.distance /
																		1000,
																)
															: Math.round(
																	item.distance /
																		0.621371 /
																		1000,
																)) +
														` ${distanceUnit}`,
													streetAddress:
														item.street_address.toLowerCase(),
													city: item.city.toLowerCase(),
													province: item.state,
													postalCode: item.postal,
													phone: item.phone_number,
												};
											},
										);
										setDealers(resData);
									}
									setIsLoading(false);
									setIsLoaded(true);
								})
								.finally(() => {
									const newCenter = new google.maps.LatLng(
										lat(),
										lng(),
									);
									if (map) {
										map.panTo(newCenter);
										map.setZoom(12);
									}
								});
						}
					}
				},
			);
		};

		return (
			<div
				id={id}
				className={
					className ||
					twMerge(
						'store-locator__container relative bg-white lg:bg-light',
						...classNames,
					)
				}
				ref={ref}
			>
				<div className="store-locator__header px-4 md:px-8">
					<div className="container relative z-10 pt-8 lg:pt-30">
						<div className="flex flex-col gap-2 lg:max-w-[calc(8/12*100%)] lg:flex-row lg:gap-8">
							{sectionTitle && (
								<h2 className="mb-0 text-[26px] lg:text-4xl">
									{sectionTitle}
								</h2>
							)}
							{introText && (
								<p
									dangerouslySetInnerHTML={{
										__html: introText,
									}}
									className="m-0 lg:text-base"
								/>
							)}
						</div>
						<div className="mt-6">
							<form
								onSubmit={(e) => {
									e.preventDefault();
								}}
								className="flex flex-wrap items-center justify-between gap-4 lg:flex-nowrap lg:gap-8"
								ref={formRef}
							>
								<div className="form-row relative w-full shrink-0 grow-0 lg:w-[309px] xl:w-[296px] 2xl:w-[336px]">
									<label
										htmlFor="store-locator-postal-code"
										className={twMerge(
											...labelClassNames,
											'[&.input-focused]:!left-3',
											'[&.input-filled]:!left-3',
											'[&.input-contains-placeholder]:!left-3',
											'group',
										)}
									>
										<i className="icon-map-pin text-xl group-[.input-contains-placeholder]:text-base group-[.input-filled]:text-base group-[.input-focused]:text-base" />
										{i18n?.postalCodePlaceholder ||
											'Search by City or Postal Code'}
									</label>
									<input
										type="text"
										id="store-locator-postal-code"
										className="h-[44px] w-full appearance-none rounded-none border-b border-primary bg-light px-4 pb-1 pt-6 !leading-[1.1] md:text-base lg:h-auto lg:pb-2 lg:pt-7 lg:text-lg"
										placeholder={
											isCanada ? 'A1A 1A1' : '10001'
										}
										value={states.postal_code}
										onChange={(e) => {
											setStates({
												...states,
												postal_code: e.target.value,
											});
										}}
									/>
								</div>
								<div className="form-row relative w-[calc(1/2*100%-0.5rem)]">
									<label
										htmlFor="store-locator-search-radius"
										className={twMerge(...labelClassNames)}
									>
										{i18n?.searchRadius || 'Search Radius'}
									</label>
									<select
										id="store-locator-search-radius"
										className={selectClassNames.join(' ')}
										onChange={(e) => {
											setStates({
												...states,
												radius: e.target.value,
											});
										}}
									>
										<option value="10">
											10 {distanceUnit}
										</option>
										<option value="25">
											25 {distanceUnit}
										</option>
										<option value="50">
											50 {distanceUnit}
										</option>
										<option value="100">
											100 {distanceUnit}
										</option>
										<option value="200">
											200 {distanceUnit}
										</option>
										<option value="500">
											500 {distanceUnit}
										</option>
									</select>
								</div>
								<div className="form-row relative w-[calc(1/2*100%-0.5rem)]">
									<label
										htmlFor="store-locator-search-results"
										className={twMerge(...labelClassNames)}
									>
										{i18n?.numOfResults || 'Results'}
									</label>
									<select
										id="store-locator-search-results"
										className={selectClassNames.join(' ')}
										onChange={(e) => {
											setStates({
												...states,
												num_results: e.target.value,
											});
										}}
									>
										<option value="10">10</option>
										<option value="50">50</option>
										<option value="75">75</option>
										<option value="100">100</option>
									</select>
								</div>
								<div className="flex w-full items-center justify-center gap-2 lg:justify-start">
									<input
										type="submit"
										value={i18n?.submitButton || 'Search'}
										className="btn btn-primary block lg:ml-0"
										onClick={() => {
											handleSubmit();
										}}
									/>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div className="store-locator__body mt-6 overflow-hidden bg-light px-4 md:px-8">
					<div className="container relative z-10 pb-8 pt-6 lg:flex lg:h-[600px] lg:gap-8 lg:pb-30 lg:pt-0">
						<div
							className={twMerge(
								'relative mb-4 flex items-center justify-center rounded-full bg-white lg:hidden',
								"before:absolute before:left-0 before:top-0 before:z-0 before:h-8 before:w-1/2 before:rounded-full before:bg-tertiary before:transition-all before:duration-300 before:ease-in-out before:content-['']",
							)}
							ref={buttonRef}
						>
							<button
								className="selected relative z-10 flex h-8 w-1/2 items-center justify-center gap-2 rounded-full text-sm text-primary"
								aria-selected="true"
							>
								<i className="icon-map-tri-fold text-[22px]" />
								{i18n?.map || 'Map'}
							</button>
							<button
								className="relative z-10 flex h-8 w-1/2 items-center justify-center gap-2 rounded-full text-sm text-primary"
								aria-selected="false"
							>
								<i className="icon-rows text-[22px]" />
								{i18n?.list || 'List'}
							</button>
						</div>
						<div
							className={twMerge(
								'dealer-list relative hidden max-h-full lg:block lg:w-[calc(4/12*100%)] xl:w-[calc(3/12*100%)]',
								'after:absolute after:bottom-0 after:left-0 after:block after:hidden after:h-[42px] after:w-full after:content-[""] after:[background:linear-gradient(180deg,rgba(249,247,243,0)_0,#F9F7F3_100%)]',
								dealers?.length > 2 && ' lg:after:block',
							)}
							ref={listRef}
							aria-hidden="true"
						>
							<ul className="grid max-h-full gap-2 overflow-auto lg:pb-[42px]">
								{!isLoading && isLoaded ? (
									<>
										{dealers?.length === 0 && (
											<li>
												{i18n?.noDealer ||
													'No dealer found near you.'}
											</li>
										)}
										{dealers?.length > 0 &&
											dealers.map((dealer, index) => (
												<li key={index}>
													<DealerCard
														key={index}
														{...dealer}
														dealerCptUrl={
															dealerDetailsURL
														}
														searchIndex={index}
														i18n={i18n}
													/>
												</li>
											))}
									</>
								) : (
									<>
										{isLoading ? (
											<span className="spinner-border mx-auto block h-8 w-8 !border-[3px]" />
										) : (
											<li>
												{i18n?.enterText ||
													'Enter to search for dealers near you.'}
											</li>
										)}
									</>
								)}
							</ul>
						</div>
						<div
							className="dealer-map relative h-[600px] max-h-full overflow-hidden rounded-md lg:block lg:w-[calc(8/12*100%)] xl:w-[calc(9/12*100%)]"
							ref={mapRef}
							aria-hidden="false"
						>
							<Map
								{...combinedMapOptions}
								// @ts-ignore
								markers={markers}
								onMapLoaded={(...args) => {
									setMap(...args);

									if (states.postal_code) {
										ref?.current?.scrollIntoView();
										handleSubmit();
									}
								}}
							/>

							{map && (
								<Popup
									position={popupPosition}
									map={map}
									content={popupContent}
								/>
							)}
						</div>
					</div>
				</div>

				<LineAccent
					horizontalFlipped={false}
					verticalFlipped={true}
					color={'tertiary'}
					classNames={[
						twMerge(
							'absolute md:block hidden w-full max-w-[566px] h-auto z-0 top-0 right-0',
						),
					]}
				/>
			</div>
		);
	},
);

export default DealerLocator;
