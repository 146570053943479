import React from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { IImage } from 'tbk-components/src/components/Image';
import { colorPalette } from '../../../../tailwind-colors';
import cn from 'classnames';
import uniqueIdHash from '../../../hooks/generateUniqueIdHash';
export interface IUpsideDownHeroTriangleMaskProps extends IBaseElementProps {
	image?: IImage;
}
const UpsideDownHeroTriangleMask: React.FC<
	IUpsideDownHeroTriangleMaskProps
> = ({
	image,
	id = 'triangle-canvas-upside-down-hero',
	className,
	classNames = [],
}) => {
	const idHash = uniqueIdHash();
	const imageCanvasMaskId = `image-canvas-mask-${idHash}`,
		maskId = `mask-${idHash}`;

	return (
		<svg
			id={id}
			xmlns="http://www.w3.org/2000/svg"
			version="1.1"
			viewBox="0 0 600 362"
			className={className || cn(...classNames)}
		>
			<defs>
				<mask
					id={maskId}
					x="-183.1"
					y="-5.7"
					width="806.8"
					height="367.2"
					maskUnits="userSpaceOnUse"
				>
					<g data-name="mask0 3018 14737">
						<path
							className="cls-4"
							fill={colorPalette.white.DEFAULT}
							stroke={colorPalette.white.DEFAULT}
							strokeWidth="1px"
							d="M.2,360.5V1.7h599.3v358.8H.2Z"
						/>
					</g>
				</mask>
			</defs>
			<mask id={imageCanvasMaskId}>
				<polygon
					className="cls-1"
					fill="#eee"
					strokeWidth="0px"
					points="0 209.4 0 0 512 .1 151.4 361 0 209.4"
				/>
			</mask>
			<image
				xlinkHref={image?.src}
				mask={`url(#${imageCanvasMaskId})`}
				width="86%"
				height="100%"
				y="0"
				x="0"
				preserveAspectRatio="xMidYMid slice"
			/>
			<g className="cls-2" mask={`url(#${maskId})`}>
				<g className="clipping-masks">
					<path
						className="cls-3"
						fill="none"
						stroke={colorPalette.light.DEFAULT}
						strokeMiterlimit="10"
						d="M-104.4,105.2l255.8,255.8"
					/>
					<path
						className="cls-3"
						fill="none"
						stroke={colorPalette.light.DEFAULT}
						strokeMiterlimit="10"
						d="M512,.1L151.4,361"
					/>
					<path
						className="cls-3"
						fill="none"
						stroke={colorPalette.light.DEFAULT}
						strokeMiterlimit="10"
						d="M-4.4-5.5l155.8,366.5"
					/>
					<path
						className="cls-3"
						fill="none"
						stroke={colorPalette.light.DEFAULT}
						strokeMiterlimit="10"
						d="M623.5,165.3l-472.1,195.7"
					/>
					<path
						className="cls-3"
						fill="none"
						stroke={colorPalette.light.DEFAULT}
						strokeMiterlimit="10"
						d="M151.4,361L-182.9,222.6"
					/>
				</g>
			</g>
		</svg>
	);
};

export default UpsideDownHeroTriangleMask;
