import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Bullets, { IBullet } from '../Bullets';
import Image, { IImage } from 'tbk-components/src/components/Image';
import ContentSection from '../ContentSection';

export interface IFuzionForProfessionalsProps extends IBaseElementProps {
	title?: string;
	/**
	 * Excerpt
	 * @control richtext
	 */
	excerpt?: string;
	bullets?: IBullet[];
	image?: IImage;
	tile1Title?: string;
	tile1Description?: string;
	tile2Image?: IImage;
	tile2Description?: string;
}

/**
 * Fuzion For Professionals
 * @icon grid-view
 * @block
 */
const FuzionForProfessionals: React.FC<IFuzionForProfessionalsProps> = ({
	title,
	excerpt,
	bullets = [],
	image,
	tile1Title,
	tile1Description,
	tile2Image,
	tile2Description,
	className,
	classNames = [],
	id,
}) => (
	<div
		id={id}
		className={
			className ||
			cn(
				'fuzion-for-professionals relative flex flex-col overflow-hidden lg:flex-row',
				...classNames,
			)
		}
	>
		<div className="bg-gradient-general-radial block flex justify-center px-6 py-8 text-white md:basis-1/2 lg:py-14 lg:py-30">
			<ContentSection
				title={title}
				titleClassName={'h2-large mb-0'}
				excerpt={excerpt}
				classNames={['lg:max-w-[443px] mx-auto block']}
			>
				{bullets?.length > 0 && (
					<Bullets
						iconColor="secondary"
						bullets={bullets}
						classNames={['lg:mt-11']}
						bulletTextClassNames={['mb-0']}
					/>
				)}
			</ContentSection>
		</div>
		<div className="relative flex min-h-[500px] items-stretch justify-center px-6 md:basis-1/2 ">
			<div className="absolute left-1/2 top-0 z-[1] flex h-full w-full flex-shrink-0 -translate-x-1/2 content-stretch items-stretch justify-center xl:left-[55%] xl:-translate-x-[45%] ">
				{(tile1Title || tile1Description) && (
					<div className="flex h-1/2 w-full max-w-[220px] items-center bg-medium p-6">
						<p className="mb-0 text-center text-white">
							{tile1Title && (
								<span className="h2-large block leading-none">
									{tile1Title}
								</span>
							)}
							{tile1Description && (
								<span className="mt-2 block">
									{tile1Description}
								</span>
							)}
						</p>
					</div>
				)}
				{(tile2Image || tile2Description) && (
					<div className="mb-0 mt-auto flex h-1/2 w-full max-w-[220px] flex-col items-center justify-center bg-primary p-6">
						{tile2Image && (
							<Image
								{...tile2Image}
								className="mx-auto h-auto w-[72px]"
							/>
						)}
						{tile2Description && (
							<p className="mb-0 mt-2 block text-center text-white">
								{tile2Description}
							</p>
						)}
					</div>
				)}
			</div>
			{image && (
				<Image
					{...image}
					className="absolute right-0 top-0 z-0 block h-full w-full object-cover"
				/>
			)}
		</div>
	</div>
);

export default FuzionForProfessionals;
