import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Carousel from 'tbk-components/src/components/Carousel';
import CarouselItem from 'tbk-components/src/components/CarouselItem';
import Blockquote, { IBlockQuoteProps } from '../Blockquote';
import uniqueIdHash from '../../hooks/generateUniqueIdHash';

export interface ITestimonialCarouselProps extends IBaseElementProps {
	showArrows?: boolean;
	showBullets?: boolean;
	/**
	 *  @noUI
	 */
	options?: { [x: string]: any };
	testimonials?: IBlockQuoteProps[];
	/**
	 * @control uniqueid
	 * @prefix testimonial-carousel
	 */
	id?: string;
}

/**
 * TestimonialCarousel
 * @block
 * @icon slides
 */
const TestimonialCarousel: React.FC<ITestimonialCarouselProps> = ({
	id,
	className,
	classNames = [],
	showArrows = true,
	showBullets = true,
	options,
	testimonials = [],
}) => {
	const genId = id || `testimonial-carousel-${uniqueIdHash()}`;

	return (
		<div
			className={
				className ||
				cn('testimonial-carousel__container px-4', ...classNames)
			}
		>
			{testimonials.length > 0 &&
				(testimonials.length > 1 ? (
					<Carousel
						options={options}
						showArrows={showArrows}
						showBullets={showBullets}
						id={genId}
					>
						{testimonials.map((testimonial, index) => (
							<CarouselItem key={index}>
								<Blockquote
									{...testimonial}
									classNames={['px-[13%] lg:px-0']}
								/>
							</CarouselItem>
						))}
					</Carousel>
				) : (
					<Blockquote
						{...testimonials[0]}
						classNames={['px-[13%] lg:px-0']}
					/>
				))}
		</div>
	);
};

export default TestimonialCarousel;
