import React from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import cn from 'classnames';
import Image, { IImage } from 'tbk-components/src/components/Image';
import { IFuzionButtonProps } from '../Button';
import ContentSection from '../ContentSection';
import NewImage from '../NewImage/NewImage';
import useScreenSize from '../../hooks/useScreenSize';
import AdvancedMedia from '../AdvancedMedia';

export interface IProductIndexAdBannerProps extends IBaseElementProps {
	title?: string;
	/**
	 * @control richtext
	 */
	excerpt?: string;
	buttons?: IFuzionButtonProps[];
	image?: IImage;
}

/**
 * Product Index Ad Banner
 * @block
 * @icon align-full-width
 */
const ProductIndexAdBanner: React.FC<IProductIndexAdBannerProps> = ({
	id,
	className,
	classNames = [],
	title,
	excerpt,
	buttons = [],
	image,
}) => {
	/* Tricky part here is that we need to cap the image height to the content height
	to avoid having a longer section than the content or a cropped image */
	const [height, setHeight] = React.useState<number>(0);
	const contentWrapperRef = React.useRef<HTMLDivElement>(null);

	// We'll only have to do this on lg and up
	let screenWidth = useScreenSize();
	const isLgAndUp = screenWidth >= 1024;

	// And before fetching the height, we need to make sure the image is loaded
	// else we end up getting a wrong height value since it's the one pre-layout shift
	const updateHeight = () => {
		if (contentWrapperRef.current) {
			const contentWrapperHeight = contentWrapperRef.current.clientHeight;
			setHeight(contentWrapperHeight);
		}
	};

	return (
		<div
			id={id}
			className={
				className ||
				cn(
					'product-index-ad-banner flex max-w-[1240px] flex-col overflow-hidden bg-tertiary lg:flex-row lg:rounded-md',
					...classNames,
				)
			}
		>
			{image?.src && (
				<div
					className={`relative w-full shrink-0 overflow-hidden p-4 pb-0 lg:max-w-[320px] lg:p-0 lg:[clip-path:polygon(0_0,100%_0,80%_100%,0_100%)]`}
				>
					{/* JUST BECAUSE DEMO IS HAPPENING TOMORROW, I'm making this change at the project level.
					But the change will be ported to tbk-components, we're adding an onLoad function */}
					<AdvancedMedia
						image={image}
						className="relative flex !h-full max-h-[120px] w-full items-center justify-center overflow-hidden rounded-md object-cover object-center xs:max-h-[330px] lg:max-h-none lg:rounded-none lg:[&_img]:absolute lg:[&_img]:left-1/2 lg:[&_img]:top-1/2 lg:[&_img]:-translate-x-1/2 lg:[&_img]:-translate-y-1/2"
					/>
				</div>
			)}
			<div
				className="content-wrapper px-4 py-4 md:px-8 lg:py-10"
				ref={contentWrapperRef}
			>
				<ContentSection
					title={title}
					titleElement="h2"
					titleClassName="mb-0 h4"
					excerpt={excerpt}
					excerptClassName="mb-0 text-base [&>p]:mb-0 [&>p]:text-base"
					buttons={buttons}
					gapSize="16px"
				/>
			</div>
		</div>
	);
};

export default ProductIndexAdBanner;
