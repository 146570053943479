import React from 'react';

export interface IAddressStringProps {
	streetAddress?: string;
	city?: string;
	province?: string;
	postalCode?: string;
	country?: string;
}

const AddressString: React.FC<IAddressStringProps> = ({
	streetAddress,
	city,
	province,
	postalCode,
	country,
}) => {
	const addressComponents = [streetAddress, city, province].filter(Boolean);

	const addressString = addressComponents.join(', ');

	return (
		<>
			{addressString}
			{country && `, ${country}`}
			{postalCode && ` ${postalCode}`}
		</>
	);
};

export default AddressString;
