import React, { useEffect } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Carousel from 'tbk-components/src/components/Carousel';
import CarouselItem from 'tbk-components/src/components/Carousel';
import Image, { IImage } from 'tbk-components/src/components/Image';
import Button, { ILink } from '../Button';
import uniqueIdHash from '../../hooks/generateUniqueIdHash';

export interface IFullWidthGalleryProps extends IBaseElementProps {
	/**
	 *  @noUI
	 */
	options?: { [x: string]: any };
	images?: IImage[];
	cta?: ILink;
	/**
	 * @control uniqueid
	 * @prefix full-width-gallery
	 */
	id?: string;
	productSku?: string;
}

/**
 * Full Width Gallery
 * @block
 * @icon slides
 */
const FullWidthGallery: React.FC<IFullWidthGalleryProps> = ({
	id,
	className,
	classNames = [],
	options = {
		type: 'carousel',
		perView: 1.2,
		gap: '32px',
		breakpoints: {
			480: {
				perView: 1,
			},
		},
	},
	images = [],
	cta,
	productSku,
}) => {
	const genId = id || `full-width-gallery-${uniqueIdHash()}`;

	return (
		<div
			className={
				className ||
				cn('full-width-gallery__container relative', ...classNames)
			}
		>
			{images.length > 0 &&
				(images.length > 1 ? (
					<Carousel
						options={options}
						showArrows={true}
						showBullets={false}
						id={genId}
						classNames={['!pb-0']}
					>
						{images.map((image, index) => (
							<li key={index} className="glide__slide">
								<div className="h-full overflow-hidden rounded-md">
									{
										<Image
											{...image}
											className="aspect-[3/2] h-full max-h-[780px] w-full object-cover object-center"
										/>
									}
								</div>
							</li>
						))}
					</Carousel>
				) : (
					<Image
						{...images[0]}
						className="aspect-[3/2] h-full max-h-[780px] w-full object-cover object-center"
					/>
				))}
			{cta && (
				<div className="roomvo-container absolute bottom-4 left-4 sm:left-8">
					<Button
						buttonType="Primary"
						size="sm"
						link={cta}
						classNames={['roomvo-stimr']}
						icon="check"
						data-sku={productSku}
						href="javascript:;"
						style={{ visibility: 'hidden' }}
					/>
				</div>
			)}
		</div>
	);
};

export default FullWidthGallery;
