import React, { useEffect, useRef } from 'react';
import Button, { ILink, IFuzionButtonProps } from '../Button';
import ProductCards, { IProductCardsProps } from '../ProductCards';

interface SuggestedItemProps {
	name?: string;
	resourceType?: string;
	url?: string;
	highlight?: string;
}

interface ProductResponseProps {
	productCards?: IProductCardsProps;
	totalProducts?: number;
	allResultsUrl?: IFuzionButtonProps;
}

interface IHeaderSearchResultsI18nProps {
	searchSuggestions?: string;
	products?: string;
	of?: string;
	resultsDisplayed?: string;
	viewAllProductsTagged?: string;
	noResultsFound?: string;
}

export interface IHeaderSearchResultsProps {
	suggestedItems?: SuggestedItemProps[];
	products?: ProductResponseProps;
	keywordsLinks?: ILink[];
	i18n?: IHeaderSearchResultsI18nProps;
	searchPerformed?: boolean;
}

const HeaderSearchResults: React.FC<IHeaderSearchResultsProps> = ({
	suggestedItems = [],
	products,
	keywordsLinks = [],
	i18n,
	searchPerformed = false,
}) => {
	const ref = useRef(null);

	const viewAllLink = (classNames) =>
		products?.allResultsUrl?.link?.href && (
			<Button {...products?.allResultsUrl} classNames={[classNames]} />
		);

	useEffect(() => {
		if (!ref.current) return;

		const header = ref.current.closest('.header__container');
		const searchBar = ref.current.previousSibling;

		if (header && searchBar) {
			const headerH = header.offsetHeight;
			const searchBarH = searchBar.offsetHeight;
			ref.current.style.maxHeight = `calc(100dvh - ${headerH + searchBarH}px)`;
			ref.current.style.overflow = 'auto';
		}
	}, [ref, suggestedItems, products, keywordsLinks]);

	return (
		<>
			{suggestedItems?.length > 0 ||
			products?.productCards?.productCards?.length > 0 ? (
				<div
					className="header-search-results__container bg-white"
					ref={ref}
				>
					<div className="container flex flex-col-reverse p-4 md:px-8 lg:flex-row lg:gap-[34px] lg:py-10">
						{(suggestedItems?.length > 0 ||
							keywordsLinks?.length > 0) && (
							<div className="header-search-results__suggestions mt-8 lg:mt-0 lg:w-1/3 lg:shrink-0">
								<span className="block font-header text-sm text-medium">
									{i18n?.searchSuggestions ||
										'Search Suggestions:'}
								</span>

								<ul className="header-search-results__suggestions mt-4 border-b border-tertiary lg:mt-[35px]">
									{suggestedItems?.length > 0 && (
										<>
											{suggestedItems.map(
												(item, index) => (
													<li
														key={index}
														className="border-t border-tertiary"
													>
														<a
															href={item.url}
															className="flex items-center justify-between gap-8 py-2 text-primary no-underline transition-all duration-300 ease-in-out hover:bg-tertiary focus:bg-tertiary lg:py-3"
														>
															<span
																className="text-sm capitalize lg:text-base"
																dangerouslySetInnerHTML={{
																	__html:
																		item.highlight ??
																		item.name,
																}}
															/>
															<span
																className={`flex items-center justify-center gap-2 whitespace-nowrap rounded-full border border-tertiary px-2 py-[6px] text-xs ${item.resourceType === 'pdf' && 'bg-tertiary'} shrink-0 lg:h-[24px] lg:w-[24px] lg:border-medium lg:bg-medium lg:p-0 lg:text-white`}
															>
																<i
																	className={`${item.resourceType === 'faq' && 'icon-blog hidden lg:inline-block'} ${item.resourceType === 'blog' && 'icon-blog hidden lg:inline-block'} ${item.resourceType === 'pdf' && 'icon-download'} text-base`}
																/>
																<span className="lg:hidden">
																	{item.resourceType ===
																		'faq' &&
																		'FAQ'}
																	{item.resourceType ===
																		'blog' &&
																		'Blog Article'}
																	{item.resourceType ===
																		'pdf' &&
																		'Download'}
																</span>
															</span>
														</a>
													</li>
												),
											)}
										</>
									)}
									{keywordsLinks?.length > 0 && (
										<>
											{keywordsLinks.map(
												(link, index) => (
													<li
														key={index}
														className="border-t border-tertiary"
													>
														<a
															href={link.href}
															className="flex items-center justify-between gap-8 py-2 text-medium no-underline transition-all duration-300 ease-in-out hover:bg-tertiary focus:bg-tertiary lg:py-[14px]"
														>
															<span className="text-sm">
																{i18n?.viewAllProductsTagged ||
																	'View all products tagged'}{' '}
																<strong className="font-bodyBold">
																	"
																	{link.label}
																	"
																</strong>
															</span>
															<i className="icon-chevron-right text-sm" />
														</a>
													</li>
												),
											)}
										</>
									)}
								</ul>
								{viewAllLink(
									'btn-sm mt-6 lg:hidden capitalize',
								)}
							</div>
						)}
						{products?.productCards?.productCards?.length > 0 && (
							<div className="header-search-results__products lg:mt-[-5px] lg:grow">
								<div className="flex flex-wrap items-center justify-between gap-4">
									<span className="font-header text-sm text-medium">
										{i18n?.products || 'Products:'}
									</span>
									<span className="text-sm text-medium lg:ml-auto lg:mr-0 lg:block">
										<strong className="font-bodyBold">
											{products?.totalProducts > 4
												? 4
												: products?.totalProducts}
										</strong>
										{` ${i18n?.of || 'of'} ${products?.totalProducts} ${i18n?.resultsDisplayed || 'Results Displayed'}`}
									</span>
									{viewAllLink(
										'btn-sm hidden lg:inline-block capitalize',
									)}
								</div>
								<div className="mt-5 lg:mt-6">
									<ProductCards
										{...products?.productCards}
										compact={true}
									/>
								</div>
								{viewAllLink(
									'btn-sm mt-6 lg:hidden capitalize',
								)}
							</div>
						)}
					</div>
				</div>
			) : (
				searchPerformed && (
					<div
						className="header-search-results__container bg-white"
						ref={ref}
					>
						<div className="container flex flex-col-reverse p-4 md:px-8 lg:flex-row lg:gap-[34px] lg:py-10">
							<div className="header-search-results__suggestions lg:w-1/3 lg:shrink-0"></div>

							<div className="header-search-results__products text-primary lg:mt-[-5px] lg:grow">
								{i18n?.noResultsFound || 'No results found'}
							</div>
						</div>
					</div>
				)
			)}
		</>
	);
};

export default HeaderSearchResults;
