import React, {
	useEffect,
	useState,
	useRef,
	useMemo,
	useLayoutEffect,
} from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { kebabCase } from 'lodash';
import useHashAsState from 'tbk-components/src/hooks/useHashAsState';

export interface IProductTabsProps extends IBaseElementProps {
	children?: any;
	defaultActiveTab?: number;
	trackStateInHash?: boolean;
}

const ProductTabs: React.FC<IProductTabsProps> = ({
	children,
	defaultActiveTab = 0,
	className,
	classNames = [],
	trackStateInHash = false,
}) => {
	const ref = useRef(null);
	// We'll use a Memo to calculate our tabs.  We're basing it off of the children passed in rather than DOM elements.
	const tabs = useMemo<[string, string][]>(() => {
		const tabs: [string, string][] = [];
		(Array.isArray(children)
			? children
			: children
				? [children]
				: []
		).forEach((child: JSX.Element) => {
			if (!child) {
				return;
			}

			// We're just going to have to treat each child like a "Tab" element.  There's no way in a production
			// build to check what kind of component the child is.
			tabs.push([
				child.props.id || kebabCase(child.props.title),
				child.props.title,
			]);
		});
		return tabs;
	}, [children]);

	// Helper function to tag a tag slug like my-first-tab and figure out the index amongst our tabs
	const convertTabNameToTabIndex = (tabName: string) => {
		let tabIndex = null;
		tabs.forEach((tab: [string, string], i) => {
			if (tab[0] == tabName) {
				tabIndex = i;
			}
		});
		return tabIndex || 0;
	};

	// If we're not tracking state in hash, then we pass in an empty config to useStateAsHash
	const [state, , updateState] = useHashAsState(
		trackStateInHash
			? {
					parameters: {
						tab: 'string',
					},
				}
			: {},
	);

	// The initial state has to calculate every time, but it's only actually used on the first pass through the
	// subsequent useState() call.
	let initialTab = state.tab
		? convertTabNameToTabIndex(state.tab)
		: defaultActiveTab;
	const [activeTab, setActiveTab] = useState<number>(initialTab);

	// I'm using the tab slug as the dependency here as it's nicer than having both activeTab and tabs as dependencies
	// The following useEffect is in place to update the hash state whenever the tab changes.
	const expectedTab = tabs[activeTab] ? tabs[activeTab][0] : null;
	useEffect(() => {
		if (trackStateInHash) {
			updateState({ tab: expectedTab });
		}
	}, [expectedTab, trackStateInHash]);

	// We'll use the layout effect to add and remove the active class on the tab panes.  Because they're rendered as
	// children, we don't have access to the props to update the classNames declaratively, so we have to do DOM
	// manipulation.
	useLayoutEffect(() => {
		if (!ref.current) {
			return;
		}
		(ref.current! as HTMLDivElement)
			.querySelectorAll('.tab-pane')
			.forEach((tab, i) => {
				if (i == activeTab) {
					tab.classList.add('active', '!block');
				} else {
					tab.classList.remove('active', '!block');
				}
			});
	}, [activeTab]);

	/**
	 * Bootstrap is not actually taking care of switching tabs.  We are doing that ourselves by
	 * switching the "activeTab".  We still want to trigger the BS tab events though as other
	 * parts of the system might rely on those firing properly.  This isn't a 100% solution however
	 * because I'm not able to pass in the "relatedTarget", like is normally on the BS event.
	 *
	 * @param tabID
	 */
	const setActiveTabWithEvents = (tabID: string) => {
		if (activeTab) {
			const previous = document.querySelector(
				'[data-bs-target="#' + tabs[activeTab][0] + '"]',
			);
			previous?.dispatchEvent(new Event('hide.bs.tab'));
			setTimeout(() => {
				previous?.dispatchEvent(new Event('hidden.bs.tab'));
			});
		}
		const next = document.querySelector(
			'[data-bs-target="#' + tabID + '"]',
		);
		next?.dispatchEvent(new Event('show.bs.tab'));
		setTimeout(() => {
			next?.dispatchEvent(new Event('shown.bs.tab'));
		});

		// Just figure out the index of the target tab to be able to update our activeTab
		let nextActiveIndex = 0;
		tabs.forEach((tab: [string, string], i) => {
			if (tab[0] === tabID) {
				nextActiveIndex = i;
			}
		});
		setActiveTab(nextActiveIndex);
	};

	return (
		<div className={className || cn('tabs', ...classNames)} ref={ref}>
			<ul
				className="nav nav-tabs flex w-full overflow-x-auto border-b border-secondary"
				role="tablist"
			>
				{tabs.map(([tabID, title], i) => (
					<li className="nav-item" role="presentation" key={tabID}>
						<button
							className={cn(
								'nav-link border-b px-6 pb-5',
								activeTab == i && 'active',
								activeTab == i
									? 'border-primary'
									: 'border-transparent',
								i,
								activeTab,
							)}
							id={`${tabID}-tab`}
							data-bs-toggle="tab"
							data-bs-target={`#${tabID}`}
							type="button"
							role="tab"
							aria-controls={tabID}
							aria-selected={activeTab == i ? 'true' : 'false'}
							onClick={() => {
								setActiveTabWithEvents(tabID);
							}}
						>
							{title}
						</button>
					</li>
				))}
			</ul>
			<div className="tab-content">{children}</div>
		</div>
	);
};

export default ProductTabs;
