import React from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { IImage } from 'tbk-components/src/components/Image';
import { colorPalette } from '../../../../tailwind-colors';
import cn from 'classnames';
import uniqueIdHash from '../../../hooks/generateUniqueIdHash';
import useScreenSize from '../../../hooks/useScreenSize';
import { twMerge } from 'tailwind-merge';
export interface ILocatorAreaTriangleMaskProps extends IBaseElementProps {
	image?: IImage;
}
const LocatorAreaTriangleMask: React.FC<ILocatorAreaTriangleMaskProps> = ({
	image,
	id = 'triangle-canvas-locator-area',
	className,
	classNames = [],
}) => {
	const screenWidth = useScreenSize();
	const isMdAndUp = screenWidth >= 768;
	const idHash = uniqueIdHash();
	const imageCanvasMaskId = `image-canvas-mask-${idHash}`,
		clipPathId = `clippath-${idHash}`,
		maskId = `mask-${idHash}`,
		clipPathId1 = `clippath-1-${idHash}`,
		clipPathId2 = `clippath-2-${idHash}`,
		clipPathId3 = `clippath-3-${idHash}`;

	return (
		<svg
			id={id}
			xmlns="http://www.w3.org/2000/svg"
			data-name="Layer 1"
			version="1.1"
			viewBox="0 0 586 542"
			className={className || cn(...classNames)}
		>
			<defs>
				<clipPath id={clipPathId}>
					<rect
						className="cls-2"
						strokeWidth="0px"
						fill="none"
						width="586"
						height="542"
					/>
				</clipPath>
				<mask
					id={maskId}
					x="-166.5"
					y="-384.4"
					width="1055.5"
					height="1229.6"
					maskUnits="userSpaceOnUse"
				>
					<g data-name="mask0 2983 13403">
						<path
							className="cls-7"
							strokeWidth="0px"
							fill={colorPalette.white.DEFAULT}
							d="M-3.2-205h892.2v803H-3.2V-205Z"
						/>
					</g>
				</mask>
				<clipPath id={clipPathId1}>
					<rect
						className="cls-2"
						strokeWidth="0px"
						fill="none"
						x="292.8"
						y="326.8"
						width="38.4"
						height="38.4"
					/>
				</clipPath>
				<clipPath id={clipPathId2}>
					<rect
						className="cls-2"
						strokeWidth="0px"
						fill="none"
						x="84.6"
						y="466.6"
						width="30.7"
						height="30.7"
					/>
				</clipPath>
				<clipPath id={clipPathId3}>
					<rect
						className="cls-2"
						strokeWidth="0px"
						fill="none"
						x="48.6"
						y="245.6"
						width="30.7"
						height="30.7"
					/>
				</clipPath>
			</defs>
			<mask id={imageCanvasMaskId}>
				<polygon
					className="cls-8"
					strokeWidth="0px"
					fill="#eee"
					points="111.5 125.1 0 392 0 542 528.4 542 111.5 125.1"
				/>
			</mask>
			<image
				xlinkHref={image?.src}
				mask={`url(#${imageCanvasMaskId})`}
				width="90%"
				height="80%"
				y={isMdAndUp ? '120' : '265'}
				preserveAspectRatio="xMidYMid slice"
			/>
			<g className="cls-1" clipPath={`url(#${clipPathId})`}>
				<g>
					<path
						className="cls-2"
						strokeWidth="0px"
						fill="none"
						d="M-86.7,598L111.5,124.8l473.7,473.2H-86.7Z"
					/>
					<path
						className="cls-2"
						strokeWidth="0px"
						fill="none"
						d="M-86.7,598L111.5,124.8l473.7,473.2H-86.7Z"
					/>
					<g className="cls-9" mask={`url(#${maskId})`}>
						<g>
							<path
								className="cls-3"
								stroke={colorPalette.white.DEFAULT}
								strokeMiterlimit="10"
								strokeWidth="1px"
								fill="none"
								d={
									isMdAndUp
										? 'M111.5,125.1v720.1'
										: 'M111.5,265v720.1'
								}
							/>
							<path
								className={twMerge(
									'cls-3',
									!isMdAndUp && 'hidden',
								)}
								stroke={colorPalette.white.DEFAULT}
								strokeMiterlimit="10"
								strokeWidth="1px"
								fill="none"
								d="M620.7-384L111.5,125.1"
							/>
							<path
								className="cls-3"
								stroke={colorPalette.white.DEFAULT}
								strokeMiterlimit="10"
								strokeWidth="1px"
								fill="none"
								d={
									isMdAndUp
										? 'M111.5,125.1l509.2,509.2'
										: 'M253,266l509.2,509.2'
								}
							/>
							<path
								className="cls-3"
								stroke={colorPalette.white.DEFAULT}
								strokeMiterlimit="10"
								strokeWidth="1px"
								fill="none"
								d={
									isMdAndUp
										? 'M111.5,125.1L-166.1,789.4'
										: 'M53,265L-166.1,789.4'
								}
							/>
							<path
								className="cls-3"
								stroke={colorPalette.white.DEFAULT}
								strokeMiterlimit="10"
								strokeWidth="1px"
								fill="none"
								d={
									isMdAndUp
										? 'M111.5,125.1l275.5,665.3'
										: 'M170,265l275.5,665.3'
								}
							/>
							<path
								className={twMerge(
									'cls-3',
									!isMdAndUp && 'hidden',
								)}
								stroke={colorPalette.white.DEFAULT}
								strokeMiterlimit="10"
								strokeWidth="1px"
								fill="none"
								d="M111.5,125.1L881.5-204.5"
							/>
						</g>
					</g>
					<circle
						className="cls-6"
						strokeWidth="0px"
						fill={colorPalette.secondary.DEFAULT}
						cx="312"
						cy="346"
						r="40"
					/>
					<g className="cls-10" clipPath={`url(#${clipPathId1})`}>
						<g>
							<path
								className="cls-5"
								strokeWidth="0.8px"
								strokeLinecap="round"
								strokeLinejoin="round"
								stroke={colorPalette.primary.DEFAULT}
								fill="none"
								d="M312,347.2c2.7,0,4.8-2.1,4.8-4.8s-2.1-4.8-4.8-4.8-4.8,2.1-4.8,4.8,2.1,4.8,4.8,4.8Z"
							/>
							<path
								className="cls-5"
								strokeWidth="0.8px"
								strokeLinecap="round"
								strokeLinejoin="round"
								stroke={colorPalette.primary.DEFAULT}
								fill="none"
								d="M324 342.4C324 353.2 312 361.6 312 361.6C312 361.6 300 353.2 300 342.4C300 339.217 301.264 336.165 303.515 333.915C305.765 331.664 308.817 330.4 312 330.4C315.183 330.4 318.235 331.664 320.485 333.915C322.736 336.165 324 339.217 324 342.4Z"
							/>
						</g>
					</g>
					<circle
						className="cls-6"
						strokeWidth="0px"
						fill={colorPalette.secondary.DEFAULT}
						cx="100"
						cy="482"
						r="32"
					/>
					<g className="cls-12" clipPath={`url(#${clipPathId2})`}>
						<g>
							<path
								className="cls-4"
								strokeWidth="0.6px"
								strokeLinecap="round"
								strokeLinejoin="round"
								stroke={colorPalette.primary.DEFAULT}
								fill="none"
								d="M100,483c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8-3.8,1.7-3.8,3.8,1.7,3.8,3.8,3.8Z"
							/>
							<path
								className="cls-4"
								strokeWidth="0.6px"
								strokeLinecap="round"
								strokeLinejoin="round"
								stroke={colorPalette.primary.DEFAULT}
								fill="none"
								d="M109.6 479.12C109.6 487.76 100 494.48 100 494.48C100 494.48 90.4004 487.76 90.4004 479.12C90.4004 476.574 91.4118 474.132 93.2122 472.332C95.0125 470.532 97.4543 469.52 100 469.52C102.546 469.52 104.988 470.532 106.789 472.332C108.589 474.132 109.6 476.574 109.6 479.12Z"
							/>
						</g>
					</g>
					<circle
						className="cls-6"
						strokeWidth="0px"
						fill={colorPalette.secondary.DEFAULT}
						cx="64"
						cy="261"
						r="32"
					/>
					<g className="cls-11" clipPath={`url(#${clipPathId3})`}>
						<g>
							<path
								className="cls-4"
								strokeWidth="0.6px"
								strokeLinecap="round"
								strokeLinejoin="round"
								stroke={colorPalette.primary.DEFAULT}
								fill="none"
								d="M64,262c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8-3.8,1.7-3.8,3.8,1.7,3.8,3.8,3.8Z"
							/>
							<path
								className="cls-4"
								strokeWidth="0.6px"
								strokeLinecap="round"
								strokeLinejoin="round"
								stroke={colorPalette.primary.DEFAULT}
								fill="none"
								d="M73.6004 258.12C73.6004 266.76 64.0004 273.48 64.0004 273.48C64.0004 273.48 54.4004 266.76 54.4004 258.12C54.4004 255.574 55.4118 253.132 57.2122 251.332C59.0125 249.532 61.4543 248.52 64.0004 248.52C66.5465 248.52 68.9883 249.532 70.7886 251.332C72.589 253.132 73.6004 255.574 73.6004 258.12Z"
							/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default LocatorAreaTriangleMask;
