import React, { useLayoutEffect, useState } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Image, { IImage } from 'tbk-components/src/components/Image';
// @ts-ignore
import lengthPattern from '../../assets/length-pattern.svg';
// @ts-ignore
import widthPattern from '../../assets/width-pattern.svg';
import variationLow from '../../assets/images/variation-low-default.svg';
import variationLowHighlighted from '../../assets/images/variation-low-current.svg';
import variationModerate from '../../assets/images/variation-moderate-default.svg';
import variationModerateHighlighted from '../../assets/images/variation-moderate-current.svg';
import variationStrong from '../../assets/images/variation-strong-default.svg';
import variationStrongHighlighted from '../../assets/images/variation-strong-current.svg';
import variationIntense from '../../assets/images/variation-intense-default.svg';
import variationIntenseHighlighted from '../../assets/images/variation-intense-current.svg';
import { twMerge } from 'tailwind-merge';

export interface ILength {
	length: string;
	percentage?: number;
	highlighted?: boolean;
}

export interface IWidth {
	width: string;
	percentage?: number;
	highlighted?: boolean;
}

export interface ILevel {
	level: string;
	highlighted?: boolean;
	imageDefaultState?: IImage;
	imageHighlightedState?: IImage;
}

export interface ILengthSection {
	heading?: string;
	length1: ILength;
	length2: ILength;
	length3: ILength;
	length4: ILength;
	length5: ILength;
	length6: ILength;
	length7: ILength;
	description?: string;
	chartText?: string;
}

export interface IWidthSection {
	heading?: string;
	width1: IWidth;
	width2: IWidth;
	width3: IWidth;
	width4: IWidth;
	width5: IWidth;
	width6: IWidth;
	width7: IWidth;
	width8: IWidth;
	description?: string;
}

export interface IVariationSection {
	heading?: string;
	description?: string;
	level1: ILevel;
	level2: ILevel;
	level3: ILevel;
	level4: ILevel;
}

export interface IFloorLengthWidthProps extends IBaseElementProps {
	imageOne: IImage;
	imageTwo: IImage;
	lengthSection?: ILengthSection;
	widthSection?: IWidthSection;
	variationSection?: IVariationSection;
	productType?: string;
}

/**
 * FloorLengthWidth
 * @icon chart-bar
 * @block
 */
const FloorLengthWidth: React.FC<IFloorLengthWidthProps> = ({
	imageOne,
	imageTwo,
	lengthSection,
	widthSection,
	variationSection,
	productType,
	className,
	classNames = [],
}) => {
	const [featuredImage, setFeaturedImage] = useState<IImage | null>(imageTwo);
	const lengths: ILength[] = [
		{ ...lengthSection?.length1 },
		{ ...lengthSection?.length2 },
		{ ...lengthSection?.length3 },
		{ ...lengthSection?.length4 },
		{ ...lengthSection?.length5 },
		{ ...lengthSection?.length6 },
		{ ...lengthSection?.length7 },
	];

	const widths: IWidth[] = [
		{ ...widthSection?.width1 },
		{ ...widthSection?.width2 },
		{ ...widthSection?.width3 },
		{ ...widthSection?.width4 },
		{ ...widthSection?.width5 },
		{ ...widthSection?.width6 },
		{ ...widthSection?.width7 },
		{ ...widthSection?.width8 },
	];

	const levels: ILevel[] = [
		{
			level: 'Low',
			imageDefaultState: variationLow,
			imageHighlightedState: variationLowHighlighted,
			...variationSection?.level1,
		},
		{
			level: 'Moderate',
			imageDefaultState: variationModerate,
			imageHighlightedState: variationModerateHighlighted,
			...variationSection?.level2,
		},
		{
			level: 'Strong',
			imageDefaultState: variationStrong,
			imageHighlightedState: variationStrongHighlighted,
			...variationSection?.level3,
		},
		{
			level: 'Intense',
			imageDefaultState: variationIntense,
			imageHighlightedState: variationIntenseHighlighted,
			...variationSection?.level4,
		},
	];

	useLayoutEffect(() => {
		if (imageTwo) {
			setFeaturedImage({
				...imageTwo,
				src: `${imageTwo.src}?width=1800`,
			});
		}
	}, []);
	return (
		<div
			className={
				className ||
				cn('floor-length-width bg-secondary', ...classNames)
			}
		>
			<div className="relative mx-auto max-w-8xl px-8 py-16 md:py-30">
				<div className="flex flex-col gap-8 sm:flex-row">
					<div className="aspect-[437/328] max-h-[328px] w-full max-w-[437px]">
						<Image
							{...imageOne}
							className="!h-full w-full rounded-md object-cover object-center"
						/>
					</div>
					<div className="aspect-[907/328] max-h-[328px] w-full max-w-[907px]">
						<Image
							{...featuredImage}
							className="!h-full w-full rounded-md object-cover object-center"
						/>
					</div>
				</div>
				{productType && productType !== 'Carpet Tile' && (
					<div className="mt-28 flex flex-wrap gap-x-10 gap-y-16">
						{lengthSection && (
							<div className="basis-full 2xl:flex-1">
								{lengthSection?.heading && (
									<h2 className="mb-10 text-4xl 2xl:mb-[72px]">
										{lengthSection?.heading}
									</h2>
								)}
								<div className="h-[160px] divide-y divide-secondary">
									{lengths &&
										lengths.length > 0 &&
										lengths.map(
											(
												{
													length,
													highlighted,
													percentage,
												},
												index,
											) => (
												<div
													className={twMerge(
														'grid',
														length &&
															'grid-cols-[60px_auto]',
													)}
													key={index}
													style={{
														height: `${100 / lengths.length}%`,
													}}
												>
													{length && (
														<div
															className={twMerge(
																'self-center',
																highlighted
																	? 'text-sm text-primary'
																	: 'text-[13px] text-[#58534C]',
															)}
														>
															{length}
															{length !== '' &&
																`"`}
														</div>
													)}
													<div
														className={twMerge(
															highlighted
																? 'bg-primary'
																: 'bg-tertiary',
															index === 0 &&
																'rounded-t-md',
															index ===
																lengths.length -
																	1 &&
																'rounded-b-md',
														)}
														style={{
															backgroundImage:
																highlighted
																	? `url(${lengthPattern})`
																	: 'none',
															width: `${percentage}%`,
														}}
													></div>
												</div>
											),
										)}
								</div>
								{lengthSection?.chartText && (
									<div className={'mb-10 mt-4 grid 2xl:mt-6'}>
										<div
											className={twMerge(
												'text-[13px] text-medium',
											)}
										>
											{lengthSection.chartText}
										</div>
									</div>
								)}
								{lengthSection?.description && (
									<p className="m-0 max-w-[320px] text-base">
										{lengthSection?.description}
									</p>
								)}
							</div>
						)}
						{widthSection && (
							<div className="basis-full lg:flex-1">
								{widthSection?.heading && (
									<h2 className="mb-10 text-4xl 2xl:mb-[72px]">
										{widthSection?.heading}
									</h2>
								)}
								<div
									className="mb-10 grid divide-x divide-secondary"
									style={{
										gridTemplateColumns: widths
											.map(
												({ percentage }) =>
													`${percentage}%`,
											)
											.join(' '),
									}}
								>
									{widths &&
										widths.length > 0 &&
										widths.map(
											({ width, highlighted }, index) => (
												<div
													key={index}
													className="relative grid grid-rows-[160px_auto] gap-y-6"
												>
													<div className="relative">
														<div
															style={{
																backgroundImage:
																	highlighted
																		? `url(${widthPattern})`
																		: 'none',
																backgroundSize:
																	'contain',
															}}
															className={twMerge(
																'h-full',
																highlighted
																	? 'absolute top-1/2 z-10 h-[110%] w-full -translate-y-1/2 transform bg-primary'
																	: 'bg-tertiary',
																index === 0 &&
																	'rounded-l-md',
																index ===
																	widths.length -
																		1 &&
																	'rounded-r-md',
															)}
														></div>
													</div>
													<div
														className={twMerge(
															'z-10 text-center',
															'floor-width-grid-label',
															highlighted
																? 'text-sm text-primary'
																: 'text-[13px] text-[#58534C]',
														)}
													>
														{width}
														{width !== '' && `"`}
													</div>
												</div>
											),
										)}
								</div>
								{widthSection?.description && (
									<p className="m-0 max-w-[320px] text-base">
										{widthSection?.description}
									</p>
								)}
							</div>
						)}
						{variationSection && (
							<div className="basis-full lg:flex-1">
								{variationSection?.heading && (
									<h2 className="mb-10 text-4xl 2xl:mb-[72px]">
										{variationSection?.heading}
									</h2>
								)}
								<div
									className="mb-10 grid divide-x divide-secondary"
									style={{
										gridTemplateColumns: `repeat(${levels?.length}, 1fr)`,
									}}
								>
									{levels &&
										levels.length > 0 &&
										levels.map(
											(
												{
													level,
													highlighted,
													imageDefaultState,
													imageHighlightedState,
												},
												index,
											) => (
												<div
													key={index}
													className="relative grid grid-rows-[160px_auto] gap-y-6"
												>
													<div className="relative">
														<div
															style={{
																backgroundImage:
																	highlighted
																		? `url(${imageHighlightedState})`
																		: `url(${imageDefaultState})`,
																backgroundSize:
																	'auto 100%',
																backgroundPosition:
																	'center',
															}}
															className={twMerge(
																'h-full',
																highlighted
																	? 'absolute top-1/2 h-[110%] w-full -translate-y-1/2 transform'
																	: '',
																index === 0 &&
																	'rounded-l-md',
																index ===
																	levels.length -
																		1 &&
																	'rounded-r-md',
															)}
														></div>
													</div>
													<div
														className={twMerge(
															'text-center',
															highlighted
																? 'text-sm text-primary'
																: 'text-[13px] text-[#58534C]',
														)}
													>
														{level}
													</div>
												</div>
											),
										)}
								</div>
								{variationSection?.description && (
									<p className="m-0 max-w-[320px] text-base">
										{variationSection?.description}
									</p>
								)}
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default FloorLengthWidth;
