import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import IconTextGrid, { IIconTextGridProps } from '../IconTextGrid';
import ImageToSVG from '../ImageToSVG';
import checkIcon from '../../assets/icon-check.svg';
import crossIcon from '../../assets/icon-cross.svg';
import { twMerge } from 'tailwind-merge';

export interface IFloorProsConsProps extends IBaseElementProps {
	prosTitle: string;
	consTitle: string;
	pros: IIconTextGridProps;
	cons: IIconTextGridProps;
}

/**
 * Floor Pros Cons
 * @icon saved
 * @block
 */
const FloorProsCons: React.FC<IFloorProsConsProps> = ({
	prosTitle,
	consTitle,
	pros,
	cons,
	className,
	classNames = [],
}) => {
	return (
		<div className={className || cn('floor-pros-cons', ...classNames)}>
			<div className="mx-auto flex max-w-8xl flex-col gap-20 px-8 py-16 md:py-30 lg:justify-center xl:flex-row">
				{pros?.iconTexts?.length > 0 && (
					<div
						className={twMerge(
							!cons?.iconTexts || cons?.iconTexts?.length === 0
								? 'w-full'
								: 'basis-1/2',
						)}
					>
						<span
							className={twMerge(
								'flex items-center gap-4 text-primary sm:mb-16',
								(!cons?.iconTexts ||
									cons?.iconTexts?.length === 0) &&
									'justify-center',
							)}
						>
							<ImageToSVG
								image={{ src: checkIcon, width: 0, height: 0 }}
							/>
							<h2 className="h2-large mb-0">{prosTitle}</h2>
						</span>
						<IconTextGrid
							{...pros}
							orientation="horizontal"
							borderColor="border-tertiary"
							iconColor="text-medium"
							textColor="text-primary"
							largeDesktopGrid={
								!cons?.iconTexts ||
								cons?.iconTexts?.length === 0
							}
							numbOfItems={pros?.iconTexts?.length}
						/>
					</div>
				)}
				{cons?.iconTexts?.length > 0 && (
					<div
						className={twMerge(
							!pros?.iconTexts || pros?.iconTexts?.length === 0
								? 'w-full'
								: 'basis-1/2',
						)}
					>
						<span
							className={twMerge(
								'flex items-center gap-4 text-medium sm:mb-16',
								(!pros?.iconTexts ||
									pros?.iconTexts?.length === 0) &&
									'justify-center',
							)}
						>
							<ImageToSVG
								image={{ src: crossIcon, width: 0, height: 0 }}
							/>
							<h2 className="h2-large mb-0">{consTitle}</h2>
						</span>
						<IconTextGrid
							{...cons}
							orientation="horizontal"
							borderColor="border-tertiary"
							iconColor="text-secondary"
							textColor="text-medium"
							largeDesktopGrid={
								!pros?.iconTexts ||
								pros?.iconTexts?.length === 0
							}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default FloorProsCons;
