import React from 'react';
import { twMerge } from 'tailwind-merge';

export interface IIconProps {
	name: string;
	className?: string;
	classNames?: string[];
}

const Icon: React.FC<IIconProps> = ({ name, className, classNames = [] }) => (
	<i className={className || twMerge(`icon icon-${name}`, ...classNames)} />
);

export default Icon;
