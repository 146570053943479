import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import ImageToSVG from '../ImageToSVG';
import OpenQuotationMark from '../../assets/images/icon-quotation-open.svg';

export interface IBlockQuoteProps extends IBaseElementProps {
	/**
	 * @control richtext
	 */
	quote?: string;
	author?: string;
	showBorder?: boolean;
}

/**
 *
 * Blockquote
 * @block
 * @icon format-quote
 */
const Blockquote: React.FC<IBlockQuoteProps> = ({
	id,
	className,
	classNames = [],
	quote,
	author,
	showBorder = true,
}) => {
	return (
		<div
			id={id}
			className={
				className ||
				cn(
					`blockquote__container mx-auto max-w-[906px] px-4 pb-10 pt-10 md:pt-16 ${showBorder ? 'border-b border-t border-secondary' : ''}`,
					...classNames,
				)
			}
		>
			<blockquote className="mx-auto max-w-[730px] text-center">
				{quote && (
					<>
						<ImageToSVG
							image={{
								src: OpenQuotationMark,
								width: 0,
								height: 0,
							}}
							classNames={[
								'mx-auto',
								'mb-8',
								'max-w-[40px]',
								'max-h-[40px]',
								'text-primary',
							]}
						/>
						<p
							className="blockquote text-primary font-bodyLight"
							dangerouslySetInnerHTML={{
								__html: quote,
							}}
						/>
					</>
				)}
				{author && (
					<footer className="item-center mt-8 flex justify-center">
						<span className="cite text-medium">{author}</span>
					</footer>
				)}
			</blockquote>
		</div>
	);
};

export default Blockquote;
