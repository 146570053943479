import React, { useState, useContext, useEffect, useRef } from 'react';
import BlogRollPagination from '../ProductIndex/BlogRollPagination';
import Loading from 'tbk-components/src/components/Loading';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import SearchBar, { ISearchBarI18nProps } from './SearchBar';
import IndexSearchHeading, {
	IIndexSearchHeadingI18nProps,
} from './IndexSearchHeading';
import IndexSearchProducts from './IndexSearchProducts';
import ResourcesList from '../ResourcesList';
import BlogsList from '../BlogsList';
import { ILink } from '../Button';
import { IGetAQuoteProps } from '../GetAQuote/';

interface ISearchPageI18nProps
	extends IIndexSearchHeadingI18nProps,
		ISearchBarI18nProps {
	products?: string;
	resources?: string;
	blogs?: string;
}

export interface ISearchPageProps extends IBaseElementProps {
	wooCommCartUrl?: string;
	tryOurFlooringMatchmaker?: ILink;
	viewAllProducts?: ILink;
	defaultImageUrl?: string;
	productCompareLink?: string;
	getAQuote?: IGetAQuoteProps;
	i18n?: ISearchPageI18nProps;
}

const Search: React.FC<ISearchPageProps> = ({
	id,
	className,
	classNames = [],
	wooCommCartUrl,
	tryOurFlooringMatchmaker,
	viewAllProducts,
	defaultImageUrl,
	productCompareLink,
	getAQuote,
	i18n,
}) => {
	const [productResults, setProductResults] = useState<any>(null);
	const [resourceResults, setResourceResults] = useState<any>(null);
	const [blogResults, setBlogResults] = useState<any>(null);

	const [search, setSearch] = useState('');
	const [tagged, setTagged] = useState('');
	const [currentPage, setCurrentPage] = useState<number>(1);

	const [totalProducts, setTotalProducts] = useState<number>(0);
	const [productNum, setProductNum] = useState<number>(0);
	const [resourceNum, setResourceNum] = useState<number>(0);
	const [blogNum, setBlogNum] = useState<number>(0);

	// ref to pass down to pagination so it can force a scroll to top of blogRoll when user changes page.
	const topRef = useRef(null);

	const loadingScreen = (
		<div className="loading-wrapper container mx-auto block flex justify-center p-12 text-center">
			<Loading />
		</div>
	);

	useEffect(() => {
		let params = new URLSearchParams(document.location.search);
		let s = params.get('search');
		let t = params.get('tagged');

		if (s) setSearch(s);
		if (t) {
			setSearch(t);
			setTagged(t);
		}
	}, []);

	return (
		<div
			id={id}
			ref={topRef}
			className={className || cn('search__container ', ...classNames)}
		>
			<SearchBar
				inHeaderDropdown={false}
				onProductSearch={(s, value, total) => {
					setProductResults({
						...productResults,
						products: {
							enableProductCompare: true,
							productCards: value,
							productCompareLink: productCompareLink,
						},
					});
					setTotalProducts(total);
					setProductNum(value.length);
				}}
				onResourceSearch={(s, value) => {
					setResourceResults({
						...resourceResults,
						resources: value,
					});
					setResourceNum(value.length);
				}}
				onBlogSearch={(s, value) => {
					setBlogResults({
						...blogResults,
						blogs: value,
					});
					setBlogNum(value.length);
				}}
				searchFromUrl={search}
				currentPage={currentPage}
				classNames={[tagged ? 'hidden' : '']}
				wooCommCartUrl={wooCommCartUrl}
				defaultImageUrl={defaultImageUrl}
				onChange={() => setCurrentPage(1)}
				getAQuote={getAQuote}
				i18n={i18n}
			/>
			<IndexSearchHeading
				searchKeyword={tagged}
				numOfProducts={productNum}
				numOfResources={resourceNum}
				numOfBlogs={blogNum}
				endRecord={totalProducts}
				tryOurFlooringMatchmaker={tryOurFlooringMatchmaker}
				viewAllProducts={viewAllProducts}
				i18n={i18n}
			/>
			<IndexSearchProducts
				{...productResults}
				sectionTitle={tagged ? '' : i18n?.products ?? 'Products'}
				i18n={i18n}
			/>
			{productResults?.products?.productCards?.length > 0 && (
				<>
					<BlogRollPagination
						page={currentPage}
						perPage={12}
						total={totalProducts}
						onChange={(page: any) => setCurrentPage(page)}
						topRef={topRef}
						className="blogroll-pagination mb-8 lg:mb-20"
					/>
				</>
			)}
			{!tagged && (
				<>
					<ResourcesList
						{...resourceResults}
						sectionTitle={i18n?.resources ?? 'Resources'}
						i18n={i18n}
					/>
					<BlogsList
						{...blogResults}
						sectionTitle={i18n?.blogs ?? 'Blogs'}
						i18n={i18n}
					/>
				</>
			)}
		</div>
	);
};
export default Search;
