import React from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import ContentSection from '../ContentSection';
import Image, { IImage } from 'tbk-components/src/components/Image';
import bgImage from '../../assets/images/alternating-content-star-line-burst.svg';
import AdvancedMedia from '../AdvancedMedia';
import LineAccent from '../LineAccent';
import { IFuzionButtonProps, ILink } from '../Button';
import { twJoin, twMerge } from 'tailwind-merge';
import Bullets, { IBulletsProps } from '../Bullets';
import { IVideoProps } from '../Video';

export interface IAlternatingContentProps extends IBaseElementProps {
	accentImage?: IImage;
	eyebrow?: string;
	title?: string;
	/**
	 * Text
	 * @control richtext
	 */
	excerpt?: string;
	/**
	 * Lead
	 * @control richtext
	 */
	lead?: string;
	bulletListing?: IBulletsProps;
	buttons?: IFuzionButtonProps[];
	style?: 'Default' | 'Narrow' | 'Wide';
	footerLogos?: IImage[];
	imageFooterLinks?: ILink[];
	imageFooterLinksClassName?: string;
	imagePrimary?: IImage;
	imageSecondary?: IImage;
	videoPrimary?: IVideoProps;
	videoSecondary?: IVideoProps;
	reverseColumn?: boolean;
	enableLineAccent?: boolean;
	bgColor?:
		| 'bg-white'
		| 'bg-gradient-general-radial'
		| 'bg-gradient-rainbow'
		| 'bg-light';
	titleClassName?: string;
	/* Some sections in the design have the styling of the Narrow style but not the container size.
	This should override for specific sections the container size without affecting the rest
	of the items relying on that style choice such as the images. Ex: Why Fuzion Flooring */
	customContainerSizeOverride?: number;
	LineAccentPosition?: 'Left' | 'Right';
}

const computedImageAspectRatio = (style: string, doubleImage: boolean) => {
	switch (true) {
		case style === 'Default' && !doubleImage:
			return '4/3';
		case style === 'Narrow' && !doubleImage:
			return '3/4';
		case style === 'Wide' && !doubleImage:
			return '4/3';
		case style === 'Default' && doubleImage:
			return '16/9';
		case style === 'Narrow' && doubleImage:
			return '16/9';
		case style === 'Wide' && doubleImage:
			return '3/4';
		default:
			return 'Default';
	}
};

const computedContainerSize = (style: string) => {
	switch (style) {
		case 'Default':
			return 'max-w-[1142px]';
		case 'Narrow':
			return 'max-w-[980px]';
		case 'Wide':
			return 'max-w-[1376px]';
		default:
			return 'container';
	}
};

const computedAccentStrokeColor = (bgColor: string) => {
	switch (bgColor) {
		case 'bg-gradient-rainbow':
			return 'white';
		case 'bg-gradient-general-radial':
			return 'primary';
		default:
			return 'secondary';
	}
};

/**
 * Alternating Content
 * @block
 * @icon align-left
 */
const AlternatingContent: React.FC<IAlternatingContentProps> = ({
	id,
	className,
	classNames = [],
	eyebrow,
	title,
	excerpt,
	lead,
	buttons = [],
	footerLogos = [],
	imagePrimary,
	accentImage,
	imageSecondary,
	videoPrimary,
	videoSecondary,
	imageFooterLinks = [],
	imageFooterLinksClassName,
	style = 'Default',
	bulletListing,
	reverseColumn,
	enableLineAccent = true,
	bgColor = 'bg-gradient-general-radial',
	titleClassName,
	customContainerSizeOverride,
	LineAccentPosition = 'Left',
}) => {
	const doubleImage = imagePrimary && imageSecondary ? true : false;
	const imageAspectRatio = computedImageAspectRatio(style, doubleImage);
	const containerSize = computedContainerSize(style);
	const accentStrokeColor = computedAccentStrokeColor(bgColor);

	return (
		<div
			id={id}
			className={
				className ||
				twMerge(
					`alternating-content px-4 md:px-8 ${bgColor} relative z-0 py-8 md:py-12 xl:py-[7.5rem]`,
					...classNames,
				)
			}
		>
			<div
				className="absolute bottom-0 left-0 max-h-[326px] max-w-[654px]"
				style={{
					background: `url(${bgImage}) bottom left / contain no-repeat`,
				}}
			/>
			<div
				className={twMerge(
					`relative z-[1] mx-auto w-full`,
					containerSize,
				)}
				style={{
					maxWidth:
						customContainerSizeOverride &&
						`${customContainerSizeOverride}px`,
				}}
			>
				<div
					className={`flex flex-col items-start justify-between gap-8 md:items-center ${reverseColumn ? 'md:flex-row-reverse' : 'md:flex-row'}`}
				>
					<div
						className={twMerge(
							`flex w-full flex-col`,
							style !== 'Narrow'
								? 'md:basis-1/2'
								: 'md:basis-[49%]',
						)}
					>
						<div
							className={twMerge(
								'flex items-center justify-center',
								doubleImage && 'gap-4 md:gap-8',
								style !== 'Wide' && 'flex-col',
							)}
						>
							{imagePrimary && (
								<AdvancedMedia
									image={imagePrimary}
									video={videoPrimary}
									size={imageAspectRatio}
									rounded
								/>
							)}
							{imageSecondary && (
								<AdvancedMedia
									image={imageSecondary}
									video={videoSecondary}
									size={imageAspectRatio}
									rounded
								/>
							)}
						</div>
						{imageFooterLinks.length > 0 && (
							<ul className="mt-8 flex gap-4">
								{imageFooterLinks.map((link, index) => (
									<li
										key={index}
										className="relative flex items-center justify-center gap-3"
									>
										<a
											key={index}
											href={link.href}
											rel={
												link.target === '_blank'
													? 'noopener noreferrer'
													: ''
											}
											className={twJoin(
												'underline lg:text-base',
												imageFooterLinksClassName,
											)}
										>
											{link.label}
										</a>
										{index <
											imageFooterLinks.length - 1 && (
											<span className="relative left-0.5 block h-0.5 w-0.5 overflow-hidden rounded-full bg-current"></span>
										)}
									</li>
								))}
							</ul>
						)}
					</div>
					<div
						className={twMerge(
							'md:p-0',
							style !== 'Narrow'
								? 'md:basis-5/12'
								: 'md:basis-[60%]',
						)}
					>
						<ContentSection
							accentImage={accentImage}
							eyebrow={eyebrow}
							titleElement="h2"
							title={title}
							lead={lead}
							excerpt={excerpt}
							buttons={buttons}
							classNames={[
								'text-current',
								imageFooterLinks.length > 0 && 'mb-10',
							]}
							titleClassName={titleClassName}
						>
							{bulletListing?.bullets && (
								<Bullets {...bulletListing} />
							)}
						</ContentSection>
						{footerLogos.length > 0 && (
							<div className="mt-10 flex flex-wrap items-center gap-12 md:gap-20">
								{footerLogos.map((logo, index) => (
									<Image
										key={index}
										{...logo}
										className="h-[80px] w-auto max-w-[200px] object-contain object-left md:h-[120px]"
									/>
								))}
							</div>
						)}
					</div>
				</div>
			</div>
			{enableLineAccent && (
				<LineAccent
					horizontalFlipped={!reverseColumn && style !== 'Narrow'}
					verticalFlipped={style === 'Narrow'}
					color={accentStrokeColor}
					classNames={[
						twMerge(
							'absolute md:block hidden w-full max-w-[566px] h-auto z-0',
							LineAccentPosition === 'Left'
								? 'left-0'
								: 'right-0',
							style !== 'Narrow' ? 'bottom-0' : 'top-0',
						),
					]}
				/>
			)}
		</div>
	);
};

export default AlternatingContent;
