import React from 'react';
import { IImage } from 'tbk-components/src/components/Image';
import AdvancedMedia from '../AdvancedMedia';
import { useTranslation } from 'react-i18next';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { twMerge } from 'tailwind-merge';

interface IBlogCardI18nProps {
	readMore: string; // Read More
}

export interface IBlogCardProps extends IBaseElementProps {
	blogTitle?: string;
	/**
	 * The prop is used in BlogBarsList component
	 */
	blogExcerpt?: string;
	blogURL?: string;
	categories?: string[];
	featuredImage?: IImage;
	/**
	 * The prop is used in BlogBarsList component
	 */
	featuredImageSecondary?: IImage;
	/**
	 * The prop is used in BlogBarsList component
	 */
	blogBarLayout?:
		| 'Narrow Width / Image Right'
		| 'Medium Width / Image Right'
		| 'Wide Width / Image Left';
	i18n?: IBlogCardI18nProps;
}

const BlogCard: React.FC<IBlogCardProps> = ({
	id,
	className,
	classNames = [],
	blogTitle,
	blogExcerpt,
	categories = [],
	blogURL,
	featuredImage,
	featuredImageSecondary,
	i18n,
}) => {
	const { t } = useTranslation();

	return (
		<a
			id={id}
			href={blogURL}
			className={
				className ||
				twMerge(
					'flex h-full min-h-12 max-w-full items-start justify-start gap-4 rounded-md bg-white transition-all duration-300 ease-in-out hover:scale-105 focus:scale-105 md:flex-col md:gap-10 lg:justify-start',
					...classNames,
				)
			}
		>
			{featuredImage && (
				<AdvancedMedia
					image={featuredImage}
					size="4/3"
					rounded
					classNames={[
						'!w-1/3',
						'min-w-[99px]',
						'max-w-1/3',
						'md:!w-full',
						'grow-0',
						'shrink-0',
					]}
				/>
			)}
			<div className="mt-[-4px] md:mt-0 lg:max-w-[320px]">
				{categories?.length > 0 && (
					<div className="mb-2 flex flex-wrap items-start justify-start gap-x-4">
						{categories.map((cat, index) => (
							<span
								key={index}
								className={`block font-header text-sm text-medium lg:text-base`}
							>
								{cat}
							</span>
						))}
					</div>
				)}
				{blogTitle && (
					<h3 className="h4 mb-0 font-header text-lg leading-[1.2] lg:text-2xl">
						{blogTitle}
					</h3>
				)}

				<span className="btn btn-secondary hidden md:mt-8 md:inline-flex">
					{i18n?.readMore ? i18n?.readMore : t('Read More')}
				</span>
			</div>
		</a>
	);
};

export default BlogCard;
