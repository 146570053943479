import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Image, { IImageProps } from 'tbk-components/src/components/Image';
import Button, { ILink } from 'tbk-components/src/components/Button';
import starLineBurst from '../../assets/images/cta-star-line-burst.svg';

export interface ICallToActionProps extends IBaseElementProps {
	title?: string;
	description?: string;
	image?: IImageProps;
	buttonLink?: ILink;
}

/**
 * Call to Action
 * @block
 * @icon align-center
 */
const CallToAction: React.FC<ICallToActionProps> = ({
	id,
	className,
	classNames = [],
	title,
	description,
	image,
	buttonLink,
}) => {
	return (
		<div
			id={id}
			className={
				className ||
				cn(
					'call-to-action__container bg-gradient-general relative ',
					...classNames,
				)
			}
		>
			<div
				className="absolute left-0 top-0 h-full w-full"
				style={{
					background: `url(${starLineBurst}) bottom left / contain no-repeat`,
				}}
			/>
			<div className="container relative flex flex-wrap gap-8 px-4 py-16 md:px-8 lg:flex-nowrap lg:items-center lg:py-[120px]">
				{image && (
					<div className="w-1/2 max-w-full overflow-hidden lg:w-[25%]">
						<Image {...image} className="max-w-full" />
					</div>
				)}
				{(title || description) && (
					<div className="w-full lg:w-[50%]">
						{title && <h2 className="mb-4">{title}</h2>}
						{description && <p className="m-0">{description}</p>}
					</div>
				)}
				{buttonLink && (
					<div className="flex justify-end lg:w-[25%]">
						<Button
							{...buttonLink}
							classNames={['btn-secondary']}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default CallToAction;
