import React, { useEffect } from 'react';

/**
 * A customized popup on the map.
 * Source: https://developers.google.com/maps/documentation/javascript/examples/overlay-popup
 */
const createPopup = (closeText) => {
	// Class is wrapped with a generator function because Google Maps is loaded asynchronously.
	class Popup extends google.maps.OverlayView {
		position: google.maps.LatLng;
		containerDiv: HTMLDivElement;

		show(position: google.maps.LatLng, content: string) {
			this.position = position;

			// Cleanup before re-rendering.
			this.onRemove();

			// This zero-height div is positioned at the bottom of the bubble.
			const bubbleAnchor = document.createElement('div');

			bubbleAnchor.classList.add('popup-bubble-anchor');
			bubbleAnchor.innerHTML = `<div class="popup-bubble">${content}</div>`;

			// This zero-height div is positioned at the bottom of the tip.
			this.containerDiv = document.createElement('div');
			this.containerDiv.classList.add(
				'popup-container',
				'absolute',
				'max-w-[320px]',
				'translate-x-[-50%]',
				'translate-y-[-123%]',
			);
			this.containerDiv.appendChild(bubbleAnchor);

			// Create a close button to hide this popup
			let closeButtonElement = document.createElement('button');
			closeButtonElement.classList.add(
				'popup-close',
				'absolute',
				'right-2',
				'top-2',
				'flex',
				'h-6',
				'w-6',
				'items-center',
				'justify-center',
				'text-2xl',
			);
			closeButtonElement.innerHTML = `<i class="icon-close" /><span class="sr-only">${closeText || 'Close dealer details pop-up'}</span>`;
			this.containerDiv.appendChild(closeButtonElement);
			closeButtonElement.addEventListener(
				'click',
				(e) => {
					e.preventDefault();
					this.hide();
				},
				false,
			);

			// Optionally stop clicks, etc., from bubbling up to the map.
			Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
		}

		hide() {
			this.onRemove();
		}

		/** Called when the popup is added to the map. */
		onAdd() {
			this.getPanes()!.floatPane.appendChild(this.containerDiv);
		}

		/** Called when the popup is removed from the map. */
		onRemove() {
			if (this.containerDiv && this.containerDiv.parentElement) {
				this.containerDiv.parentElement.removeChild(this.containerDiv);
			}
		}

		/** Called each frame when the popup needs to draw itself. */
		draw() {
			const divPosition = this.getProjection().fromLatLngToDivPixel(
				this.position,
			)!;

			// Hide the popup when it is far out of view.
			const display =
				Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
					? 'block'
					: 'none';

			if (display === 'block') {
				this.containerDiv.style.left = `${divPosition.x}px`;
				this.containerDiv.style.top = `${divPosition.y}px`;
			}

			if (this.containerDiv.style.display !== display) {
				this.containerDiv.style.display = display;
			}
		}
	}

	return new Popup();
};

export interface IPopupProps {
	content?: string;
	map?: google.maps.Map;
	position?: google.maps.LatLng;
	closeText?: string;
}

const Popup: React.FC<IPopupProps> = ({
	content = '',
	position,
	map,
	closeText,
}) => {
	const [popup] = React.useState(map ? createPopup(closeText) : null);

	useEffect(
		() => () => {
			popup.setMap(null);
		},
		[popup],
	);

	if (!popup || !map) {
		return null;
	}

	if (popup && position && content) {
		popup.show(position, content);
		popup.setMap(map);

		//Center map with a y(-150) offset
		map.panTo(position);
		map.panBy(0, -100);
	} else {
		popup.setMap(null);
	}

	return null;
};

export default Popup;
