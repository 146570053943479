import React from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { IImage } from 'tbk-components/src/components/Image';
import { colorPalette } from '../../../../tailwind-colors';
import cn from 'classnames';
import uniqueIdHash from '../../../hooks/generateUniqueIdHash';
export interface IHeroTriangleMaskProps extends IBaseElementProps {
	image?: IImage;
}

const HeroTriangleMask: React.FC<IHeroTriangleMaskProps> = ({
	image,
	id = 'triangle-canvas-hero',
	className,
	classNames = [],
}) => {
	const idHash = uniqueIdHash();
	const imageCanvasMaskId = `image-canvas-mask-${idHash}`,
		clipPathId = `clippath-${idHash}`,
		maskId = `mask-${idHash}`;

	return (
		<svg
			id={id}
			xmlns="http://www.w3.org/2000/svg"
			version="1.1"
			viewBox="0 0 601 360"
			className={className || cn(...classNames)}
		>
			<defs>
				<clipPath id={clipPathId}>
					<rect
						className="clipping-path cls-2"
						fill="none"
						strokeWidth="0"
						x=".7"
						width="600.3"
						height="360"
					/>
				</clipPath>
				<mask
					id={maskId}
					x="-23.1"
					y="-.6"
					width="806.8"
					height="367.3"
					maskUnits="userSpaceOnUse"
				>
					<g data-name="mask0 2456 9254">
						<path
							className="cls-6"
							fill={colorPalette.white.DEFAULT}
							stroke={colorPalette.white.DEFAULT}
							strokeWidth="1px"
							d="M600.5,359.3H1.2V.5h599.3v358.8Z"
						/>
					</g>
				</mask>
			</defs>
			<mask id={imageCanvasMaskId}>
				<polygon
					className="cls-5"
					fill="#eee"
					stroke={colorPalette.light.DEFAULT}
					strokeMiterlimit="10"
					points="601 360 601 151.7 449.3 0 88.6 360.9 601 360"
				/>
			</mask>
			<image
				xlinkHref={image?.src}
				mask={`url(#${imageCanvasMaskId})`}
				width="85%"
				x="90"
				height="100%"
				preserveAspectRatio="xMidYMid slice"
			/>
			<g className="triangle-clipped-group">
				<g className="cls-1" clipPath={`url(#${clipPathId})`}>
					<g className="cls-4" mask={`url(#${maskId})`}>
						<g className="pathing-lines">
							<path
								className="cls-3"
								stroke={colorPalette.light.DEFAULT}
								strokeMiterlimit="10"
								fill="none"
								d="M705.1,255.8L449.3,0"
							/>
							<path
								className="cls-3"
								stroke={colorPalette.light.DEFAULT}
								strokeMiterlimit="10"
								fill="none"
								d="M605.1,366.5L449.3,0"
							/>
							<path
								className="cls-3"
								stroke={colorPalette.light.DEFAULT}
								strokeMiterlimit="10"
								fill="none"
								d="M-22.9,195.7L449.3,0"
							/>
							<path
								className="cls-3"
								stroke={colorPalette.light.DEFAULT}
								strokeMiterlimit="10"
								fill="none"
								d="M449.3,0L88.6,360.9,449.3,0l334.3,138.5"
							/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default HeroTriangleMask;
