import React from 'react';
import ImageToSVG from '../ImageToSVG';
import { twMerge } from 'tailwind-merge';
import useScreenSize from '../../hooks/useScreenSize';
import { IImage } from 'tbk-components/src/components/Image';

export interface IIconTextProps {
	text?: string;
	/**
	 * @noUI
	 */
	orientation?: 'horizontal' | 'vertical';
	/**
	 * @noUI
	 */
	borderColor?: string;
	/**
	 * @noUI
	 */
	iconColor?: string;
	/**
	 * @noUI
	 */
	textColor?: string;
	image?: IImage;
	/**
	 * @noUI
	 */
	nbItems?: number;
	largeDesktopGrid?: boolean;
}

const IconTextGrid: React.FC<IIconTextProps> = ({
	orientation,
	borderColor,
	iconColor,
	textColor,
	image,
	text,
	nbItems,
	largeDesktopGrid = false,
}) => {
	const screenWidth = useScreenSize();
	const isSmAndUp = screenWidth >= 640;
	const isLgAndUp = screenWidth >= 1024;
	const borderLogicXs =
		nbItems % 2 === 0
			? '[&:nth-last-child(-n+2)]:border-b-0'
			: '[&:nth-last-child(-n+1)]:border-b-0';

	const borderLogicSm =
		nbItems % 3 === 0
			? 'sm:[&:nth-last-child(-n+3)]:border-b-0'
			: nbItems % 3 === 2
				? 'sm:[&:nth-last-child(-n+2)]:border-b-0'
				: 'sm:[&:nth-last-child(-n+1)]:border-b-0';

	const borderLogicLg =
		nbItems % 5 === 0
			? 'lg:[&:nth-last-child(-n+5)]:border-b-0'
			: nbItems % 5 === 4
				? 'lg:[&:nth-last-child(-n+4)]:border-b-0'
				: nbItems % 5 === 3
					? 'lg:[&:nth-last-child(-n+3)]:border-b-0'
					: nbItems % 5 === 2
						? 'lg:[&:nth-last-child(-n+2)]:border-b-0'
						: 'lg:[&:nth-last-child(-n+1)]:border-b-0';
	return (
		<div
			className={twMerge(
				`flex ${orientation === 'horizontal' ? 'items-center justify-center' : 'flex-col items-center justify-center text-center'} gap-2 border-b ${borderColor} border-r px-6 pb-6 pt-6 last:border-b-0
			[&:nth-child(2n)]:border-r-0 sm:[&:nth-child(2n)]:border-r sm:[&:nth-child(3n)]:border-r-0
			${isLgAndUp && largeDesktopGrid ? borderLogicLg : isSmAndUp ? borderLogicSm : borderLogicXs}`,
				isLgAndUp && largeDesktopGrid && 'lg:!border-r',
				// If there are less than 5 items, remove the border on the last item
				isLgAndUp &&
					largeDesktopGrid &&
					nbItems < 5 &&
					`lg:[&:nth-child(${nbItems}n)]:!border-r-0`,
				largeDesktopGrid &&
					nbItems >= 5 &&
					'lg:[&:nth-child(5n)]:!border-r-0',
			)}
		>
			<div className={iconColor}>
				<ImageToSVG image={image} />
			</div>
			<div className={twMerge(textColor, 'font-body text-base')}>
				{text}
			</div>
		</div>
	);
};

export default IconTextGrid;
