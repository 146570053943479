import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { IImage } from 'tbk-components/src/components/Image';
import HeroTriangleMask from './Masks/Hero';
import LocatorAreaTriangleMask from './Masks/LocatorArea';
import WhyFuzionTriangleMask from './Masks/WhyFuzion';
import UpsideDownHeroTriangleMask from './Masks/UpsideDownHero';
import GetAQuoteTriangleMask from './Masks/GetAQuoteModal';

export interface ITriangleImageProps extends IBaseElementProps {
	image?: IImage;
	mask?:
		| 'Hero'
		| 'Hero Upside-Down'
		| 'Locator Area'
		| 'Why Fuzion'
		| 'Get A Quote Modal';
}

const TriangleImage: React.FC<ITriangleImageProps> = ({
	className,
	classNames = [],
	id,
	image,
	mask = 'Hero',
}) => {
	if (!image) return;

	switch (mask) {
		case 'Hero':
			return (
				<HeroTriangleMask
					id={id}
					className={
						className ||
						cn(
							'triangle-image',
							'triangle-image--hero',
							...classNames,
						)
					}
					image={image}
				/>
			);
		case 'Locator Area':
			return (
				<LocatorAreaTriangleMask
					id={id}
					className={
						className ||
						cn(
							'triangle-image',
							'triangle-image--locator',
							'-mt-36 md:mt-0',
							...classNames,
						)
					}
					image={image}
				/>
			);
		case 'Why Fuzion':
			return (
				<WhyFuzionTriangleMask
					id={id}
					className={
						className ||
						cn(
							'triangle-image',
							'triangle-image--why-fuzion',
							...classNames,
						)
					}
					image={image}
				/>
			);
		case 'Hero Upside-Down':
			return (
				<UpsideDownHeroTriangleMask
					id={id}
					className={
						className ||
						cn(
							'triangle-image',
							'triangle-image--upside-down-hero',
							...classNames,
						)
					}
					image={image}
				/>
			);
		case 'Get A Quote Modal':
			return (
				<GetAQuoteTriangleMask
					id={id}
					className={
						className ||
						cn(
							'triangle-image',
							'triangle-image--get-a-quote',
							...classNames,
						)
					}
					image={image}
				/>
			);
		default:
			return null;
	}
};

export default TriangleImage;
