import React, { useEffect, useRef, useState } from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import cn from 'classnames';
import Image, { IImage } from 'tbk-components/src/components/Image';
import Button, { IFuzionButtonDataProps, ILink } from '../Button';
import AdvancedMedia from '../AdvancedMedia';
import { twMerge } from 'tailwind-merge';
import Modal from 'tbk-components/src/components/Modal';
import Bullets, { IBullet } from '../Bullets';
import uniqueIdHash from '../../hooks/generateUniqueIdHash';
import CustomCarouselWithImagePreviews, {
	ICustomCarouselI18n,
} from './CustomCarouselWithImagePreviews';
import Icon from '../Icon';
import GetAQuote, { IGetAQuoteProps } from '../GetAQuote';
import { Generic } from 'react-structured-data';
import LazyJSONLD from '../LazyJSONLD';

export interface ISpec {
	label: string;
	value: string;
	href?: string;
}
export interface i18nCard {
	quickView?: string; //Quick View
	sku?: string; //SKU:
	compare?: string; //Compare
	carouselI18n?: ICustomCarouselI18n;
	productWidth?: string;
}

export interface IProductQuickView {
	fullSpecs?: ISpec[];
	detailLinks?: IBullet[];
	images?: IImage[];
	sku?: string;
	additionalDetailsLabel?: string;
	additionalDetailsExcerpt?: string;
	imageObjectFit?: 'cover' | 'contain';
}

export interface IFuzionButtonProps {
	id?: string;
	link?: ILink;
	data?: IFuzionButtonDataProps;
}

export interface IProductCardProps extends IBaseElementProps {
	i18n?: i18nCard;
	productId?: string | number;
	title?: string;
	subtitle?: string;
	excerpt?: string;
	imageCaption?: string;
	image?: IImage;
	hoverImage?: IImage;
	primaryButton?: ILink;
	textButton?: IFuzionButtonProps;
	sizeSpec?: ISpec;
	collectionSpec?: string;
	collectionName?: string;
	pageLink?: ILink;
	titleClassName?: string;
	productImageResizeWidth?: number;
	/**
	 * The number put here will put that amount of Dollar Signs on the card.
	 * It should ideally only be 1-4.
	 */
	dollarIndicator?: string; // This is provided by the API in the following format $, $$, $$$, $$$$
	productWidth?: string;
	enableQuickViewModal?: boolean;
	enableProductCompare?: boolean;
	/**
	 * This is here so that the parent component can control the state of the product card.
	 * And potentially maintain its state on page refresh.
	 */
	productForCompare?: boolean;
	onProductCompareChange?: Function;
	quickViewDetails?: IProductQuickView;
	/**
	 * This is for the compact version of the product card in header search results.
	 */
	compact?: boolean;
	stayAsColumn?: boolean;
	getAQuote?: IGetAQuoteProps;
	sku?: string;
	gtmListName?: string;
}

/**
 * Product Card
 * @block
 * @icon align-full-width
 */
const ProductCard: React.FC<IProductCardProps> = ({
	id,
	i18n,
	className,
	classNames = [],
	title,
	subtitle,
	imageCaption,
	primaryButton,
	getAQuote,
	textButton,
	sizeSpec,
	collectionSpec,
	collectionName,
	dollarIndicator,
	stayAsColumn = false,
	image,
	hoverImage,
	productWidth,
	titleClassName,
	pageLink,
	excerpt,
	enableProductCompare = false,
	enableQuickViewModal = false,
	productForCompare,
	quickViewDetails,
	compact = false,
	onProductCompareChange = () => {},
	productImageResizeWidth = 600,
	sku,
	gtmListName,
}) => {
	const [showModal, setShowModal] = useState(false);
	const [compressedImage, setCompressedImage] = useState<IImage | null>(
		image,
	);
	const [compressedHoverImage, setCompressedHoverImage] =
		useState<IImage | null>(hoverImage);

	const ref = useRef(null);

	const extractDetailLinkIntoButton = (detailLink: IBullet) => {
		//There is a small chance specific products only have one detail link.
		//It has been fully wired to use custom Markup and would be a chunky task for B/E to rewire everything based off a conditional.
		//Easier to extract it here.

		//Pull out the href, and label from bulletText
		const detailHref = detailLink.bulletText.match(/href=['"]([^'"]*)['"]/);
		const detailLabel = detailLink.bulletText.match(/>([^>]*)</);

		return (
			<Button
				buttonType="Primary"
				size="sm"
				icon={detailLink.bulletIcon}
				link={{ href: detailHref[1], label: detailLabel[1] }}
			/>
		);
	};

	useEffect(() => {
		//There is some CDN logic where if the image src is passed a width parameter, it will update the image size.
		//We are using this for optimization.
		if (image) {
			setCompressedImage({
				...image,
				src: `${image.src}?width=${productImageResizeWidth}`,
			});
		}
		if (hoverImage) {
			setCompressedHoverImage({
				...hoverImage,
				src: `${hoverImage.src}?width=${productImageResizeWidth}`,
			});
		}
	}, []);

	const staticImageRender = image && (
		<div className="relative overflow-hidden rounded-md">
			<AdvancedMedia
				size="1/1"
				rounded
				image={compressedImage}
				caption={imageCaption}
				captionPosition="top left"
			/>
			{hoverImage && (
				<div className="absolute inset-0 z-[1] opacity-0 transition-opacity group-focus-within:opacity-100 group-hover:opacity-100">
					<AdvancedMedia
						size="1/1"
						rounded
						image={compressedHoverImage}
						caption={imageCaption}
						captionPosition="top left"
					/>
				</div>
			)}
		</div>
	);
	const carouselGenId = `product-card-carousel-${uniqueIdHash()}`;

	const quickViewModal = (
		<Modal
			className="modal-product-quick-view"
			onCancel={() => {
				setShowModal(false);
			}}
			modalClassNames={[
				'max-w-[1600px]',
				'modal-dialog-centered',
				'w-full',
				'px-2',
				'py-4',
			]}
			headerClassNames={[]}
			bodyClassNames={[]}
			showCancel={false}
			showSubmit={false}
			trapFocus
		>
			<div className="product-quick-view-body relative w-full rounded-md bg-white  px-3 py-8 sm:px-5">
				<div className="container">
					<div className="absolute right-0 top-0 z-[1] mb-4 flex justify-end p-4">
						<button
							onClick={() => {
								setShowModal(null);
							}}
							className="flex items-center gap-3 text-sm text-primary transition-opacity hover:opacity-50"
						>
							{i18n?.quickView ?? 'Quick View'}
							<Icon
								classNames={['text-lg scale-150']}
								name="close"
							/>
						</button>
					</div>
					<div className="mb-6 mt-6 flex flex-col gap-3">
						{title && <h2 className="mb-0">{title}</h2>}
					</div>
					<div className="flex flex-col gap-5 md:flex-row md:gap-10 lg:gap-16">
						{quickViewDetails?.images?.length > 0 && (
							<div className="overflow-hidden md:mb-12 md:basis-1/2">
								{quickViewDetails?.images?.length > 1 ? (
									<CustomCarouselWithImagePreviews
										options={{
											type: 'slider',
											perView: 1,
										}}
										i18n={i18n?.carouselI18n}
										showImageBullets={true}
										showArrows={false}
										id={carouselGenId}
										classNames={['w-full relative']}
										imagePreviews={quickViewDetails?.images}
									>
										{quickViewDetails?.images?.map(
											(image, index) => (
												<li
													key={index}
													className="glide__slide block w-full"
												>
													<AdvancedMedia
														size="1/1"
														rounded
														image={image}
														classNames={[
															'block aspect-[3/2] h-auto w-full w-full rounded-md object-cover object-center',
														]}
													/>
												</li>
											),
										)}
									</CustomCarouselWithImagePreviews>
								) : (
									<Image
										{...quickViewDetails.images[0]}
										className={twMerge(
											'block aspect-[3/2] h-auto w-full rounded-md object-cover object-center',
											quickViewDetails.imageObjectFit ===
												'contain' && 'object-contain',
										)}
									/>
								)}
							</div>
						)}
						<div className="additional-details block md:basis-1/2">
							<div className="additional-details-head md:px-3">
								<h3 className="mb-5">
									{quickViewDetails?.additionalDetailsLabel ||
										'Additional Details'}
								</h3>
								{quickViewDetails?.sku && (
									<p className="mb-5 text-sm">
										<b>{i18n?.sku ?? 'SKU:'}</b>{' '}
										{quickViewDetails?.sku}
									</p>
								)}
								{quickViewDetails?.additionalDetailsExcerpt && (
									<p
										className="mb-8"
										dangerouslySetInnerHTML={{
											__html: quickViewDetails.additionalDetailsExcerpt,
										}}
									/>
								)}
							</div>
							{quickViewDetails?.fullSpecs?.length > 0 && (
								<ul className="m-0 list-none p-0">
									{quickViewDetails.fullSpecs.map(
										(spec, index) => (
											<li
												key={index}
												className={twMerge(
													'xs:text-body text-sm',
													index % 2 === 0
														? 'bg-light'
														: 'bg-transparent',
												)}
											>
												<p className="mb-0 flex gap-5 p-3 py-1 text-base">
													<span className="w-[120px] shrink-0 text-medium">
														{spec.label}:
													</span>
													<span>{spec.value}</span>
												</p>
											</li>
										),
									)}
								</ul>
							)}
							{(quickViewDetails?.detailLinks?.length > 0 ||
								(getAQuote?.formId && getAQuote?.children)) && (
								<div
									className={twMerge(
										'mt-8',
										quickViewDetails?.detailLinks?.length >
											1
											? 'inline-block rounded-md bg-primary p-4 px-6 !text-white'
											: 'mb-4',
									)}
								>
									{quickViewDetails?.detailLinks?.length >
										0 &&
										(quickViewDetails?.detailLinks?.length >
										1 ? (
											<Bullets
												iconColor="light"
												className="bullets flex flex-col gap-2 [&+div]:mt-4"
												bulletTextClassNames={[
													'[&_a]:text-white [&_a]:no-underline [&_a]:text-sm [&_a]:transition-opacity [&_a]:hover:opacity-50 mb-0',
												]}
												bullets={
													quickViewDetails.detailLinks
												}
											/>
										) : (
											extractDetailLinkIntoButton(
												quickViewDetails.detailLinks[0],
											)
										))}
									{getAQuote?.formId &&
										getAQuote?.children && (
											<GetAQuote
												{...getAQuote}
												toggleButton={{
													...getAQuote.toggleButton,
													icon: 'storefront',
													className: twMerge(
														'flex flex-row-reverse text-sm gap-4 [&_i]:text-[1.5rem] hover:[&>span]:opacity-50 focus:[&>span]:opacity-50',
														quickViewDetails
															?.detailLinks
															?.length > 0
															? 'mt-3'
															: 'btn btn-primary btn-sm',
													),
												}}
											/>
										)}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);

	const imageViewRender = () => {
		if (!image) return;

		const staticWrapperClasses =
			'card-wrapper block h-full w-full max-w-[121px] sm:max-w-[156px] max-sm:w-[156px] md:max-w-none shrink-0';

		return (
			<div className="group relative md:w-full">
				{pageLink?.href ? (
					<a
						href={pageLink.href}
						data-sku={sku}
						data-title={title}
						data-subtitle={subtitle}
						data-list_name={gtmListName}
						data-collection={collectionName}
						className={twMerge(
							staticWrapperClasses,
							!hoverImage
								? 'transition-opacity hover:opacity-75 focus:opacity-75'
								: 'group',
							`${compact && 'max-w-[64px] lg:max-w-full'}`,
						)}
					>
						{staticImageRender}
					</a>
				) : (
					<div className={twMerge(staticWrapperClasses)}>
						{staticImageRender}
					</div>
				)}
				<div className="absolute bottom-0 z-[1] mb-3 w-full px-2 transition-opacity group-focus-within:opacity-100 group-hover:opacity-100 sm:text-center md:opacity-0">
					{enableQuickViewModal && (
						<button
							className="hover:filter-brightness-90 transition-filter btn btn-sm btn-secondary mx-auto !bg-tertiary max-sm:!p-3 max-sm:!text-[18px]"
							onClick={() => {
								setShowModal(true);
							}}
						>
							<span className="hidden sm:inline-block">
								{i18n?.quickView ?? 'Quick View'}
							</span>
							<Icon name="eye" classNames={['sm:hidden']} />
						</button>
					)}
				</div>
			</div>
		);
	};
	return (
		<div
			id={id}
			className={
				className || cn('product-card relative block ', ...classNames)
			}
			ref={ref}
		>
			<LazyJSONLD>
				<Generic
					type="page"
					jsonldtype="Product"
					schema={{ '@type': 'Product',
						name: title,
						description: excerpt,
						image: image?.src,
						category: subtitle,
						sku: sku,
						brand: {
							'@type': 'Brand',
							name: 'Fuzion Flooring',
						},
						offers: {
							'@type': 'Offer',
							url: pageLink?.href,
							price: 5,
							priceCurrency: "CAD",
							itemCondition: 'https://schema.org/NewCondition',
							availability: 'https://schema.org/InStock'
						},
					  }}
				/>
			</LazyJSONLD>

			<div
				className={`product-card-inner ${compact && 'flex items-start justify-start gap-4 lg:block'}`}
			>
				<div
					className={`product-card-copy ${compact ? 'lg:pt-2' : 'pt-2'}`}
				>
					<div
						className={twMerge(
							stayAsColumn ? 'flex-col' : 'flex-row',
							'flex items-center gap-4 md:flex-col md:gap-0',
						)}
					>
						{imageViewRender()}

						<div className="card-content pt-2">
							{(title || subtitle) &&
								React.createElement(
									pageLink?.href ? 'a' : 'div',
									{
										className: twMerge(
											'flex flex-col',
											compact
												? 'text-left lg:text-center'
												: 'mb-4 text-left md:text-center',
											pageLink?.href &&
												'text-decoration-none text-inherit group',
											'information',
										),
										href: pageLink?.href,
										'data-sku': sku,
										'data-title': title,
										'data-subtitle': subtitle,
										'data-collection': collectionName,
										'data-list_name': gtmListName,
										children: (
											<>
												{title && (
													<h4
														className={
															titleClassName ||
															twMerge(
																'h3 mb-0 capitalize text-primary',
																compact &&
																	'text-lg leading-none',
																pageLink?.href &&
																	'transition-opacity group-hover:opacity-50 group-focus:opacity-50',
															)
														}
													>
														{title}
													</h4>
												)}
												{subtitle && (
													<p
														className={twMerge(
															'mb-0 text-left text-sm text-primary md:text-center',
															compact && 'mt-1',
															pageLink?.href &&
																'transition-opacity group-hover:opacity-50 group-focus:opacity-50',
														)}
													>
														{subtitle}
													</p>
												)}
												{excerpt && (
													<p
														className={twMerge(
															'mb-0 mt-1 text-sm text-primary',
															compact
																? 'mt-0'
																: 'mt-1',
															pageLink?.href &&
																'transition-opacity group-hover:opacity-50 group-focus:opacity-50',
														)}
													>
														{excerpt}
													</p>
												)}
												{collectionSpec && !compact && (
													<p
														className={twMerge(
															'mb-0 mt-2 inline gap-2 text-sm font-light leading-none text-medium transition-opacity',
															pageLink?.href &&
																'group-hover:opacity-50 group-focus:opacity-50',
														)}
													>
														{collectionSpec}
													</p>
												)}
												{sku && compact && (
													<p
														className={twMerge(
															'mb-0 text-left text-sm text-medium md:text-center',
															compact && 'mt-1',
															pageLink?.href &&
																'transition-opacity group-hover:opacity-50 group-focus:opacity-50',
														)}
													>
														{sku}
													</p>
												)}
											</>
										),
									},
								)}

							{(primaryButton || getAQuote || textButton) && (
								<div className="button-wrapper xxl:justify-between flex flex-wrap content-center items-center justify-start gap-3 md:justify-center">
									{primaryButton && (
										<Button
											buttonType="Secondary"
											size="sm"
											link={primaryButton}
											classNames={[
												'max-sm:!bg-transparent max-sm:border-secondary max-sm:border',
											]}
										/>
									)}
									{getAQuote?.formId &&
										getAQuote?.children && (
											<GetAQuote
												className="max-sm:[&_button]:border max-sm:[&_button]:border-secondary max-sm:[&_button]:!bg-transparent"
												{...getAQuote}
											/>
										)}
									{textButton && (
										<Button
											id={textButton.id}
											buttonType="Secondary"
											size="underline"
											classNames={[
												'!text-medium !text-sm',
											]}
											link={textButton.link}
											data={textButton.data}
										/>
									)}
								</div>
							)}
						</div>
					</div>
					{(sizeSpec ||
						dollarIndicator ||
						productWidth ||
						enableProductCompare) && (
						<div className="align-center mt-4 flex flex-wrap items-center gap-2 border-b border-t border-tertiary py-2">
							{dollarIndicator && (
								<div className="flex gap-0.5">
									{Array.from(
										{ length: dollarIndicator.length },
										(_, i) => (
											<span
												key={i}
												className="relative mb-0  p-2 px-2 text-xs leading-none text-primary"
											>
												$
												<span className="absolute left-2/4 top-2/4 h-[20px] w-[20px] -translate-x-2/4 -translate-y-2/4 rounded-full border border-tertiary"></span>
											</span>
										),
									)}
								</div>
							)}

							{(sizeSpec ||
								productWidth ||
								enableProductCompare) && (
								<div className="flex flex-grow flex-row-reverse items-center justify-between gap-2">
									{enableProductCompare && (
										<div className="flex items-center gap-2">
											<span className="-translate-y-[1px] text-sm">
												{i18n?.compare || 'Compare'}
											</span>
											<input
												type="checkbox"
												checked={productForCompare}
												onChange={(e) =>
													onProductCompareChange(e)
												}
												aria-label="Compare"
											/>
										</div>
									)}
									{productWidth && (
										<p
											className={twMerge(
												'mb-0 text-sm leading-none text-medium',
											)}
										>
											{i18n?.productWidth ?? 'Width'}:{' '}
											{productWidth}
										</p>
									)}
									{sizeSpec && (
										<div className="flex gap-2">
											<span className="text-sm leading-none text-medium">
												{sizeSpec.label}
											</span>
											<span className="text-sm leading-none text-primary">
												{sizeSpec.value}
											</span>
										</div>
									)}
								</div>
							)}
						</div>
					)}
				</div>
			</div>
			{showModal && quickViewModal}
		</div>
	);
};

export default ProductCard;
