import React, { useEffect, useRef } from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Button, { ILink, IFuzionButtonProps } from '../Button';
import Image, { IImage } from 'tbk-components/src/components/Image';
import { kebabCase } from 'lodash';
import CategoryListings from '../FeaturedCategories/CategoryListings';
import { twMerge } from 'tailwind-merge';

export interface IBoxNavProps {
	/**
	 * Enter a comma separated list. Add class names to assign box background styles. For example: 'bg-gradient-engineered-hardwood', 'bg-gradient-overlay'.
	 */
	classNames?: string[];
	link?: ILink;
	navLabelPosition?: 'top' | 'bottom';
	/**
	 * The image to be shown as the background of the box nav.
	 */
	featuredImage?: IImage;
	/**
	 * The image to be shown as the graphic watermark of the box nav.
	 */
	graphic?: IImage;
	graphicClassNames?: string[];
	/**
	 * The image to be shown as the hover effect of the box nav.
	 */
	hoverImage?: IImage;
}

export interface ISubCategoryProps {
	sampleImage?: IImage;
	link?: ILink;
}

export interface ICategoryProps {
	catName?: string;
	link?: ILink;
	subCategories?: ISubCategoryProps[];
}

interface IMegaMenuI18nProps {
	back?: string;
}

export interface IMegaMenuProps extends IBaseElementProps {
	boxNavs?: IBoxNavProps[];
	categoryTitle?: string;
	categoryTitleMobile?: string;
	categories?: ICategoryProps[];
	masterLink?: ILink;
	buttonLink?: IFuzionButtonProps;
	i18n?: IMegaMenuI18nProps;
}

export const closeMenu = () => {
	const shownMenu = document.querySelector('.dropdown-menu.mega-menu.show');

	if (!shownMenu) return;

	(shownMenu.previousElementSibling as HTMLButtonElement)?.click();
};

const changeState = (target, self, height, action) => {
	if (action === 'open') {
		target.classList.remove('invisible');
		target.style.maxHeight = `${height}px`;
		target.setAttribute('aria-hidden', 'false');
		self.setAttribute('aria-expanded', 'true');
	} else {
		target.classList.add('invisible');
		target.style.maxHeight = '0';
		target.setAttribute('aria-hidden', 'true');
		self.setAttribute('aria-expanded', 'false');
	}
};

/**
 * Mega Menu
 * @block
 * @icon menu
 */
const MegaMenu: React.FC<IMegaMenuProps> = ({
	id,
	className,
	classNames = [],
	boxNavs = [],
	categoryTitle,
	categoryTitleMobile,
	categories = [],
	masterLink,
	buttonLink,
	i18n,
}) => {
	const ref = useRef(null);
	const listRef = useRef(null);

	useEffect(() => {
		if (!listRef.current) return;

		const btns = listRef?.current?.querySelectorAll(
			'li > button',
		) as NodeListOf<HTMLButtonElement>;
		if (!btns.length) return;

		/**
		 * Reset both buttons and subcategories to their initial state
		 */
		const toggleReset = () => {
			btns.forEach((btn) => {
				const targetUl = btn.nextElementSibling.querySelector(
					'ul',
				) as HTMLUListElement;

				changeState(targetUl, btn, 0, 'close');
			});
		};

		/**
		 * The category menus work as accordion on mobile and as tab on desktop.
		 * Bootstrap does not provide a native way to handle this, so we need to
		 * implement it manually.
		 */
		const handleClick = (e) => {
			e.stopPropagation();

			const me = e.currentTarget as HTMLButtonElement;
			const subCat = me.nextElementSibling.querySelector(
				'ul',
			) as HTMLUListElement;
			const sH = subCat.scrollHeight;

			if (window.innerWidth < 1024) {
				toggleReset();

				if (me.getAttribute('aria-expanded') === 'false') {
					changeState(subCat, me, sH, 'open');
				} else if (me.getAttribute('aria-expanded') === 'true') {
					changeState(subCat, me, 0, 'close');
				}
			} else if (me.getAttribute('aria-expanded') === 'false') {
				toggleReset();

				/**
				 * Open the clicked subcategory with a delay to let previous subcategories
				 * close before the new one opens.
				 */
				setTimeout(() => {
					changeState(subCat, me, sH, 'open');
				}, 300);
			}
		};

		const eventHandlers = [];

		for (let i = 0; i < btns.length; i++) {
			btns[i].addEventListener('click', handleClick);
			/**
			 * Push the added event listeners to the eventHandlers array to be removed later
			 */
			eventHandlers.push([btns[i], 'click', handleClick]);
		}

		const openFirstSubCategory = () => {
			/**
			 * Open the first subcategory on desktop
			 */
			if (window.innerWidth >= 1024) {
				const targetUl = btns[0].nextElementSibling.querySelector(
					'ul',
				) as HTMLUListElement;
				const sH = targetUl.scrollHeight;

				changeState(targetUl, btns[0], sH, 'open');
			}
		};

		const ddToggle = ref?.current
			?.closest('.dropdown-mega-menu')
			?.querySelector('.dropdown-toggle');

		if (ddToggle) {
			ddToggle.addEventListener(
				'shown.bs.dropdown',
				openFirstSubCategory,
			);
			ddToggle.addEventListener('hidden.bs.dropdown', toggleReset);
		}

		/**
		 * Close the menu when a link is clicked
		 */
		const validLinks = document.querySelectorAll('a[href]:not([href="#"])');

		for (let i = 0; i < validLinks.length; i++) {
			validLinks[i].addEventListener('click', closeMenu);
			eventHandlers.push([validLinks[i], 'click', closeMenu]);
		}

		return () => {
			for (const i of eventHandlers) {
				const [el, eventName, handler] = i;
				el.removeEventListener(eventName, handler);
			}

			if (ddToggle) {
				ddToggle.removeEventListener(
					'shown.bs.dropdown',
					openFirstSubCategory,
				);
				ddToggle.removeEventListener('hidden.bs.dropdown', toggleReset);
			}
		};
	}, []);

	return (
		<div
			id={id}
			className={
				className ||
				twMerge('mega-menu__container text-primary', ...classNames)
			}
			ref={ref}
		>
			<div className="container p-4 md:px-8 lg:flex lg:items-start lg:justify-between lg:gap-8 lg:py-10 2xl:gap-16">
				<button
					className="mb-4 flex items-center justify-center gap-2 text-sm lg:hidden"
					onClick={closeMenu}
				>
					<i className="icon-chevron-left text-[10px]" />
					{i18n?.back || 'Back'}
				</button>
				<CategoryListings categories={boxNavs} />
				<div className="mt-8 lg:mt-0 lg:grow">
					{categoryTitleMobile && (
						<span className="block font-bodyBold text-base text-primary lg:hidden">
							{categoryTitleMobile}
						</span>
					)}
					{categoryTitle && (
						<span
							className={`block font-bodyBold text-base text-primary ${categoryTitleMobile && '!hidden lg:!block'}`}
						>
							{categoryTitle}
						</span>
					)}
					{categories?.length > 0 && (
						<ul
							className="mega-menu__categories-list mt-4 border-b border-secondary lg:relative lg:mt-2 lg:border-b-0"
							ref={listRef}
						>
							{categories.map((category, index) => (
								<li
									key={index}
									className="lg:flex lg:items-start lg:justify-start lg:gap-4 2xl:gap-8"
								>
									<Button
										className="group flex w-full shrink-0 grow-0 items-center justify-between border-t border-secondary py-2 text-start hover:bg-light focus:bg-light md:text-base lg:basis-1/2 lg:border-b lg:border-transparent lg:py-3 lg:leading-none lg:aria-expanded:border-secondary"
										link={category.link}
										aria-expanded="false"
										aria-controls={`collapse-${kebabCase(category.catName)}`}
									>
										{/* This will become an anchor link only if `category.link` is provided.
										`category.link.label` will be rendered as the link text rather than `category.catName`.
										`category.catName` will be rendered as the link text if `category.link` is not provided. */}
										{!category?.link?.href &&
											category?.subCategories?.length >
												0 && (
												<>
													{category.catName}
													<i className="icon-chevron-down text-[10px] transition-all duration-300 ease-in-out group-aria-expanded:rotate-180 lg:rotate-[-90deg] lg:text-[1rem] lg:group-aria-expanded:rotate-[-90deg]"></i>
												</>
											)}
									</Button>
									{category?.subCategories && (
										<div className="lg:w-1/2">
											<ul
												id={`collapse-${kebabCase(category.catName)}`}
												className="mega-menu__sub-categories-list invisible max-h-0 overflow-hidden transition-all duration-300 ease-in-out lg:absolute lg:top-0"
												aria-hidden="true"
											>
												{category.subCategories.map(
													(subCategory, index) => (
														<li
															key={index}
															className={`flex items-center justify-start gap-1 ${subCategory?.sampleImage?.src ? 'mb-3 lg:mb-2' : 'mb-0 last:mb-4'}`}
														>
															{subCategory
																?.sampleImage
																?.src && (
																<img
																	src={
																		subCategory
																			.sampleImage
																			.src
																	}
																	alt={
																		subCategory
																			.sampleImage
																			.alt
																	}
																	className="!h-8 w-8 overflow-hidden rounded-full object-cover"
																/>
															)}
															<Button
																link={
																	subCategory.link
																}
																className={`border-b border-transparent text-sm leading-[32px] text-primary hover:border-secondary focus:border-secondary lg:py-3 lg:text-[1rem] lg:leading-none`}
															/>
														</li>
													),
												)}
											</ul>
										</div>
									)}
								</li>
							))}
						</ul>
					)}
					{masterLink?.href && (
						<Button
							link={masterLink}
							size="underline"
							className="inline-flex items-center justify-between py-3 text-start font-bodyBold hover:underline focus:underline md:text-base lg:w-auto lg:basis-1/2 lg:border-b lg:border-transparent lg:leading-none lg:aria-expanded:border-secondary [&_span]:border-b-0"
						/>
					)}
					{masterLink?.href && buttonLink?.link?.href && <br />}
					{buttonLink?.link?.href && (
						<>
							<Button
								{...buttonLink}
								buttonType="Secondary"
								size="sm"
								classNames={[
									'inline-flex',
									'flex-row-reverse',
									'items-center',
									'justify-between',
									'gap-2',
									'!py-1',
									'mt-5',
									'[&_i]:text-2xl',
									'[&_i]:leading-none',
								]}
							/>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default MegaMenu;
