import React, { useEffect } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Button, { ILink } from '../Button';
import Carousel, { CarouselItem } from 'tbk-components/src/components/Carousel';
import Image, { IImage } from 'tbk-components/src/components/Image';
import uniqueIdHash from '../../hooks/generateUniqueIdHash';
import ProductCard, { IProductCardProps } from '../ProductCard';
import ProductCards from '../ProductCards';
import { twMerge } from 'tailwind-merge';
import { ICustomCarouselI18n } from '../ProductCard/CustomCarouselWithImagePreviews';

export interface IRelatedProductsProps extends IBaseElementProps {
	heading?: string;
	subheading?: string;
	cta?: ILink;
	productCards?: IProductCardProps[];
	gtmListName?: string;
	productCompareLink?: ILink;
	i18n?: i18nCards;
}
interface i18nCards {
	compareProducts?: string;
	viewComparison?: string;
	hideComparison?: string;
	comparisonLimitDisclaimer?: string; // Compare up to 3 products
	quickView?: string; //Quick View
	sku?: string; //SKU:
	compare?: string; //Compare
	carouselI18n?: ICustomCarouselI18n;
	productWidth?: string;
}

/**
 * Related Products
 * @block
 * @icon slides
 */
const RelatedProducts: React.FC<IRelatedProductsProps> = ({
	id,
	heading,
	subheading,
	cta,
	productCards = [],
	gtmListName,
	className,
	classNames = [],
	productCompareLink,
	i18n,
}) => {

	// fire the GTM event
	// @ts-ignore
	gtmViewItemList( gtmListName, productCards );

	return (
		<div
			id={id}
			className={className || twMerge('related-products', ...classNames)}
		>
			<div className="relative mx-auto block max-w-[1375px] px-4 py-20 md:px-6">
				<div className="flex flex-col items-start justify-between gap-x-5 gap-y-3 md:flex-row">
					<div className="block flex flex-col gap-4">
						{heading && <h2 className="mb-0">{heading}</h2>}
						{subheading && (
							<p className="subheading mb-0 max-w-[672px]">
								{subheading}
							</p>
						)}
					</div>
					{cta && (
						<Button
							link={cta}
							buttonType="Primary"
							classNames={['mt-4 flex-shrink-0']}
						/>
					)}
				</div>
				{productCards?.length > 0 && (
					<ProductCards
						classNames={['mt-10 md:mt-8']}
						productCards={productCards}
						gtmListName={gtmListName}
						productCompareLink={productCompareLink}
						i18n={i18n}
					/>
				)}
			</div>
		</div>
	);
};

export default RelatedProducts;
