import React from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import cn from 'classnames';
import { ISpec } from '../../ProductCard';
import PriceRangeIndicator from '../../PriceRangeIndicator';
import { twMerge } from 'tailwind-merge';
export interface IFeaturesTabProps extends IBaseElementProps {
	tabLabel?: string;
	/*
	 * For Price Range Spec, just use dollar signs ($$$) to represent the price range.
	 */
	specs?: ISpec[];
}

const generatedSpecValue = (value: string) => {
	//If value has just dollar signs
	if (String(value).match(/^\$+$/)) {
		return <PriceRangeIndicator priceRange={value} />;
	}
	return value;
};

const FeaturesTab: React.FC<IFeaturesTabProps> = ({
	id,
	className,
	classNames = [],
	specs = [],
}) => {
	return (
		<div
			id={id}
			className={
				className ||
				cn(
					'features-tab grid grid-cols-1 gap-x-5 gap-y-6 md:grid-cols-2',
					...classNames,
				)
			}
		>
			{specs?.length > 0 &&
				specs.map((spec, index) => (
					<div className="spec flex gap-6 text-[14px]" key={index}>
					{spec?.label && (
						<span className="spec__title mb-0 min-w-[125px] max-w-[125px] text-medium">
							{spec.label}
						</span>
					)}
					{spec?.value && (
						React.createElement(
							spec.href ? 'a' : 'span',
							{
								className: twMerge(
									'mb-0 text-[11px] text-sm lg:text-[14px]',
									spec.href &&
										'text-decoration-none text-inherit transition-opacity hover:opacity-75 focus:opacity-75',
								),
								href: spec.href,
								children: (
									<>
										{generatedSpecValue(spec.value)}
									</>
								),
							},
						)
					)}
					</div>
				))}
		</div>
	);
};

export default FeaturesTab;
