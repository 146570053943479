import React from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { colorPalette } from '../../../tailwind-colors';
import cn from 'classnames';

export interface ILineAccentProps extends IBaseElementProps {
	color?: 'primary' | 'secondary' | 'white' | 'tertiary' | 'primary-medium';
	verticalFlipped?: boolean;
	horizontalFlipped?: boolean;
}

const LineAccent: React.FC<ILineAccentProps> = ({
	id,
	className,
	classNames = [],
	horizontalFlipped = false,
	verticalFlipped = false,
	color = 'primary',
}) => {
	const strokeColor = colorPalette[color].DEFAULT
		? colorPalette[color].DEFAULT
		: colorPalette.primary.DEFAULT;

	return (
		<svg
			id={id}
			className={
				className ||
				cn(
					`line-accent`,
					horizontalFlipped && '-scale-x-[1]',
					verticalFlipped && '-scale-y-[1]',
					...classNames,
				)
			}
			width="566"
			height="349"
			viewBox="0 0 566 349"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_4333_6126)">
				<path
					d="M239.148 250.482L-11.0115 0.322449"
					stroke={strokeColor}
					strokeMiterlimit="10"
				/>
				<path
					d="M-11.0115 500.643L239.148 250.483"
					stroke={strokeColor}
					strokeMiterlimit="10"
				/>
				<path
					d="M103.768 577.333L239.148 250.483"
					stroke={strokeColor}
					strokeMiterlimit="10"
				/>
				<path
					d="M239.15 250.483L566 115.103"
					stroke={strokeColor}
					strokeMiterlimit="10"
				/>
				<path
					d="M239.15 250.483L566 385.873"
					stroke={strokeColor}
					strokeMiterlimit="10"
				/>
				<path
					d="M-87.7015 115.102L239.148 250.482"
					stroke={strokeColor}
					strokeMiterlimit="10"
				/>
			</g>
			<defs>
				<clipPath id="clip0_4333_6126">
					<rect
						width="566"
						height="349"
						fill="white"
						transform="matrix(-1 0 0 -1 566 349)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default LineAccent;
