import React from 'react';
import Button from '../Button';
import { useTranslation } from 'react-i18next';
import { ILink } from '../Button';

export interface IIndexSearchHeadingI18nProps {
	allProductsTagged?: string;
	resultDisplayed?: string;
	resultsDisplayed?: string;
	jumpTo?: string;
	resources?: string;
	blogs?: string;
	of?: string;
}

export interface IIndexSearchHeadingProps {
	searchKeyword?: string;
	numOfProducts?: number;
	numOfResources?: number;
	numOfBlogs?: number;
	endRecord?: number;
	tryOurFlooringMatchmaker?: ILink;
	viewAllProducts?: ILink;
	i18n?: IIndexSearchHeadingI18nProps;
}

const IndexSearchHeading: React.FC<IIndexSearchHeadingProps> = ({
	searchKeyword,
	numOfProducts,
	numOfResources,
	numOfBlogs,
	endRecord,
	tryOurFlooringMatchmaker,
	viewAllProducts,
	i18n,
}) => {
	const { t } = useTranslation();
	const num_non_products = (numOfResources ?? 0) + (numOfBlogs ?? 0);
	const num_results = (numOfProducts ?? 0) + num_non_products;

	let displayRecordRange = '';
	let displayResultsText = ` ${i18n?.resultsDisplayed || t('Results Displayed')}`;
	if (num_results === 1) {
		displayResultsText = ` ${i18n?.resultDisplayed || t('Result Displayed')}`;
	}
	if (searchKeyword) {
		displayRecordRange = `<strong>${numOfProducts}</strong> ${i18n?.of || t('of')} ${endRecord}`;
	} else {
		displayRecordRange = `<strong>${num_results}</strong> ${i18n?.of || t('of')} ${num_non_products + endRecord}`;
	}
	return (
		<div className="index-search-heading__container">
			{searchKeyword && (
				<div className="bg-light">
					<div className="container p-4 md:px-8 lg:pb-10 lg:pt-20">
						<h1 className="h2 m-0 lg:text-4xl">
							{i18n?.allProductsTagged ||
								t('All products tagged ')}
							<strong>{`"${searchKeyword}"`}</strong>
						</h1>
					</div>
				</div>
			)}
			<div className="container p-4 md:px-8 lg:flex lg:items-center lg:justify-between">
				<span className="block text-sm text-primary lg:text-base">
					<span
						dangerouslySetInnerHTML={{ __html: displayRecordRange }}
					/>
					{displayResultsText}
				</span>
				{!searchKeyword && (numOfResources > 0 || numOfBlogs > 0) && (
					<div className="hidden lg:flex lg:items-center lg:justify-between lg:gap-2">
						<span className="block text-sm text-primary lg:text-base">
							{i18n?.jumpTo || t('Jump to:')}
						</span>
						{numOfResources > 0 && (
							<Button
								link={{
									label: i18n?.resources || t('Resources'),
								}}
								onClick={() => {
									const resources =
										document.getElementById('resources');
									if (resources) {
										resources.scrollIntoView({
											behavior: 'smooth',
										});
									}
								}}
								classNames={['btn', 'btn-sm', 'btn-outline']}
							/>
						)}
						{numOfBlogs > 0 && (
							<Button
								link={{
									label: i18n?.blogs || t('Blogs'),
								}}
								onClick={() => {
									const blogs =
										document.getElementById('blogs');
									if (blogs) {
										blogs.scrollIntoView({
											behavior: 'smooth',
										});
									}
								}}
								buttonType="Outline"
								size="sm"
							/>
						)}
					</div>
				)}
				{searchKeyword && (
					<div className="hidden lg:flex lg:items-center lg:justify-between lg:gap-2">
						{tryOurFlooringMatchmaker?.href && (
							<Button
								link={tryOurFlooringMatchmaker}
								buttonType="Primary"
								size="sm"
							/>
						)}
						{viewAllProducts?.href && (
							<Button
								link={viewAllProducts}
								buttonType="Secondary"
								size="sm"
							/>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default IndexSearchHeading;
