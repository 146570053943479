import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';
import { twMerge } from 'tailwind-merge';

const BlogRollPagination = ({
	page,
	perPage,
	total,
	onChange,
	className = 'blogroll-pagination mt-12',
	hideLastPage = true,
	topRef,
}) => {
	const { t } = useTranslation();

	const totalPages = Math.ceil(total / perPage);
	if (isNaN(totalPages) || totalPages <= 1) {
		return null;
	}
	const pageNumbers = [];
	for (let p = 1; p <= totalPages; p++) {
		pageNumbers.push(p);
	}
	const ellipsis = '...';

	// make a maximum of 7 pages, with ellipsis as placeholders for the others.
	if (pageNumbers.length > 7) {
		switch (true) {
			case page <= 3:
				pageNumbers.splice(4, pageNumbers.length - 5, ellipsis);
				break;
			case page >= totalPages - 3:
				pageNumbers.splice(1, pageNumbers.length - 6, ellipsis);
				break;
			default:
				pageNumbers.splice(1, page - 3, ellipsis); // splice
				pageNumbers.splice(5, pageNumbers.length - 6, ellipsis);
		}
		/*
		 * Vanilla Elasticsearch cacks if trying to find records past 10,000.  This just makes that harder to get to
		 * becuase it takes the last page number out of the list.
		 */
		if (hideLastPage && total > 10000) {
			pageNumbers.pop();
		}
	}

	//@ts-ignore
	window.scrollToTop = () => {
		setTimeout(() => {
			scrollTo({
				top: topRef.current.offsetTop - 32, // go to top of search results -2rem to account for possible overlap with sticky header
			});
		}, 0);
	};

	const goToPage = (e, next) => {
		e.preventDefault();
		e.stopPropagation();
		onChange(isNaN(next) ? 1 : parseInt(next, 10));
		//@ts-ignore
		window.scrollToTop();
	};

	return (
		<div className={classNames([className])}>
			<ul className="pagination flex items-center justify-center md:gap-3">
				<li
					className={
						page <= 1
							? 'disabled first-page-btn opacity-50'
							: 'first-page-btn'
					}
				>
					<button
						className="next group relative rounded-full p-2 px-1 transition-colors duration-200 ease-in-out max-sm:text-[12px] md:p-4"
						disabled={page <= 1}
						onClick={(e) => goToPage(e, 1)}
						aria-label={t('Next')}
					>
						<Icon
							name="caret-double-left"
							classNames={['z-[1] relative mt-[2px] block']}
						/>
						{page !== 1 && (
							<span className="absolute left-0 left-2/4 top-0 top-2/4 z-0 h-[24px] w-[24px] -translate-x-2/4 -translate-y-2/4 rounded-full bg-secondary opacity-0 transition-opacity group-hover:opacity-100 md:h-[48px] md:w-[48px]"></span>
						)}
					</button>
				</li>
				<li
					className={
						page <= 1 ? 'disabled prev-btn opacity-50' : 'prev-btn'
					}
				>
					<button
						disabled={page <= 1}
						className="previous group relative rounded-full p-2 px-1 transition-colors duration-200 ease-in-out max-sm:text-[12px] md:p-4"
						onClick={(e) => goToPage(e, page - 1)}
						aria-label={t('Previous')}
					>
						<Icon
							name="chevron-left"
							classNames={['z-[1] relative mt-[2px] block']}
						/>
						{page !== 1 && (
							<span className="absolute left-0 left-2/4 top-0 top-2/4 z-0 h-[24px] w-[24px] -translate-x-2/4 -translate-y-2/4 rounded-full bg-secondary opacity-0 transition-opacity group-hover:opacity-100 md:h-[48px] md:w-[48px]"></span>
						)}
					</button>
				</li>
				{pageNumbers.length > 0 &&
					pageNumbers.map((pageNumber, i) => (
						<li
							key={i}
							className={classNames({
								disabled: pageNumber === ellipsis,
								active: pageNumber === page,
							})}
						>
							<button
								disabled={pageNumber === ellipsis}
								onClick={(e) => goToPage(e, pageNumber)}
								className="group relative rounded-full p-3 text-base transition-colors duration-200 ease-in-out max-sm:text-[12px] sm:p-4"
							>
								<span className="relative z-[1] block">
									{pageNumber}
								</span>
								{pageNumber !== ellipsis && (
									<span
										className={twMerge(
											'absolute left-0 left-2/4 top-0 top-2/4 z-0 h-[24px] w-[24px] -translate-x-2/4 -translate-y-2/4 rounded-full bg-secondary transition-opacity group-hover:opacity-100 md:h-[48px] md:w-[48px]',
											pageNumber === page
												? 'opacity-100'
												: 'opacity-0',
										)}
									></span>
								)}
							</button>
						</li>
					))}
				<li
					className={
						page === totalPages
							? 'disabled next-btn opacity-50'
							: 'next-btn'
					}
				>
					<button
						className="next group relative rounded-full p-2 px-1 transition-colors duration-200 ease-in-out max-sm:text-[12px] md:p-4"
						disabled={page >= totalPages}
						onClick={(e) => goToPage(e, page + 1)}
						aria-label={t('Next')}
					>
						<Icon
							name="chevron-right"
							classNames={['z-[1] relative mt-[2px] block']}
						/>
						<span className="absolute left-0 left-2/4 top-0 top-2/4 z-0 h-[24px] w-[24px] -translate-x-2/4 -translate-y-2/4 rounded-full bg-secondary opacity-0 transition-opacity group-hover:opacity-100 md:h-[48px] md:w-[48px]"></span>
					</button>
				</li>
				<li
					className={
						page === totalPages
							? 'disabled last-page-btn opacity-50'
							: 'last-page-btn'
					}
				>
					<button
						className="next group relative rounded-full p-2 px-1 transition-colors duration-200 ease-in-out max-sm:text-[12px] md:p-4"
						disabled={page >= totalPages}
						onClick={(e) => goToPage(e, totalPages)}
						aria-label={t('Next')}
					>
						<Icon
							name="caret-double-right"
							classNames={['z-[1] relative mt-[2px] block']}
						/>
						<span className="absolute left-0 left-2/4 top-0 top-2/4 z-0 h-[24px] w-[24px] -translate-x-2/4 -translate-y-2/4 rounded-full bg-secondary opacity-0 transition-opacity group-hover:opacity-100 md:h-[48px] md:w-[48px]"></span>
					</button>
				</li>
			</ul>
		</div>
	);
};

export default BlogRollPagination;
