import React, { useState, useEffect, useRef } from 'react';
// @ts-ignore
import ReactDOM from 'react-dom';
// @ts-ignore
import Icon from '../Icon';
import { useTranslation } from 'react-i18next';
import FilterAccordion from '../FilterAccordion';
import { twMerge } from 'tailwind-merge';
import useScreenSize from '../../hooks/useScreenSize';
import Button from '../Button';
import Loading from 'tbk-components/src/components/Loading';

const BlogRollFilterBar = (props: any) => {
	const screenWidth = useScreenSize();
	const isLgAndUp = screenWidth >= 1024;
	const [state, updateState] = useState<any>({
		open: { default: true, advanced: !isLgAndUp },
	});
	const { t } = useTranslation();
	const {
		onChange = (a: any) => {},
		assets = {},
		targetUrl = null,
		total,
		searchResultsLoading,
	} = props;

	// To be able to make the Collapse All button work, we'll centralize the
	// Accordions open states here.
	const [globalAccordionOpenStates, setGlobalAccordionOpenStates] = useState<
		boolean[]
	>([...assets.taxonomies, ...assets.advancedTaxonomies].map(() => false));

	const ref = useRef(null);

	const i18n = props.i18n;

	const onMobileFiltersClose = props.onMobileFiltersClose;

	const toggleFilter = (key: any) => {
		updateState((s: any) => ({
			...s,
			open: {
				...s.open,
				[key]: !s.open[key],
			},
		}));
	};
	const taxonomies = assets.taxonomies || [];
	const advancedTaxonomies = assets.advancedTaxonomies || [];
	const filters = { ...(props.filters || {}) };
	let activeCount = filters.search ? 1 : 0;
	const clearedState: any = {
		search: '',
		page: 1,
	};
	[...taxonomies, ...advancedTaxonomies].forEach((taxonomy: any) => {
		if (!filters[taxonomy.taxonomy]) {
			filters[taxonomy.taxonomy] = [];
		}

		if (
			taxonomy.taxonomy !== 'product_type' ||
			(taxonomy.taxonomy == 'product_type' && props.productTypeView != 1)
		) {
			activeCount += filters[taxonomy.taxonomy].length;
		}
		clearedState[taxonomy.taxonomy] = [];
	});

	const onCheckFilter = (e: any) => {
		const input = e.target;
		const taxonomy = input.name;
		const index = filters[taxonomy].indexOf(input.value);
		if (input.checked) {
			if (index === -1) {
				filters[taxonomy].push(input.value);
			}
		} else if (index !== -1) {
			filters[taxonomy].splice(index, 1);
		}
		onChange({
			[taxonomy]: filters[taxonomy],
			page: 1,
		});
	};

	const collapseAllAccordions = () => {
		setGlobalAccordionOpenStates(
			[...assets.taxonomies, ...assets.advancedTaxonomies].map(
				() => false,
			),
		);
	};

	// Close filters by default on smaller screens
	useEffect(() => {
		updateState((s: any) => ({
			...s,
			open: {
				...s.open,
				advanced: !isLgAndUp,
			},
		}));
	}, [isLgAndUp]);

	//Multiple sections do a form Check render. Unify the render.
	const formCheckRender = (taxonomy: any, term: any) => {
		// Thickness filter is a special case
		// If nothing is selected, show both filters
		// Otherwise:
		// It needs to detect if 'engineered-hardwood' is selected, and show the values.
		// If not, show the other thickness.
		if (taxonomy.taxonomy === 'thickness') {
			// check if any product types have been selected
			// The div element will have '-product_type-' in the id
			let productTypesSelected = 0;
			const productTypeElements =
				document.querySelectorAll<HTMLInputElement>(
					'[id^="product_type-"]',
				);
			// loop through all the product types and see if anything is selected
			// if anything is selected, set the productTypeSelected to true
			if (productTypeElements) {
				productTypeElements.forEach((input: HTMLInputElement) => {
					if (input.checked) {
						productTypesSelected++;
					}
				});
			}
			// check if engineered-hardwood in the product_type filter is selected, based on ID
			// The div element will have 'engineered-hardwood' in the id
			let engineeredHardwoodSelected = false;
			const engineeredHardwoodElement = document.querySelector(
				'[id^="product_type-engineered-hardwood"]',
			) as HTMLInputElement;
			const engineeredHardwoodElementFr = document.querySelector(
				'[id^="product_type-bois-franc-d\'ingénierie"]',
			) as HTMLInputElement;

			if (engineeredHardwoodElement) {
				engineeredHardwoodSelected = engineeredHardwoodElement.checked;
			}

			if (engineeredHardwoodElementFr) {
				engineeredHardwoodSelected =
					engineeredHardwoodElementFr.checked;
			}

			// if no values are selected or if engineered-hardwood is selected, show engineered_hardwood
			// if only engineered-hardwood is selected, hide the others
			if (
				term.term_id.includes('engineered_hardwood') ||
				term.term_id.includes('bois_franc_dingenierie')
			) {
				if (
					!(engineeredHardwoodSelected || productTypesSelected === 0)
				) {
					return null;
				}
			}

			// hide the "various" thickness filter items
			if (term.term_id.includes('various')) {
				if (engineeredHardwoodSelected && productTypesSelected === 1) {
					return null;
				}
			}
		}

		return (
			<div className="form-check flex gap-3">
				<input
					id={
						taxonomy.taxonomy +
						'-' +
						term.name.replace(/\s+/g, '-').toLowerCase() +
						`-` +
						term.term_id
					}
					className={twMerge(
						'form-check-input !mt-[1px] flex h-[20px] w-[20px] cursor-pointer appearance-none items-center justify-center border border-secondary',
						'checked:bg-secondary checked:after:font-icomoon checked:after:text-sm checked:after:text-primary',
					)}
					type="checkbox"
					defaultValue={term.term_id}
					checked={
						filters[taxonomy.taxonomy].indexOf(
							`${term.term_id}`,
						) !== -1
					}
					name={taxonomy.taxonomy}
					onChange={onCheckFilter}
				/>
				<label
					className="form-check-label text-base"
					htmlFor={
						term.name.replace(/\s+/g, '-').toLowerCase() +
						term.term_id
					}
					key={term.term_id}
					onClick={(e) => {
						const checkbox = (e.target as HTMLElement)
							.previousElementSibling;
						if (checkbox instanceof HTMLInputElement) {
							checkbox.click();
						}
					}}
				>
					{term.name}
				</label>
			</div>
		);
	};

	useEffect(() => {
		const header = document.querySelector(
			'.subpage-header',
		) as HTMLDivElement;
		const filtersSummary = document.querySelector('.filter-summary');

		if (!header) {
			return;
		}

		if (filtersSummary) {
			header.style.marginTop = `${filtersSummary.clientHeight - 1}px`;
		} else {
			header.style.marginTop = '0';
		}
	}, [window.location.hash]);

	const el = document.getElementById('blogroll-filters');
	const content = (
		<div className="blogroll__filter-bar mt-md-0 mt-4 lg:mt-0" ref={ref}>
			<form
				onSubmit={(e) => e.preventDefault()}
				className="filter-container"
			>
				{activeCount > 0 ? (
					<div className="filter-summary mb-8">
						<ul className="filter-tags keywords mb-0 inline-flex flex-wrap items-center gap-2 p-0">
							{[...taxonomies, ...advancedTaxonomies].map(
								(taxonomy: any) => {
									if (!filters[taxonomy.taxonomy].length) {
										return null;
									}
									return filters[taxonomy.taxonomy].map(
										(term: any) => {
											let tag = null;
											taxonomy.terms.forEach((t: any) => {
												if (`${t.term_id}` === term) {
													tag = (
														<li
															key={t.term_id}
															className="keyword"
														>
															<button
																type="button"
																className="flex items-center gap-2 rounded-2xl border border-secondary px-3 py-1  text-center text-sm text-primary hover:opacity-75"
																onClick={() =>
																	onCheckFilter(
																		{
																			target: {
																				name: taxonomy.taxonomy,
																				checked:
																					false,
																				value: term,
																			},
																		},
																	)
																}
															>
																<Icon name="close" />
																{t.name}
															</button>
														</li>
													);
												}
											});
											if (
												taxonomy.taxonomy ===
													'product_type' &&
												props.productTypeView == 1
											) {
												return null;
											}
											return tag;
										},
									);
								},
							)}
							{activeCount > 0 && (
								<li className="reset-filters hidden lg:list-item">
									<button
										type="button"
										className="text-sm text-error underline hover:opacity-75"
										onClick={() => {
											if (props.productTypeView == 1) {
												const {
													product_type: product_type,
													...irrelevantArgs
												} = filters;
												let relevantHash = {};
												if (product_type) {
													relevantHash = {
														product_type:
															product_type,
													};
												}
												onChange({
													...clearedState,
													...relevantHash,
												});
											} else {
												onChange(clearedState);
											}
											collapseAllAccordions();
										}}
									>
										{i18n?.clearAllFilters || 'Clear All'}
									</button>
								</li>
							)}
						</ul>
					</div>
				) : (
					''
				)}
				<div className="filter-content">
					{props.displaySearchBar && (
						<div className="filter-item search-wrapper full-width">
							<div className="search">
								<label
									htmlFor="filter-search"
									className="visually-hidden"
								>
									{t('Filter Search')}
								</label>
								<input
									name="search"
									type="text"
									id="filter-search"
									className="search__input"
									placeholder={t(
										props.SearchPlaceholder || 'Search',
									)}
									value={filters.search || ''}
									aria-label={t('Search')}
									onChange={(e) =>
										onChange({
											search: e.target.value,
											page: 1,
										})
									}
								/>
								<button
									type="submit"
									aria-label={t('submit search')}
									className="search__btn"
								>
									<Icon name="magnifying-glass" />
								</button>
							</div>
						</div>
					)}
					{(taxonomies.length > 0 ||
						advancedTaxonomies.length > 0) && (
						<div className="all-taxonomies">
							<button
								onClick={() => toggleFilter('default')}
								className="h5 mb-4 hidden items-center gap-2 text-medium lg:mb-10"
							>
								<Icon
									name={
										state.open.default ? 'eye-slash' : 'eye'
									}
									aria-hidden="true"
								/>
								<span className="underline">
									{state.open.default
										? i18n.hideFilters || t('Hide Filters')
										: i18n.openFilters || t('Open Filters')}
								</span>
							</button>
							<div
								className={twMerge(
									'all-taxonomies-wrapper mb-5 lg:mb-0',
								)}
							>
								{taxonomies.length > 0 && (
									<div className="default-taxonomies">
										<div className="taxonomies-header">
											<span className="h4 right-0 top-0 mb-5 flex items-center gap-3 text-medium transition-opacity lg:hidden">
												{i18n.filters || 'Filters'}
											</span>

											<h5 className="hidden text-medium lg:block">
												{i18n.filters || 'Filters'}
											</h5>
										</div>
										<FilterAccordion
											items={taxonomies.map(
												(taxonomy: any, index) => {
													return {
														title: taxonomy.label,
														selectedCount:
															filters[
																taxonomy
																	.taxonomy
															].length,
														htmlContent: (
															<div
																key={index}
																className="flex flex-col gap-3"
															>
																{taxonomy.terms.map(
																	(
																		term: any,
																	) => {
																		return formCheckRender(
																			taxonomy,
																			term,
																		);
																	},
																)}
															</div>
														),
													};
												},
											)}
											accordionOpenStates={globalAccordionOpenStates.slice(
												0,
												taxonomies.length,
											)}
											setAccordionOpenStates={(
												newStates: boolean[],
											) => {
												setGlobalAccordionOpenStates([
													...newStates,
													...globalAccordionOpenStates.slice(
														taxonomies.length,
													),
												]);
											}}
										/>
									</div>
								)}

								{advancedTaxonomies.length > 0 && (
									<div className="advanced-taxonomies mt-10">
										<button
											className="hidden w-full items-center justify-between gap-2 text-medium lg:flex"
											onClick={() =>
												toggleFilter('advanced')
											}
										>
											<h5 className="mb-0">
												{i18n?.advancedFilters ||
													t('Advanced Filters')}
											</h5>
											<div className=" flex items-center gap-1">
												<Icon
													name={
														state.open.advanced
															? 'eye-slash'
															: 'eye'
													}
													aria-hidden="true"
													classNames={['text-base']}
												/>
												<span className="mb-0 text-sm underline">
													{state.open.advanced
														? i18n.hide || t('Hide')
														: i18n.show ||
															t('Show')}
												</span>
											</div>
										</button>
										<h5 className="mb-0 text-medium lg:hidden">
											{i18n?.advancedFilters ||
												t('Advanced Filters')}
										</h5>
										<div
											className={twMerge(
												'advanced-taxonomies-filters mt-4',
												!state.open.advanced
													? 'hidden'
													: 'block',
											)}
										>
											<FilterAccordion
												items={advancedTaxonomies.map(
													(taxonomy: any, index) => {
														return {
															title: taxonomy.label,
															selectedCount:
																filters[
																	taxonomy
																		.taxonomy
																].length,
															htmlContent: (
																<div
																	key={index}
																	className="flex flex-col gap-2"
																>
																	{taxonomy.terms.map(
																		(
																			term: any,
																		) => {
																			return formCheckRender(
																				taxonomy,
																				term,
																			);
																		},
																	)}
																</div>
															),
														};
													},
												)}
												accordionOpenStates={globalAccordionOpenStates.slice(
													taxonomies.length,
												)}
												setAccordionOpenStates={(
													newStates: boolean[],
												) => {
													setGlobalAccordionOpenStates(
														[
															...globalAccordionOpenStates.slice(
																0,
																taxonomies.length,
															),
															...newStates,
														],
													);
												}}
											/>
										</div>
									</div>
								)}
							</div>
						</div>
					)}
				</div>
			</form>
			{/* To make the Filters view on mobile more useful, we're adding a Clear All
			button near the bottom and removing the tiny one at the top, and we're also
			displaying the number of results shown for the selected criteria as well as a
			loading state for when the component is fetching results */}
			<div className="fixed bottom-0 left-0 flex w-full justify-between gap-4 bg-white px-5 py-2 lg:hidden">
				<Button
					buttonType="Outline"
					classNames={['basis-1/2', 'min-h-14']}
					onClick={() => {
						if (props.productTypeView == 1) {
							const {
								product_type: product_type,
								...irrelevantArgs
							} = filters;
							let relevantHash = {};
							if (product_type) {
								relevantHash = {
									product_type: product_type,
								};
							}

							onChange({
								...clearedState,
								...relevantHash,
							});
						} else {
							onChange(clearedState);
						}
						collapseAllAccordions();
					}}
				>
					{i18n?.clearAllFilters || 'Clear All'}
				</Button>
				<Button
					classNames={['basis-1/2', 'min-h-14']}
					disabled={searchResultsLoading}
					onClick={() => {
						onMobileFiltersClose();
					}}
				>
					{searchResultsLoading ? (
						<Loading size={1} />
					) : !total ? (
						i18n?.noResults || t('No results')
					) : total == 1 ? (
						total + ' ' + (i18n?.result || t('result'))
					) : (
						total + ' ' + (i18n?.results || t('results'))
					)}
				</Button>
			</div>
		</div>
	);

	return el ? ReactDOM.createPortal(content, el) : content;
};

export default BlogRollFilterBar;
