import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import ContentSection, { IContentSectionProps } from '../ContentSection';
import ImageToSVG from '../ImageToSVG';
import Image, { IImage } from 'tbk-components/src/components/Image';
import { twMerge } from 'tailwind-merge';
import TriangleImage from '../TriangleImage';
import Stat, { IStatProps } from '../Stat';

export interface ISquareContent {
	backgroundColor?: string;
	ImageOrText?: 'Image' | 'Text';
	topText?: Pick<IStatProps, 'count' | 'prefix' | 'suffix'>;
	topTextClassName?: string;
	topImage?: IImage;
	topImageClassName?: string;
	subtext?: string;
	subtextClassName?: string;
	order?: number;
	responsiveOrder?: number;
}

export interface IContentWithNumbersProps extends IBaseElementProps {
	accentImage?: IImage;
	title?: string;
	/**
	 * Text
	 * @control richtext
	 */
	excerpt?: string;
	excerptClassName?: string;
	squares: ISquareContent[];
	triangleClippingMaskImage?: IImage;
}

/**
 * ContentWithNumbers
 * @icon grid-view
 * @block
 */
const ContentWithNumbers: React.FC<IContentWithNumbersProps> = ({
	accentImage,
	title,
	excerpt,
	excerptClassName,
	squares = [],
	triangleClippingMaskImage,
	className,
	classNames = [],
}) => {
	/**
	 * This component has a lot of customization options
	 * 1- The user can choose between an image or text for the top part of the square
	 * 2- The user can choose the background color of the square
	 * 3- The user can define the order of the squares
	 * 4- The user can define the text for the bottom part of the square
	 * 5- The user can through classnames personalize the color or any other property of the content in the square
	 */

	// The goal from all this code below is to allow the user to place the squares in whichever order they want
	let squaresLength = squares.length;

	// If there are less than 4 squares, fill the rest with empty squares
	let emptySquaresArray = [];
	for (let i = 1; i <= 4 - squaresLength; i++) {
		emptySquaresArray.push({});
	}

	// Merge the array passed down through props with the new one containing the empty squares
	let mergedArray = squares.concat(emptySquaresArray);

	// Let's store in an array the order of the squares inputed by the user
	let orderArray = mergedArray
		.filter((square) => square.order)
		.map((square) => square.order);

	// If a square doesn't have an order (empty square or one where user didn't input an order), we give it one
	// after we check if the order is already taken
	mergedArray.map((square, index) => {
		if (!square.order) {
			let order = 1;
			while (orderArray.includes(order)) {
				order++;
			}
			square.order = order;
			orderArray.push(order);
		} else {
			return square;
		}
	});

	// If by any chance adds more than 4 squares, we slice the array to only 4
	// This should be mentioned in the WordPress gutenberg block for the user
	if (squares.length > 4) {
		squares = squares.slice(0, 4);
	}

	return (
		<div
			className={
				className ||
				cn(
					'content-with-numbers bg-gradient-general relative',
					...classNames,
				)
			}
		>
			<div className="relative z-10 mx-auto flex max-w-8xl flex-col lg:flex-row">
				<div className="flex basis-1/2 items-center justify-center px-8 py-16">
					<ContentSection
						accentImage={accentImage}
						title={title}
						excerpt={excerpt}
						excerptClassName={excerptClassName}
						titleClassName="h2-large mb-0"
						titleElement="h2"
						classNames={['max-w-[437px]']}
					/>
				</div>
				<div className="basis-1/2">
					<div className="grid md:grid-cols-2 md:grid-rows-2 lg:max-w-[438px]">
						{Array.isArray(mergedArray) &&
							mergedArray.length > 0 &&
							mergedArray.map((square, index) => (
								<div
									key={index}
									className={cn(
										'flex items-center justify-center gap-x-4 px-6 py-6 md:h-[270px] md:flex-col lg:h-[379px]',
										square?.backgroundColor
											? `${square?.backgroundColor.toLowerCase()}`
											: '',
										`order-${square?.order}`,
										`md:order-${square?.responsiveOrder}`,
										`lg:order-${square?.order}`,
										!square?.topText?.count &&
											!square?.topImage?.src &&
											!square?.subtext &&
											'hidden md:flex',
									)}
								>
									{square.ImageOrText === 'Text' ? (
										<Stat
											count={square?.topText?.count}
											prefix={square?.topText?.prefix}
											suffix={square?.topText?.suffix}
											countClassName="h2-large m-2"
										/>
									) : square.ImageOrText === 'Image' ? (
										<ImageToSVG
											image={square.topImage}
											classNames={[
												'mb-2 w-[72px] h-[72px]',
												square?.topImageClassName,
											]}
										/>
									) : null}
									<p
										className={twMerge(
											'mb-0 text-center',
											square?.subtextClassName,
										)}
									>
										{square.subtext}
									</p>
								</div>
							))}
					</div>
				</div>
			</div>
			{triangleClippingMaskImage && (
				<TriangleImage
					image={triangleClippingMaskImage}
					mask="Why Fuzion"
					classNames={[
						'max-w-[970px] hidden xl:block  absolute top-0 right-0  h-full z-0',
					]}
				/>
			)}
		</div>
	);
};

export default ContentWithNumbers;
