import React from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Button, { ILink, IFuzionButtonProps } from '../Button';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';

interface IBlogInnerHeaderI18nProps {
	publishedOn?: string;
}

export interface IBlogInnerHeaderProps extends IBaseElementProps {
	postTitle?: string;
	categories?: ILink[];
	datePosted?: string;
	i18n?: IBlogInnerHeaderI18nProps;
}

const BlogInnerHeader: React.FC<IBlogInnerHeaderProps> = ({
	id,
	className,
	classNames = [],
	postTitle,
	categories = [],
	datePosted,
	i18n,
}) => {
	const { t } = useTranslation();

	return (
		<div
			id={id}
			className={
				className ||
				twMerge(
					'blog-inner-header__container lg:max-w-[calc(2/3*100%)]',
					...classNames,
				)
			}
		>
			{postTitle && <h1 className="h1-small">{postTitle}</h1>}
			{(categories?.length > 0 || datePosted) && (
				<div className="mt-4 flex flex-wrap items-center justify-start gap-3 md:mt-7">
					{categories?.length > 0 && (
						<ul className="list-style-none flex flex-wrap items-center justify-start gap-2">
							{categories?.map((category, index) => (
								<li key={index}>
									<Button
										link={category}
										buttonType="Outline"
										size="sm"
									/>
								</li>
							))}
						</ul>
					)}
					{datePosted && (
						<span className="text-sm">
							{i18n?.publishedOn || t('Published on ')}
							{datePosted}
						</span>
					)}
				</div>
			)}
		</div>
	);
};

export default BlogInnerHeader;
