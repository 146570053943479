import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import cn from 'classnames';

export interface IFormProps extends IBaseElementProps {
	children?: any;
	style?: 'single-stroke' | 'double-stroke';
	theme?: 'light' | 'dark';
	alignment?: 'left' | 'center';
	smallForm?: boolean;
	/**
	 * Toggle to make form body and footer inline.
	 */
	inlineForm?: boolean;
}

/**
 * Form
 * @block
 * @requiresHooks
 */

const Form: React.FC<IFormProps> = ({
	className,
	classNames = [],
	children,
	style = 'single-stroke',
	theme = 'dark',
	alignment = 'left',
	smallForm = false,
	inlineForm = false,
	id,
}) => {
	// Used to force re-render when the DOM changes
	const [count, setCount] = React.useState(0);
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (typeof (window as any).jQuery !== 'undefined') {
			// Emit the gform_post_render event using global jQuery
			(window as any).jQuery(document).trigger('gform_post_render');
		}
	}, []);

	useEffect(() => {
		if (!ref.current) return;

		const readOnlyInputs = ref.current.querySelectorAll<HTMLInputElement>(
			'.gf_readonly input, .gf_readonly textarea, .gf_readonly select',
		);

		if (readOnlyInputs.length === 0) return;

		readOnlyInputs.forEach((field) => {
			field.readOnly = true;
			field.tabIndex = -1;
		});
	}, [count]);

	// Force re-render if the DOM changes
	useEffect(() => {
		const observer = new MutationObserver(() => {
			setCount((prev) => prev + 1);
		});

		if (ref.current) {
			observer.observe(ref.current, {
				childList: true,
				subtree: true,
			});
		}

		return () => {
			observer.disconnect();
		};
	}, []);

	return (
		<div
			className={
				className ||
				cn(
					'form-wrapper',
					`form-wrapper--style-${style}`,
					`form-wrapper--theme-${theme}`,
					`form-wrapper--alignment-${alignment}`,
					smallForm && `form-wrapper--small-form`,
					inlineForm && `form-wrapper--inline-form`,
					...classNames,
				)
			}
			ref={ref}
			id={id}
		>
			{children}
		</div>
	);
};

export default Form;
