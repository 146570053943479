import React, { useRef, useEffect, useLayoutEffect } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Image, { IImage } from 'tbk-components/src/components/Image';
import Button, { IFuzionButtonProps, ILink } from '../Button';
import starLineBurst from '../../assets/images/home-hero-banner-star-line-burst.svg';
import defaultAccentImage from '../../assets/images/dark-starburst-accent.svg';
import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { twMerge } from 'tailwind-merge';
gsap.registerPlugin(SplitText, ScrollTrigger);

export interface IHomeHeroBannerProps extends IBaseElementProps {
	titleSize?: 'h1' | 'h1-small';
	/**
	 * @control richtext
	 */

	titleTopLine?: string;
	/**
	 * @control richtext
	 */
	titleBottomLine?: string;
	heroImage?: IImage;
	featuredCategoryTitle?: string;
	featuredCategoryLink?: IFuzionButtonProps;
	/**
	 * @control richtext
	 */
	ctaTitle?: string;
	ctaLink?: IFuzionButtonProps;
	titleLink?: ILink;
	enableAnimation?: boolean;
	enableStarburst?: boolean;
}

/**
 * Home Hero Banner
 * @block
 * @icon cover-image
 */
const HomeHeroBanner: React.FC<IHomeHeroBannerProps> = ({
	id,
	className,
	classNames = [],
	titleTopLine,
	titleBottomLine,
	heroImage,
	featuredCategoryTitle,
	featuredCategoryLink,
	ctaTitle,
	ctaLink,
	titleSize = 'h1',
	titleLink,
	enableAnimation = true,
	enableStarburst = true,
}) => {
	const ref = useRef(null);

	useEffect(() => {
		if (!enableAnimation) return;
		//Adding a temporary delay to allow the page to load before animating. Our CDN fonts are taking a bit to load in and it causes a stutter.
		setTimeout(() => {
			let ctx = gsap.context(() => {
				const heroTitleNodes =
					ref.current.querySelectorAll('.tl-line-text');
				const heroImageCanvas = ref.current.querySelector(
					'.tl-hero-image-canvas',
				);
				let tl;

				if (heroImageCanvas) {
					tl = gsap.timeline({
						scrollTrigger: {
							scrub: 1,
							trigger: heroImageCanvas,
							start: 'top 80%',
							end: '50% end',
						},
					});
				}

				const mySplitText = new SplitText(heroTitleNodes, {
						type: 'lines, words',
						linesClass: '!inline [&_em]:text-medium h1 mb-0',
					}),
					words = mySplitText.words; //an array of all the divs that wrap each character

				gsap.matchMedia().add(
					{
						md: '(min-width: 768px)',
						reduced: '(prefers-reduced-motion: reduce)',
					},
					() => {
						gsap.to(ref.current.querySelector('.hero-title h1'), {
							duration: 0.1,
							opacity: 1,
							delay: 0.5,
						});
						gsap.fromTo(
							words,
							{
								opacity: 0,
								y: 80,
							},
							{
								duration: 1,
								opacity: 1,

								y: 0,
								stagger: {
									each: 0.15,
									axis: 'y',
									grid: 'auto',
									ease: 'power2.inOut',
								},
							},
						);
						gsap.fromTo(
							ref.current.querySelector('.pro-insider-btn'),
							{
								opacity: 0,
								y: 10,
							},
							{
								duration: 1,
								opacity: 1,
								y: 0,
								delay: 0.5,
							},
						);
						gsap.fromTo(
							heroImageCanvas?.querySelector(
								'.hero-image-wrapper',
							),
							{
								opacity: 0,
								scale: 1.1,
							},
							{
								duration: 1,
								opacity: 1,
								delay: 0.5,
								scale: 1,
							},
						);
						gsap.fromTo(
							ref.current.querySelector('.star-accent'),
							{
								opacity: 0,
								scale: 1.1,
							},
							{
								duration: 1,
								opacity: 1,
								delay: 0.5,
								scale: 1,
							},
						);

						if (tl) {
							tl.fromTo(
								heroImageCanvas.querySelector(
									'.hero-image-wrapper',
								),
								{
									width: '1340px',
									height: '80vh',
								},
								{
									duration: 5,
									width: '2800px',
									height: '120vh',
									ease: 'power2.inOut',
								},
							);
						}
					},
				);
			}, ref);

			return () => {
				ctx.revert();
			};
		}, 500);
	}, []);

	return (
		<div
			id={id}
			ref={ref}
			className={
				className ||
				cn('home-hero-banner__container relative z-0', ...classNames)
			}
		>
			<div
				className="absolute left-0 top-0 h-full max-h-[1150px] w-full max-w-[1015px] sm:h-1/2 sm:w-1/2 xl:h-full xl:w-full"
				style={{
					background: `url(${starLineBurst}) top left / contain no-repeat`,
				}}
			/>
			<div className="relative z-[2] px-4 py-16 md:py-24 lg:py-30 lg:pt-[200px]">
				<div className="hero-title container relative mx-auto w-full max-w-[1376px] !bg-[length:52px_52px] bg-[right_bottom] bg-no-repeat text-primary lg:!bg-[length:86px_86px] lg:bg-[100%_calc(100%-1rem)] [&_em]:italic [&_i]:italic">
					<div>
						<h1
							className={twMerge(
								'pr-0 xs:pr-12 lg:pr-20',
								titleSize,
								enableAnimation && 'md:opacity-0',
							)}
						>
							{titleTopLine && (
								<span
									className="tl-line-text h1 mb-0 inline overflow-hidden pb-1 md:inline-block [&_em]:text-medium"
									dangerouslySetInnerHTML={{
										__html: titleTopLine,
									}}
								></span>
							)}{' '}
							{titleBottomLine && (
								<span
									className="tl-line-text h1 mb-0 block overflow-hidden pb-1 md:inline-block [&_em]:text-medium"
									dangerouslySetInnerHTML={{
										__html: titleBottomLine,
									}}
								></span>
							)}
							{titleLink && titleLink?.href && (
								<div className="block">
									<Button
										link={titleLink}
										buttonType="Primary"
										size="lg"
										classNames={[
											'pro-insider-btn inline-block mt-5',
											enableAnimation && 'md:hidden',
										]}
									/>
								</div>
							)}
						</h1>
					</div>
					{enableStarburst && (
						<Image
							src={defaultAccentImage}
							width={86}
							height={86}
							className={twMerge(
								'star-accent absolute bottom-0 right-0 z-[1] mb-10 hidden md:block',
								enableAnimation &&
									'animate-spin-slow md:opacity-0',
							)}
						/>
					)}
				</div>
				{heroImage && (
					<div
						className={twMerge(
							'tl-hero-image-canvas mx-auto',
							enableAnimation && 'md:h-[130vh]',
						)}
					>
						<div
							className={twMerge(
								'hero-image-wrapper top-0 mx-auto block aspect-[4/3] h-auto max-w-full items-center justify-center overflow-hidden rounded-md py-8 pb-0 md:h-[60%] md:min-h-[450px] ',
								enableAnimation
									? 'sticky w-[1320px] md:max-w-full md:opacity-0 2xl:max-w-[1440px]'
									: 'w-[1376px]',
								(!featuredCategoryTitle ||
									!featuredCategoryLink) &&
									'md:pb-0 md:pt-16 lg:pb-0 lg:pt-20',
								enableAnimation
									? 'md:h-[60vh] md:min-h-[450px]'
									: 'max-h-[774px]',
							)}
						>
							<Image
								{...heroImage}
								className="h-full w-full rounded-md object-cover"
							/>
						</div>
					</div>
				)}
				{(featuredCategoryTitle || featuredCategoryLink) && (
					<div className="mx-auto mt-6 max-w-[906px] text-primary md:flex md:items-center md:justify-between">
						{featuredCategoryTitle && (
							<span className="h4 mb-0 block">
								{featuredCategoryTitle}
							</span>
						)}
						{featuredCategoryLink && (
							<Button
								{...featuredCategoryLink}
								classNames={['mt-4', 'md:mt-0']}
							/>
						)}
					</div>
				)}
				{(ctaTitle || ctaLink?.link?.label || ctaLink?.link?.href) && (
					<div className="mt-8 md:mt-16 md:text-center lg:mt-20">
						{ctaTitle && (
							<div
								dangerouslySetInnerHTML={{ __html: ctaTitle }}
								className="text-primary lg:[&_h2]:text-5xl"
							/>
						)}
						{ctaLink?.link?.label && <Button {...ctaLink} />}
					</div>
				)}
			</div>
			<div className="bg-gradient-rainbow absolute left-0 top-0 -z-[1] h-full w-full"></div>
		</div>
	);
};

export default HomeHeroBanner;
