import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import cn from 'classnames';
import ProductCard, { IProductCardProps } from '../ProductCard';
import { twMerge } from 'tailwind-merge';
import Icon from '../Icon';
import Button, { ILink } from '../Button';
import Image from 'tbk-components/src/components/Image';
import { ICustomCarouselI18n } from '../ProductCard/CustomCarouselWithImagePreviews';
import isEqual from 'lodash/isEqual';
export interface i18nCards {
	compareProducts?: string;
	viewComparison?: string;
	hideComparison?: string;
	comparisonLimitDisclaimer?: string; // Compare up to 3 products
	quickView?: string; //Quick View
	sku?: string; //SKU:
	compare?: string; //Compare
	carouselI18n?: ICustomCarouselI18n;
	productWidth?: string;
}
export interface IProductCardsProps extends IBaseElementProps {
	productCards?: IProductCardProps[];
	gtmListName?: string;
	/**
	 * @noUI
	 */
	productTotal?: number; //This is used for the total number of products returned by the API.
	desktopGridSize?: 3 | 4;
	//Incase something like the Search wants to use custom grid logic.
	productListingClasses?: string[];
	/**
	 * This is for the compact version of the product card in header search results.
	 */
	compact?: boolean;
	productCompareLink?: ILink;
	i18n?: i18nCards;
	cardTitleClassName?: string;
	stayAsColumnCard?: boolean;
}

/**
 * Product Cards
 * @block
 * @icon align-full-width
 */
const ProductCards: React.FC<IProductCardsProps> = ({
	id,
	className,
	classNames = [],
	productCards = [],
	gtmListName,
	desktopGridSize = 4,
	productListingClasses,
	cardTitleClassName,
	stayAsColumnCard = false,
	compact = false,
	productCompareLink,
	i18n,
}) => {
	const p = localStorage.getItem('productToCompare');
	const parsedStorage = JSON.parse(p);
	const [isIndexPage, setIsIndexPage] = useState(false);
	const [productsToCompare, setProducts] = useState([]);
	const [expandedProductBar, setExpandedProductBar] = useState(false);

	const barRef = useRef(null);

	useLayoutEffect(() => {
		// Check if the page is the index page by fetching the index node.
		// This node only exists on the Index Page so it is a good way to catch this.
		const indexPageNode = document.querySelector('.product-index');
		if (indexPageNode) {
			//Set the boolean so we can use it conditionally later
			setIsIndexPage(true);
			//Enable the locally stored product comparison
			setProducts(parsedStorage || []);
		}
	}, []);
	const productCompare = (product, isStored = false) => {
		if (productsToCompare.includes(product) || isStored) {
			if (isStored) {
				productsToCompare.splice(
					productsToCompare.findIndex((p) => p.sku === product.sku),
					1,
				);
			}

			setProducts(productsToCompare.filter((p) => p !== product));
			if (isIndexPage) {
				localStorage.setItem(
					'productToCompare',
					JSON.stringify(
						productsToCompare.filter((p) => p !== product),
					),
				);
			}
		} else {
			if (productsToCompare.length <= 2) {
				setProducts([...productsToCompare, product]);
				if (isIndexPage) {
					localStorage.setItem(
						'productToCompare',
						JSON.stringify([...productsToCompare, product]),
					);
				}
			}
		}
	};

	const submitComparison = () => {
		if (productCompareLink) {
			window.location.href =
				productCompareLink +
				'?products=' +
				productsToCompare
					.map((product) => product.productId ?? product.id)
					.join('%2C');
		}
	};

	/**
	 * Hide product floating bar on single product page when compare bar is activated
	 */
	const hideFloatingBar = () => {
		const productFloatingBar = document.querySelector(
			'.floating-cta-bar',
		) as HTMLElement;

		if (!productFloatingBar) return;

		productFloatingBar.style.display =
			expandedProductBar && productsToCompare.length > 0
				? 'none'
				: 'block';
	};

	useEffect(() => {
		hideFloatingBar();

		if (expandedProductBar === true) return;

		const productFloatingBar = document.querySelector(
			'.floating-cta-bar > div',
		) as HTMLElement;

		if (!productFloatingBar || !barRef.current) return;

		if (window.innerWidth < 1024) {
			barRef.current.style.bottom =
				productFloatingBar.offsetHeight + 'px';
		} else {
			barRef.current.style.bottom = '0';
		}
	}, [productsToCompare, expandedProductBar]);

	return (
		<div
			id={id}
			className={className || cn('product-cards', ...classNames)}
		>
			{productCards?.length > 0 && (
				<div
					className={twMerge(
						productListingClasses?.length > 0
							? productListingClasses
							: [
									'product-cards-listing grid grid-cols-1 md:grid-cols-2 md:gap-8 lg:gap-10 xl:gap-16',
									compact
										? 'gap-4 xs:grid-cols-2'
										: 'gap-6 md:grid-cols-2',
									desktopGridSize === 4
										? 'lg:grid-cols-4'
										: 'lg:grid-cols-3',
								],
					)}
				>
					{productCards.map((productCard, index) => {
						const isStored = parsedStorage?.some(
							(product) => product.sku === productCard.sku,
						);

						return (
							<ProductCard
								key={productCard?.id ?? index}
								{...productCard}
								gtmListName={gtmListName}
								productForCompare={
									productsToCompare.includes(productCard) ||
									isStored
								}
								stayAsColumn={stayAsColumnCard}
								titleClassName={cardTitleClassName}
								productImageResizeWidth={
									desktopGridSize === 4 ? 400 : 600
								}
								onProductCompareChange={() => {
									productCompare(productCard, isStored);
								}}
								compact={compact}
								i18n={{
									quickView: i18n?.quickView || 'Quick View',
									sku: i18n?.sku || 'SKU',
									compare: i18n?.compare || 'Compare',
									carouselI18n: i18n?.carouselI18n,
									productWidth: i18n?.productWidth || 'Width',
								}}
							/>
						);
					})}
				</div>
			)}

			{productsToCompare.length > 0 && (
				<div
					className={twMerge(
						'product-compare-bar fixed bottom-0 right-0 z-[2] flex max-h-[80dvh] w-full flex-col lg:z-10',
						/**
						 * This makes sure the bar is at the bottom of the screen when expanded especially when there's a product floating bar.
						 */
						expandedProductBar && '!bottom-0',
					)}
					ref={barRef}
				>
					<button
						onClick={() => {
							setExpandedProductBar(!expandedProductBar);
						}}
						className="mx-auto rounded-tl-md rounded-tr-md bg-primary px-6 py-3 text-sm text-white md:ml-auto md:mr-0 md:rounded-tr-none"
					>
						{!expandedProductBar
							? i18n?.viewComparison ?? 'View Comparison'
							: i18n?.hideComparison ?? 'Hide Comparison'}{' '}
						{productsToCompare.length > 0 &&
							`(${productsToCompare.length})`}{' '}
						<Icon
							name={
								!expandedProductBar
									? 'chevron-up'
									: 'chevron-down'
							}
						/>
					</button>
					{expandedProductBar && (
						<div className="product-compare-bar-expanded overflow-auto bg-light p-4">
							<div className="product-bar-head mb-4 flex flex-col flex-wrap items-center gap-4 gap-y-0 md:flex-row-reverse">
								<Button
									buttonType="Secondary"
									size="sm"
									icon="chevron-right"
									onClick={() => {
										submitComparison();
									}}
								>
									{i18n?.compareProducts ??
										'Compare Products'}
								</Button>
								<p className="mb-0 mt-1 text-sm text-medium">
									{i18n?.comparisonLimitDisclaimer ??
										'Compare up to 3 products'}
								</p>
							</div>
							<div
								className="product-bar-listing lg:gap-15 grid w-full justify-center gap-4 md:flex md:flex-wrap md:gap-10 xl:gap-20"
								style={{
									gridTemplateColumns: `repeat(${productsToCompare?.length == 1 ? '2' : productsToCompare?.length},1fr)`,
								}}
							>
								{productsToCompare.map((product, index) => (
									<button
										key={index}
										className={twMerge(
											'product-compare-preview text-decoration-none color-inherit group relative inline-flex w-auto flex-shrink-0 flex-col items-center gap-3 md:flex-row',
										)}
										onClick={() => {
											productCompare(product);
										}}
									>
										{product.image && (
											<div className="md:relative">
												<div
													className={twMerge(
														'icon-wrapper',
														'absolute right-2 rounded-full bg-tertiary p-1 text-lg text-primary transition-opacity group-hover:opacity-100 md:right-0 md:mr-2 md:mt-2 md:bg-white md:opacity-0',
														'top-2 md:top-0',
														'md:translate-y-0',
													)}
												>
													<Icon name="close" />
												</div>
												<Image
													className="aspect-[1/1] h-auto w-full max-w-[100px] flex-shrink-0 flex-grow rounded md:w-[100px]"
													{...product.image}
												/>
											</div>
										)}
										{product.title && (
											<h4 className="mb-0 block max-w-60 md:max-w-none">
												{product.title}
											</h4>
										)}
									</button>
								))}
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default ProductCards;
