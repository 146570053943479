import React from 'react';
import { IImage } from 'tbk-components/src/components/Image';

export interface IMediaFile extends IImage {
	size?: number;
	prettySize?: string;
}

export interface IImageProps extends IImage {
	className?: string;
	onLoad?: () => void;
	defaultImage?: string;
}

const NewImage: React.FC<IImageProps> = ({
	src,
	alt = '',
	width,
	height,
	className,
	srcSets,
	defaultImage,
	onLoad = () => {},
}) => {
	const onError = (e: React.SyntheticEvent<HTMLImageElement>) => {
		const target = e.target as HTMLImageElement;
		target.src = defaultImage || '';
	};

	return (
		<img
			src={src}
			alt={alt}
			width={width}
			height={height}
			className={className || ''}
			srcSet={srcSets}
			onError={defaultImage ? onError : undefined}
			onLoad={onLoad}
		/>
	);
};
export default NewImage;
