import React from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import cn from 'classnames';
import Button, { IFuzionButtonProps } from '../Button';

export interface IDealerLocatorPageBannerProps extends IBaseElementProps {
	title: string;
	subheading?: string;
	buttons?: IFuzionButtonProps[];
}

/**
 * Page Banner
 * @block
 * @icon align-full-width
 */
const DealerLocatorPageBanner: React.FC<IDealerLocatorPageBannerProps> = ({
	id,
	className,
	classNames = [],
	title,
	subheading,
	buttons = [],
}) => {
	return (
		<div
			id={id}
			className={
				className ||
				cn(
					'hero-banner__container bg-gradient-rainbow lg:relative lg:flex lg:items-center',
					...classNames,
				)
			}
		>
			<div className="container px-4 pb-10 pt-[72px] md:px-8 lg:flex lg:items-center lg:gap-x-[60px] lg:pb-[80px] lg:pt-[204px]">
				{title && (
					<h1 className="hero-banner__title lg:shrink-0 lg:basis-1/2 lg:text-6xl lg:leading-[1.1]">
						{title}
					</h1>
				)}
				<div>
					{subheading && (
						<p
							className="hero-banner__subheading mb-10 mt-3"
							dangerouslySetInnerHTML={{
								__html: subheading,
							}}
						/>
					)}
					{buttons?.length > 0 && (
						<div className="flex flex-wrap gap-4 lg:mt-7 lg:shrink-0 lg:basis-1/2 lg:gap-x-4 lg:gap-y-8">
							{buttons.map((button, index) => (
								<Button
									key={index}
									{...button}
									classNames={[
										'!inline-block text-left leading-none [&_i]:text-[12px] [&_i]:ml-1 lg:[&_i]:ml-2',
									]}
								/>
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default DealerLocatorPageBanner;
