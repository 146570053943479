import React, { useEffect, useRef } from 'react';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import cn from 'classnames';
import { kebabCase } from 'lodash';
import { twMerge } from 'tailwind-merge';

export interface FilterAccordionItemProps {
	title?: string;
	/**
	 * @control richtext
	 */
	content?: string;
	/**
	 * @noUI
	 */
	htmlContent?: any;
	selectedCount?: number;
}

export interface IFilterAccordionProps extends IBaseElementProps {
	items: FilterAccordionItemProps[];
	accordionOpenStates?: boolean[];
	setAccordionOpenStates?: (states: boolean[]) => void;
}

const FilterAccordion: React.FC<IFilterAccordionProps> = ({
	id,
	className,
	classNames = [],
	items,
	accordionOpenStates,
	setAccordionOpenStates,
}) => {
	// This ref is used to store the content elements of each accordion item so that we can reference them later
	const contentRefs = useRef<(HTMLDivElement | null)[]>([]);

	const toggleAccordion = (index: number) => {
		if (accordionOpenStates && setAccordionOpenStates) {
			const newState = [...accordionOpenStates];
			newState[index] = !newState[index];

			const contentEl = contentRefs.current[index];
			if (contentEl) {
				if (newState[index]) {
					contentEl.style.maxHeight = `${contentEl.scrollHeight}px`;
				} else {
					contentEl.style.maxHeight = `0`;
				}
			}

			setAccordionOpenStates(newState);
		}
	};

	// Since the state is updated outside of the component, we need to update the accordion content height manually
	useEffect(() => {
		contentRefs.current.forEach((contentEl, index) => {
			if (contentEl) {
				if (accordionOpenStates[index]) {
					contentEl.style.maxHeight = `${contentEl.scrollHeight}px`;
				} else {
					contentEl.style.maxHeight = `0px`;
				}
			}
		});
	}, [accordionOpenStates]);

	// if accordion is collapsed then don't allow tabbing into the content
	useEffect(() => {
		contentRefs.current.forEach((contentEl, index) => {
			if (contentEl) {
				const focusableElements =
					contentEl.querySelectorAll<HTMLElement>('input');
				focusableElements.forEach((el) => {
					el.tabIndex = accordionOpenStates[index] ? 0 : -1;
				});
			}
		});
	}, [accordionOpenStates]);

	return (
		<div
			id={id}
			className={
				className || cn('filter-accordion__container', ...classNames)
			}
		>
			{items?.length > 0 && (
				<ul className="FilterAccordion_list">
					{items.map((item, index) => (
						<li
							key={index}
							className={`group/item filter-accordion-${kebabCase(item.title)}`}
						>
							<div className="filter-accordion__item border-t border-secondary py-3 group-[:nth-last-child(1)]/item:border-b lg:py-4">
								<button
									className="filter-accordion__button group/button flex w-full items-center justify-between text-left text-base leading-[1.4] text-primary lg:text-[18px]"
									type="button"
									aria-expanded={accordionOpenStates[index]}
									aria-controls={`collapse-${kebabCase(item.title)}`}
									onClick={() => toggleAccordion(index)}
								>
									<span>
										{item.title}
										{item?.selectedCount > 0 && (
											<span className="ml-3">
												({item.selectedCount})
											</span>
										)}
									</span>
									<i
										className={`icon-chevron-${
											accordionOpenStates[index]
												? 'up'
												: 'down'
										}`}
									></i>
								</button>
								<div
									id={`collapse-${kebabCase(item.title)}`}
									aria-hidden={!accordionOpenStates[index]}
									ref={(el) =>
										(contentRefs.current[index] = el)
									}
									className={twMerge(
										'filter-accordion__content max-h-0 overflow-hidden transition-all duration-300 ease-in-out',
									)}
								>
									{item.htmlContent && (
										<div className="filter-accordion__content-inner py-5 pb-4">
											{item.htmlContent}
										</div>
									)}
								</div>
							</div>
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default FilterAccordion;
