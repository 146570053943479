import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Image, { IImage } from 'tbk-components/src/components/Image';
import Button, { ILink, IFuzionButtonProps } from '../Button';

export interface IBlogProps {
	blogTitle?: string;
	blogLink?: ILink;
	blogImage?: IImage;
	blogCategories?: ILink[];
}

interface IInspirationCTAI18nProps {
	featuredFlooring?: string;
}

export interface IInspirationCTAProps extends IBaseElementProps {
	title?: string;
	ctaButton?: IFuzionButtonProps;
	blogs?: IBlogProps[];
	i18n?: IInspirationCTAI18nProps;
}

/**
 * Inspiration CTA
 * @block
 * @icon feedback
 */
const InspirationCTA: React.FC<IInspirationCTAProps> = ({
	id,
	className,
	classNames = [],
	title,
	ctaButton,
	blogs = [],
	i18n,
}) => {
	return (
		<div
			id={id}
			className={
				className ||
				cn(
					'inspiration-cta__container relative z-[1] bg-light',
					...classNames,
				)
			}
		>
			<div className="container px-4 py-8 md:px-8 lg:py-[120px]">
				{(title || ctaButton) && (
					<div className="xs:flex xs:items-center xs:justify-between xs:gap-8">
						{title && (
							<h2 className="inspiration-cta__title h2-large mb-4 xs:mb-0">
								{title}
							</h2>
						)}
						{ctaButton && <Button {...ctaButton} />}
					</div>
				)}
				{blogs?.length > 0 && (
					<div className="inspiration-cta__blogs mt-8 grid gap-8 md:grid-cols-[minmax(0,319px)_minmax(0,437px)_minmax(0,556px)] lg:mt-20">
						{blogs.map((blog, index) => (
							<div key={index} className="inspiration-cta__blog">
								{blog.blogLink && (
									<a
										href={blog.blogLink.href}
										title={blog.blogTitle}
										className="group flex flex-col no-underline"
									>
										<div className="md:h-[240px] lg:h-[437px]">
											<Image
												{...blog.blogImage}
												className="aspect-[4/3] h-full w-full max-w-full rounded-md object-cover object-center transition-transform duration-300 ease-in-out group-hover:scale-[1.05] group-focus:scale-[1.05] xs:aspect-auto"
											/>
										</div>
										<h3 className="my-4">
											{blog.blogTitle}
										</h3>
									</a>
								)}
								{blog.blogCategories.length > 0 && (
									<>
										<strong className="block w-full text-base text-medium">
											{i18n?.featuredFlooring ??
												'Featured Flooring'}
										</strong>
										<div className="flex flex-wrap items-start">
											{blog.blogCategories.map(
												(category, index) => (
													<Button
														key={index}
														link={category}
														buttonType="Medium"
														size="underline"
														classNames={[
															'!text-medium',
															'text-base',
															'[&+a]:before:w-[2px]',
															'[&+a]:before:h-[2px]',
															'[&+a]:before:bg-primary',
															'[&+a]:before:rounded-full',
															'[&+a]:before:mx-2',
															'[&+a]:before:block',
															'underline',
															'hover:no-underline',
															'focus:no-underline',
															'[&>span]:border-b-0',
														]}
													/>
												),
											)}
										</div>
									</>
								)}
							</div>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

export default InspirationCTA;
