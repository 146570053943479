import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';

export interface IPriceRangeIndicatorProps extends IBaseElementProps {
	priceRange?: string;
}

const PriceRangeIndicator: React.FC<IPriceRangeIndicatorProps> = ({
	priceRange,
	className,
	classNames = [],
}) => {
	// split the price range characters into an array
	const dollarSigns = priceRange.toString().split('');

	const indicators = dollarSigns.map((value, index) => (
		<span
			key={index}
			className="price-indicator rounded-[32px] border border-tertiary px-[5.5px] py-[1px] text-xs text-primary"
		>
			$
		</span>
	));

	return (
		Array.isArray(indicators) &&
		indicators.length > 0 && (
			<div
				className={
					className ||
					cn('price-range-indicator flex gap-[2px]', ...classNames)
				}
			>
				{indicators}
			</div>
		)
	);
};

export default PriceRangeIndicator;
