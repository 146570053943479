import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { colorPalette } from '../../../tailwind-colors';

export interface IContainerAccentProps extends IBaseElementProps {
	strokeColor?: 'primary' | 'primary-medium' | 'secondary' | 'white';
}

/**
 * ContainerAccent
 * @block
 */
const ContainerAccent: React.FC<IContainerAccentProps> = ({
	id,
	className,
	strokeColor = 'primary-medium',
}) => {
	const generatedStrokeColor = colorPalette[strokeColor].DEFAULT
		? colorPalette[strokeColor].DEFAULT
		: colorPalette.primary.DEFAULT;
	return (
		<svg
			className={className}
			id={id}
			width="654"
			height="327"
			viewBox="0 0 654 327"
			fill="none"
		>
			<path
				d="M577.297 76.6904L327.137 326.85"
				stroke={generatedStrokeColor}
				stroke-miterlimit="10"
			/>
			<path
				d="M76.9807 76.6904L327.141 326.85"
				stroke={generatedStrokeColor}
				stroke-miterlimit="10"
			/>
			<path
				d="M462.527 0L327.137 326.85"
				stroke={generatedStrokeColor}
				stroke-miterlimit="10"
			/>
			<path
				d="M0.290649 191.46L327.141 326.85"
				stroke={generatedStrokeColor}
				stroke-miterlimit="10"
			/>
			<path
				d="M327.138 326.85L653.988 191.46"
				stroke={generatedStrokeColor}
				stroke-miterlimit="10"
			/>
		</svg>
	);
};

export default ContainerAccent;
