import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Button, { ILink } from 'tbk-components/src/components/Button';
import { twMerge } from 'tailwind-merge';

export interface SocialPlatform {
	name?: string;
	url?: ILink;
}

export interface ISocialIconsProps extends IBaseElementProps {
	platforms: SocialPlatform[];
	ulClassNames?: string[];
	iconLinkClassNames?: string[];
}

const SocialIcons: React.FC<ISocialIconsProps> = ({
	id,
	className,
	classNames = [],
	platforms,
	ulClassNames = [],
	iconLinkClassNames = [
		'text-primary',
		'hover:text-secondary',
		'focus:text-secondary',
	],
}) => {
	return (
		<>
			{platforms?.length > 0 && (
				<div
					id={id}
					className={
						className ||
						cn('social-icons__container', ...classNames)
					}
				>
					<ul
						className={twMerge(
							'flex items-center justify-center gap-3',
							...ulClassNames,
						)}
					>
						{platforms.map((platform, index) => {
							return (
								platform.url?.href && (
									<li key={index}>
										<Button
											{...platform.url}
											aria-label={platform.name}
											icon={platform.name.toLowerCase()}
											className={cn(
												'transition-color flex items-center justify-center rounded-md text-[28px] duration-300 ease-in-out',
												...iconLinkClassNames,
											)}
										/>
									</li>
								)
							);
						})}
					</ul>
				</div>
			)}
		</>
	);
};

export default SocialIcons;
